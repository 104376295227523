import CompanyDto from '../dto/CompanyDto';
import CompanyUpdateDto from '../dto/CompanyUpdateDto';
import CompanyUploadPictureDto from '../dto/CompanyUploadPictureDto';
import CompanyForm from '../forms/CompanyForm';

export enum ActionCompanyType {
    COMPANY_FORM_NEW = 'COMPANY_FORM_NEW',
    COMPANY_FORM_UPDATE = 'COMPANY_FORM_UPDATE',
    COMPANY_FORM_SEND_VALUES = 'COMPANY_FORM_SEND_VALUES', 
    COMPANY_ERROR_REQUIRED = 'COMPANY_ERROR_REQUIRED',
    COMPANY_LOADING = 'COMPANY_LOADING',
    COMPANY_SUCCESS = 'COMPANY_SUCCESS',
    COMPANY_ERROR = 'COMPANY_ERROR',
    COMPANY_ADD_LOADING = 'COMPANY_ADD_LOADING',
    COMPANY_ADD_SUCCESS = 'COMPANY_ADD_SUCCESS',
    COMPANY_ADD_ERROR = 'COMPANY_ADD_ERROR',
    COMPANY_EDIT_LOADING = 'COMPANY_EDIT_LOADING',
    COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS',
    COMPANY_EDIT_ERROR = 'COMPANY_EDIT_ERROR',
    COMPANY_DELETE_LOADING = 'COMPANY_DELETE_LOADING',
    COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS',
    COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR',
    COMPANY_CHANGE_SEARCH_TABLE = 'COMPANY_CHANGE_SEARCH_TABLE',
    COMPANY_CHANGE_PAGE_TABLE = 'COMPANY_CHANGE_PAGE_TABLE',
    COMPANY_CHANGE_PAGE_SIZE = 'COMPANY_CHANGE_PAGE_SIZE',
    COMPANY_UPLOAD_PICTURE_LOADING = 'COMPANY_UPLOAD_PICTURE_LOADING',
    COMPANY_UPLOAD_PICTURE_SUCCESS = 'COMPANY_UPLOAD_PICTURE_SUCCESS',
    COMPANY_UPLOAD_PICTURE_ERROR = 'COMPANY_UPLOAD_PICTURE_ERROR',
    COMPANY_SEND_IMAGE_VALUES = 'COMPANY_SEND_IMAGE_VALUES',
    COMPANY_CHANGE_FILTER = 'COMPANY_CHANGE_FILTER'
}

export interface actionCompanySendValues {
    type: ActionCompanyType.COMPANY_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionCompanySendImageValues {
    type: ActionCompanyType.COMPANY_SEND_IMAGE_VALUES,
    value: CompanyUploadPictureDto | null
}

export interface actionCompanyErrorRequired {
    type: ActionCompanyType.COMPANY_ERROR_REQUIRED,
    value: string
}

export interface actionCompanyFormNew {
    type: ActionCompanyType.COMPANY_FORM_NEW    
}

export interface actionCompanyFormUpdate {
    type: ActionCompanyType.COMPANY_FORM_UPDATE,
    value: CompanyForm  
}

export interface actionCompanyLoading {
    type: ActionCompanyType.COMPANY_LOADING,
    value: {reset: boolean  }
}
export interface actionCompanySuccess {
    type: ActionCompanyType.COMPANY_SUCCESS,
    value: {data: CompanyDto[], reset: boolean}
}
export interface actionCompanyError {
    type: ActionCompanyType.COMPANY_ERROR   
}

export interface actionCompanyAddLoading {
    type: ActionCompanyType.COMPANY_ADD_LOADING,
    value: {company: CompanyUpdateDto,
            image: CompanyUploadPictureDto | null}
}
export interface actionCompanyAddSuccess {
    type: ActionCompanyType.COMPANY_ADD_SUCCESS,
    value: boolean
}
    
export interface actionCompanyAddError {
    type: ActionCompanyType.COMPANY_ADD_ERROR   
}

export interface actionCompanyEditLoading {
    type: ActionCompanyType.COMPANY_EDIT_LOADING,
    value: CompanyUpdateDto
}
export interface actionCompanyEditSuccess {
    type: ActionCompanyType.COMPANY_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionCompanyEditError {
    type: ActionCompanyType.COMPANY_EDIT_ERROR   
}

export interface actionCompanyDeleteLoading {
    type: ActionCompanyType.COMPANY_DELETE_LOADING,
    value: number
}
export interface actionCompanyDeleteSuccess {
    type: ActionCompanyType.COMPANY_DELETE_SUCCESS,
    value: boolean
}
    
export interface actionCompanyDeleteError {
    type: ActionCompanyType.COMPANY_DELETE_ERROR   
}

export interface actionCompanyGroupsChangeSerachTable {
    type: ActionCompanyType.COMPANY_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionCompanyGroupsChangePageSizeTable {
    type: ActionCompanyType.COMPANY_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionCompanyGroupsChangePageTable {
    type: ActionCompanyType.COMPANY_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionCompanyUploadPictureLoading {
    type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_LOADING,
    value: CompanyUploadPictureDto
}
export interface actionCompanyUploadPictureSuccess {
    type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_SUCCESS,
    value: boolean
}
    
export interface actionCompanyUploadPictureError {
    type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_ERROR   
}

export interface actionCompanyChangeFilterTable {
    type: ActionCompanyType.COMPANY_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionCompanyActions = actionCompanyErrorRequired |actionCompanySendValues | actionCompanySendImageValues |
                            actionCompanyFormUpdate | actionCompanyFormNew |
                            actionCompanyLoading | actionCompanySuccess | actionCompanyError |
                            actionCompanyAddLoading | actionCompanyAddSuccess | actionCompanyAddError |
                            actionCompanyEditLoading | actionCompanyEditSuccess | actionCompanyEditError |
                            actionCompanyDeleteLoading | actionCompanyDeleteSuccess | actionCompanyDeleteError |
                            actionCompanyGroupsChangeSerachTable | actionCompanyGroupsChangePageSizeTable | actionCompanyGroupsChangePageTable |
                            actionCompanyUploadPictureLoading | actionCompanyUploadPictureSuccess | actionCompanyUploadPictureError |
                            actionCompanyChangeFilterTable;