import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { convertDteIso } from '../../utils/utils';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { useEffect, useState } from 'react';
import { ActionPublicJobType } from '../../models/actions/typePublicJobActions';
import { TypeActions } from '../../models/actions/typeActions';
import { AttachedFiles, QuestionsResponse, WebProfiles } from '../../models/dto/ApplicationPublicJobDto';
import ApplyQuestions from './ApplyQuestions';
import './PublicJob.scss';
import ApplyProfile from './ApplyProfile';
import ApplyJobFinish from './ApplyJobFinish';

function ApplyJob() {  
  const { t } = useTranslation();
  const state:TypePublicJobState = useSelector((state:TypeState) => state.publicJobReducer);
  const dispatch = useDispatch();
  const [viewQuestions, setViewQuestions] = useState(false)
  const [viewProfile, setViewProfile] = useState(false ) 
  const [questions, setQuestions] = useState<QuestionsResponse[]>([]) 
  
  useEffect(()=>{
    if (state.publicJob.data) {
      dispatch<TypeActions>({type: ActionPublicJobType.APPLY_QUESTIONS_LOADING, value: state.publicJob.data.id});
    }    
  }, [])

  useEffect(()=>{
    setViewQuestions(state.applyQuestions.data.length > 0)
    setViewProfile(state.applyQuestions.data.length === 0)
  }, [state.applyQuestions.data])

  const endQuestions = (questions: QuestionsResponse[])=>{    
    setViewQuestions(false);
    setViewProfile(true)
    setQuestions(questions)
  }
  const dataProfile = (data: {  webProfiles: WebProfiles[],
                                name: string,
                                surname: string,
                                phone: string,
                                attachedFiles: AttachedFiles[]
                              })=> {

    if (state.publicJob.data) {
      const applicationData = {jobId: state.publicJob.data?.id, linkId: state.updateLinkNetworkerId.newLink, ...data, questions}
      dispatch<TypeActions>({type: ActionPublicJobType.APPLY_JOB_LOADING, value: applicationData});
    } 
  }
  
  return <>{state.publicJob.data !== null && !state.applyJob.ok && <div className='swissinx-job-container-column__job-container'>
                <div>                   
                    <h2 className='swissinx-job-container-column__title'>{state.publicJob.data.jobTitle}</h2>
                    <p className='swissinx-job-container-column__subtitle'>{t('publicJob.swisslinx')}</p>
                    <p className='swissinx-job__date'>{t('publicJob.posted')} {convertDteIso(state.publicJob.data.postedOnDate)}</p>                   
                    <div className='swissinx-job-skills-container'>
                        {state.publicJob.data.skills.map(skill => <div key={skill} className='swissinx-job__skill'>
                            {skill}
                        </div>)}
                    </div>
                </div>
                {viewQuestions && <ApplyQuestions endQuestions={endQuestions} />}
                {viewProfile && <ApplyProfile dataProfile={dataProfile}/>}           
            </div>}
            {state.applyJob.ok && <ApplyJobFinish />}
  </>
}

export default ApplyJob;