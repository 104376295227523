import typePaymentsState from '../../models/initialState/typePaymentsState';
import { DEFAULTITEMSPERPAGE } from '../../utils/utils';

export const initialPaymentsState: typePaymentsState = {
      loading: false,
      error: false,
      messageError: '',
      data: [],      
      filters: {orderBy: 1, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false
}