
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ClientDto from '../../models/dto/ClientDto';
import TypeClientsState from '../../models/initialState/typeClientsState';
import TypeState from '../../models/initialState/typeState';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';

interface Props {
    edit(event: any, index:ClientDto): void,
    remove(event: any, index:ClientDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function ClientList(props: Props) {
  const { t } = useTranslation();
  const state:TypeClientsState = useSelector((state:TypeState) => state.clientsReducer);
  const stateCompanyGroups:TypeCompanyGroupsState = useSelector((state:TypeState) => state.companyGroupsReducer);

  const headers = [{title: t('admin.clients.tableHeader1'), field: 'name', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: '' , },                 
                  {title: t('admin.clients.tableHeader2'), field: 'companyGroupId', render: (rowData:ClientDto) => {
                      const companyGroup = stateCompanyGroups.data.find(companyGroup => companyGroup.companyGroupId === rowData.companyGroupId);
                      return companyGroup !== undefined ? companyGroup.name : '';
                    }, defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: '' , }
  ];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.clients.listTitle')}</h2>
            <Table<ClientDto> 
              data={state.clientsData} 
              headers={headers} 
              actions={actions} 
              new={true} 
              initialPage={state.initialPage}
              serchText={state.searchText}
              pageSize={state.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}/>
        </div>;
  }

export default ClientList;