import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';

import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import NetworkerRequestListDto from '../models/dto/NetworkerRequestListDto';
import { actionNetworkersRequestListChangeStatus, actionNetworkersRequestListLoading, ActionNetworkersRequestListType } from '../models/actions/typeNetworkersRequestListActions';

const baseUrlApi:string = getBaseUrlApi();

const urlNetworkersRequestList = baseUrlApi + '/api/TA/getRequestListToBecomeAmbassadorAtCompany';
const urlNetworkersRequestListChangeStatus = baseUrlApi + '/api/TA/responseToBecomeAmbassadorAtCompany';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* networkerRequestList(action:actionNetworkersRequestListLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersRequestList + '?companyId=' + state.companySelected.company.id, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:NetworkerRequestListDto[] = yield call([response, 'json']);                
                yield put<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_SUCCESS, value: {data: data, reset: action.value.reset}});
            } else {                
                yield call(checkError, response.status, {type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR, value: 'Error Networker Request List'});           
            }    
        }                    
             
    } catch (e) {
        console.log(e);        
        yield put<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR, value: 'Error Networker Request List'});
    }
}

function* networkerRequestListChangeStaus(action:actionNetworkersRequestListChangeStatus) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersRequestListChangeStatus + '?requestId=' + action.value.requestId + '&companyId=' + action.value.companyId + '&response=' + action.value.response, getFetchParams('POST', null, state.token));
            if (response.ok) {                            
                yield put<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_LOADING, value: {reset: false}});
            } else {                
                yield call(checkError, response.status, {type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR, value: 'Error Networker Request List'});           
            }    
        }                    
             
    } catch (e) {
        console.log(e);        
        yield put<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR, value: 'Error Networker Request List'});
    }
}

function* networkersRequestListWhatcher() {
    yield takeLatest(ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_LOADING, networkerRequestList);
}

function* networkerRequestListChangeStausWhatcher() {
    yield takeLatest(ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_STATUS, networkerRequestListChangeStaus);
}

export default function* rootNetworkersRequestListSaga() {
    yield all([
        networkersRequestListWhatcher(),
        networkerRequestListChangeStausWhatcher()    
    ])
}