import React,{useState, useEffect, useMemo} from 'react';
import TextField from '@mui/material/TextField';
import { LANGUAGE_NUMBER_FORMAT, MILES_SEPARATOR } from '../../../constanst/GlobalConstants';
import { getLabelFormatted } from '../../../utils/utils';

// CSS asociado al componente
//import './FieldText.scss';

/**
 
 * Componente funcional que recoge un campo de texto y lo envía al padre. 
 * @param name              - atributo name del input
 * @param type              - atributo type del input. (text/password)
 * @param placeholder       - atributo placeholder del input
 * @param label             - texto a mostrar en la label
 * @param error             - boolean, determina si el campo tiene un error. Se utiliza por el padre para indicar que hay un error. Ej. Al enviar un formulario
 *                              y detectar en el padre que el campo está vacío y es obligatorio
 * @param requiredError    - texto con el error a mostrar en el caso de error por campo requerido
 * @param value             - valor por defecto 
 * @param required          - boolean, indica si el campo es obligatorio
 * @param disabled          - boolean, indica que el campo no se puede modificar
 * @param validators       - es array opcional, donde se pueden pasar funciones para validar el campo. Las funciones validadoras solo pueden devolver un valor true/false
 *                                  En el array también hay que indicar para cada validador el mensaje a mostrar en caso de error.
 *                                  validators = {[
                                        {validador: validarEmail, mensajeError: 'Formato email no valido'}                                                      
                                    ]}
 * @param onChange         - funcion que devuelve el valor al padre
 */



interface newState {
    value: string,
    errorRequired: boolean,
    errorFormat: boolean
}

export interface dataState {
    name: string,
    value: string,
    error: {
        required: boolean,
        format: boolean
    }
}

interface validatorObject {
    validator: (n:string) => boolean,
    messageError: string 
}

interface Props {
    name: string,    
    type?: string,
    placeholder?: string,
    label?: string,
    error: boolean,    
    value: string,
    formatNumber?: boolean,
    required?: boolean,
    disabled?: boolean,
    requiredError?: string,
    fullWidth: boolean,
    maxlength?: number,
    isMaxlength?: boolean,    
    validators?: validatorObject[],
    onChange: (dataState:dataState)=>{} | void 
}

let newFirstState:newState = {
    value: '',
    errorRequired: false,
    errorFormat: false       
}

const FieldTextMui: React.FC<Props> = ({
    name = '',    
    type='text',
    placeholder = '',
    label = '',
    error = false,    
    value = '',
    required = false,
    disabled = false,
    requiredError = '',
    fullWidth = true,
    maxlength = 30,
    isMaxlength = false,    
    validators = [],
    formatNumber= false,    
    onChange = ()=>{} 
   
}) => {  
    const [messageError, setMessageError] = useState('');
    const [state, setState] = useState(newFirstState);
        
    const validate = (value: string):void => {    
        const newState = JSON.parse(JSON.stringify(state));
            newState.value = value ? value.replace(MILES_SEPARATOR, '') : '';            
            newState.errorFormat = false;                
            if (newState.value !== '') {
                newState.errorRequired = false;
                if (validators.length > 0) {
                    validators.map(elem =>{//Se aplican los validadores
                        if (!newState.errorFormat) {
                            newState.errorFormat = elem.validator(state.value);
                            if (newState.errorFormat) {
                                setMessageError(elem.messageError);                                    
                            } 
                        }        
                    }) 
                }                     
            } else if (required) {                           
                newState.errorRequired = true;
                setMessageError(requiredError);                   
            }
            setState(newState);          
            onChange({name, value: newState.value, error: {required: newState.errorRequired, format: newState.errorFormat}});
    }

    useEffect(() => {
        if (error || value!=='') {
            validate(value);
        }  
        if (value === '') {
            setState(JSON.parse(JSON.stringify(newFirstState)));
        }         
    }, [error, value]);
   
    const formatValue = useMemo(()=>(formatNumber && value && /^\d*\.?\d*$/.test(value) && value.indexOf('.')!== value.length-1  ) ? parseFloat(value).toLocaleString(LANGUAGE_NUMBER_FORMAT, {
        maximumFractionDigits: 2}) : value, [value, formatNumber])
return <>
    <div className="react-fieldText">   
        <TextField                    
          error={error}
          fullWidth={fullWidth}
          value={formatValue}        
          id={name}
          label={getLabelFormatted(label, required) }
          disabled={disabled}        
          helperText={error && messageError}
          onChange={(e:React.ChangeEvent<HTMLInputElement>)=>validate(e.currentTarget.value)}          
        />                    
    </div>
    </>
}

export default FieldTextMui;