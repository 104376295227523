import { useTranslation } from 'react-i18next';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import TableTemplates from '../table/TableTemplates';
import { Question } from '../../models/dto/JobTemplateDto';
import FiledError from '../Form/common/FieldError/FiledError';

interface Props {
  back(): void,
  update(): void,
  add: boolean,
  data: Question[],
  name: string,
  setData(rows: Question[]): void,
  setName(name: string): void
}

function TemplateEdit(props: Props) {
  const { t } = useTranslation();

  function onSubmit() {
    if (props.name !== '' && props.data.length > 0) {
      props.update();
    }
  }

  const headersTemplate = [
    { title: t('admin.jobs.questionCategory'), field: 'questionCategory', validate: (rowData: Question) => rowData.questionCategory !== undefined, lookup: { 0: 'YOU', 1: 'SKILL', 2: 'PROFILE' } },
    { title: t('admin.jobs.questionText'), field: 'questionText', validate: (rowData: Question) => rowData.questionText !== undefined && rowData.questionText !== '' },
    { title: t('admin.jobs.questionType'), field: 'questionType', validate: (rowData: Question) => rowData.questionType !== undefined, lookup: { 0: 'Text', 1: 'FileUpload', 2: 'YesNo', 3: 'Number', 4: 'Ratio' } },
    { title: t('admin.jobs.questionOrder'), field: 'order', validate: (rowData: Question) => rowData.order !== undefined && rowData.order > 0, type: 'numeric' }
  ];

  return <div className='swissinx-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
      {props.add ? t('admin.templates.createTitle') : t('admin.templates.editTitle')}
    </h2>
    <div className="swissinx-container-fields-form">
      <h3>{t("admin.templates.mainInformation")}</h3>
      <div className="swissinx-container-fields">
        <div className='swissinx-field-container'>
          <div className="swissinx-field field-name">
            <FieldTextMui
              placeholder=''
              label={t('admin.templates.label_name')}
              name='name'
              required={true}
              requiredError={t('admin.templates.name_error_required')}
              value={props.name}
              fullWidth={true}
              error={props.name === ''}
              onChange={({ name, value, error }) => props.setName(value)}
            />
          </div>
        </div>
      </div>
      <h3>{t("admin.templates.questions")}</h3>
      <TableTemplates data={props.data} headers={headersTemplate} setData={props.setData} />

      {props.data.length === 0 && <FiledError messageError={t('admin.templates.error_questionRequired')}></FiledError>}
    </div>
    <div className='swissinx-container-buttons'>
      <button className="swissinx-button-back" type="button" onClick={props.back}>{t('admin.templates.text_button_back')}</button>
      <button className="swissinx-button-submit" type="button" onClick={onSubmit}>{props.add ? t('admin.templates.text_button_submit_create') : t('admin.templates.text_button_submit_edit')}</button>
    </div>
  </div>
}

export default TemplateEdit;