import { all } from 'redux-saga/effects';
import rootAmbassadorsSaga from './sagaAmbassadors';
import rootClientsSaga from './sagaClients';
import rootCompanySaga from './sagaCompany';
import rootCompanyGroupsSaga from './sagaCompanyGroups';
import rootDashboardSaga from './sagaDashboard';
import rootFeeSaga from './sagaFee';
import rootJobsSaga from './sagaJobs';
import rootLanguagesSaga from './sagaLenguages';
import rootLocationSaga from './sagaLocation';
import rootLoginSaga from './sagaLogin';
import rootMarketsSaga from './sagaMarkets';
import rootNetworkersSaga from './sagaNetworkers';
import rootNetworkersRequestListSaga from './sagaNetworkersRequestList';
import rootPaymentsSaga from './sagaPayments';
import rootPublicJobSaga from './sagaPublicJob';
import rootSkillGroupSaga from './sagaSkillGroup';
import rootSkillsSaga from './sagaSkills';
import rootTemplatesSaga from './sagaTemplates';

export default function* rootSaga() {
    yield all([        
        rootLoginSaga(),
        rootCompanyGroupsSaga(),
        rootJobsSaga(),
        rootLocationSaga(),
        rootClientsSaga(),
        rootNetworkersSaga(),
        rootPublicJobSaga(),
        rootAmbassadorsSaga(),
        rootCompanySaga(),
        rootTemplatesSaga(),
        rootNetworkersRequestListSaga(),
        rootSkillsSaga(),
        rootMarketsSaga(),
        rootLanguagesSaga(),
        rootDashboardSaga(),
        rootFeeSaga(),
        rootSkillGroupSaga(),
        rootPaymentsSaga()
    ])
}