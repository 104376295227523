
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Form from '../Form/Form/Form';
import TypeState from '../../models/initialState/typeState';
import SkillGroupDto from '../../models/dto/SkillGroupDto';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import SkillGroupForm from '../../models/forms/SkillGroupForm';
import typeSkillGroupState from '../../models/initialState/typeSkillGroupState';

interface Props {
    data: SkillGroupDto,
    back(): void,
    update(skill: SkillGroupDto): void,
    add: boolean
}

interface dataState {
    name: string,
    value: string,
    error: {
        required: boolean,
        format: boolean
    }
}

function SkillGroupEdit(props: Props) {
    const state: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);
    const skillGroupForm: SkillGroupForm = state.skillGroupForm;
    const name = skillGroupForm.name;
    const [stateForm, setStateForm] = useState({ ...skillGroupForm, name: { ...name, value: props.data.name } });
    const { t } = useTranslation();


    const change = (dataState: dataState) => {
        const name = stateForm.name;
        setStateForm({ ...stateForm, name: { ...name, value: dataState.value, error: dataState.error } });
    }

    const errorRequired = (key: string) => {
        setStateForm({ ...stateForm, name: { ...name, value: '', error: { required: true, format: false } } });
    }

    const onSubmit = () => {
        props.update({ id: props.data.id, name: stateForm.name.value });
    }

    return <div className='swissinx-container'>
        <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
            {props.add ? t('admin.skillGroup.title_page_new') : t('admin.skillGroup.title_page_edit')}
        </h2>
        {<Form contex={stateForm} errorRequerired={(key) => errorRequired(key)} onSubmit={onSubmit}
            fields={<div className='swissinx-container-fields-form'>
                <div className="swissinx-container-fields">
                    <div className="swissinx-field-container ">
                        <div className="swissinx-field field-name">
                            <FieldTextMui
                                placeholder=''
                                label={t('admin.skills.name')}
                                name='name'
                                required={true}
                                requiredError={t('admin.skills.nameErrorRequired')}
                                value={stateForm.name.value}
                                fullWidth={true}
                                error={stateForm.name.error.required}
                                onChange={change}
                            />
                        </div>
                    </div>
                </div>
                <div className='swissinx-container-buttons'>
                    <button type='button' className="swissinx-button-back" onClick={props.back}>{t('admin.skills.cancelButtonText')}</button>
                    <button type='submit' className="swissinx-button-submit">{props.add ? t('admin.skills.addButtonText') : t('admin.skills.editButtonText')}</button>
                </div>
            </div>
            }>
        </Form>
        }

    </div>;
}

export default SkillGroupEdit;