
import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { ActionLanguagesType } from '../../models/actions/typeLanguagesActions';
import { useSelector, useDispatch } from 'react-redux';
import LanguageDto from '../../models/dto/LanguageDto'; 
import LanguagesList from './LanguagesList';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
import LanguagesEdit from './LanguagesEdit';
//import './Languages.scss';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Languages(props: Props) {
  const [editLanguage, setEditLanguage] = useState(false);
  const [deleteLanguage, setDeleteLanguage] = useState(false);
  const [addLanguage, setAddLanguage] = useState(false);  
  const [languageEdit, setLanguageEdit] = useState({languageId: 0, name: ''});
  const [languageRemove, setLanguageRemove] = useState({languageId: 0, name: ''});
  
  const { t } = useTranslation();
  const state:TypeLanguagesState = useSelector((state:TypeState) => state.languagesReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: true}});
      setEditLanguage(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: true}}); 
    }          
  }, []);  

  const edit = (event:SyntheticEvent, index:LanguageDto) => {
    setLanguageEdit(index);
    setEditLanguage(true);
    setAddLanguage(false);
  }

  const removeConfirmation = (event:SyntheticEvent, language:LanguageDto) => {
    setLanguageRemove(language);
    setDeleteLanguage(true);  
  }

  const newRegister = () => {
    setLanguageEdit({languageId: 0, name: ''});
    setEditLanguage(true);
    setAddLanguage(true);
  }  

  const back = () => {
    setEditLanguage(false);
  }

  const update = (language: LanguageDto) => {
    setEditLanguage(false);
    if (addLanguage) {
      dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_ADD_LOADING, value: language.name});      
    } else {
      dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_EDIT_LOADING, value: language});
    }
    //setEditLanguage(false);
  }  

  const remove = () => {
    dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_DELETE_LOADING, value: languageRemove.languageId});
    setDeleteLanguage(false);
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editLanguage && <LanguagesList 
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.loading && !state.error && editLanguage && <LanguagesEdit data={languageEdit} update={update} back={back} add={addLanguage}/>}
           
            {deleteLanguage && <ModalConfirmDelete show={deleteLanguage} title={t('admin.language.modal_delete_title')} back={()=>setDeleteLanguage(false)} confirm={remove} textButtonBack={t('admin.language.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.language.modal_delete_buttonDelete')} />}
            
            {state.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={state.messageError !== '' ? state.messageError : t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.language.editMessage')}
              addMessage={t('admin.language.addMessage')}
              delteMessage={t('admin.language.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionLanguagesType.LANGUAGES_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Languages;
