import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { convertDteIso } from '../../utils/utils';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';

import JobsResponseDto from '../../models/dto/JobsResponseDto';
import DashboardJobActivityTree from './DashboardJobActivityTree';
import { useState } from 'react';

interface Props {
  job: JobsResponseDto | null
}

function DashboardJobActivity(props: Props) {
  const [showPushTree, setPushTree] = useState(true);
  const [showPikedUpsTree, setPikedUpsTree] = useState(true);

  const { t } = useTranslation();
  const state: TypeJobsState = useSelector((state: TypeState) => state.jobsReducer);
  return <div className='swissinx-job-activity-container'>

    <h3 onClick={() => setPushTree(!showPushTree)}>{t('admin.jobs.pushesTitleTree')} ({state.jobActivity.data && state.jobActivity.data.pushes.length})
      <FontAwesomeIcon icon={showPushTree ? faAngleUp : faAngleDown} />
    </h3>
    {showPushTree && state.jobActivity.data && state.jobActivity.data.pushes.map(push => <div key={push.pushId} className='swissinx-push-tree-container'>
      <div className='swissinx-push-tree-title'>
        <p>{convertDteIso(push.date)}</p>
        <p className={`${push.active ? 'active' : 'expired'}`}>{push.active ? 'Active' : 'Expired'}</p>
      </div>
      <DashboardJobActivityTree contacts={push.contacts} type='push' />

    </div>)}
    <h3 onClick={() => setPikedUpsTree(!showPikedUpsTree)}>{t('admin.jobs.pikedUpsTitleTree')} ({state.jobActivity.data && state.jobActivity.data.pickedUp.contacts.length})
      <FontAwesomeIcon icon={showPikedUpsTree ? faAngleUp : faAngleDown} /> </h3>
    {showPikedUpsTree && state.jobActivity.data && state.jobActivity.data.pickedUp && state.jobActivity.data.pickedUp && <div className='swissinx-push-tree-container'>
      <DashboardJobActivityTree contacts={state.jobActivity.data.pickedUp.contacts} type='pikedup' />
    </div>}
  </div>;
}

export default DashboardJobActivity;