
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import { ReactNode } from 'react';
import Info from '@material-ui/icons/Info';

import './ModalInfo.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
  };

interface Props {
    title: string,    
    textButtonConfirm: string,
    show: boolean,
    data?:ReactNode,   
    confirm():void  
}


function ModalInfo(props: Props) {
  

  return <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.show}
            onClose={props.confirm}
        >               
                <Box sx={style}>
                <div className='swissin-modal-info'>
                <Info />
                <h2>{props.title}</h2>                
                {props.data !== undefined && <div className='swissin-modal-info-data'>
                  {props.data}
                </div>}
                <div className='swissin-modal-info-buttons'>
                    <button onClick={props.confirm}>{props.textButtonConfirm}</button>
                </div>                    
            </div>
            </Box>
        </Modal>           
       
  }

export default ModalInfo;
