import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChangeEvent, useState } from 'react';
import PublicJobOfferRefer from './PublicJobOfferRefer';
import ContentEditable, {ContentEditableEvent} from 'react-contenteditable';
import FiledError from '../Form/common/FieldError/FiledError';
import './EndSMSRegistration.scss';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import TypeState from '../../models/initialState/typeState';
import GooglePlay from '../../assest/GooglePlay.svg';
import AppStore from '../../assest/AppStore.svg';
import ModalInfo from '../commons/ModalInfo';
import Info from '@material-ui/icons/Info';
import DownloadApp from './DownloadApp';



interface Props {
    type: number,
    referBy(option: number, textDetails: string, referralName: string):void     
}

function EndSMSRegistration(props: Props) {
  const { t } = useTranslation();  
  const [contetDetails, setContenDetails] = useState<string>(t('publicJob.defaultMessage'));
  const [referralName, setReferralName] = useState('');
  const [showModalInfo, setShowModalInfo] = useState(false);
  /* const [referralNameError, setReferralNameError] = useState(false); */
  const state:TypePublicJobState = useSelector((state:TypeState) => state.publicJobReducer);

  const onChangeDetail = (event: ContentEditableEvent)=> {    
    setContenDetails(event.target.value);
  }  

  const getReferralDateTime = ()=> {
    const date = new Date();
    return (date.toLocaleDateString() + ' - ' + date.toLocaleTimeString())
  }

  const sendSMSRegistrationCode = (type: number) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(contetDetails, 'text/html');
      const body = htmlDoc.querySelector('body');
      let textDetails = t('publicJob.referralDetails');
      if (body !== null && body.textContent !== null) {
        textDetails = body.textContent;
      }
      //Si se deja el campo vacío se envía feha - hora. Antes era obligatorio y salía mensaje de error al dejar el campo vacío
      const referral = referralName !== '' ? referralName : getReferralDateTime();
      props.referBy(type, textDetails, referral);
  }

  const onChangeReferralName = (event: ChangeEvent<HTMLInputElement> ) => {
    setReferralName(event.target.value);
    //setReferralNameError(event.target.value === '')
  }
  
  return <>
        
                {/* {state.publicJob.data !== null && <div className='swissinx-job-body-main'>                   
                    <p className='swissinx-job__title'>{state.publicJob.data.jobTitle}</p>
                    <p className='swissinx-job__subtitle'>{t('publicJob.swisslinx')}</p>
                    <p className='swissinx-job__date'>{t('publicJob.posted')} {convertDteIso(state.publicJob.data.postedOnDate)}</p>

                    <div className='swissinx-job-shares-container'>
                    <div className='swissinx-job__shares'>
                        <img src={share} alt=""></img>
                        <p>{state.publicJob.data.timesHasBeenShared} {t('publicJob.shares')}</p>
                    </div>
                    <div className='swissinx-job__fee'>
                        <img src={money} alt=""></img>
                        <p> {state.publicJob.data.fee} {state.publicJob.data.feeCurrency}</p>
                    </div>
                    </div>
              </div>} */}

              <h2 className='swissinx-job-container-column__title'>{t('publicJob.boardingComplete')}</h2>

              <h2 className='swissinx-job-container-column__subtitle'>{t('publicJob.referralDetails')}</h2>
              <p className='swissinx-job-container-column__description'>{t('publicJob.linkWarning')} {state.jobForwared.data.forwardTimeUsedLimit} {t('publicJob.linkWarning2')}</p>
                    <div className='swissinx-job-body__blockDetails'>                        
                        <ContentEditable                            
                            html={contetDetails}
                            disabled={false}
                            onChange={onChangeDetail}         
                            />
                        {!state.jobForwared.loading && !state.jobForwared.error && <p className='swissinx-job-body__linkDetails'>{state.jobForwared.data.sharedLink}</p>}
                        {state.jobForwared.loading && <p className='swissinx-job-body__linkDetails'>{t('publicJob.generatingLinkLoading')}</p>}
                        {state.jobForwared.error && <p className='swissinx-job-body__linkDetails'>{t('publicJob.generatingLinkError')}</p>}
                    </div>
                    <div className="swissinx-job-body-referal-container">
                      <input className="swissinx-job-body__referal" type="text" placeholder={t('publicJob.referalName')} value={referralName} onChange={onChangeReferralName}></input>                
                      <Info onClick={()=>setShowModalInfo(true)}/>
                    </div>
                  
                   {/* {referralNameError && <FiledError messageError={t('publicJob.referralNameErrorRequired')} />} */}
              
                <PublicJobOfferRefer sendSMSRegistrationCode={sendSMSRegistrationCode} />                        
                
                <DownloadApp />      
                          
            {state.jobForwaredUpdate.error && <FiledError messageError={t('publicJob.forwardUpdateError')} />}
            <ModalInfo show={showModalInfo} confirm={()=>setShowModalInfo(false)} title={''} data={<p>{t('publicJob.modalInfo')}</p>} textButtonConfirm='Ok' />          

        
  </>
}

export default EndSMSRegistration;