import PaymentDto from "../dto/PaymentDto"
import SkillDto from "../dto/SkillDto"
import { UpdateRewardDto } from "../dto/UpdateRewardDto"

export enum actionPaymentsType {    
    PAYMENTS_ERROR_REQUIRED = 'PAYMENTS_ERROR_REQUIRED',
    PAYMENTS_LOADING = 'PAYMENTS_LOADING',
    PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS',
    PAYMENTS_ERROR = 'PAYMENTS_ERROR',    
    PAYMENTS_EDIT_LOADING = 'PAYMENTS_EDIT_LOADING',
    PAYMENTS_EDIT_SUCCESS = 'PAYMENTS_EDIT_SUCCESS',
    PAYMENTS_EDIT_ERROR = 'PAYMENTS_EDIT_ERROR',    
    PAYMENTS_CHANGE_SEARCH_TABLE = 'PAYMENTS_CHANGE_SEARCH_TABLE',
    PAYMENTS_CHANGE_PAGE_TABLE = 'PAYMENTS_CHANGE_PAGE_TABLE',
    PAYMENTS_CHANGE_PAGE_SIZE = 'PAYMENTS_CHANGE_PAGE_SIZE',
    PAYMENTS_CHANGE_FILTER = 'PAYMENTS_CHANGE_FILTER'
}

export interface actionPaymentsErrorRequired {
    type: actionPaymentsType.PAYMENTS_ERROR_REQUIRED,
    value: string
}

export interface actionPaymentsLoading {
    type: actionPaymentsType.PAYMENTS_LOADING,
    value: number
}
export interface actionPaymentsSuccess {
    type: actionPaymentsType.PAYMENTS_SUCCESS,
    value: PaymentDto[]
}
export interface actionPaymentsError {
    type: actionPaymentsType.PAYMENTS_ERROR,
    value: string  
}
export interface actionPaymentsEditLoading {
    type: actionPaymentsType.PAYMENTS_EDIT_LOADING,
    value: UpdateRewardDto
}
export interface actionPaymentsEditSuccess {
    type: actionPaymentsType.PAYMENTS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionPaymentsEditError {
    type: actionPaymentsType.PAYMENTS_EDIT_ERROR,
    value: string  
}

export interface actionPaymentsChangeSerachTable {
    type: actionPaymentsType.PAYMENTS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionPaymentsChangePageSizeTable {
    type: actionPaymentsType.PAYMENTS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionPaymentsChangePageTable {
    type: actionPaymentsType.PAYMENTS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionPaymentsChangeFilterTable {
    type: actionPaymentsType.PAYMENTS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeactionPaymentsActions = actionPaymentsErrorRequired |  
                            actionPaymentsLoading | actionPaymentsSuccess | actionPaymentsError |                            
                            actionPaymentsEditLoading | actionPaymentsEditSuccess | actionPaymentsEditError |                            
                            actionPaymentsChangeSerachTable | actionPaymentsChangePageSizeTable | actionPaymentsChangePageTable |
                            actionPaymentsChangeFilterTable;