import ClientDto from '../dto/ClientDto';
import ClientForm from '../forms/ClientForm';

export enum ActionClientsType {
    
    CLIENTS_LOADING = 'CLIENTS_LOADING',
    CLIENTS_SUCCESS = 'CLIENTS_SUCCESS',
    CLIENTS_ERROR = 'CLIENTS_ERROR',
    CLIENTS_FORM_NEW = 'CLIENTS_FORM_NEW',
    CLIENTS_FORM_UPDATE = 'CLIENTS_FORM_UPDATE',
    CLIENTS_FORM_SEND_VALUES = 'CLIENTS_FORM_SEND_VALUES', 
    CLIENTS_ERROR_REQUIRED = 'CLIENTS_ERROR_REQUIRED',    
    CLIENTS_ADD_LOADING = 'CLIENTS_ADD_LOADING',
    CLIENTS_ADD_SUCCESS = 'CLIENTS_ADD_SUCCESS',
    CLIENTS_ADD_ERROR = 'CLIENTS_ADD_ERROR',
    CLIENTS_EDIT_LOADING = 'CLIENTS_EDIT_LOADING',
    CLIENTS_EDIT_SUCCESS = 'CLIENTS_EDIT_SUCCESS',
    CLIENTS_EDIT_ERROR = 'CLIENTS_EDIT_ERROR',
    CLIENTS_DELETE_LOADING = 'CLIENTS_DELETE_LOADING',
    CLIENTS_DELETE_SUCCESS = 'CLIENTS_DELETE_SUCCESS',
    CLIENTS_DELETE_ERROR = 'CLIENTS_DELETE_ERROR',
    CLIENTS_CHANGE_SEARCH_TABLE = 'CLIENTS_CHANGE_SEARCH_TABLE',
    CLIENTS_CHANGE_PAGE_TABLE = 'CLIENTS_CHANGE_PAGE_TABLE',
    CLIENTS_CHANGE_PAGE_SIZE = 'CLIENTS_CHANGE_PAGE_SIZE',
    CLIENTS_CHANGE_FILTER = 'CLIENTS_CHANGE_FILTER'
    
}

export interface actionClientsLoading {
    type: ActionClientsType.CLIENTS_LOADING,
    value: {reset: boolean}
}
export interface actionClientsSuccess {
    type: ActionClientsType.CLIENTS_SUCCESS,
    value: {data: ClientDto[], reset: boolean}
    
}
export interface actionClientsError {
    type: ActionClientsType.CLIENTS_ERROR   
}

export interface actionClientsSendValues {
    type: ActionClientsType.CLIENTS_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionClientsErrorRequired {
    type: ActionClientsType.CLIENTS_ERROR_REQUIRED,
    value: string
}

export interface actionClientsFormNew {
    type: ActionClientsType.CLIENTS_FORM_NEW    
}

export interface actionClientsFormUpdate {
    type: ActionClientsType.CLIENTS_FORM_UPDATE,
    value: ClientForm 
}

export interface actionClientsAddLoading {
    type: ActionClientsType.CLIENTS_ADD_LOADING,
    value: ClientDto
}
export interface actionClientsAddSuccess {
    type: ActionClientsType.CLIENTS_ADD_SUCCESS,
    value: boolean
}
    
export interface actionClientsAddError {
    type: ActionClientsType.CLIENTS_ADD_ERROR   
}

export interface actionClientsEditLoading {
    type: ActionClientsType.CLIENTS_EDIT_LOADING,
    value: ClientDto
}
export interface actionClientsEditSuccess {
    type: ActionClientsType.CLIENTS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionClientsEditError {
    type: ActionClientsType.CLIENTS_EDIT_ERROR   
}

export interface actionClientsDeleteLoading {
    type: ActionClientsType.CLIENTS_DELETE_LOADING,
    value: number
}
export interface actionClientsDeleteSuccess {
    type: ActionClientsType.CLIENTS_DELETE_SUCCESS,
    value: boolean
}
    
export interface actionClientsDeleteError {
    type: ActionClientsType.CLIENTS_DELETE_ERROR   
}

export interface actionClientsChangeSerachTable {
    type: ActionClientsType.CLIENTS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionClientsChangePageSizeTable {
    type: ActionClientsType.CLIENTS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionClientsChangePageTable {
    type: ActionClientsType.CLIENTS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionClientsChangeFilterTable {
    type: ActionClientsType.CLIENTS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeClientsActions = actionClientsErrorRequired |actionClientsSendValues | 
                            actionClientsFormUpdate | actionClientsFormNew |
                            actionClientsLoading | actionClientsSuccess | actionClientsError |
                            actionClientsAddLoading | actionClientsAddSuccess | actionClientsAddError |
                            actionClientsEditLoading | actionClientsEditSuccess | actionClientsEditError |
                            actionClientsDeleteLoading | actionClientsDeleteSuccess | actionClientsDeleteError |
                            actionClientsChangeSerachTable | actionClientsChangePageSizeTable | actionClientsChangePageTable |
                            actionClientsChangeFilterTable;
                                