
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TypeState from '../../../models/initialState/typeState';
import TypeLocationState from '../../../models/initialState/typeLocationState';
import {CountryDto} from '../../../models/dto/LocationDto'; 

interface Props {
    edit(event: any, index:CountryDto): void,
    remove(event: any, index:CountryDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function LanguageList(props: Props) {
  const { t } = useTranslation();
  const state:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);

  const headers = [{title: t('admin.countries.description'), field: 'description', defaultSort: state.countries.filters.orderBy === 0 ? state.countries.filters.orderDirection: ''},
                   {title: t('admin.countries.code'), field: 'code', defaultSort: state.countries.filters.orderBy === 1 ? state.countries.filters.orderDirection: ''},
                   {title: t('admin.countries.language'), field: 'language', defaultSort: state.countries.filters.orderBy === 2 ? state.countries.filters.orderDirection: ''},
                   {title: t('admin.countries.phoneCode'), field: 'phoneCode', defaultSort: state.countries.filters.orderBy === 3 ? state.countries.filters.orderDirection: ''}                   
                  ];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.countries.listTitle')}</h2>
            <Table<CountryDto> 
              data={state.countries.data} 
              headers={headers} 
              actions={actions} 
              new={true}
              initialPage={state.countries.initialPage}
              serchText={state.countries.searchText}
              pageSize={state.countries.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}/>
        </div>;
  }

export default LanguageList;