import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ApplicationJob from '../../models/dto/ApplicationsDto';
import TypeJobsState, { PathwayCandidate } from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackbarOrigin } from '@material-ui/core';
import Reject from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import Like from '@material-ui/icons/ThumbUpAlt';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import Table from '../table/Table';
import NetworkerDto from '../../models/dto/NetworkerDto';
import { APPROVED_APPLICATION_JOB, REJECT_APPLICATION_JOB } from '../../constanst/GlobalConstants';
interface rowData {
    networker: NetworkerDto,
    tableData: {id: number}
}
interface Props {    
    back():void,
    like(rowData:Object | null):void,
    reject(rowData:Object | null):void,
    changeSelected(rowData:Object | null):void,
    data: ApplicationJob | null    
  }

function CandidateData(props: Props) {
  const { t } = useTranslation();
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const dispatch = useDispatch();

  const SNACBARPOSITION: SnackbarOrigin= {vertical: 'top', horizontal:'center' };
  const SNACKBARTIMETOHIDE = 3000;

  const headersPathway = [
    {title: t('admin.candidates.label_name'), field: 'networker.name'},
    {title: t('admin.candidates.label_surname'), field: 'networker.surname'},
    {title: t('admin.candidates.label_email'), field: 'networker.email'},
    {title: t('admin.candidates.label_phone'), field: 'networker.phone'},
    {title: t('admin.candidates.label_level'), field: 'networker.networkerId', 
        render: (rowData: rowData)=> rowData.tableData.id},
    {title: t('admin.candidates.label_reward'), field: 'reward'},
  ]
  
  const downloadFile = (urlFile: string, fileName: string)=> {
    if (props.data !== null) {
        dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_LOADING, 
            value: {jobId: props.data.job.id, urlFile: urlFile ,fileName}});
        }
    }
    
   const closeSnackbarErrorDownloadFile = () => dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_ERROR, value: false});       

   return <div className='swissinx-data-candidate-container'>
        <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back}/> 
            {t('admin.candidates.titleData')}
        </h2>
        <h3 className='swissinx-data-candidate__job-name'>{props.data && props.data?.job.description}</h3>     
        <h3>{t('admin.candidates.sectionData')}</h3>
            <div className='swissinx-data-container'>
                <p>{props.data && props.data?.networker.name} {props.data && props.data?.networker.surname}</p>
                <p>{props.data && <a href={'mailto:' + props.data.networker.email}>{props.data.networker.email}</a>}</p>
                <p>{props.data && props.data?.networker.phone}</p>
                <div className='swissinx-data-candidate_status-container'>
                    <p className='swissinx-data-candidate__status'><span >{props.data && props.data.selectedForJob ? 'Selected' : props.data?.status.description}</span></p>
                    <IconButton disabled={props.data?.status.id === REJECT_APPLICATION_JOB || props.data?.selectedForJob} className='swissinx-data-candidate_status-button'>
                        <Reject titleAccess={t('table.tooltip.actionReject')} fontSize="large" color={(props.data && props.data?.status.id === 2) ? 'disabled': 'inherit'} onClick={()=>props.reject(props.data)}/>
                    </IconButton>
                    <IconButton disabled={props.data?.status.id === APPROVED_APPLICATION_JOB || props.data?.selectedForJob} className='swissinx-data-candidate_status-button'>
                        <Like titleAccess={t('table.tooltip.actionLike')} fontSize="large" color={(props.data && props.data?.status.id === 1) ? 'disabled': 'inherit'} onClick={()=>props.like(props.data)}/>
                    </IconButton>
                    {props.data && props.data.selectedForJob && <IconButton  className='swissinx-data-candidate_status-button'><Star titleAccess={t('table.tooltip.actionCandidateSelected')} fontSize="large" onClick={()=>props.changeSelected(props.data)}/></IconButton>}
                    {props.data && !props.data.selectedForJob && <IconButton disabled={props.data?.status.id === REJECT_APPLICATION_JOB} className='swissinx-data-candidate_status-button'><StarBorder titleAccess={t('table.tooltip.actionCandidateNoSelected')} fontSize="large" onClick={()=>props.changeSelected(props.data)}/></IconButton>}
                </div>
                
            </div> 

        {props.data && props.data?.questions.length > 0 && <>
            <h3>{t('admin.candidates.sectionQuestions')}</h3>
            <div className='swissinx-data-container'>
                {props.data?.questions.map((elem, index) => <div key={index}>
                    <p key={'question'+index}>(<span className='swissinx-data-candidate__category'>{elem.questionCategory.description}</span>) - <span className='swissinx-data-candidate__question'>{elem.questionText}</span></p>
                    {elem.questionType.id !== 1 && <p className='swissinx-data-candidate__answer' key={index}>{elem.answer}</p>}
                    {elem.questionType.id === 1 && elem.urlFile !== null && <p className='swissinx-data-candidate__file' key={index} onClick={()=>downloadFile(elem.urlFile, elem.fileName)}>{elem.fileName}</p>}
                    {elem.questionType.id === 1 && elem.urlFile === null && <p className='swissinx-data-candidate__answer'key={index}>{t('admin.candidates.noFile')}</p>}
                </div>                
                )}
            </div>
        </>}
        {props.data && props.data?.webProfiles.length > 0 && <>
            <h3>{t('admin.candidates.sectionWebProfiles')}</h3> 
            <div className='swissinx-data-container'>
                {props.data?.webProfiles.map((elem, index) =><a key={index} className='swissinx-data-candidate__profile' target="_blank" href={elem.webProfileLink} rel="noreferrer">{elem.webProfileLink} {elem.webProfileDescription}</a> )}
            </div>
        </>}
        
        {props.data && props.data?.attachedFiles.length > 0 && <>
            <h3>{t('admin.candidates.sectionAttachedFiles')}</h3>
            <div className='swissinx-data-container'>
                {props.data?.attachedFiles.map((elem, index) =><p key={index} className='swissinx-data-candidate__file' onClick={()=>downloadFile(elem.urlFile, elem.fileName)}>{elem.fileName}</p> )}
            </div>
        </>}

        <h3>{t('admin.candidates.pathway')}</h3>
        <div className='swissinx-data-container'>
            {state.candidates.pathway.loading && 'Loading'}
            {state.candidates.pathway.error && 'Error'}
            {!state.candidates.pathway.loading && !state.candidates.pathway.error &&
                <Table<PathwayCandidate> 
                    data={state.candidates.pathway.data} 
                    headers={headersPathway}
                />
            }
        </div>


        <Snackbar anchorOrigin={SNACBARPOSITION} open={state.candidates.downloadFile.error} autoHideDuration={SNACKBARTIMETOHIDE} onClose={closeSnackbarErrorDownloadFile}>                    
            <Alert onClose={closeSnackbarErrorDownloadFile} severity="error" sx={{ width: '100%' }} >
            {t('admin.candidates.errorDownloadFile')}
            </Alert>
        </Snackbar>

        </div>; 
 
}

export default CandidateData;