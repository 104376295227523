export enum StatusJob {
    ACTIVE_STATUS = 1,
    INACTIVE_STATUS = 0,
    EXPIRED_STATUS = 2,
    PLACEMENT_STATUS = 3
}

export enum DisplayedStatusJob {
    inactive = 0,
    active = 1, 
    expired = 2,
    placement = 3
}

export const ACTIVE_STATUS = 'ACTIVE';

export const INACTIVE_STATUS = 'INACTIVE'
export interface Status {
    id: StatusJob,
    name: string
} 

