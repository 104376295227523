import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './PublicJob.scss';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { useMemo, useState } from 'react';
import { QuestionsResponse } from '../../models/dto/ApplicationPublicJobDto';
import AttachedFilesInput from '../commons/AttachedFilesInput';
import Star from '../../assest/star.svg';
import StarBlue from '../../assest/starBlue.svg';

interface Props {
  endQuestions(questions: QuestionsResponse[]): void
}

function ApplyQuestions(props: Props) {
  const { t } = useTranslation();
  const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
  const [question, setQuestion] = useState(1);
  const [questionYesNo, setQuestionYesNo] = useState<string | null>(null);
  const [questionNumber, setQuestionNumber] = useState<string | null>(null);
  const [questionRatio, setQuestionRatio] = useState<number>(0);
    
  const [questionResponse, setQuestionResponse] = useState<QuestionsResponse[]>(state.applyQuestions.data.map(applyQuestion => ({
    questionId: applyQuestion.questionId,
    questionType: applyQuestion.questionType.id,
    answer: '',
    file: null,
    fileName: null,
    contentType: null
  })))

  const numQuestions = useMemo(() => state.applyQuestions.data.length, [state.applyQuestions.data]);
  let progress = useMemo(() => {
    let progress = 0;
    if (numQuestions > 0) {
      progress = 100 * question / numQuestions;
    }
    return progress;
  }, [question, numQuestions])

  const sendQuestionText = (text: string) => {
    const questionsAux = [...questionResponse]
    if (questionsAux[question - 1].questionType === 0) {
      questionsAux[question - 1].answer = text;
      setQuestionResponse(questionsAux);
    }
  }

  const sendQuestionYesNo = (option: string) => {
    const questionsAux = [...questionResponse]
    if (questionsAux[question - 1].questionType === 2) {
      questionsAux[question - 1].answer = option;
      setQuestionResponse(questionsAux);
      setQuestionYesNo(option);
    }
  }

  const sendQuestionNumber = (option: string) => {
    const questionsAux = [...questionResponse]
    if (questionsAux[question - 1].questionType === 3) {
      questionsAux[question - 1].answer = option;
      setQuestionResponse(questionsAux);
      setQuestionNumber(option);
    }
  }

  const sendQuestionRatio = (option: number) => {
    const questionsAux = [...questionResponse]
    if (questionsAux[question - 1].questionType === 4) {
      questionsAux[question - 1].answer = option.toLocaleString();
      setQuestionResponse(questionsAux);
      setQuestionRatio(option);
    }
  }

  /* const sendQuestionFile = (e: ChangeEvent<HTMLInputElement>)=>{
    const questionsAux = [...questionResponse]    
    if (questionsAux[question - 1].questionType === 1) {
      const file = e.currentTarget && e.currentTarget.files ? e.currentTarget.files[0] : null;      
      let reader = new FileReader();
      reader.onloadend = function () {
        if (file && reader.result) {
          const fileResult = reader.result as string;
          questionsAux[question - 1].fileName = file ? file.name : null;
          questionsAux[question - 1].file = fileResult.split(',')[1] as string;
          questionsAux[question - 1].contentType = file ? file.type : null;
          setQuestionResponse(questionsAux);  
        }      
      }  
      if (file) {
          reader.readAsDataURL(file);
      } 
    } 
  } */

  const sendQuestionFile = (files: any) => {
    const questionsAux = [...questionResponse]
    questionsAux[question - 1].fileName = files[0] ? files[0].fileName : null;
    questionsAux[question - 1].file = files[0] ? files[0].file : null;
    questionsAux[question - 1].contentType = files[0] ? files[0].contentType : null;
    setQuestionResponse(questionsAux);
  }

  const deleteFile = () => {
    const questionsAux = [...questionResponse]
    questionsAux[question - 1].fileName = null;
    questionsAux[question - 1].file = null;
    questionsAux[question - 1].contentType = null;
    setQuestionResponse(questionsAux);
  }

  const setEndQuestion = () => {
    props.endQuestions(questionResponse);
  }

  return <div className='questions-container'>
    <div>
      <div className="progress-questions-container">
        <div className="progress-questions" style={{ width: progress + '%' }}></div>
      </div>

      <div className='questions-input-container'>
        <label className='questions-title'>{state.applyQuestions.data[question - 1].questionText}</label>
        {state.applyQuestions.data[question - 1].questionType.id === 0 && <input type='text' className='questions-input-text' value={questionResponse[question - 1].answer as string} onChange={(e) => sendQuestionText(e.currentTarget.value)} />}
        {state.applyQuestions.data[question - 1].questionType.id === 1 &&

          <div className='swissinx-job-body-block'>
            {/* <label htmlFor='questionFile' className='label-file'>{questionResponse[question-1].fileName ? questionResponse[question-1].fileName : t('publicJob.filePlaceHolder')}</label>
               <input id='questionFile' type='file' className='questions-input-file' onChange={sendQuestionFile}/> */}
            <AttachedFilesInput attachedFiles={sendQuestionFile} file={questionResponse[question - 1].fileName ? questionResponse[question - 1].fileName : null} index={0} deleteFile={deleteFile} />
        </div>}

          {state.applyQuestions.data[question - 1].questionType.id === 2 &&  <div className='questions-yes-no-container'>
               <div className={`questions-yes-no ${questionYesNo === 'Yes' ? 'question-yes-no-active' : ''}`} onClick={()=>sendQuestionYesNo('Yes')}>{t('publicJob.questionsYes')}</div>
               <div className={`questions-yes-no ${questionYesNo === 'No' ? 'question-yes-no-active' : ''}`} onClick={()=>sendQuestionYesNo('No')}>{t('publicJob.questionsNo')}</div>
          </div>}

          {state.applyQuestions.data[question - 1].questionType.id === 3 && <input type='number' className='questions-input-text' value={questionResponse[question - 1].answer as string} onChange={(e) => sendQuestionNumber(e.currentTarget.value)} />}

          {state.applyQuestions.data[question - 1].questionType.id === 4 &&  <div className='questions-number-container'>
            {[1,2,3,4,5].map(elem=> <div key={'number' + elem} className='questions-number-item'>
              <div className={`questions-number ${elem === questionRatio ? 'question-number-active' : ''}`} onClick={()=>sendQuestionRatio(elem)}></div>
              <p>{elem}</p>
            </div>)}
          </div>}

          {/* {state.applyQuestions.data[question - 1].questionType.id === 4 &&  <div className='questions-ratio-container'>            
            {[1,2,3,4,5].map(elem=><img key={'star' + elem} src={elem <= questionRatio ? StarBlue : Star} alt='' onClick={()=>sendQuestionRatio(elem)}/>)}
          </div>} */}

          

      </div>
    </div>
    <div className='buttons-questions-container'>
      {question > 1 && <button type='button' className='buttons-questions-back' onClick={() => setQuestion(question - 1)}>{t('publicJob.backButton')}</button>}
      {question < numQuestions && <button type='button' className='buttons-questions-next' onClick={() => setQuestion(question + 1)}>{t('publicJob.nextButton')}</button>}
      {question === numQuestions && <button type='button' className='buttons-questions-finish' onClick={setEndQuestion}>{t('publicJob.finishButton')}</button>}
    </div>

  </div>



}

export default ApplyQuestions;