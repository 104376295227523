
import React, {useState, useEffect, SyntheticEvent, ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { useSelector, useDispatch } from 'react-redux';
import {RegionDto} from '../../models/dto/LocationDto'; 
import PaymentsList from './PaymentsList';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
//import RegionsEdit from './RegionsEdit';
//import './Regions.scss';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import { actionPaymentsType } from '../../models/actions/typePaymentsActions';
import typePaymentsState from '../../models/initialState/typePaymentsState';
import PaymentDto from '../../models/dto/PaymentDto';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ALL } from '../../utils/utils';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Payments(props: Props) {
  const [editPayment, setEditPayment] = useState(false);
  
  const { t } = useTranslation();
  const state:typePaymentsState = useSelector((state:TypeState) => state.paymentsReducer);
  const dispatch = useDispatch();
  const [companySelected, setCompanySelected] = useState(0);

  const [stringFilter, setStringFilter] = useState({
    currency: ALL,
    method: ALL,
    status: ALL
  })
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(null);
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(null);
    
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
      //dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_LOADING, value: companySelected});       
      setEditPayment(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
    }

  }, []);  

  const edit = (event:SyntheticEvent, index:PaymentDto) => {    
    /* setAddRegion(false)
    setRegionSelected(index);
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_FORM_UPDATE, value: index}); 
    setEditRegion(true);  */
  } 

  /* const back = () => {
    setEditRegion(false);
  }

  const update = () => {
    setEditRegion(false);    
    if (addRegion) {
      dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_ADD_LOADING, value: {
        name: state.regions.regionsForm.name.value as string, countryId: companySelected }
      });      
    } else {
      dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_EDIT_LOADING, value: {
        id: regionSelected.id, name: state.regions.regionsForm.name.value as string, countryId: companySelected }
      });
    } 
  }   */

  const changeCompany = (event:ChangeEvent<HTMLInputElement>) => {
    const company = parseInt(event.target.value);
    setCompanySelected(company);
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_LOADING, value: company});   
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  const onChangeStringFilter = (id: string, value: string) => {    
    setStringFilter({...stringFilter, [id]: value})
  }

  return <> 
            {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editPayment && <PaymentsList 
                                                                        edit={edit}                                                                         
                                                                        company={companySelected}
                                                                        changeCompany={changeCompany}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}
                                                                        stringFilter={stringFilter}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        onChangeStringFilter={onChangeStringFilter}
                                                                        onChangeStartDate={setStartDate}
                                                                        onChangeEndDate={setEndDate}
                                                                        />}
           {/*  {!state.regions.loading && !state.regions.error && editRegion && <RegionsEdit update={update} back={back} add={addRegion}/>} */}
           
            
            {state.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_LOADING, value: companySelected})}/>}
            
            {/* <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.regions.editMessage')}
              addMessage={t('admin.regions.addMessage')}
              delteMessage={t('admin.regions.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_DELETE_SUCCESS, value: false})}/>       */}      
        </>;
  }

export default Payments;
