import { useTranslation } from 'react-i18next';
import './Dashboard.scss';

interface Props {
    setOption(option: number): void
}

function DashboardMenu(props: Props) {
    const { t } = useTranslation();

    return <div className='swissinx-dashboard-container'>
        <h2>{t('admin.dashboard.title')}</h2>
        <div className='swissinx-dashboard-block'>
            <div className='swissinx-dashboard-option' onClick={() => props.setOption(1)}>
                <h3>{t('admin.dashboard.titleUsers')}</h3>
                <p className='swissinx-dashboard__item'>{t('admin.dashboard.dashboardUserDescription')}</p>
            </div>

            <div className='swissinx-dashboard-option' onClick={() => props.setOption(2)}>
                <h3>{t('admin.dashboard.network')} </h3>
                <p className='swissinx-dashboard__item'>{t('admin.dashboard.dashboardNetworkDescription')}</p>
            </div>
        </div>
    </div>
}

export default DashboardMenu;