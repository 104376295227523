import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SNACBARPOSITION, SNACKBARTIMETOHIDE } from '../../utils/utils';

interface Props {
    edit: boolean,    
    add: boolean,
    delete: boolean,
    editMessage:string,   
    addMessage:string,
    delteMessage: string,
    onCloseEdit(): void,
    onCloseAdd(): void,
    onCloseDelete(): void
}

function Notification(props: Props) {   

  return  <>
        <Snackbar anchorOrigin={SNACBARPOSITION} open={props.edit} autoHideDuration={SNACKBARTIMETOHIDE} onClose={props.onCloseEdit}>                    
            <Alert onClose={()=>props.onCloseEdit()} severity="success" sx={{ width: '100%' }} >
                {props.editMessage}
            </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={SNACBARPOSITION} open={props.add} autoHideDuration={SNACKBARTIMETOHIDE} onClose={props.onCloseAdd}>                    
            <Alert onClose={()=>props.onCloseAdd()} severity="success" sx={{ width: '100%' }} >
                {props.addMessage}
            </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={SNACBARPOSITION} open={props.delete} autoHideDuration={SNACKBARTIMETOHIDE} onClose={props.onCloseDelete}>                    
            <Alert onClose={props.onCloseDelete} severity="success" sx={{ width: '100%' }} >
                {props.delteMessage}
            </Alert>
        </Snackbar>
    </>
  }

export default Notification;
