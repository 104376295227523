import TypeAmbassadorsState from '../../models/initialState/typeAmbassadorsState';

export const initialAmbassadorsState: TypeAmbassadorsState = {
    loading: false,
    error: false,
    ambassadorsData: [],
    automaticList: {
        loading: false,
        data: [],
        error: false
  },
  pushFilter: {
    languages: [],
    skills: [],
    markets: [],
    search: '',
    countries: []
  },
  listAmbassadorsFiltered: []
}