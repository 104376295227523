import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.scss';
import logoDark from '../../assest/swapp_logo_dark.svg';

function PrivacyPolicy() {    
  const { t } = useTranslation();

    return <div className="job-layout">
        <div className='job-layout__header'>
          <div className='header'>
            <img className='header__logo' src={logoDark} alt=""></img>
            <div className='header__cta'>
              <p className='header__title'>{t('publicJob.headerTitle')}</p>
              <a className='header__button'  href='/' target="_blank">{t('publicJob.buttonMore')}</a>
            </div>
          </div>
        </div>
             
        <div className='job-layout__content'>
        <div className="swissinx-privacy-policy">
          <h2>SWAPP PRO Privacy Policy</h2> 
          <p>Updated February 21 2022</p>

          <p>This Privacy Policy describes the type of personal data collected when using the SWAPP PRO mobile application or the SWAPP PRO website (subsequent &ldquo;SWAPP PRO&rdquo;), where the data is stored and how it is handled to ensure the best protection and respect of your rights. It also includes a description of your rights in accordance with GDPR (General Data Protection Regulation). </p>
          <p>The General Terms of Use of SWAPP PRO are an integral part of this Privacy Policy. By accepting this Privacy Policy you also accept the General Terms of Use of the SWAPP PRO.</p>

          <p><h3>YOUR PERSONAL DATA </h3></p>
          <p>This policy concerns the use and the processing of all data via the SWAPP PRO app and website.</p>

          <p><b>What personal data is collected by SWAPP PRO</b></p>
          <p>  SWAPP PRO collects information from the use of your mobile or any other device such as the log in time, location data, IP addresses, the device, the operative system of the device, the browser and any request or action done via the app or the website. </p>
          <p>We store changes in your profile, as well as messages you send to:</p>
          <p> - companies that you apply to, and</p>
          <p> - intermediaries </p>
          <p>via the app or website, including when the message was sent.  </p>

          <p>We collect the information you provide to register on the app or on the website, such as your mobile phone number, e-mail address and name.  </p>
          <p>If you apply for a vacancy, we collect the information you upload. </p>
          <p>Furthermore, when you use SWAPP PRO we collect and store this data on our external servers. </p>
          <p> All the personal data that you find on SWAPP PRO is data that you expressly consented to the Swisslinx Group of companies storing using, supplying or transferring.</p>

          <p><b>Why is your personal data collected by SWAPP PRO</b></p>
          <p>SWAPP PRO&rsquo;s sole purpose is to give you access to vacancies and for you to share them with your network and be part of the referral scheme. SWAPP PRO allows you to forward job vacancies to anyone in your personal network, and to upload your own application to apply for a vacancy.</p>

          <p><b>How is personal data used by SWAPP PRO</b></p>
          <p>SWAPP PRO can be accessed by anyone who receives an invitation and registers to download the app. </p>
          <p>You have access to the app or the website with your personal access code which you must keep safe. By giving away the access code, you hand over to third parties the access to your personal data. </p>
          <p>Companies that publish their vacancies on SWAPP PRO, can only access your personal data, when you apply to the corresponding vacancy. </p>
          <p>Once you apply for a vacancy and upload your application documents, your data can be accessed and downloaded by the company that has the vacancy. Once this company downloads this data from the app, it will be physically stored on a device of the company, where its safeguard cannot be guaranteed by us. </p>
          <p>Once any information is downloaded from the SWAPP PRO (e.g. by a company whose vacancy you are applying for), SWAPP PRO no longer has any form of control over it and consequently refuses any responsibility regarding the handling and/or disclosure thereof. </p>
          <p>We collect and store the personal data on our external systems and use the information for the purpose of your application and referral scheme. </p>
          <p>We grant you access to your own above mentioned personal data via the SWAPP PRO app and website, for the purpose as set out before. </p>
          <p>All the data SWAPP PRO accesses is stored on servers in Western Europe. </p>
          <p>We do not share any data with third parties unless you give express consent or it is required by law. </p>
          <p>Your personal data will be stored as long as your account remains open.  </p>
          <p>Your personal information can be stored and processed in countries where we have our offices or in which we engage service providers, within or outside of the EEA/EFTA. All data will be treated confidentially and will not be used for any other purposes than those explicitly agreed to by the person concerned. </p>
          <p>We respect and apply the Swiss data protection laws, in particular the Swiss Data Protection Act as well as the EU General Data Protection regulation, effective from 25th May 2018.</p>
          <p>You can access and update your personal data on the app and on the website. Your personal details are in the &ldquo;Profile&rdquo; section, email and password are editable in the &ldquo;Settings&rdquo; section. You can also withdraw your consent at any time by contacting info@swapp.biz. &#8195; 

          </p>
          <p><h3>YOUR PERSONAL DATA: YOUR RIGHTS</h3></p>
          <p>Under GDPR you have the following rights, however there are restrictions which can apply, as some of the rights are not absolute.</p>

          <p>1. Right to access </p>
          <p>2. Right to be informed </p>
          <p>3. Right to be forgotten </p>
          <p>4. Right to withdraw consent </p>
          <p>5. Right of rectification </p>
          <p>6. Right to object </p>
          <p>7. Right not to be subject to automated decision making </p>
          <p>8. Right to data portability</p>

          <p>For more information on Swisslinx privacy practices, please contact us dataprotection@swisslinx.com </p>
          <p>We engage all our efforts and have taken appropriate measures to ensure the security of our app and our website as well as of the transmission and access of all data in the context of our services. Nonetheless, the internet being an unprotected network can be subject to breaches of security and privacy beyond our control for which we assume no liabilities. Please use other means of communication if you deem this necessary.</p>
          <p>We reserve the right to amend this Privacy Policy at all times.</p>

          <p></p>
        </div>
        <div className='job-layout__footer'>
          <div className='header'>
            <img className='header__logo' src={logoDark} alt=""></img>
            <div className='header__cta'>
              <p className='header__title'>{t('publicJob.headerTitle')}</p>
              <a className='header__button' href='/' target="_blank">{t('publicJob.buttonMore')}</a>
            </div>
          </div>
        </div> 
      </div>
  </div>
         
  }

export default PrivacyPolicy;

