import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ActionAmbasadorsType, TypeAmbasadorsActions } from '../../models/actions/typeAmbassadorActions';
import TypeAmbassadorsState from '../../models/initialState/typeAmbassadorsState';
import TypeState from '../../models/initialState/typeState';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { getLabelCountry, getLabelLanguage, getLabelMarket, getSkillOptions } from '../../utils/utils';
import typeSkillGroupState from '../../models/initialState/typeSkillGroupState';
import { skillAutocomplete } from '../../models/dto/SkillDto';
import { useState } from 'react';
import TypeLocationState from '../../models/initialState/typeLocationState';

interface Props {   
    sendDataFilter(): void    
}

function JobsPushFilterAmbassadors(props: Props) {   
  const { t } = useTranslation();
  const state:TypeAmbassadorsState = useSelector((state:TypeState) => state.ambassadorsReducer);
  const stateSkillGroup: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);
  const stateSkills: TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);
  const stateLanguages:TypeLanguagesState = useSelector((state:TypeState) => state.languagesReducer);
  const stateLocation:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const [skill, setSkill] = useState<skillAutocomplete[] | undefined>(undefined)  
  const dispatch = useDispatch(); 

  const resetFilter = () => {
    dispatch<TypeAmbasadorsActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_RESET});
    setSkill([]);
  }

  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    const {
      target: { value },
    } = event;
   
    // On autofill we get a the stringified value.
    let sendValue: number[];
    typeof value === 'string' ? sendValue = [parseInt(value)] : sendValue = value;     

    dispatch<TypeAmbasadorsActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES, value: {name: name, value: sendValue}});
  };

  const changeSkill = (
    event: React.SyntheticEvent<Element, Event>,
    value: skillAutocomplete[] | null,
    reason: any
  ) => {
    let skillIds:number[]=[];
    if (value) {
      setSkill([...value]);
      skillIds = value.map(skill=>skill.id)
    } 
    dispatch<TypeAmbasadorsActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES, value: {name: 'skills', value: skillIds}});
  };
   
return  <>  <h4>{t('admin.jobs.ambassadorFilter')}</h4>      
            {/* {<Form contex={state.pushJobForm} errorRequerired={(key)=>dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_PUSH_ERROR_REQUIRED, value: key})} onSubmit={onSubmit} 
                fields={ */}
                <div className="swissinx-container-fields-form filter-container">
                  <div className='swissinx-field-container'>
                      <div className="swissinx-field field-search">
                          <FieldTextMui                         
                              placeholder = ''
                              label = {t('admin.jobs.label_search')}                   
                              name='search'                                              
                              required={false}
                              value={state.pushFilter.search as string}
                              fullWidth={true}
                              error={false}                               
                                                                        
                              onChange = {({name, value, error})=>dispatch<TypeAmbasadorsActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES_SEARCH, value: {name: name, value: value}})}                                            
                          />         
                      </div>

                      <div className="swissinx-field field-language">
                      <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="languages">{t('admin.jobs.label_languages')}</InputLabel>
                      <Select
                          labelId="languages"
                          id="languageId"
                          label={t('admin.jobs.label_languages')}
                          multiple
                          value={state.pushFilter.languages}
                          onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'languages')}                    
                          input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_languages')} />}
                          error={false}
                          renderValue={(selected: number[]) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value: number) => (
                                <Chip key={'languages-' + value} label={getLabelLanguage(value, stateLanguages.data, stateLanguages.loading, stateLanguages.error)} />
                              ))}
                            </Box>
                          )}          
                        >
                          {stateLanguages.data.map((language) => (
                            <MenuItem
                              key={language.languageId}
                              value={language.languageId}                                              
                            >
                              {language.name}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                    </div>
                    <div className="swissinx-field field-countries">
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="countries">{t('admin.jobs.label_countries')}</InputLabel>
                        <Select
                            labelId="countries"
                            id="countries"
                            label={t('admin.jobs.label_countries')}
                            multiple
                            value={state.pushFilter.countries}
                            onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'countries')}                    
                            input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_countries')} />}
                            error={false}
                            renderValue={(selected: number[]) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value: number) => (
                                    <Chip key={value} label={getLabelCountry(value, stateLocation.countries.data, stateLocation.countries.loading, stateLocation.countries.error)} />
                                ))}
                                </Box>
                            )}          
                            >
                            {stateLocation.countries.data.map((country) => (
                                <MenuItem
                                key={country.id}
                                value={country.id}                                              
                                >
                                {country.description}
                                </MenuItem>
                            ))}
                            </Select>
                            </FormControl>
                        </div>

                    </div>
                    <div className='swissinx-field-container'>
                
                <div className="swissinx-field field-autocomplete-multiple">
                  <Autocomplete
                    multiple
                    id="skills"
                    sx={{ width: 300 }}
                    options={getSkillOptions(stateSkills.data, stateSkillGroup.data)}
                    getOptionLabel={(option) =>option.description}
                    value={skill}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={changeSkill}
                    /* renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>                    
                        {option.description}
                      </Box>
                    )} */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('admin.jobs.label_skills')}
                        /* inputProps={{
                          ...params.inputProps,
                          autoComplete: 'country', // disable autocomplete and autofill
                        }} */                              
                      />
                    )}
                  />   
                </div>
                    
                <div className="swissinx-field field-market">
                  <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="marketId">{t('admin.jobs.label_markets')}</InputLabel>
                  <Select
                      labelId="markets"
                      id="markets"
                      label={t('admin.jobs.label_markets')}
                      multiple
                      value={state.pushFilter.markets}
                      onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'markets')}                    
                      input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_markets')} />}
                      error={false}
                      renderValue={(selected:number[]) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value: number) => (
                            <Chip key={'markets' + value} label={getLabelMarket(value, stateMarkets.data, stateMarkets.loading, stateMarkets.error)} />
                          ))}
                        </Box>
                      )}                   
                    >
                      {stateMarkets.data.map((market) => (
                        <MenuItem
                          key={market.marketId}
                          value={market.marketId}                                              
                        >
                          {market.name}
                        </MenuItem>
                      ))}
                    </Select>
                    </FormControl>
                    </div>
                    <div className="swissinx-container-buttons">
                      <button className="swissinx-button-back" type="button" onClick={resetFilter}>{t('admin.jobs.text_button_reset')}</button> 
                      <button className="swissinx-button-submit" type="button" onClick={props.sendDataFilter}>{t('admin.jobs.text_button_filter')}</button>                      
                    </div>
                </div>                   
                </div>
               {/*  }>
            </Form> } */}

</>
 
}

export default JobsPushFilterAmbassadors;