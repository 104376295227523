import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddBox from '@material-ui/icons/AddBox';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
import { useState } from 'react';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import Users from '@material-ui/icons/SupervisedUserCircleRounded';
//import './Jobs.scss';
import { ContactData } from '../../models/dto/JobActivityDto';
import Tooltip from '@mui/material/Tooltip';


interface Props {
    contacts: ContactData[],
    type: string
}

function DashboardJobActivityTree(props: Props) {

    const { t } = useTranslation();
    const state: TypeJobsState = useSelector((state: TypeState) => state.jobsReducer);
    const dispatch = useDispatch();

    /* const [localBranch, setLocalBranch] = useState<ambassadorPath>(props.branch); */
    const [open, setOpen] = useState<boolean[]>(props.contacts.map(elem => false));

    const moreAmbassadors = (contact: ContactData, index: number) => {
        if (contact.path === null && contact.contacts.length > 0) {
            dispatch<TypeActionJobsActions>({ type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING, value: { pushId: contact.pushId, contactLinks: contact.contacts, pathId: contact.pathId, type: props.type } });
        }
        const openAux = [...open];
        openAux[index] = true;
        setOpen(openAux);
    }

    const lessAmbassadors = (index: number) => {
        const openAux = [...open];
        openAux[index] = false;
        setOpen(openAux);
    }

    return <div className='swissinx-job-activity-tree-container'>
        {props.contacts.map((contact, index) => <div key={index} className={`swissinx-push-path ${index % 2 === 0 ? 'even' : 'odd'} ${open ? 'open1' : ''} `}>
            <div className='swissinx-push-path__data-container'>
                {!open[index] && contact.contacts.length > 0 && <span><AddBox onClick={() => moreAmbassadors(contact, index)} /><span className='swissinx-push-path_index'> ({contact.contacts.length})</span></span>}
                {open[index] && <span onClick={() => lessAmbassadors(index)}><IndeterminateCheckBox /> </span>}
                {/*  <div>Networker - {props.ambassador.networkerId} </div> */}

                <div className='swissinx-push-path__name'>{contact.name} {contact.surname}
                    {contact.isAmbassador && <Tooltip title={t('admin.jobs.label_ambassador') as string}><Users /></Tooltip>}
                </div>
                <div className='swissinx-push-path__name'>{contact.phone} - {contact.email} </div>
            </div>
            <div className='swissinx-push-path__block-container'>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListShares')}</span>
                    <span className='swissinx-push-path__data'>{contact.shares}</span>
                </div>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListReshares')}</span>
                    <span className='swissinx-push-path__data'>{contact.reshares}</span>
                </div>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListApplies')}</span>
                    <span className='swissinx-push-path__data'>{contact.applies}</span>
                </div>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListViews')}</span>
                    <span className='swissinx-push-path__data'>{contact.views}</span>
                </div>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListNetworkers')}</span>
                    <span className='swissinx-push-path__data'>{contact.networkers}</span>
                </div>
                <div className='swissinx-push-path__block-data'>
                    <span>{t('admin.jobs.pushListRejects')}</span>
                    <span className='swissinx-push-path__data'>{contact.rejects}</span>
                </div>
            </div>
            {open[index] && contact.path && <DashboardJobActivityTree contacts={contact.path} type={props.type} />}
        </div>)}
        {state.jobActivity.loading && <span>{t('admin.jobs.loading')}</span>}
        {state.jobActivity.error && <span>{t('admin.jobs.error')}</span>}
    </div>;
}

export default DashboardJobActivityTree;