import CompanyGroupForm from '../../models/forms/CompanyGroupsForm';
import { TypeActions } from '../../models/actions/typeActions';
import {ActionCompanyGroupsType} from '../../models/actions/typeCompanyGroupActions';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import { initialCompanyGroupsState } from '../inititalState/initialCompanyGroupsState';

const companyGroupsReducer = (state:TypeCompanyGroupsState = initialCompanyGroupsState, action:TypeActions):TypeCompanyGroupsState => {
    switch (action.type) {

        case ActionCompanyGroupsType.COMPANY_GROUPS_ERROR_REQUIRED : {
            const name = action.value;
            let companyGroupsForm:CompanyGroupForm = {...state.companyGroupsForm};            
            companyGroupsForm[name as keyof CompanyGroupForm] =  {...companyGroupsForm[name as keyof CompanyGroupForm], error: {required: true, format: companyGroupsForm[name as keyof CompanyGroupForm].error.format}};
            return {...state,  companyGroupsForm: {...companyGroupsForm}}         
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_LOADING: {           
            return {...state,  loading: true, error: false}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_SUCCESS: {            
            return {...state, loading: false, data: action.value.data, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_ERROR: {            
            return {...state,  loading: false, error: true, data: []}
        }
        
        case ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_LOADING: {
            return {...state, loading: true, error: false, edit: false}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_SUCCESS: {
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_LOADING: {           
            return {...state, loading: true, error: false, delete: false}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_ADD_LOADING: {           
            return {...state, loading: true, error: false, add: false}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_ADD_SUCCESS: {            
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
        
    }
    return {...state};
}
export default companyGroupsReducer;