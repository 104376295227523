import { useTranslation } from 'react-i18next';
import { ChangeEvent, useMemo, useState } from 'react';
import { AttachedFiles } from '../../models/dto/ApplicationPublicJobDto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import './AttachedFiles.scss';
import { MAX_SIZE_FILE, MAX_SIZE_FILE_MB } from '../../constanst/GlobalConstants';
interface Props {
  attachedFiles(files: AttachedFiles[]): void,
  deleteFile(index:number): void,
  file: string | null,
  index: number
}
const EXTENSIONS_ALLOWED = ['pdf', 'doc', 'docx','xsl','xlsx', 'text', 'txt', 'png', 'jpg', 'gif', 'jpeg', 'ppt', 'pptx']
function AttachedFilesInput(props: Props) { 
  const [error, setError] = useState(''); 
  const { t } = useTranslation();
    
  const sendAttachedFiles = (e: ChangeEvent<HTMLInputElement>)=>{
      const files = e.currentTarget && e.currentTarget.files ? e.currentTarget.files : [];

      function readAsDataURL(file: File){
        return new Promise(function(resolve,reject) {
            let fr = new FileReader();

            fr.onloadend = function(){
              if (fr.result) {
                const extension = file.name.split('.').pop();
                if (extension && EXTENSIONS_ALLOWED.indexOf(extension.toLowerCase() as string) === -1) {
                  setError(t('publicJob.fileNotAllowed'));
                  reject(fr);
                }  else if (file.size > MAX_SIZE_FILE) {
                  setError(t('publicJob.fileNotAllowedTooGig') + MAX_SIZE_FILE_MB + 'MB');
                  reject(fr);
                } else {
                  setError('');
                  const fileResult = fr.result as string;
                  resolve({file: fileResult.split(',')[1] as string, fileName: file.name, contentType: file.type});
                }
                
              } else {
                resolve({file: null, fileName: '', contentType: ''});
              }
            };

            fr.onerror = function(){
                reject(fr);
            };

            fr.readAsDataURL(file);
        });
      }
      let readers = [];      
      for(let i = 0;i < files.length;i++){                   
          readers.push(readAsDataURL(files[i]));                
      }
      Promise.all(readers).then((values: any) => {
        if(values.length > 0) {
          props.attachedFiles(values)
        }
      });
      e.currentTarget.value = '';     
  }  
  const labelFile = useMemo(()=>{
    let label = t('publicJob.filePlaceHolder');
    if (error) {
      label = error
    } else if (props.file) {
      label = props.file
    }
    return label
  }, [error, props.file])

  const deleteFile = ()=>{    
    props.deleteFile(props.index);
  }
  return <div>
            <div className='file-container'>          
                <label htmlFor={'attachedFiles' + props.index} className='label-file'>{labelFile}</label>
                {props.file && <span className="profile-action-icon" onClick={deleteFile}><FontAwesomeIcon icon={faMinus} /></span>}
                <input id={'attachedFiles' + props.index} type='file' className='questions-input-file' onChange={sendAttachedFiles}/>           
            </div>
          </div> 
  
}

export default AttachedFilesInput;