
import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../../models/actions/typeActions';
import { useSelector, useDispatch } from 'react-redux';
import {CountryDto, CountrySendDto} from '../../../models/dto/LocationDto'; 
import CountriesList from './CountriesList';
import ModalConfirmDelete from '../../commons/ModalConfirmDelete';
import ComponentError from '../../commons/ComponentError';
import Loading from '../../commons/Loading';
import TypeState from '../../../models/initialState/typeState';
import Notification from '../../commons/Notification';
import CountriesEdit from './CountriesEdit';
//import './Countries.scss';
import { ActionLocationType } from '../../../models/actions/typeLocationActions';
import TypeLocationState from '../../../models/initialState/typeLocationState';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Countries(props: Props) {
  const [editCountry, setEditCountry] = useState(false);
  const [showModaldeleteCountry, setShowModalDeleteCountry] = useState(false);
  const [addCountry, setAddCountry] = useState(false);  
  const [countrySelected, setCountrySelected] = useState<CountryDto>({id: 0, description: '', code: '', phoneCode: '', language: ''});
  
  const { t } = useTranslation();
  const state:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: true}});
      setEditCountry(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: true}}); 
    }          
  }, []);  

  const edit = (event:SyntheticEvent, index:CountryDto) => {    
    setAddCountry(false)
    setCountrySelected(index);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_FORM_UPDATE, value: index}); 
    setEditCountry(true);
  }

  const removeConfirmation = (event:SyntheticEvent, country:CountryDto) => {
    setCountrySelected(country);
    setShowModalDeleteCountry(true);
  }

  const remove = () => {
    setShowModalDeleteCountry(false);
    if (countrySelected !== null && countrySelected.id !== undefined) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_DELETE_LOADING, value: countrySelected.id});
    }  
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_FORM_NEW});
    setEditCountry(true);
    setAddCountry(true);
  }  

  const back = () => {
    setEditCountry(false);
  }

  const update = () => {
    setEditCountry(false);
    const data:CountrySendDto = {
      id: addCountry ? undefined : countrySelected.id,
      name: state.countries.countriesForm.description.value as string,
      code: state.countries.countriesForm.code.value as string,
      language: state.countries.countriesForm.language.value as string,
      phoneCode: state.countries.countriesForm.phoneCode.value as string,
    }
    if (addCountry) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ADD_LOADING, value: data});      
    } else {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_EDIT_LOADING, value: data});
    }
  }  

  

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.countries.loading && !state.countries.error && <Loading />}
            {!state.countries.loading && !state.countries.error && !editCountry && <CountriesList 
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.countries.loading && !state.countries.error && editCountry && <CountriesEdit update={update} back={back} add={addCountry}/>}
           
            {showModaldeleteCountry && <ModalConfirmDelete show={showModaldeleteCountry} title={t('admin.countries.modal_delete_title')} back={()=>setShowModalDeleteCountry(false)} confirm={remove} textButtonBack={t('admin.countries.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.countries.modal_delete_buttonDelete')} />}
            
            {state.countries.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.countries.edit}
              add={state.countries.add}
              delete={state.countries.delete}
              editMessage={t('admin.countries.editMessage')}
              addMessage={t('admin.countries.addMessage')}
              delteMessage={t('admin.countries.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Countries;
