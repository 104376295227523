export interface Params {
        method: string,
        body?: string | FormData, 
        headers:{
            'Content-Type'?: string,
            Authorization?: string
        }  
}

//export const getBaseUrlApi = ():string =>  process.env.NODE_ENV === "development" ? '' : 'https://swappback.azurewebsites.net';
//export const getBaseUrlApi = ():string => 'https://swappback.azurewebsites.net';

//export const getBaseUrlApi = ():string => 'https://api-swap-dev.azurewebsites.net/';


//export const getBaseUrlApi = ():string => 'https://api-swap-pro.azurewebsites.net';
export const getBaseUrlApi = ():string => 'https://swapp.biz';

export const getFetchParams = (method: string, body: any, token: string | null, ):Params => {
    const params:Params = {
        method: method,
        headers: {
            'Content-Type': 'Application/json',
        }
    };
    if (body !== null) {
        params.body = JSON.stringify(body);
    }
    if (token !== null) {
        params.headers.Authorization = 'Bearer ' + token;
    }

    return params;
    
}