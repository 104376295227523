import { TypeActions } from '../../models/actions/typeActions';
import { ActionSkillsType } from '../../models/actions/typeSkillsActions';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import { initialSkillsState } from '../inititalState/initialSkillsState';

const skillReducer = (state:TypeSkillsState = initialSkillsState, action:TypeActions):TypeSkillsState => {
    switch (action.type) {        

        case ActionSkillsType.SKILLS_LOADING: {           
            return {...state,  loading: true, error: false}
        }

        case ActionSkillsType.SKILLS_SUCCESS: {            
            return {...state, loading: false, data: action.value.data, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionSkillsType.SKILLS_ERROR: {            
            return {...state,  loading: false, error: true, data: [], messageError: action.value}
        }
        
        case ActionSkillsType.SKILLS_EDIT_LOADING: {
            return {...state, loading: true, error: false, edit: false}
        }

        case ActionSkillsType.SKILLS_EDIT_SUCCESS: {
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionSkillsType.SKILLS_DELETE_LOADING: {           
            return {...state, loading: true, error: false, delete: false}
        }

        case ActionSkillsType.SKILLS_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionSkillsType.SKILLS_ADD_LOADING: {           
            return {...state, loading: true, error: false, add: false}
        }

        case ActionSkillsType.SKILLS_ADD_SUCCESS: {            
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionSkillsType.SKILLS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionSkillsType.SKILLS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionSkillsType.SKILLS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }        
        
        case ActionSkillsType.SKILLS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
        
    }
    return {...state};
}
export default skillReducer;