import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType, actionLoginServerSendData, actionLightSignupLoading, actionActivateAccountLoading, actionResendActivationCodeLoading, actionRecoverAccountPasswordLoading } from '../models/actions/typeLoginActions';
import loginResponseDto from '../models/dto/LoginResponseDto';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import LightSignupResponseDto from '../models/dto/LightSignupResponseDto';
import TypeState from '../models/initialState/typeState';
import TypeMainState from '../models/initialState/typeMainState';
import ActivateAccountResponseDto from '../models/dto/ActivateAccountResponseDto';

const baseUrlApi:string = getBaseUrlApi();
const urlLogin = baseUrlApi + '/api/Account/Signin';
const urlRenewToken = baseUrlApi + '/api/Account/RenewToken';
const urlLightSignup = baseUrlApi + '/api/Account/LightSignup';
const urlActivateAccount = baseUrlApi + '/api/Account/ActivateAccount';
const urlResendActivationCode = baseUrlApi + '/api/Account/ResendActivationCode';
const urlRecoverAccountPassword = baseUrlApi + '/api/Account/RecoverAccountPassword'

function isAuth(role:number) {  
    return role < 3;    
  }

function* login(action: actionLoginServerSendData) {
    try {        
        
        const response:Response = yield call(fetch, urlLogin, getFetchParams('POST', action.value, null));
        if (response.ok) {
            const data:loginResponseDto = yield call([response, 'json']);
            if (isAuth(data.role.id) ) {                
                yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_SUCCESS, value: data});
            } else {
                yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
            }            
        } else {           
            yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorAccess'});
        }                
                
    } catch (e) {  
        console.log(e);      
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorServer'});
    }
}

function* renewToken() {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlRenewToken, getFetchParams('POST', null, state.token));
        if (response.ok) {
            const data:loginResponseDto = yield call([response, 'json']);
            if (isAuth(data.role.id) ) {                
                yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_SUCCESS, value: data});
            } else {
                yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
            }            
        } else {           
            yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorAccess'});
        }                
                
    } catch {        
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorServer'});
    }
}

function* lightSigup(action: actionLightSignupLoading) {
    try {
        const response:Response = yield call(fetch, urlLightSignup, getFetchParams('POST', action.value, null));
        if (response.ok) {
            const data:LightSignupResponseDto = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionLoginType.LIGHTSIGNUP_SUCCESS, value: { data: data,
                                                                                        prefix: action.value.phonePrefix,
                                                                                        phone: action.value.phone}});                   
        } else {
            yield put<TypeActions>({type: ActionLoginType.LIGHTSIGNUP_ERROR, value: {error: true, alreadyExist: false}});    
        }            
    } catch (e) {             
        yield put<TypeActions>({type: ActionLoginType.LIGHTSIGNUP_ERROR, value: {error: true, alreadyExist: false}});
    }
}

function* activateAccount(action: actionActivateAccountLoading) {
    try {
        const response:Response = yield call(fetch, urlActivateAccount, getFetchParams('POST', action.value, null));
        if (response.ok) {          
            const data:ActivateAccountResponseDto = yield call([response, 'json']);              
            yield put<TypeActions>({type: ActionLoginType.ACTIVATE_ACCOUNT_SUCCESS, value: data});                   
        } else  {         
            yield put<TypeActions>({type: ActionLoginType.ACTIVATE_ACCOUNT_ERROR});
        }
    } catch (e) {        
        yield put<TypeActions>({type: ActionLoginType.ACTIVATE_ACCOUNT_ERROR});
    }
}

function* resendActivationCode(action: actionResendActivationCodeLoading) {
    try {        
        
        const response:Response = yield call(fetch, urlResendActivationCode, getFetchParams('POST', action.value, null));
        if (response.ok) {                     
            yield put<TypeActions>({type: ActionLoginType.RESEND_ACTIVATION_CODE_SUCCESS});        }                 
         else {
            yield put<TypeActions>({type: ActionLoginType.RESEND_ACTIVATION_CODE_ERROR});
        }
                
    } catch (e) {             
        yield put<TypeActions>({type: ActionLoginType.RESEND_ACTIVATION_CODE_ERROR});
    }
}

function* recoverAccountPassword(action: actionRecoverAccountPasswordLoading) {
    try {
        const response:Response = yield call(fetch, urlRecoverAccountPassword, getFetchParams('POST', action.value, null));
        if (response.ok) {                     
            yield put<TypeActions>({type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_SUCCESS});        }                 
         else {
            const data:string = yield call([response, 'text']);
            yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: data});
            yield put<TypeActions>({type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_ERROR})
        }       
    } catch (e) {             
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorServer'});
        yield put<TypeActions>({type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_ERROR})
    }
}

function* loginWhatcher() {
    yield takeLatest(ActionLoginType.LOGIN_SERVER_SEND_DATA, login);
}

function* renewTokenWatcher() {
    yield takeLatest(ActionLoginType.LOGIN_RENEWTOKEN_LOADING, renewToken);
}

function* lightSigupWatcher() {
    yield takeLatest(ActionLoginType.LIGHTSIGNUP_LOADING, lightSigup);
}

function* activateAccountWatcher() {
    yield takeLatest(ActionLoginType.ACTIVATE_ACCOUNT_LOADING, activateAccount);
}

function* resendActivationCodeWatcher() {
    yield takeLatest(ActionLoginType.RESEND_ACTIVATION_CODE_LOADING, resendActivationCode);
}

function* recoverAccountPasswordWatcher() {
    yield takeLatest(ActionLoginType.RECOVER_ACCOUNT_PASSWORD_LOADING, recoverAccountPassword);
}


export default function* rootLoginSaga() {
    yield all([        
        loginWhatcher(),
        renewTokenWatcher(),
        lightSigupWatcher(),
        activateAccountWatcher(),
        resendActivationCodeWatcher(),
        recoverAccountPasswordWatcher()
    ])
}