import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../table/Table';
//import './Jobs.scss';
import PushHistoryJobDto from '../../models/dto/PushHistoryJobDto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { convertDateHourIso, dateHour_time } from '../../utils/utils';
import Users from '@material-ui/icons/SupervisedUserCircleRounded';
import ToggleOff from '@material-ui/icons/ToggleOff';
import ToggleOn from '@material-ui/icons/ToggleOn';
import PublicIcon from '@material-ui/icons/Public';
import Cached from '@material-ui/icons/Cached';
import ModalInfo from '../commons/ModalInfo';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import AmbassadorListForPush from './AmbassadorListForPush';
import Pagination from '@mui/material/Pagination';
import AmbassadorWithKpisDto from '../../models/dto/AmbassadorWithKpisDto';

interface Props {
    title: string,
    back():void,
    reload(): void
}

function JobsHistoryPushList(props: Props) {
  const [showList, setShowList] = useState(false);
  const [dataModal, setDataModal] = useState<ReactNode>(<></>);
  const [titleModal, setTitleModal] = useState('');
  const [showAmbassadorListForPush, setShowAmbassadorListForPush] = useState(false);
  const [pushSelected, setPushSelected] = useState(0);
  const [page, setPage] = useState(1);
  const [listToShow, setListToShow] = useState<AmbassadorWithKpisDto[]>([]);
  const { t } = useTranslation();
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const dispatch = useDispatch();

  const SIZE_lIST = 5;
  
  const modalCountriesExcluded = (event: SyntheticEvent, index: PushHistoryJobDto) => {
    const countries = ():ReactNode => index.countryExceptions.map(country => 
      <div key={country.id}>{country.description}</div>
    );
    setDataModal(countries);
    setTitleModal('Countries excluded List');    
    setShowList(true);
  }

  const modalAmbassadors = (event: SyntheticEvent, index: PushHistoryJobDto) => {
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_LOADING, value: index.pushId});    
    setPushSelected(index.pushId);
    setShowAmbassadorListForPush(true);    
  }

  const updatePushStatus = (event: SyntheticEvent, index: PushHistoryJobDto) => {
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_UPDATE_PUSH_STATUS_LOADING, value: {
      status: {
        pushId: index.pushId,
        status: index.status.id === 0 ? 1 : 0
      },
      jobId: index.jobId
    }});
  }

  const descriptionStatus = (rowData:PushHistoryJobDto)=>{
    const now = new Date();
    let description = rowData.status.description;
    if (now > new Date(rowData.endDateTime)) {
      description = 'Expired'
    }
    return description
  }

  const headers = [
    {title: t('admin.jobs.label_status'), field: 'status.id', sorting: false, render: ((rowData:PushHistoryJobDto) => <span className={descriptionStatus(rowData).toLowerCase()}>{descriptionStatus(rowData)}</span>)},
    {title: t('admin.jobs.label_endDateTime'), field: 'endDateTime', render: (rowData:PushHistoryJobDto) => convertDateHourIso(rowData.endDateTime)},
    {title: t('admin.jobs.label_createdDate'), field: 'createdDate', render: (rowData:PushHistoryJobDto) => convertDateHourIso(rowData.createdDate)},
    {title: t('admin.jobs.label_veryHighFee'), field: 'veryHighFee'},
    {title: t('admin.jobs.label_highFee'), field: 'highFee'},
    {title: t('admin.jobs.label_lowFee'), field: 'lowFee'},    
    {title: t('admin.jobs.label_timeToEmbassadors'), field: 'timeToEmbassadors', render: (rowData:PushHistoryJobDto) => dateHour_time(rowData.createdDate, rowData.timeToEmbassadors)},
    {title: t('admin.jobs.label_timeToNetwork'), field: 'label_timeToNetwork', render: (rowData:PushHistoryJobDto) => dateHour_time(rowData.createdDate, rowData.timeToNetwork)},
  ];

  const actions = [
      (rowData:PushHistoryJobDto) => {
        const row = rowData as PushHistoryJobDto;
        const today = new Date();
        const endDate = new Date(row.endDateTime);
        return {
            icon: row.status.id === 1 ? ToggleOn : ToggleOff,
            disabled: today > endDate,                                     
            tooltip: row.status.id === 1 ? t('table.tooltip.actionStatusPushDesactive') : t('table.tooltip.actionStatusPushActive'),
            onClick: updatePushStatus
      }},
      {
        icon: Users,
        tooltip: t('table.tooltip.actionHistoryAmbassadors'),
        disabled: false,
        onClick: modalAmbassadors
      },
      {
        icon: PublicIcon,
        tooltip: t('table.tooltip.actionCountriesExcluded'),
        disabled: false,
        onClick: modalCountriesExcluded
      },
  ];

  const back = ()=>{
    setShowAmbassadorListForPush(false);
  }
  
  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    /* Al cambiar de pagina borro todas las ramas del arbol*/
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_RESET});
    setPage(value);
    const start = (value-1) * SIZE_lIST;
    const end = (value * SIZE_lIST) -1;
    /* se extrae la sub lista a mostrar */
    setListToShow(state.historyPush.ambassadorList.data.filter((elem,index)=> index >= start && index <= end));
  };

  useEffect(()=>{
    /* La primera vez se muestran los 5 primeros */
    setListToShow(state.historyPush.ambassadorList.data.filter((elem,index)=> index < SIZE_lIST));
  }, [state.historyPush.ambassadorList.data])
     
return <div className='swissinx-container'>
          {!showAmbassadorListForPush 
          ? <>
            <h2 className="swissinx__reload"> <div>
                <FontAwesomeIcon icon={faArrowLeft} onClick={props.back}/> 
                {t('admin.jobs.listHistoryPushTitle')}
              </div>
              <Cached onClick={props.reload} titleAccess="Reload"/> 
            </h2>
            <h3>{props.title}</h3>
            <Table<PushHistoryJobDto> 
              data={state.historyPush.data} 
              actions={actions} 
              headers={headers} 
            />
            <ModalInfo show={showList} confirm={()=>setShowList(false)} data={dataModal} title={titleModal} textButtonConfirm='Ok' />          
          </>
          : <>
                <h2><FontAwesomeIcon icon={faArrowLeft} onClick={back}/> {t('admin.jobs.listAmbassadors')}</h2>
                <h3>{props.title}</h3>
                {state.historyPush.ambassadorList.data.length > 0 && <>
                
                {/* {state.historyPush.ambassadorList.data */}
                {listToShow
                  .map((ambassador, index)=><AmbassadorListForPush key={'main-' + index}
                                                pushId={pushSelected} 
                                                ambassador={ambassador}           
                                                /* branch={state.historyPush.ambassadorPathway[index]} */
                                                branch={state.historyPush.ambassadorPathway[SIZE_lIST * (page -1) + index]}
                                                index={index}
                                                page={page}
                                                />)
                }
                {state.historyPush.ambassadorList.data.length > SIZE_lIST && <Pagination count={Math.ceil(state.historyPush.ambassadorList.data.length / SIZE_lIST)} page={page} onChange={onChangePage} />}
                </>}
                {state.historyPush.ambassadorList.data.length === 0 && <p>{t('admin.jobs.noAmbassadorsMessage')}</p>}
          </>
          }  

        </div>; 
}

export default JobsHistoryPushList;