
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TypeState from '../../models/initialState/typeState';
import FeeDto from '../../models/dto/FeeDto'; 
import TypeFeeState from '../../models/initialState/typeFeeState';

interface Props {
    edit(event: any, index:FeeDto): void,
    remove(event: any, index:FeeDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function FeeList(props: Props) {
  const { t } = useTranslation();
  const state:TypeFeeState = useSelector((state:TypeState) => state.feeReducer);
  /* const stateSkills: TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);
  const stateLanguages:TypeLanguagesState = useSelector((state:TypeState) => state.languagesReducer);  */

  const headers = [{title: t('admin.fee.name'), field: 'description', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: '' },
                  //  {title: t('admin.fee.language'), field: 'languageIds', render: (rowData: FeeDto)=> `${rowData.languageIds.map(language=>getLabelLanguage(language, stateLanguages.data, stateLanguages.loading, stateLanguages.error))} ` }, 
                  //  {title: t('admin.fee.skill'), field: 'skillIds', render: (rowData: FeeDto)=> `${rowData.skillIds.map(skill=>getLabelSkill(skill, stateSkills.data, stateSkills.loading, stateSkills.error))} ` },  
                  //  {title: t('admin.fee.market'), field: 'marketIds', render: (rowData: FeeDto)=> `${rowData.marketIds.map(market=>getLabelMarket(market, stateMarkets.data, stateMarkets.loading, stateMarkets.error))} ` },
                   {title: t('admin.fee.veryHighFee'), field: 'veryHighFee', defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: '' },
                   {title: t('admin.fee.highFee'), field: 'highFee', defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: '' },
                   {title: t('admin.fee.lowFee'), field: 'lowFee', defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: '' },  
                   {title: t('admin.jobs.label_timeToEmbassadors'), field: 'timeToEmbassadors', defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: '' },
                   {title: t('admin.jobs.label_timeToNetwork'), field: 'timeToNetwork', defaultSort: state.filters.orderBy === 5 ? state.filters.orderDirection: '' }                   
                  ];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.fee.listTitle')}</h2>
            <Table<FeeDto> 
              data={state.data} 
              headers={headers} 
              actions={actions} 
              new={true}
              initialPage={state.initialPage}
              serchText={state.searchText}
              pageSize={state.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}/>
        </div>;
  }

export default FeeList;