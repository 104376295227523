/*
* Funciones utilizadas para validar campos de formularios
*/

export const validateEmail = function (value:string):boolean {
    if (value !== '') {
        return !(value.match(/\b(^(\S+@).+((\..{2,2})|(\..{3,3})|(\..{4,4}))$$)\b/gi));       
    } else {
        return false;
    } 
}

export const validatePassword = function (value:string):boolean {
    //const patron =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    //const patron =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    const length = value.length;
    if (length >= 6 && length <= 15) {
        return false
    } else  {
        return true;
    }   
}

export const validatePhone = function (value:string):boolean {
    const patron =  /^[+]?[0-9]{8,15}$/;    
    if (value.match(patron)) {
        return false
    } else  {
        return true;
    }  
}

export const validateLogin = function (value:string):boolean { 
   if (!validatePhone(value) || !validateEmail(value)) {
       return false;
   } else {
       return true;
   }
}

export const validateNumber = function (value:string):boolean {
        //const patron =  /^[0-9]+([.][0-9]+)?$/; 
        const patron = /^\d*\.?\d*$/;
        if (patron.test(value)) {
            return false;
        } else  {
            return true;
        }
 }

 export const validateSalaryMax = function (value: string, value2: string):boolean {
     if ((value!== '') && parseFloat(value2) >= 0 && parseFloat(value) >= 0) {
        return (parseFloat(value2) > parseFloat(value))
     } else {
         return false;
     }     
 }

 export function validateUrl(url: string) {
    let validUrl;
    
    try {
        validUrl = new URL(url);
    } catch (_) {
      return false;  
    }
  
    return validUrl.protocol === "http:" || validUrl.protocol === "https:";
  }