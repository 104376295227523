import TypeJobsState from '../../models/initialState/typeJobsState';
import { ALL, DEFAULTITEMSPERPAGE, newFormJob, newPushJobForm } from '../../utils/utils';

export const initialJobState: TypeJobsState = {      
      jobsResponseDto: null,
      pushJobDto: null,
      pushJobForm: newPushJobForm(),
      pushJobSuccess: false,
      jobsForm: newFormJob(),
      loading: false,
      jobsData: [],
      error: false,
      messageError: '',      
      languagesLoading: false,
      languagesError: false,
      languages: [],     
      edit: false,
      add: false,
      delete: false,
      filters: {orderBy: 0, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      jobPushedFilter: -1,
      jobStatusFilter: -1,
      jobsSkillsFilter: ALL,
      jobsMarketsFilter: ALL,
      jobsRegionsFilter: ALL,
      jobsFiltered: [],
      jobSelected: {
        loading: false,
        error: false,
        data: null,
        messageError: ''
      }, 
      historyPush: {
        loading: false,
        data: [],
        error: false,
        ambassadorList: {
          loading: false,
          error: false,
          data: []
        },
        ambassadorPathway: [],
        ambassadorPathwayLoading: false,
        ambassadorPathwayError: false
      },
      templates: {
        loading: false,
        error: false,
        data: [],
        edit: false,
        add: false,
        delete: false,
        filters: {orderBy: 0, orderDirection: 'desc'},
        initialPage: 0,
        searchText: '',
        pageSize: DEFAULTITEMSPERPAGE,
      },
      candidates: {
        loading: false,
        error: false,
        data: [],
        selected: {
          loading: false,
          error: false,
          ok: false,
          messageError: ''
        },
        status: {
          loading: false,
          error: false,
          ok: false
        },
        pathway: {
          loading: false,
          error: false,
          data: []
        },
        downloadFile: {
          error: false
        },
        filters: {orderBy: 0, orderDirection: 'desc'},
        initialPage: 0,
        searchText: '',
        pageSize: DEFAULTITEMSPERPAGE,    
      },
      kpisJobs: [],
      downloadToXml: {
        loading: false,
        error: false,
        ok: false
      },
      uploadToXml: {
        loading: false,
        error: false,
        data: null,
        ok: false
      },
      updatePushStatus: {
        loading: false,
        error: false,
        ok: false
      },
      jobActivity: {
        loading: false,
          error: false,
          data: null
      } 
    }