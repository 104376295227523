import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { useMemo } from 'react';
import './PublicJob.scss';
import TypeLocationState from '../../models/initialState/typeLocationState';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FieldText from '../Form/FieldText/FieldText';
import GooglePlay from '../../assest/GooglePlay.svg';
import AppStore from '../../assest/AppStore.svg';
import TypeState from '../../models/initialState/typeState';
import Flag from 'react-flagpack';
import MenuItem from '@mui/material/MenuItem';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionPublicJobType } from '../../models/actions/typePublicJobActions';
import shareWhite from '../../assest/shareWhite.svg';
import DownloadApp from './DownloadApp';

interface Props {
  shareAgain(): void
}

function ShareJobFinish(props: Props) {
  const { t } = useTranslation();
  const state:TypePublicJobState = useSelector((state:TypeState) => state.publicJobReducer);
  const stateLocation:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const dispatch = useDispatch();
  const codesOut = useMemo(()=>{
    //Estos codigos dan error, no muestro las banderas.
    return ['AN', 'ZR', 'GB'];
  }, [])

  const shareAgain = ()=>{
    /* if (state.activateAccount.ok && state.linkOpened.linkOpenedData !== null && state.lightSignup.lightSignupData !== null) {
      dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_LOADING, value: {
          originNetworkerId: state.lightSignup.lightSignupData.networkerId,
          parentLinkId: state.updateLinkNetworkerId.newLink
      }});
    }
    props.shareAgain(); */
    if (state.linkOpened.linkOpenedData) {
      dispatch<TypeActions>({type: ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_LOADING, value: {
        forwardId: state.linkOpened.linkOpenedData.forwardId,
        pushId: state.linkOpened.linkOpenedData.pushId
    }});
    }
    
  }
  
  return <div className='swissinx-job-finish-block swissinx-job-body'>
        <div className='swissinx-job-body-block'>
            <h2 className='swissinx-job-container-column__title'>{t('publicJob.shareJobFinishTitle')}</h2>
            <button className='public-job-button' onClick={shareAgain}>
                {/* <img src={shareWhite} alt="Share icon"></img> */}
                {t('publicJob.shareAgain')}
            </button>
        </div>

        <div className='swissinx-job-body-block swissinx-job-body-block--dark'>
            <h2 className='swissinx-job-container-column__subtitle'>{t('publicJob.shareJobFinishSubtitle1')}</h2>
            <p className='swissinx-job-container-column__description'>{t('publicJob.shareJobFinishDescription1')}</p>
            
            <div className='swissinx-job-container-phone send-password'>    
                  <div className='swissinx-job-container-prefix'>           
                    <FormControl fullWidth>             
                      <Select
                        labelId="prefix"
                        id="prefix"
                        value={state.lightSignup.prefix}               
                        disabled={true}
                      >
                        {stateLocation.countriesWithoutPushExceptions.data.map(country => <MenuItem  key={country.id}  value={country.phoneCode}>
                          {country.phoneCode} {codesOut.indexOf(country.code) === -1 && <Flag code={country.code} size="m" gradient="real-linear"/>} {country.code}</MenuItem> )}
                      </Select>
                    </FormControl>
                  </div>        
                  <div className='swissinx-job-container-number'>    
                    <FieldText     
                        label= ''
                        placeholder = ''
                        name='phone'                                                            
                        required={true}               
                        requiredError = {t('publicJob.phoneErrorRequired')}
                        value={state.lightSignup.phone}
                        error={false}
                        disabled={true}                                                      
                        onChange = {({value, error})=>{}}        
                      />
                  </div>                  
            </div>

            <p className='swissinx-job-container-column__description'>{t('publicJob.shareJobFinishDescription2')}</p>     
          </div>       
            <DownloadApp />
            
        </div>  
}

export default ShareJobFinish;