import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {eng} from './en';

i18next
    .use(initReactI18next)
    .init({
        interpolacion: {
            escapeValue: false
        },
        lng: 'en',
        resources: {
            en: {
                translation: eng
            }
        }
    });

export default i18next;