import JobsResponseDto from '../../models/dto/JobsResponseDto';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface Props {
    job: JobsResponseDto | null    
  }

export default function DashboardJobActivityMaps(props: Props) { 
  
  const center = { lat: 48.85341, lng: 2.3488 };
  const markers = [{ lat: 40.41831, lng: -3.70256 }, { lat: 46.818188, lng: 8.227512 }, { lat: 48.85341, lng: 2.3488 }];

  return (
    <>    
        <LoadScript googleMapsApiKey="">
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '500px'
                }}     
                center={center}
                zoom={4}
                >
            { /* Child components, such as markers, info windows, etc. */ }
                {markers.map(marker => <Marker position={marker} label={'12'}/>)}
            </GoogleMap>
        </LoadScript>
    </>
  );
}

