import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.scss';
import { useEffect, useMemo, useState } from 'react';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import TypeState from '../../models/initialState/typeState';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMarketsType, TypeActionMarketsActions } from '../../models/actions/typeMarketsActions';
import { ActionSkillsType, TypeActionSkillsActions } from '../../models/actions/typeSkillsActions';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Chip, InputLabel, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { getLabelMarket, getLabelSkill } from '../../utils/utils';
import { TypeActions } from '../../models/actions/typeActions';
import MarketDto from '../../models/dto/MarketDto';
import SkillDto from '../../models/dto/SkillDto';
import TypeDashboardState from '../../models/initialState/typeDashboardState';
import { ActionDashboardType } from '../../models/actions/typeDashboardActions';
import { UserKpi, UserPerformanceIndicators } from '../../models/dto/UserDashboardDto';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import NetworkerDto from '../../models/dto/NetworkerDto';
import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import { ActionNetworkersType } from '../../models/actions/typeNetworkersActions';
import Table from '../table/Table';

interface Props {
  setOption(option: number): void
}

interface Filter {
  skillIds: number[],
  marketIds: number[],
  dateStart: string,
  dateEnd: string,
  usersList: number[]
}
const today = new Date();

function UserDashboard(props: Props) {
  const [dataFilter, setDataFilter] = useState<Filter>({ skillIds: [], marketIds: [], usersList: [], dateStart: today.toISOString(), dateEnd: today.toISOString() });
  const [showFilterUser, setShowFilterUser] = useState(false);
  const [showData, setShowdata] = useState(false);
  const state: TypeDashboardState = useSelector((state: TypeState) => state.dashboardReducer);
  const stateSkills: TypeSkillsState = useSelector((state: TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state: TypeState) => state.marketsReducer);
  const stateNetworkers: TypeNetworkersState = useSelector((state: TypeState) => state.networkersReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({ type: ActionMarketsType.MARKETS_LOADING, value: { reset: false } });
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({ type: ActionSkillsType.SKILLS_LOADING, value: { reset: false } });
    }
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_LOADING, value: { reset: true } });

  }, []);

  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    setShowdata(false);
    const value = event.target.value;
    let sendValue: number[];
    typeof value === 'string' ? sendValue = [parseInt(value)] : sendValue = value;
    if (name === 'skillIds') {
      setDataFilter({ ...dataFilter, skillIds: sendValue });
    } else {
      setDataFilter({ ...dataFilter, marketIds: sendValue });
    }

  };

  const filter = () => {
    dispatch<TypeActions>({ type: ActionDashboardType.USER_DASHBOARD_LOADING, value: dataFilter });
    setShowdata(true);
  }

  const reset = () => {
    setShowdata(false);
    setDataFilter({ ...dataFilter, skillIds: [], marketIds: [], dateStart: today.toISOString(), dateEnd: today.toISOString() });
  }

  const onChangeDate = (date: MaterialUiPickersDate, name: string) => {
    const newDate = date !== null ? date.toISOString() : new Date().toISOString();
    if (name === 'dateStart') {
      setDataFilter({ ...dataFilter, dateStart: newDate });
    } else {
      setDataFilter({ ...dataFilter, dateEnd: newDate });
    }
  }

  const onSelectionChange = (data: NetworkerDto[]) => {
    setDataFilter({ ...dataFilter, usersList: data.map(elem => elem.networkerId) });
  }

  const warningPickedUpJobs = useMemo(() => state.user.data &&
    state.user.data.kpis &&
    state.user.data.kpis.maxApplicationsPerJob > 20 ? 'warning' : '', [state]);

  return <div className='swissinx-dashboard-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={() => props.setOption(0)} />{t('admin.dashboard.titleUserDashboard')}</h2>

    <div className='swissinx-dashboard-block'>
      <div>
        <h3 className='swissinx-dashboard-filter'>{t('admin.dashboard.filter')}</h3>
        <div className='swissinx-dashboard-field-container'>

          <div className="swissinx-dashboard-field">
            <div className="swissinx-dashboard-table-filter" onClick={() => setShowFilterUser(!showFilterUser)}>
              <div className="swissinx-dashboard-table-filter__text">
                <span>{t('admin.dashboard.titleUsers')}</span>
                <span>({dataFilter.usersList.length}) {t('admin.dashboard.selected')}</span>
              </div>
              <div className="swissinx-dashboard-table-filter__icon">
                <FontAwesomeIcon icon={showFilterUser ? faAngleUp : faAngleDown} />
              </div>
            </div>
          </div>

          <div className='swissinx-dashboard-field'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="swissinx-jobs-field swissinx-jobs-field-date field-dateStart">
                <KeyboardDatePicker
                  label={t('admin.dashboard.dateStart')}
                  format="DD/MM/yyyy"
                  inputVariant="outlined"
                  value={new Date(dataFilter.dateStart as string)}
                  onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'dateStart')}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>

          <div className='swissinx-dashboard-field'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="swissinx-jobs-field swissinx-jobs-field-date field-dateEnd">
                <KeyboardDatePicker
                  label={t('admin.dashboard.dateEnd')}
                  format="DD/MM/yyyy hh:mm"
                  inputVariant="outlined"
                  value={new Date(dataFilter.dateEnd as string)}
                  onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'dateEnd')}

                />
              </div>
            </MuiPickersUtilsProvider>
          </div>

          <div className="swissinx-dashboard-field field-skills">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="skillId">{t('admin.jobs.label_skills')}</InputLabel>
              <Select
                labelId="skillId"
                id="skillId"
                label={t('admin.jobs.label_skills')}
                multiple
                value={dataFilter.skillIds}
                onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'skillIds')}
                input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_skills')} />}
                error={false}
                renderValue={(selected: number[]) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: number) => (
                      <Chip key={value} label={getLabelSkill(value, stateSkills.data, stateSkills.loading, stateSkills.error)} />
                    ))}
                  </Box>
                )}
              >
                {stateSkills.data.map((skill: SkillDto) => (
                  <MenuItem
                    key={skill.skillId}
                    value={skill.skillId}
                  >
                    {skill.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="swissinx-dashboard-field field-market">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="marketId">{t('admin.jobs.label_markets')}</InputLabel>
              <Select
                labelId="marketId"
                id="marketId"
                label={t('admin.jobs.label_markets')}
                multiple
                value={dataFilter.marketIds}
                onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'marketIds')}
                input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_markets')} />}
                error={false}
                renderValue={(selected: number[]) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: number) => (
                      <Chip key={value} label={getLabelMarket(value, stateMarkets.data, stateMarkets.loading, stateMarkets.error)} />
                    ))}
                  </Box>
                )}
              >
                {stateMarkets.data.map((market: MarketDto) => (
                  <MenuItem
                    key={market.marketId}
                    value={market.marketId}
                  >
                    {market.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="swissinx-dashboard-container-buttons">
            <button className="swissinx-dashboard-button-back" type="button" onClick={reset}>{t('admin.jobs.text_button_reset')}</button>
            <button className="swissinx-dashboard-button-submit" type="button" onClick={filter}>{t('admin.jobs.text_button_filter')}</button>
          </div>

        </div>
      </div>
    </div>

    {showFilterUser && <div className='swissinx-jobs-table-container'>
      {/* <h4>{t('admin.dashboard.usersList')}</h4> */}
      <Table<NetworkerDto>
        data={stateNetworkers.networkersData}
        headers={[
          { title: t('admin.networkers.label_name'), field: 'name' },
          { title: t('admin.networkers.label_surname'), field: 'surname' },
          { title: t('admin.networkers.label_phone'), field: 'phone' },
          { title: t('admin.networkers.label_email'), field: 'email' }
        ]}
        onSelectionChange={onSelectionChange}
        selection={true}
      />
    </div>}

    {state.user.loading && <p>{t('admin.dashboard.loading')}</p>}
    {state.user.error && <p>{t('admin.dashboard.error')}</p>}
    {showData && <div className='swissinx-dashboard-kpi-container'>
      <h3>{t('admin.dashboard.kpi')}</h3>
      {state.user.data && state.user.data.kpis && <div className='swissinx-dashboard-kpi-block'>
        {Object.keys(state.user.data.kpis).map(kpi => <div key={kpi} className={`swissinx-dashboard-kpi ${kpi === "maxApplicationsPerJob" ? warningPickedUpJobs : ""}`}>
          <p>{t('admin.dashboard.' + kpi)}</p>
          <p>{state.user.data && state.user.data.kpis[kpi as keyof UserKpi]}</p>
        </div>)
        }
      </div>}

      <h3>{t('admin.dashboard.performanceIndicator')}</h3>
      {state.user.data && state.user.data.performanceIndicators && <div className='swissinx-dashboard-kpi-block'>
        {Object.keys(state.user.data.performanceIndicators).map(performance => <div key={performance} className='swissinx-dashboard-kpi'>
          <p>{t('admin.dashboard.' + performance)}</p>
          <p>{state.user.data && state.user.data.performanceIndicators[performance as keyof UserPerformanceIndicators]}</p>
        </div>)
        }
      </div>}

    </div>}
  </div>
}

export default UserDashboard;