
import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { ActionSkillsType } from '../../models/actions/typeSkillsActions';
import { useSelector, useDispatch } from 'react-redux';
import SkillDto from '../../models/dto/SkillDto'; 
import SkillsList from './SkillsList';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
//import './Skills.scss';
import SkillsEdit from './SkillsEdit';
import { ActionSkillGroupType } from '../../models/actions/typeSkillGroupActions';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Skills(props: Props) {
  const [editSkill, setEditSkill] = useState(false);
  const [deleteSkill, setDeleteSkill] = useState(false);
  const [addSkill, setAddSkill] = useState(false);  
  const [skillEdit, setSkillEdit] = useState({skillId: 0, name: '', skillGroupId: 0});
  const [skillRemove, setSkillRemove] = useState({skillId: 0, name: '', skillGroupId: 0});
  
  const { t } = useTranslation();
  const state:TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: true}});
      setEditSkill(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: true}});       
    }          
  }, []);  

  const edit = (event:SyntheticEvent, index:SkillDto) => {    
    setSkillEdit(index);
    setEditSkill(true);
    setAddSkill(false);
  }

  const removeConfirmation = (event:SyntheticEvent, skill:SkillDto) => {
    setSkillRemove(skill);
    setDeleteSkill(true);  
  }

  const newRegister = () => {
    setSkillEdit({skillId: 0, name: '', skillGroupId: 0});
    setEditSkill(true);
    setAddSkill(true);
  }  

  const back = () => {
    setEditSkill(false);
  }

  const update = (skill: SkillDto) => {
    setEditSkill(false);
    if (addSkill) {
      dispatch<TypeActions>({type: ActionSkillsType.SKILLS_ADD_LOADING, value: {name: skill.name, skillGroupId: skill.skillGroupId}});      
    } else {
      dispatch<TypeActions>({type: ActionSkillsType.SKILLS_EDIT_LOADING, value: skill});
    }
    //setEditSkill(false);
  }  

  const remove = () => {
    dispatch<TypeActions>({type: ActionSkillsType.SKILLS_DELETE_LOADING, value: skillRemove.skillId});
    setDeleteSkill(false);
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionSkillsType.SKILLS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionSkillsType.SKILLS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionSkillsType.SKILLS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionSkillsType.SKILLS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }
  

  return <> 
            {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editSkill && <SkillsList 
                                                                edit={edit} 
                                                                remove={removeConfirmation} 
                                                                newRegister={newRegister}
                                                                changePage={changePage}
                                                                changeSearchText={changeSearchText}
                                                                changeRowsPerPage={changeRowsPerPage}
                                                                onOrderChange={onOrderChange}/>}
            {!state.loading && !state.error && editSkill && <SkillsEdit data={skillEdit} update={update} back={back} add={addSkill}/>}
           
            {deleteSkill && <ModalConfirmDelete show={deleteSkill} title={t('admin.skills.modal_delete_title')} back={()=>setDeleteSkill(false)} confirm={remove} textButtonBack={t('admin.skills.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.skills.modal_delete_buttonDelete')} />}
            
            {state.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={state.messageError !== '' ? state.messageError : t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.skills.editMessage')}
              addMessage={t('admin.skills.addMessage')}
              delteMessage={t('admin.skills.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionSkillsType.SKILLS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionSkillsType.SKILLS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionSkillsType.SKILLS_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Skills;
