import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import { DEFAULTITEMSPERPAGE, newFormField } from '../../utils/utils';

export const initialCompanyGroupsState: TypeCompanyGroupsState = {
      loading: false,
      error: false,
      data: [],
      companyGroupsForm: {
        name: newFormField('',true, false)
      },
      filters: {orderBy: 0, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false
}