import TypeClientsState from '../../models/initialState/typeClientsState';
import { DEFAULTITEMSPERPAGE, newFormClient } from '../../utils/utils';

export const initialClientsState: TypeClientsState = {
    clientsData: [],
    loading: false,
    error: false,
    clientForm: newFormClient(),
    filters: {orderBy: 0, orderDirection: 'asc'},
    initialPage: 0,
    searchText: '',
    pageSize: DEFAULTITEMSPERPAGE,
    edit: false,
    add: false,
    delete: false 
}