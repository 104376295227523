
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import './ModalConfirmDelete.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 480,
    width: 480,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

interface Props {
    title: string,
    textButtonBack: string,
    textButtonConfirm: string,
    show: boolean,
    back(): void,
    confirm(): void
}


function ModalConfirmDelete(props: Props) {


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.show}
        onClose={props.back}
    >
        <Box sx={style}>
            <div className='swissin-company-groups-modal-delete'>
                <Error />
                <h2>{props.title}</h2>

                <div className='swissin-company-groups-modal-buttons'>
                    <button className='swissin-button__secondary' onClick={props.back}>{props.textButtonBack}</button>
                    <button className='swissin-button__primary' onClick={props.confirm}>{props.textButtonConfirm}</button>
                </div>
            </div>
        </Box>
    </Modal>

}

export default ModalConfirmDelete;
