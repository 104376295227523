import React, { useState, useMemo, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FieldText from '../Form/FieldText/FieldText';
import { validatePhone } from '../Form/validators';
import ErrorFieldForm from '../../models/forms/ErrorFieldForm';
import FiledError from '../Form/common/FieldError/FiledError';
import Flag from 'react-flagpack';
import { APPLY_JOB, PREFIX_DEFAULT } from '../../constanst/GlobalConstants';
import TypeLocationState from '../../models/initialState/typeLocationState';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CountryDto } from '../../models/dto/LocationDto';
import './GetSMSRegistration.scss';

interface Props {
  sendSMS(prefix: string, phone: string): void,
  type: number
}

function GetSMSRegistration(props: Props) {
  const [prefix, setPrefix] = useState<string | null>(PREFIX_DEFAULT);
  const [country, setCountry] = useState<CountryDto | null>({
    code: "CH",
    description: "Switzerland",
    id: 38,
    language: "de",
    phoneCode: "+41"
  });
  const [phone, setPhone] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);
  const [conditions, setConditions] = useState(false);
  const [errorConditions, setErrorConditions] = useState(false);
  const { t } = useTranslation();
  const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
  const stateLocation: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);

  const codesOut = useMemo(() => {
    //Estos codigos dan error, no muestro las banderas.
    return ['AN', 'ZR', 'GB'];
  }, [])
  
  const changePhone = (value: string, error: ErrorFieldForm) => {
    setPhone(value);
    setErrorPhone((error.required || error.format));
  }

  const changeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setConditions(event.target.checked);
    setErrorConditions(!event.target.checked);
  }

  const getSMSCode = () => {
    if (prefix && phone !== '' && !errorPhone && conditions && !state.lightSignup.loading) {
      props.sendSMS(prefix, phone);
    } else {
      setErrorPhone(phone === '');
      setErrorConditions(!conditions);
    }
  }

  const changePrefix = (event: React.SyntheticEvent<Element, Event>, value: CountryDto | null, reason: any) => {    
    setPrefix(value !== null ? value.phoneCode : null);
    setCountry(value);    
  }

  const changePrefix2 = (event: React.SyntheticEvent<Element, Event>, value: string | null, reason: any) => {
    if (value !== '') {
      const phoneCode = value?.split('-')[0];
      if (phoneCode) {
        const country = stateLocation.countriesWithoutPushExceptions.data.find(country => country.phoneCode === phoneCode);
        if (country) {
          setCountry(country);
          setPrefix(phoneCode);
        }
      }
    }
  }

  const jobName = state.publicJob.data && state.publicJob.data.jobTitle ? state.publicJob.data.jobTitle : ''

  try {
    return <>
        <div>
          <h2 className='swissinx-job-container-column__title'>{props.type === APPLY_JOB ? jobName : t('publicJob.wellcome')}</h2>
          {props.type === APPLY_JOB && <p className='swissinx-job-container-column__subtitle'>{t('publicJob.subtitleGetSMSCode')}</p>}
          <p className='swissinx-job-container-column__description'>{props.type === APPLY_JOB ? t('publicJob.descriptionGetSMSCodeApply') : t('publicJob.descriptionGetSMSCodeShare')}</p>
          <div className='swissinx-job-container-phone'>
            <div className='swissinx-job-group-prefix'>
              <div className='swissinx-job-container-prefix'>
                <Autocomplete
                  id="prefix"
                  sx={{ width: 300 }}
                  options={stateLocation.countriesWithoutPushExceptions.data}
                  autoHighlight
                  getOptionLabel={(option) => option.phoneCode + ' (' + option.description + ')'}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.description}
                      {codesOut.indexOf(option.code) === -1 && <Flag code={option.code} size="m" gradient="real-linear" />}
                      ({option.phoneCode})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Prefix"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: '',
                      }}
                    />
                  )}
                  value={country}
                  onInputChange={changePrefix2}
                  onChange={changePrefix} />
              </div>
              {!prefix && <FiledError messageError={t('publicJob.prefixError')} />}
            </div>
            <div className='swissinx-job-container-number'>
              <FieldText
                label=''
                placeholder=''
                type='tel'
                name='phone'
                required={true}
                requiredError={t('publicJob.phoneErrorRequired')}
                value={phone}
                error={errorPhone}
                validators={[
                  { validator: validatePhone, messageError: t('publicJob.phoneErrorFormat') }
                ]}
                onChange={({ value, error }) => { changePhone(value, error) }}
              />
            </div>
          </div>
          <div className="swissinx-job-container-conditions">
            <FormControlLabel control={<Checkbox checked={conditions} onChange={changeCheck} />} label={t('publicJob.conditions') as string} />
            <p className='swissinx-job-container-column__description'>
              <a href={t('publicJob.termsUrl')} target="_blank" rel="noreferrer">{t('publicJob.terms')}</a>
              {t('publicJob.conditions2')}
              <a href={t('publicJob.termsUrl2')} target="_blank" rel="noreferrer">{t('publicJob.terms2')}</a>
            </p>
            {errorConditions && <FiledError messageError={t('publicJob.termsError')} />}
          </div>
        </div>
        <div className="swissinx-job-container-column__buttons">
          <button className='public-job-button' type="button" onClick={getSMSCode} disabled={state.lightSignup.loading}>{state.lightSignup.loading ? t('publicJob.loadingSMSCode') : t('publicJob.getMySMSCode')}</button>
          {state.lightSignup.error && <FiledError messageError={t('publicJob.serverError')} />}
        </div>
    </>
  } catch (e) {
    console.log(e);
    return <></>
  }
}

export default GetSMSRegistration;