import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './ComponentError.scss';

interface Props {
    title: string,
    message: string,
    buttonMessage: string,
    onClick():void
}

function ComponentError(props:Props) {

return <div className='swissinx-component-error'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <h2>{props.title}</h2>
            <p>{props.message}</p>
            <button onClick={props.onClick}>{props.buttonMessage}</button>
        </div> 
}

export default ComponentError;