import JobsForm from '../../models/forms/JobsForm';
import { ActionsType, TypeActions } from '../../models/actions/typeActions';

import { ActionJobsType } from '../../models/actions/typeJobsActions';

import { jobDtoResponseToForm, jobsFilter, newFormJob, newPushJobForm } from '../../utils/utils';

import PushJobForm from '../../models/forms/PushJobForm';

import { ActionTemplateJobType } from '../../models/actions/typeTemplateJobActions';
import { initialJobState } from '../inititalState/initialJobsState';
import TypeJobsState, { ambassadorPath } from '../../models/initialState/typeJobsState';
import { ContactData } from '../../models/dto/JobActivityDto';

const jobsReducer = (state:TypeJobsState = initialJobState, action:TypeActions):TypeJobsState => {
    switch (action.type) {       
                
        case  ActionJobsType.JOBS_FORM_SEND_VALUES:
        case  ActionJobsType.JOBS_FORM_SEND_BOOLEAN_VALUES:
        case  ActionJobsType.JOBS_FORM_SEND_ARRAY_VALUES: {
            const name = action.value.name;            
            let jobsForm:JobsForm = {...state.jobsForm};
            jobsForm[name as keyof JobsForm] = {...jobsForm[name as keyof JobsForm], value: action.value.value, error: action.value.error};         
            return {...state, jobsForm: { ...jobsForm} }
        }

        case  ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES: {
            const name = action.value.name;            
            let pushJobForm:PushJobForm = {...state.pushJobForm};
            const rules = pushJobForm[name as keyof PushJobForm].rules;            
            pushJobForm[name as keyof PushJobForm] = {...pushJobForm[name as keyof PushJobForm], value: action.value.value, error: action.value.error, rules: {...rules, ...action.value.rules}};         
            return {...state, pushJobForm: { ...pushJobForm} }
        }
        
        case ActionJobsType.JOBS_PUSH_ERROR_REQUIRED : {
            const name = action.value;           
            let pushJobForm:PushJobForm = {...state.pushJobForm};
            pushJobForm[name as keyof PushJobForm] =  {...pushJobForm[name as keyof PushJobForm], error: {required: true, format: pushJobForm[name as keyof PushJobForm].error.format}};
            return {...state, pushJobForm: { ...pushJobForm}  }      
        }

        case ActionJobsType.JOBS_FORM_NEW: {
            let jobsForm:JobsForm = newFormJob();           
            return {...state, jobsForm: { ...jobsForm} }
        }

        case ActionJobsType.JOBS_FORM_UPDATE: {
            let jobsForm:JobsForm = newFormJob();           
            return {...state, jobsForm: { ...jobsForm, ...action.value} }
        }

        case ActionJobsType.JOBS_ERROR_REQUIRED : {
            const name = action.value;          
            let jobsForm:JobsForm = {...state.jobsForm};
            jobsForm[name as keyof JobsForm] =  {...jobsForm[name as keyof JobsForm], error: {required: true, format: jobsForm[name as keyof JobsForm].error.format}};
            return {...state, jobsForm: { ...jobsForm}  }      
        }        

        case ActionJobsType.JOBS_LOADING: {                   
            return {...state, loading: true, error: false, messageError:''}
        }

        case ActionJobsType.JOBS_SUCCESS: {       
            /* let jobsFiltered;
            if (state.jobPushedFilter === -1 && state.jobStatusFilter === -1) {
                jobsFiltered = action.value.data;
            } else if (state.jobPushedFilter === -1) {
                jobsFiltered = action.value.data.filter(job=>job.jobStatusExtended.id === state.jobStatusFilter);
            } else if (state.jobStatusFilter === -1) { 
                const jobPushed = (state.jobPushedFilter === -1)
                jobsFiltered = action.value.data.filter(job=>job.hasBeenPushed === jobPushed);
            } else {
                const jobPushed = (state.jobPushedFilter === -1)
                jobsFiltered = action.value.data.filter(job=>job.hasBeenPushed === jobPushed && job.jobStatusExtended.id === state.jobStatusFilter);
            } */

            return {...state, 
                loading: false, 
                error: false, 
                jobsData: action.value.data, 
                jobsFiltered: jobsFilter(action.value.data, state.jobStatusFilter, state.jobPushedFilter, state.jobsSkillsFilter, state.jobsMarketsFilter, state.jobsRegionsFilter), 
                messageError:'', 
                searchText: action.value.reset ? '' : state.searchText, 
                initialPage: action.value.reset ? 0 : state.initialPage}           
        }    
        case ActionsType.SEND_COMPANY_SELECTED: {
            return {...state, jobsData: [], jobsFiltered: []}
        }

        case ActionJobsType.JOBS_ERROR: {            
            return {...state, loading: false, error: true, messageError: action.value}            
        }



        case ActionJobsType.JOBS_GET_JOB_LOADING: { 
            const jobSelected = state.jobSelected;                  
            return {...state, jobSelected:{...jobSelected, loading: true, error: false, data: null, messageError:''}}
        }

        case ActionJobsType.JOBS_GET_JOB_SUCCESS: {          
            const jobSelected = state.jobSelected;
            if (action.value) {
                let jobsForm:JobsForm = jobDtoResponseToForm(action.value); 
                return {...state, jobSelected:{...jobSelected, loading: false, error: false, data: action.value, messageError:''}, jobsForm: jobsForm }
            }                      
            return {...state, jobSelected:{...jobSelected, loading: false, error: false, data: action.value, messageError:''} }     
        }        

        case ActionJobsType.JOBS_GET_JOB_ERROR: {            
            const jobSelected = state.jobSelected;                  
            return {...state, jobSelected:{...jobSelected, loading: false, error: false, data: null, messageError: action.value, }}           
        }

        case ActionJobsType.JOBS_EDIT_LOADING: {           
            return {...state, edit: false, loading: true, error: false}            
        }

        case ActionJobsType.JOBS_EDIT_SUCCESS: {           
            return {...state, edit: action.value, loading: false, error: false}            
        }

        case ActionJobsType.JOBS_ADD_LOADING: {           
            return {...state, add: false, loading: true, error: false}            
        }

        case ActionJobsType.JOBS_ADD_SUCCESS: {          
            return {...state, add: action.value, loading: false, error: false}            
        }

        case ActionJobsType.JOBS_DELETE_LOADING: {           
            return {...state, delete: false, loading: true, error: false}            
        }

        case ActionJobsType.JOBS_DELETE_SUCCESS: {          
            return {...state, delete: action.value, loading: false, error: false}            
        }            

        case ActionJobsType.JOBS_SEND_PUSH_FORM_NEW: {
            let pushJobForm:PushJobForm = newPushJobForm();           
            return {...state,pushJobForm: { ...pushJobForm} }
        }

        case ActionJobsType.JOBS_SEND_PUSH_LOADING: {           
            return {...state, loading: true, error: false, pushJobSuccess: false}            
        }

        case ActionJobsType.JOBS_SEND_PUSH_SUCCESS: {           
            return {...state, loading: false, error: false, pushJobSuccess: action.value}          
        }
        
        case ActionJobsType.JOBS_HISTORY_PUSH_LOADING: {           
            const jobsPushHistory = state.historyPush;
            return {...state, historyPush : {...jobsPushHistory, loading: true, error: false, data: []}}           
        }

        case ActionJobsType.JOBS_HISTORY_PUSH_SUCCESS: {          
            const jobsPushHistory = state.historyPush;
            return {...state, historyPush : {...jobsPushHistory, loading: false, error: false, data: action.value}}            
        }

        case ActionJobsType.JOBS_HISTORY_PUSH_ERROR: {           
            const jobsPushHistory = state.historyPush;
            return {...state, historyPush : {...jobsPushHistory, loading: false, error: true, data: []}}            
        }
        
        case ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_LOADING: {           
            const jobsPushHistory = state.historyPush;
            const ambassadorList = state.historyPush.ambassadorList;
            return {...state, historyPush : {...jobsPushHistory, ambassadorPathway: [], ambassadorList: {...ambassadorList, loading: true, error: false, data: []}}}           
        }

        case ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_SUCCESS: {          
            const jobsPushHistory = state.historyPush;
            const ambassadorList = state.historyPush.ambassadorList;
            action.value.forEach(ambassador=>{jobsPushHistory.ambassadorPathway.push(
                {networker: ambassador.networkerId,
                 data: ambassador,
                 path: [],
                 pathId: [ambassador.networkerId]
                }
            )});            
            return {...state, historyPush : {...jobsPushHistory, ambassadorList: {...ambassadorList, loading: false, error: false, data: action.value}}}           
        }

        case ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_ERROR: {           
            const jobsPushHistory = state.historyPush;
            const ambassadorList = state.historyPush.ambassadorList;
            return {...state, historyPush : {...jobsPushHistory, ambassadorList: {...ambassadorList, loading: false, error: true, data: []}}}              
        }

        case ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING: {          
            const jobsPushHistory = state.historyPush;

            return {...state, historyPush : {...jobsPushHistory, ambassadorPathwayLoading: true, ambassadorPathwayError: false}}      
        }

        case ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR: {          
            const jobsPushHistory = state.historyPush;

            return {...state, historyPush : {...jobsPushHistory, ambassadorPathwayLoading: false, ambassadorPathwayError: true}};            
        }
     
        case ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_SUCCESS: { 
            /* Se insertan los datos recibidos en la lista ambassadorPathway formando un arbol. Se busca el networker y en el campo path se añaden los nuevos datos*/         
            const jobsPushHistory = state.historyPush;
            const path = action.value.localPath;
            let node = state.historyPush.ambassadorPathway;
            let nodeAux:ambassadorPath | null= null;
            let pathIdMain: number[] = [];
            path.forEach(p=>{
                const aux = node.find(elem=>elem.networker === p);
                if (aux) {
                    node = aux.path ? aux.path : [];
                    nodeAux = aux;
                    pathIdMain = aux.pathId.slice();
                } 
            })
            if (nodeAux !== null) {
                action.value.data.forEach(elem=>{
                    if (nodeAux) {
                        const pathIdAux = pathIdMain.slice();          
                        pathIdAux.push(elem.networkerId);                                                   
                        const d: ambassadorPath = {
                            networker: elem.networkerId,
                            data: elem,
                            path: [],
                            pathId: pathIdAux           
                        }
                        nodeAux.path.push(d);
                    }
                })}
            
            return {...state, historyPush : {...jobsPushHistory, ambassadorPathwayLoading: false, ambassadorPathwayError: false}}           
        }

        case ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_RESET: {
            /* Se eliminan todas las ramas de la lista */       
            const jobsPushHistory = state.historyPush;
            jobsPushHistory.ambassadorPathway.map(elem => {elem.path = []})
            return {...state, historyPush : {...jobsPushHistory}};            
        }
        
        case ActionJobsType.JOBS_UPDATE_PUSH_STATUS_LOADING: {           
            const updatePushStatus = state.updatePushStatus;
            return {...state, updatePushStatus : {...updatePushStatus, loading: true, error: false, ok: false}}            
        }

        case ActionJobsType.JOBS_UPDATE_PUSH_STATUS_SUCCESS: {           
            const updatePushStatus = state.updatePushStatus;
            return {...state, updatePushStatus : {...updatePushStatus, loading: false, error: false, ok: true}}  
        }

        case ActionJobsType.JOBS_UPDATE_PUSH_STATUS_ERROR: {           
            const updatePushStatus = state.updatePushStatus;
            return {...state, updatePushStatus : {...updatePushStatus, loading: false, error: true, ok: false}}    
        }
                    
        case ActionJobsType.JOBS_CANDIDATES_LOADING: {           
            const candidates = state.candidates;
            return {...state, candidates : {...candidates, loading: true, error: false, data: []}}            
        }

        case ActionJobsType.JOBS_CANDIDATES_SUCCESS: {           
            const candidates = state.candidates;
            return {...state, candidates : {...candidates, loading: false, error: false, data: action.value, searchText:'', initialPage: 0}}            
        }

        case ActionJobsType.JOBS_CANDIDATES_ERROR: {           
            const candidates = state.candidates;
            return {...state, candidates : {...candidates, loading: false, error: true, data: []}}            
        }
       
        case ActionJobsType.JOBS_APPLICATION_STATUS_LOADING: {           
            const candidates = state.candidates;
            const status = state.candidates.status;
            return {...state, candidates : {...candidates, status: {...status, loading: true, error: false}} }          
        }

        case ActionJobsType.JOBS_APPLICATION_STATUS_SUCCESS: {           
            const candidates = state.candidates;
            const status = state.candidates.status;
            return {...state, candidates : {...candidates, status: {...status, loading: false, error: false, ok: action.value}}}            
        }

        case ActionJobsType.JOBS_APPLICATION_STATUS_ERROR: {
            const candidates = state.candidates;
            const status = state.candidates.status;
            return {...state, candidates : {...candidates, status: {...status, loading: false, error: true, ok: false}}}            
        }
       
        case ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING: {           
            const candidates = state.candidates;
            const selected = state.candidates.selected;
            return {...state, candidates : {...candidates, selected: {...selected, loading: true, error: false, messageError: ''}} }          
        }

        case ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_SUCCESS: {           
            const candidates = state.candidates;
            const selected = state.candidates.selected;
            return {...state, candidates : {...candidates, selected: {...selected, loading: false, error: false, ok: action.value, messageError: ''}}}            
        }

        case ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR: {
            const candidates = state.candidates;
            const selected = state.candidates.selected;
            return {...state, candidates : {...candidates, selected: {...selected, loading: false, error: action.value.error, ok: false, messageError: action.value.message}}}            
        }       

        case ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_ERROR: {
            const candidates = state.candidates;
            const dowloadFile = state.candidates.downloadFile;
            dowloadFile.error = action.value;
            return {...state, candidates : {...candidates, ...dowloadFile}}            
        }

        case ActionJobsType.JOBS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionJobsType.JOBS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionJobsType.JOBS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }
       
        case ActionJobsType.JOBS_CANDIDATES_CHANGE_SEARCH_TABLE: {
            const candidates = state.candidates;                    
            return {...state, candidates: { ...candidates, searchText: action.value}}      
        }

        case ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_TABLE: {
            const candidates = state.candidates;                   
            return {...state, candidates: { ...candidates, initialPage: action.value}}         
        }

        case ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_SIZE: { 
            const candidates = state.candidates;                       
            return {...state, candidates: { ...candidates, pageSize: action.value}}           
        }

        case ActionJobsType.JOBS_CANDIDATES_PATHWAY_LOADING: { 
            const candidates = state.candidates;                     
            return {...state, candidates: { ...candidates, pathway:{loading: true, error: false, data:[]} }}           
        }
        
        case ActionJobsType.JOBS_CANDIDATES_PATHWAY_SUCCESS: { 
            const candidates = state.candidates;                     
            return {...state, candidates: { ...candidates, pathway:{loading: false, error: false, data: action.value} }}           
        }

        case ActionJobsType.JOBS_CANDIDATES_PATHWAY_ERROR: { 
            const candidates = state.candidates;                     
            return {...state, candidates: { ...candidates, pathway:{loading: false, error: true, data: []} }}           
        }
        
        
        case ActionTemplateJobType.TEMPLATE_JOB_LOADING: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: true, error: false, data: []}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_SUCCESS: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: false, error: false, data: action.value}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_ERROR: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: false, error: true, data: []}}           
        }

        case ActionTemplateJobType.TEMPLATE_JOB_EDIT_LOADING: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: true, error: false, edit: false}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_EDIT_SUCCESS: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: false, error: false, edit: action.value}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_ADD_LOADING: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: true, error: false, add: false}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_ADD_SUCCESS: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: false, error: false, add: action.value}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_DELETE_LOADING: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: true, error: false, delete: false}}            
        }

        case ActionTemplateJobType.TEMPLATE_JOB_DELETE_SUCCESS: {           
            const templates = state.templates;
            return {...state, templates : {...templates, loading: false, error: false, delete: action.value}}            
        }

        case ActionTemplateJobType.TEMPLATE_CHANGE_SEARCH_TABLE: {
            const templates = state.templates;
            return {...state, templates : {...templates, searchText: action.value}}
        }

        case ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_TABLE: { 
            const templates = state.templates;
            return {...state, templates : {...templates, initialPage: action.value}}
        }

        case ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_SIZE: {
            const templates = state.templates;
            return {...state, templates : {...templates, pageSize: action.value}}
        }

        case ActionJobsType.JOBS_EXPORT_TO_XML_LOADING: {           
            const downloadToXml = state.downloadToXml;
            return {...state, downloadToXml : {...downloadToXml, loading: true, error: false, ok: false}}            
        }

        case ActionJobsType.JOBS_EXPORT_TO_XML_SUCCESS: {           
            const downloadToXml = state.downloadToXml;
            return {...state, downloadToXml : {...downloadToXml, loading: false, error: false, ok: action.value}}     
        }

        case ActionJobsType.JOBS_EXPORT_TO_XML_ERROR: {           
            const downloadToXml = state.downloadToXml;
            return {...state, downloadToXml : {...downloadToXml, loading: false, error: action.value, ok: false}}        
        }
        
        case ActionJobsType.JOBS_IMPORT_TO_XML_LOADING: {           
            const uploadToXml = state.uploadToXml;
            return {...state, uploadToXml : {...uploadToXml, loading: true, error: false, ok: false}}            
        }

        case ActionJobsType.JOBS_IMPORT_TO_XML_SUCCESS: {           
            const uploadToXml = state.uploadToXml;
            return {...state, uploadToXml : {...uploadToXml, loading: false, error: false, data: action.value, ok: true}}     
        }

        case ActionJobsType.JOBS_IMPORT_TO_XML_ERROR: {           
            const uploadToXml = state.uploadToXml;
            return {...state, uploadToXml : {...uploadToXml, loading: false, error: action.value, ok: false}}        
        }

        case ActionJobsType.JOBS_IMPORT_TO_XML_CHANGE_STATUS: {           
            const uploadToXml = state.uploadToXml;
            return {...state, uploadToXml : {...uploadToXml, loading: false, error: false, ok: action.value}}     
        }        
        
        case ActionJobsType.JOBS_ACTIVITY_LOADING: {           
            const jobActivity = state.jobActivity;            
            return {...state, jobActivity : {...jobActivity, loading: true, error: false, data: null}}            
        }

        case ActionJobsType.JOBS_ACTIVITY_SUCCESS: {           
            const jobActivity = state.jobActivity;
            jobActivity.data = action.value;
            jobActivity.data.pushes.forEach(push=>push.contacts.forEach(contact=>{
                contact.path=null;
                contact.pathId=[contact.networkerId];
            }));
            jobActivity.data.pickedUp.contacts.forEach(pickedUp =>{
                pickedUp.path=null;
                pickedUp.pathId=[pickedUp.networkerId];
            });
            return {...state, jobActivity : {...jobActivity, loading: false, error: false}}     
        }

        case ActionJobsType.JOBS_ACTIVITY_ERROR: {           
            const jobActivity = state.jobActivity;
            return {...state, jobActivity : {...jobActivity, loading: false, error: true, data: null}}        
        }

        case ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING: {           
            const jobActivity = state.jobActivity;            
            return {...state, jobActivity : {...jobActivity, loading: true, error: false}}            
        }

        case ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_SUCCESS: {           
            const jobActivity = state.jobActivity;
            if (jobActivity.data) {
                if (action.value.type === 'push') {               
                    const push = jobActivity.data.pushes.find(elem=>elem.pushId === action.value.pushId);
                    if (push) {
                        let node:ContactData[] = push.contacts;
                        let contact: ContactData =  node[0];
                        action.value.pathId.forEach(id=> {
                            const nodeAux = [...node.filter(networker=>networker.networkerId===id)];
                            if (nodeAux[0].path) {
                                node = nodeAux[0].path;                                                      
                            }
                            contact = nodeAux[0];                                           
                        });
                        if (contact && contact.hasOwnProperty('path')) {
                            contact.path = action.value.data;
                            contact.path.forEach(contact => {
                                contact.path = null;
                                const pathIdAux = [...action.value.pathId];
                                pathIdAux.push(contact.networkerId);
                                contact.pathId = [...pathIdAux];
                            })
                        }                        
                    }      
                } else {
                    if (jobActivity.data.pickedUp) {
                        let node:ContactData[] = jobActivity.data.pickedUp.contacts;
                        let contact: ContactData =  node[0];
                        action.value.pathId.forEach(id=> {
                            const nodeAux = [...node.filter(networker=>networker.networkerId===id)];
                            if (nodeAux[0].path) {
                                node = nodeAux[0].path;                                                      
                            }
                            contact = nodeAux[0];                                           
                        });
                        if (contact && contact.hasOwnProperty('path')) {
                            contact.path = action.value.data;
                            contact.path.forEach(contact => {
                                contact.path = null;
                                const pathIdAux = [...action.value.pathId];
                                pathIdAux.push(contact.networkerId);
                                contact.pathId = [...pathIdAux];
                            })
                        }                        
                    }      
                }
            }           

            return {...state, jobActivity : {...jobActivity, loading: false, error: false}}     
        }

        case ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR: {           
            const jobActivity = state.jobActivity;
            return {...state, jobActivity : {...jobActivity, loading: false, error: true}}        
        }
        
        case ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING:{
            return {...state, loading: true, error: false, messageError:''}
        }

        case ActionJobsType.JOB_GET_STATUS_EXTENDED_ERROR:{
            return {...state, loading: false, error: true}  
        }

        case ActionJobsType.JOB_GET_STATUS_EXTENDED_SUCCESS:{
            const {jobId,data} = action.value
            const copyJobsData = [...state.jobsData]
            const index = copyJobsData.findIndex(job=>job.jobId===jobId)
            copyJobsData[index]={...copyJobsData[index],jobStatusExtended:data}
            return {...state, jobsData:copyJobsData, loading: false, error: false,}
        }

        case ActionJobsType.JOB_GET_CANDIDATURE_STATUS_LOADING:{
            return {...state, loading: true, error: false, messageError:''}
        }

        case ActionJobsType.JOB_GET_CANDIDATURE_STATUS_ERROR:{
            return {...state, loading: false, error: true}  
        }

        case ActionJobsType.JOB_GET_CANDIDATURE_STATUS_SUCCESS:{
            const {jobId,data} = action.value
            const copyJobsData = [...state.jobsData]
            const index = copyJobsData.findIndex(job=>job.jobId===jobId)
            copyJobsData[index]={...copyJobsData[index],jobCandidatureStatus:data}
            return {...state, jobsData:copyJobsData, loading: false, error: false,}
        }        
        
        case ActionJobsType.JOBS_STATUS_FILTER:{
            const status = action.value;
            /* let jobsFiltered;
            if (status === -1 && state.jobPushedFilter === -1) {
                jobsFiltered = state.jobsData;
            } else if (status === -1) {
                const jobPushed = (state.jobPushedFilter === 1) 
                jobsFiltered = state.jobsData.filter(job=>job.hasBeenPushed === jobPushed);
            } else if (state.jobPushedFilter === -1) {
                jobsFiltered = state.jobsData.filter(job=>job.jobStatusExtended.id === status);     
            } else {
                const jobPushed = (state.jobPushedFilter === 1) 
                jobsFiltered = state.jobsData.filter(job=>job.jobStatusExtended.id === status && job.hasBeenPushed === jobPushed);          
            }                     
            
            return {...state, initialPage: 0, jobsFiltered: jobsFiltered, jobStatusFilter: action.value}  */ 
            return {...state, 
                initialPage: 0, 
                jobsFiltered: jobsFilter(state.jobsData, status, state.jobPushedFilter, state.jobsSkillsFilter, state.jobsMarketsFilter, state.jobsRegionsFilter),
                jobStatusFilter: action.value
            }
        }

        case ActionJobsType.JOBS_PUSHED_FILTER:{
            const pushed = action.value;
            /* let jobsFiltered;
            if (pushed === -1 && state.jobStatusFilter === -1) {
                jobsFiltered = state.jobsData;
            } else if (pushed === -1) {
                jobsFiltered = state.jobsData.filter(job=>job.jobStatusExtended.id === state.jobStatusFilter);
            } else if (state.jobStatusFilter === -1) { 
                const jobPushed = (pushed === 1)
                jobsFiltered = state.jobsData.filter(job=>job.hasBeenPushed === jobPushed);
            } else {
                const jobPushed = (pushed === 1)
                jobsFiltered = state.jobsData.filter(job=>job.hasBeenPushed === jobPushed && job.jobStatusExtended.id === state.jobStatusFilter);
            }                
            
            return {...state, initialPage: 0, jobsFiltered: jobsFiltered, jobPushedFilter: action.value}   */
            return {...state, initialPage: 0, jobsFiltered: jobsFilter(state.jobsData, state.jobStatusFilter, pushed, state.jobsSkillsFilter, state.jobsMarketsFilter, state.jobsRegionsFilter), jobPushedFilter: action.value}
        } 

        case ActionJobsType.JOBS_SKILLS_FILTER:{
            const skill = action.value;
            
            return {...state, initialPage: 0, jobsFiltered: jobsFilter(state.jobsData, state.jobStatusFilter, state.jobPushedFilter, skill, state.jobsMarketsFilter, state.jobsRegionsFilter), jobsSkillsFilter: action.value}  
        } 

        case ActionJobsType.JOBS_MARKETS_FILTER:{
            const market = action.value;
            
            return {...state, initialPage: 0, jobsFiltered: jobsFilter(state.jobsData, state.jobStatusFilter, state.jobPushedFilter, state.jobsSkillsFilter, market, state.jobsRegionsFilter), jobsMarketsFilter: action.value}  
        }

        case ActionJobsType.JOBS_REGIONS_FILTER:{
            const region = action.value;
                        
            return {...state, initialPage: 0, jobsFiltered: jobsFilter(state.jobsData, state.jobStatusFilter, state.jobPushedFilter, state.jobsSkillsFilter, state.jobsMarketsFilter, region), jobsRegionsFilter: action.value}  
        } 

        case ActionJobsType.JOBS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }

        case ActionJobsType.JOBS_CANDIDATES_CHANGE_FILTER: {      
            const candidates = state.candidates;
            return {...state, candidates : {...candidates, filters: action.value}}                          
        }
    }

    return {...state};
}
export default jobsReducer;
