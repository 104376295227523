
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TypeState from '../../models/initialState/typeState';
import MarketDto from '../../models/dto/MarketDto';
import TypeMarketsState from '../../models/initialState/typeMarketsState';

interface Props {
    edit(event: any, index:MarketDto): void,
    remove(event: any, index:MarketDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function MarketsList(props: Props) {
  const { t } = useTranslation();
  const state:TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);  

  const headers = [{title: t('admin.markets.name'), field: 'name', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''}];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.markets.listTitle')}</h2>
            <Table<MarketDto> 
              data={state.data} 
              headers={headers} 
              actions={actions} 
              new={true}
              initialPage={state.initialPage}
              serchText={state.searchText}
              pageSize={state.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}/>
        </div>;
  }

export default MarketsList;