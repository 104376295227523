import CompanyGroupsDto from '../dto/CompanyGroupsDto';

export enum ActionCompanyGroupsType {    
    COMPANY_GROUPS_ERROR_REQUIRED = 'COMPANY_GROUPS_ERROR_REQUIRED',
    COMPANY_GROUPS_LOADING = 'COMPANY_GROUPS_LOADING',
    COMPANY_GROUPS_SUCCESS = 'COMPANY_GROUPS_SUCCESS',
    COMPANY_GROUPS_ERROR = 'COMPANY_GROUPS_ERROR',
    COMPANY_GROUPS_ADD_LOADING = 'COMPANY_GROUPS_ADD_LOADING',
    COMPANY_GROUPS_ADD_SUCCESS = 'COMPANY_GROUPS_ADD_SUCCESS',
    COMPANY_GROUPS_ADD_ERROR = 'COMPANY_GROUPS_ADD_ERROR',
    COMPANY_GROUPS_EDIT_LOADING = 'COMPANY_GROUPS_EDIT_LOADING',
    COMPANY_GROUPS_EDIT_SUCCESS = 'COMPANY_GROUPS_EDIT_SUCCESS',
    COMPANY_GROUPS_EDIT_ERROR = 'COMPANY_GROUPS_EDIT_ERROR',
    COMPANY_GROUPS_DELETE_LOADING = 'COMPANY_GROUPS_DELETE_LOADING',
    COMPANY_GROUPS_DELETE_SUCCESS = 'COMPANY_GROUPS_DELETE_SUCCESS',
    COMPANY_GROUPS_DELETE_ERROR = 'COMPANY_GROUPS_DELETE_ERROR',
    COMPANY_GROUPS_CHANGE_SEARCH_TABLE = 'COMPANY_GROUPS_CHANGE_SEARCH_TABLE',
    COMPANY_GROUPS_CHANGE_PAGE_TABLE = 'COMPANY_GROUPS_CHANGE_PAGE_TABLE',
    COMPANY_GROUPS_CHANGE_PAGE_SIZE = 'COMPANY_GROUPS_CHANGE_PAGE_SIZE',
    COMPANY_GROUPS_CHANGE_FILTER = 'COMPANY_GROUPS_CHANGE_FILTER'
}

export interface actionCompanyGroupsErrorRequired {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR_REQUIRED,
    value: string
}

export interface actionCompanyGroupsLoading {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING,
    value: {reset: boolean} 
}
export interface actionCompanyGroupsSuccess {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_SUCCESS,
    value: {data: CompanyGroupsDto[], reset: boolean}
}
export interface actionCompanyGroupsError {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR   
}

export interface actionCompanyGroupsAddLoading {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_LOADING,
    value: string
}
export interface actionCompanyGroupsAddSuccess {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_SUCCESS
    value: boolean
}
    
export interface actionCompanyGroupsAddError {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_ERROR   
}

export interface actionCompanyGroupsEditLoading {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_LOADING,
    value: CompanyGroupsDto
}
export interface actionCompanyGroupsEditSuccess {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionCompanyGroupsEditError {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_ERROR   
}

export interface actionCompanyGroupsDeleteLoading {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_LOADING,
    value: number
}
export interface actionCompanyGroupsDeleteSuccess {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_SUCCESS
    value: boolean
}
    
export interface actionCompanyGroupsDeleteError {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_ERROR   
}

export interface actionCompanyGroupsChangeSerachTable {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionCompanyGroupsChangePageSizeTable {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionCompanyGroupsChangePageTable {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionCompanyGroupsChangeFilterTable {
    type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionCompanyGroupsActions = actionCompanyGroupsErrorRequired |  
                            actionCompanyGroupsLoading | actionCompanyGroupsSuccess | actionCompanyGroupsError |
                            actionCompanyGroupsAddLoading | actionCompanyGroupsAddSuccess | actionCompanyGroupsAddError |
                            actionCompanyGroupsEditLoading | actionCompanyGroupsEditSuccess | actionCompanyGroupsEditError |
                            actionCompanyGroupsDeleteLoading | actionCompanyGroupsDeleteSuccess | actionCompanyGroupsDeleteError |
                            actionCompanyGroupsChangeSerachTable | actionCompanyGroupsChangePageSizeTable | actionCompanyGroupsChangePageTable |
                            actionCompanyGroupsChangeFilterTable;