import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SyntheticEvent, useEffect, useState } from 'react';
import CompanyList from './CompanyList';
import CompanyDto from '../../models/dto/CompanyDto';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import { ActionCompanyGroupsType } from '../../models/actions/typeCompanyGroupActions';
import CompanyEdit from './CompanyEdit';
import { companyDtoToForm, companyFormToDto } from '../../utils/utils';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
//import './Company.scss';
import Loading from '../commons/Loading';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
import CompanyUpdateDto from '../../models/dto/CompanyUpdateDto';
import { ActionLocationType, TypeActionLocationActions } from '../../models/actions/typeLocationActions';
import TypeLocationState from '../../models/initialState/typeLocationState';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Company(props: Props) {
  const { t } = useTranslation();  
  const state:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);
  const dispatch = useDispatch();
  const [companySelected, setCompanySelected] = useState<CompanyDto | null>(null);
  const [editCompany, setEditCompany] = useState(false);
  const [showModalDeleteCompany, setShowModalDeleteCompany] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  const stateLocation:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
 
  useEffect(()=> {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: true}});      
      setEditCompany(false);
      props.setReload(false);  
    }
    
  }, [props.reload]);

  useEffect(()=> {
    /* En el caso de cambiar la imagen, recargamos la lista de companies y actualizamos la company seleccionada */
    if (editCompany && !addCompany) {
      const company = state.data.find(elem => elem.companyId === companySelected?.companyId);
      if (company) {
        setCompanySelected(company);
      }
    }
  }, [state.data]);

  useEffect(()=> {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
    }
  }, []);

  const edit = (event:SyntheticEvent, index:CompanyDto) => {
    setCompanySelected(index); 
    const data = companyDtoToForm(index);    
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_FORM_UPDATE, value: data}); 
    setEditCompany(true);
    setAddCompany(false);
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}});
    }
  }

  const removeConfirmation = (event: SyntheticEvent, index: CompanyDto)=>{
    setCompanySelected(index);   
    setShowModalDeleteCompany(true);
  };

  const deleteCompany = () => {
    setShowModalDeleteCompany(false);
    if (companySelected !== null && companySelected.companyId !== undefined) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_DELETE_LOADING, value: companySelected.companyId});
    }    
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_FORM_NEW});
    setCompanySelected(null);   
    setEditCompany(true);
    setAddCompany(true);
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}});
    }
  }

  const update = () => {   
    const data:CompanyUpdateDto = companyFormToDto(state.companyForm, companySelected === null ? undefined : companySelected.companyId);
    if (addCompany) {      
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_ADD_LOADING, value: {company: data, image: state.image.data}});
    } else {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_EDIT_LOADING, value: data});
    }
    setEditCompany(false);
  }

  const back = () => setEditCompany(false);

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionCompanyType.COMPANY_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <>
      {state.loading && !state.error && <Loading />}
      {!state.loading && !state.error && !editCompany && <CompanyList 
                                                            edit={edit} 
                                                            remove={removeConfirmation} 
                                                            newRegister={newRegister}
                                                            changePage={changePage}
                                                            changeSearchText={changeSearchText}
                                                            changeRowsPerPage={changeRowsPerPage}
                                                            onOrderChange={onOrderChange}/>}
      {!state.loading && !state.error && editCompany && <CompanyEdit company={companySelected} update={update} back={back} add={addCompany}/>}
      {state.error && <ComponentError
                              title={t('admin.company.error_title')} 
                              message={''} 
                              buttonMessage={t('admin.company.error_button')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}})}/>}
      {showModalDeleteCompany && <ModalConfirmDelete show={showModalDeleteCompany} title={t('admin.company.modal_confirm_delete_title')} back={()=>setShowModalDeleteCompany(false)} confirm={deleteCompany} textButtonBack={t('admin.company.modal_confirm_delete_back')} textButtonConfirm={t('admin.company.modal_confirm_delete_confirm')} />}
      <Notification
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.company.editMessage')}
              addMessage={t('admin.company.addMessage')}
              delteMessage={t('admin.company.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionCompanyType.COMPANY_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionCompanyType.COMPANY_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionCompanyType.COMPANY_DELETE_SUCCESS, value: false})}/>  
  
  </>
}

export default Company;