import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
import { DEFAULTITEMSPERPAGE, newFormCompany } from '../../utils/utils';

export const initialCompaniesState: TypeCompaniesState = {    
      loading: false,
      error: false,
      data: [],
      companyForm: newFormCompany(),
      filters: {orderBy: 0, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false,
      image: {
            data: null,
            loading: false,
            error: false,
            ok: false
      }
}