import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.scss';
import { useEffect, useState } from 'react';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import TypeLocationState from '../../models/initialState/typeLocationState';
import TypeState from '../../models/initialState/typeState';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMarketsType, TypeActionMarketsActions } from '../../models/actions/typeMarketsActions';
import { ActionSkillsType, TypeActionSkillsActions } from '../../models/actions/typeSkillsActions';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Chip, InputLabel, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { getLabelCountry, getLabelMarket, getLabelSkill } from '../../utils/utils';
import { TypeActions } from '../../models/actions/typeActions';
import MarketDto from '../../models/dto/MarketDto';
import SkillDto from '../../models/dto/SkillDto';
import { CountryDto } from '../../models/dto/LocationDto';
import TypeDashboardState from '../../models/initialState/typeDashboardState';
import { ActionDashboardType } from '../../models/actions/typeDashboardActions';
import { ActionLocationType } from '../../models/actions/typeLocationActions';
import { NetworkKpis, NetworkPerformanceIndicators } from '../../models/dto/NetworkDashboardDto';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import TypeJobsState from '../../models/initialState/typeJobsState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import Table from '../table/Table';
import JobsResponseDto from '../../models/dto/JobsResponseDto';
import TypeMainState from '../../models/initialState/typeMainState';

interface Props {
  setOption(option: number): void
}

interface Filter {
  skillIds: number[],
  marketIds: number[],
  regionIds: number[],
  jobsList: number[],
  dateStart: string,
  dateEnd: string
}
const today = new Date();

function NetworkDashboard(props: Props) {
  const [dataFilter, setDataFilter] = useState<Filter>({ skillIds: [], marketIds: [], regionIds: [], jobsList: [], dateStart: today.toISOString(), dateEnd: today.toISOString() });
  const [showData, setShowdata] = useState(false);
  const [showFilterJob, setShowFilterJob] = useState(false);
  const state: TypeDashboardState = useSelector((state: TypeState) => state.dashboardReducer);
  const stateSkills: TypeSkillsState = useSelector((state: TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state: TypeState) => state.marketsReducer);
  const stateLocation: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);
  const stateJobs: TypeJobsState = useSelector((state: TypeState) => state.jobsReducer);
  const stateMain: TypeMainState = useSelector((state: TypeState) => state.mainReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActions>({ type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: { reset: false } });
    }
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({ type: ActionMarketsType.MARKETS_LOADING, value: { reset: false } });
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({ type: ActionSkillsType.SKILLS_LOADING, value: { reset: false } });
    }
    if (stateMain.companySelected) {
      dispatch<TypeActionJobsActions>({ type: ActionJobsType.JOBS_LOADING, value: { reset: false } });
    }
  }, [stateMain.companySelected]);

  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    setShowdata(false);
    const value = event.target.value;
    let sendValue: number[];
    typeof value === 'string' ? sendValue = [parseInt(value)] : sendValue = value;
    const data = { ...dataFilter };
    if (name === 'skillIds' || name === 'marketIds' || name === 'regionIds') {
      data[name] = sendValue;
      setDataFilter(data);
    }

  };

  const onChangeDate = (date: MaterialUiPickersDate, name: string) => {
    const newDate = date !== null ? date.toISOString() : new Date().toISOString();
    if (name === 'dateStart') {
      setDataFilter({ ...dataFilter, dateStart: newDate });
    } else {
      setDataFilter({ ...dataFilter, dateEnd: newDate });
    }
  }

  const onSelectionChange = (data: JobsResponseDto[]) => {
    setDataFilter({ ...dataFilter, jobsList: data.map(elem => elem.jobId) });
  }

  const filter = () => {
    dispatch<TypeActions>({ type: ActionDashboardType.NETWORK_DASHBOARD_LOADING, value: dataFilter });
    setShowdata(true);
  }

  const reset = () => {
    setShowdata(false);
    setDataFilter({ ...dataFilter, skillIds: [], marketIds: [], regionIds: [], dateStart: today.toISOString(), dateEnd: today.toISOString() });
  }

  return <div className='swissinx-dashboard-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={() => props.setOption(0)} />
      {t('admin.dashboard.titleNetworkDashboard')}</h2>
    <div className='swissinx-dashboard-block' >
      <div>
        <h3 className='swissinx-dashboard-filter'>{t('admin.dashboard.filter')}</h3>
        <div className='swissinx-dashboard-field-container'>
          <div className="swissinx-dashboard-field">
            <div className="swissinx-dashboard-table-filter" onClick={() => setShowFilterJob(!showFilterJob)}>
              <div className="swissinx-dashboard-table-filter__text">
                <span>{t('admin.dashboard.titleJobs')}</span>
                <span>({dataFilter.jobsList.length}) {t('admin.dashboard.selected')}</span>
              </div>
              <div className="swissinx-dashboard-table-filter__icon">
                <FontAwesomeIcon icon={showFilterJob ? faAngleUp : faAngleDown} />
              </div>
            </div>
          </div>
          <div className='swissinx-dashboard-field'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="swissinx-jobs-field field-dateStart">
                <KeyboardDatePicker
                  label={t('admin.dashboard.dateStart')}
                  format="DD/MM/yyyy"
                  inputVariant="outlined"
                  value={new Date(dataFilter.dateStart as string)}
                  onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'dateStart')}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className='swissinx-dashboard-field'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="swissinx-jobs-field field-dateEnd">
                <KeyboardDatePicker
                  label={t('admin.dashboard.dateEnd')}
                  format="DD/MM/yyyy hh:mm"
                  inputVariant="outlined"
                  value={new Date(dataFilter.dateEnd as string)}
                  onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'dateEnd')}

                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="swissinx-dashboard-field field-region">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="skillId">{t('admin.jobs.label_region')}</InputLabel>
              <Select
                labelId="regionIds"
                id="regionIds"
                label={t('admin.jobs.label_regionId')}
                multiple
                value={dataFilter.regionIds}
                onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'regionIds')}
                input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_region')} />}
                error={false}
                renderValue={(selected: number[]) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: number) => (
                      <Chip key={value} label={getLabelCountry(value, stateLocation.countries.data, stateLocation.countries.loading, stateLocation.countries.error)} />
                    ))}
                  </Box>
                )}
              >
                {stateLocation.countries.data.map((country: CountryDto) => (
                  <MenuItem
                    key={country.id}
                    value={country.id}
                  >
                    {country.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="swissinx-dashboard-field field-skills">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="skillId">{t('admin.jobs.label_skills')}</InputLabel>
              <Select
                labelId="skillId"
                id="skillId"
                label={t('admin.jobs.label_skills')}
                multiple
                value={dataFilter.skillIds}
                onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'skillIds')}
                input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_skills')} />}
                error={false}
                renderValue={(selected: number[]) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: number) => (
                      <Chip key={value} label={getLabelSkill(value, stateSkills.data, stateSkills.loading, stateSkills.error)} />
                    ))}
                  </Box>
                )}
              >
                {stateSkills.data.map((skill: SkillDto) => (
                  <MenuItem
                    key={skill.skillId}
                    value={skill.skillId}
                  >
                    {skill.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="swissinx-dashboard-field field-market">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="marketId">{t('admin.jobs.label_markets')}</InputLabel>
              <Select
                labelId="marketId"
                id="marketId"
                label={t('admin.jobs.label_markets')}
                multiple
                value={dataFilter.marketIds}
                onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'marketIds')}
                input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_markets')} />}
                error={false}
                renderValue={(selected: number[]) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: number) => (
                      <Chip key={value} label={getLabelMarket(value, stateMarkets.data, stateMarkets.loading, stateMarkets.error)} />
                    ))}
                  </Box>
                )}
              >
                {stateMarkets.data.map((market: MarketDto) => (
                  <MenuItem
                    key={market.marketId}
                    value={market.marketId}
                  >
                    {market.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="swissinx-dashboard-container-buttons">
            <button className="swissinx-dashboard-button-back" type="button" onClick={reset}>{t('admin.jobs.text_button_reset')}</button>
            <button className="swissinx-dashboard-button-submit" type="button" onClick={filter}>{t('admin.jobs.text_button_filter')}</button>
          </div>
        </div>
      </div>
    </div>

    {showFilterJob && <div className='swissinx-jobs-table-container'>
      {/* <h4>{t('admin.dashboard.jobsList')}</h4> */}
      <Table<JobsResponseDto>
        data={stateJobs.jobsData}
        headers={[
          { title: t('admin.jobs.label_title'), field: 'title' }
        ]}
        onSelectionChange={onSelectionChange}
        selection={true}
      />
    </div>}

    {state.network.loading && <p>{t('admin.dashboard.loading')}</p>}
    {state.network.error && <p>{t('admin.dashboard.error')}</p>}
    {showData && <div>

      <div className='swissinx-dashboard-kpi-container'>
        <h3>{t('admin.dashboard.kpi')}</h3>
        {state.network.data && state.network.data.kpis && <div className='swissinx-dashboard-kpi-block'>
          {Object.keys(state.network.data.kpis).map(kpi => <div key={kpi} className='swissinx-dashboard-kpi'>
            <p>{t('admin.dashboard.' + kpi)}</p>
            <p>{state.network.data && state.network.data.kpis[kpi as keyof NetworkKpis]}</p>
          </div>)
          }
        </div>}
      </div>

      <div className='swissinx-dashboard-kpi-container'>
        <h3>{t('admin.dashboard.performanceIndicator')}</h3>
        {state.network.data && state.network.data.performanceIndicators && <div className='swissinx-dashboard-kpi-block'>
          {Object.keys(state.network.data.performanceIndicators).map(performance => <div key={performance} className='swissinx-dashboard-kpi'>
            <p>{t('admin.dashboard.' + performance)}</p>
            <p>{state.network.data && state.network.data.performanceIndicators[performance as keyof NetworkPerformanceIndicators]}</p>
          </div>)
          }
        </div>}
      </div>

    </div>}
  </div>
}

export default NetworkDashboard;