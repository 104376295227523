import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import JobTemplatesDto from '../models/dto/JobTemplateDto';
import { actionTemplateJobAddLoading, actionTemplateJobDeleteLoading, actionTemplateJobEditLoading, ActionTemplateJobType } from '../models/actions/typeTemplateJobActions';
import TypeState from '../models/initialState/typeState';
import TypeMainState from '../models/initialState/typeMainState';

const baseUrlApi:string = getBaseUrlApi();

const urlLoadTemplates = baseUrlApi + '/api/ApplicationTemplate/GetApplicationTemplates';
const urlCreateTemplates = baseUrlApi + '/api/ApplicationTemplate/CreateApplicationTemplate';
const urlEditTemplates = baseUrlApi + '/api/ApplicationTemplate/UpdateApplicationTemplate';
const urlDeleteTemplates = baseUrlApi + '/api/ApplicationTemplate/DeleteApplicationTemplate';

function* loadJobTemplates() {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);             
        const response:Response = yield call(fetch, urlLoadTemplates, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:JobTemplatesDto[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_SUCCESS, value: data});
        } else {                   
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
        }
                
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
    }
}

function* createJobTemplates(action: actionTemplateJobAddLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlCreateTemplates, getFetchParams('POST', action.value, state.token) );
        if (response.ok) {            
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ADD_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});
        } else {                   
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
        }
                
    } catch (e){       
        yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
    }
}

function* editJobTemplates(action: actionTemplateJobEditLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);             
        const response:Response = yield call(fetch, urlEditTemplates, getFetchParams('PUT', action.value, state.token) );
        if (response.ok) {            
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});
        } else {                   
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
        }
                
    } catch (e){        
        yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
    }
}

function* deleteJobTemplates(action: actionTemplateJobDeleteLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);              
        const response:Response = yield call(fetch, urlDeleteTemplates + '?applicationTemplateId=' + action.value, getFetchParams('DELETE', null, state.token) );
        if (response.ok) {            
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});
        } else {                   
            yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
        }
                
    } catch (e){        
        yield put<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ERROR});
    }
}

function* loadJobTemplatesWatcher() {
    yield takeLatest(ActionTemplateJobType.TEMPLATE_JOB_LOADING, loadJobTemplates);
}

function* createJobTemplatesWatcher() {
    yield takeLatest(ActionTemplateJobType.TEMPLATE_JOB_ADD_LOADING, createJobTemplates);
}

function* editJobTemplatesWatcher() {
    yield takeLatest(ActionTemplateJobType.TEMPLATE_JOB_EDIT_LOADING, editJobTemplates);
}

function* deleteJobTemplatesWatcher() {
    yield takeLatest(ActionTemplateJobType.TEMPLATE_JOB_DELETE_LOADING, deleteJobTemplates);
}



export default function* rootTemplatesSaga() {
    yield all([        
        loadJobTemplatesWatcher(),
        createJobTemplatesWatcher(),
        editJobTemplatesWatcher(),
        deleteJobTemplatesWatcher()
    ])
}