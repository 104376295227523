import AmbassadorWithKpisDto from '../dto/AmbassadorWithKpisDto';
import ApplicationJob from '../dto/ApplicationsDto';
import ApplicationStatus from '../dto/ApplicationStatusDto';
import JobActivityDto, { ContactData, ContactLink } from '../dto/JobActivityDto';
import JobsDto from '../dto/JobsDto';
import JobsResponseDto from '../dto/JobsResponseDto';
import JobResumeListDto from '../dto/JobResumeListDto'
import PushHistoryJobDto from '../dto/PushHistoryJobDto';
import PushJobDto from '../dto/PushJobDto';
import JobStatusExtendedDto from '../dto/JobStatusExtendedDto';
import JobCandidatureStatusDto from '../dto/JobCandidatureStatusDto';
import JobsForm from '../forms/JobsForm';
import { Kpis, PathwayCandidate, UploadJobsXMLResult } from '../initialState/typeJobsState';

export enum ActionJobsType {
    JOBS_FORM_NEW = 'JOBS_FORM_NEW',
    JOBS_FORM_UPDATE = 'JOBS_FORM_UPDATE',
    JOBS_FORM_SEND_VALUES = 'JOBS_FORM_SEND_VALUES',
    JOBS_FORM_SEND_BOOLEAN_VALUES = 'JOBS_FORM_SEND_BOOLEAN_VALUES',
    JOBS_FORM_SEND_ARRAY_VALUES = 'JOBS_FORM_SEND_ARRAY_VALUES',
    JOBS_PUSH_FORM_SEND_VALUES = 'JOBS_PUSH_FORM_SEND_VALUES',
    JOBS_ERROR_REQUIRED = 'JOBS_ERROR_REQUIRED',
    JOBS_PUSH_ERROR_REQUIRED = 'JOBS_PUSH_ERROR_REQUIRED',
    JOBS_SEND_PUSH_FORM_NEW = 'JOBS_SEND_PUSH_FORM_NEW',
    JOBS_LOADING = 'JOBS_LOADING',
    JOBS_SUCCESS = 'JOBS_SUCCESS',
    JOBS_ERROR = 'JOBS_ERROR',
    JOBS_GET_JOB_LOADING = 'JOBS_GET_JOB_LOADING',
    JOBS_GET_JOB_SUCCESS = 'JOBS_GET_JOB_SUCCESS',
    JOBS_GET_JOB_ERROR = 'JOBS_GET_JOB_ERROR',
    JOBS_ADD_LOADING = 'JOBS_ADD_LOADING',
    JOBS_ADD_SUCCESS = 'JOBS_ADD_SUCCESS',
    JOBS_ADD_ERROR = 'JOBS_ADD_ERROR',
    JOBS_EDIT_LOADING = 'JOBS_EDIT_LOADING',
    JOBS_EDIT_SUCCESS = 'JOBS_EDIT_SUCCESS',
    JOBS_EDIT_ERROR = 'JOBS_EDIT_ERROR',
    JOBS_DELETE_LOADING = 'JOBS_DELETE_LOADING',
    JOBS_DELETE_SUCCESS = 'JOBS_DELETE_SUCCESS',
    JOBS_DELETE_ERROR = 'JOBS_DELETE_ERROR',
    JOBS_SEND_PUSH_LOADING = 'JOBS_SEND_PUSH_LOADING',
    JOBS_SEND_PUSH_SUCCESS = 'JOBS_SEND_PUSH_SUCCESS',
    JOBS_HISTORY_PUSH_LOADING = 'JOBS_HISTORY_PUSH_LOADING',
    JOBS_HISTORY_PUSH_SUCCESS = 'JOBS_HISTORY_PUSH_SUCCESS',
    JOBS_HISTORY_PUSH_ERROR = 'JOBS_HISTORY_PUSH_ERROR',
    JOBS_AMBASSADOR_LIST_PUSH_LOADING = 'JOBS_AMBASSADOR_LIST_PUSH_LOADING',
    JOBS_AMBASSADOR_LIST_PUSH_SUCCESS = 'JOBS_AMBASSADOR_LIST_PUSH_SUCCESS',
    JOBS_AMBASSADOR_LIST_PUSH_ERROR = 'JOBS_AMBASSADOR_LIST_PUSH_ERROR',
    JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING = 'JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING',
    JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_SUCCESS = 'JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_SUCCESS',
    JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR = 'JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR',    
    JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_RESET = 'JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_RESET',    
    JOBS_AMBASSADOR_UPDATE_LIST_PUSH = 'JOBS_AMBASSADOR_UPDATE_LIST_PUSH',
    JOBS_UPDATE_PUSH_STATUS_LOADING = 'JOBS_UPDATE_PUSH_STATUS_LOADING',
    JOBS_UPDATE_PUSH_STATUS_SUCCESS = 'JOBS_UPDATE_PUSH_STATUS_SUCCESS',
    JOBS_UPDATE_PUSH_STATUS_ERROR = 'JOBS_UPDATE_PUSH_STATUS_ERROR',
    JOBS_CANDIDATES_LOADING = 'JOBS_CANDIDATES_LOADING',
    JOBS_CANDIDATES_SUCCESS = 'JOBS_CANDIDATES_SUCCESS',
    JOBS_CANDIDATES_ERROR = 'JOBS_CANDIDATES_ERROR',
    JOBS_CANDIDATES_PATHWAY_LOADING = 'JOBS_CANDIDATES_PATHWAY_LOADING',
    JOBS_CANDIDATES_PATHWAY_SUCCESS = 'JOBS_CANDIDATES_PATHWAY_SUCCESS',
    JOBS_CANDIDATES_PATHWAY_ERROR = 'JOBS_CANDIDATES_PATHWAY_ERROR',
    JOBS_APPLICATION_STATUS_LOADING = 'JOBS_APPLICATION_STATUS_LOADING',
    JOBS_APPLICATION_STATUS_SUCCESS = 'JOBS_APPLICATION_STATUS_SUCCESS',
    JOBS_APPLICATION_STATUS_ERROR = 'JOBS_APPLICATION_STATUS_ERROR',    
    JOBS_APPLICATION_DOWNLOAD_FILE_LOADING = 'JOBS_APPLICATION_DOWNLOAD_FILE_LOADING',
    JOBS_APPLICATION_DOWNLOAD_FILE_SUCCESS = 'JOBS_APPLICATION_DOWNLOAD_FILE_SUCCESS',
    JOBS_APPLICATION_DOWNLOAD_FILE_ERROR = 'JOBS_APPLICATION_DOWNLOAD_FILE_ERROR',
    JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING = 'JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING',
    JOBS_APPLICATION_SELECTION_FOR_JOB_SUCCESS = 'JOBS_APPLICATION_SELECTION_FOR_JOB_SUCCESS',
    JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR = 'JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR',
    JOBS_CHANGE_SEARCH_TABLE = 'JOBS_NEW_SEARCH_TABLE',
    JOBS_CHANGE_PAGE_TABLE = 'JOBS_CHANGE_PAGE_TABLE',
    JOBS_CHANGE_PAGE_SIZE = 'JOBS_CHANGE_PAGE_SIZE',
    JOBS_CANDIDATES_CHANGE_SEARCH_TABLE = 'JOBS_CANDIDATES_CHANGE_SEARCH_TABLE',
    JOBS_CANDIDATES_CHANGE_PAGE_TABLE = 'JOBS_CANDIDATES_CHANGE_PAGE_TABLE',
    JOBS_CANDIDATES_CHANGE_PAGE_SIZE = 'JOBS_CANDIDATES_CHANGE_PAGE_SIZE',
    JOB_KPI_LOADING = 'JOB_KPI_LOADING',
    JOB_KPI_SUCCESS = 'JOB_KPI_SUCCESS',
    JOB_KPI_ERROR = 'JOB_KPI_ERROR',
    JOB_GET_STATUS_EXTENDED_LOADING = 'JOB_GET_STATUS_EXTENDED_LOADING',
    JOB_GET_STATUS_EXTENDED_SUCCESS = 'JOB_GET_STATUS_EXTENDED_SUCCESS',
    JOB_GET_STATUS_EXTENDED_ERROR = 'JOB_GET_STATUS_EXTENDED_ERROR',
    JOB_GET_CANDIDATURE_STATUS_LOADING = 'JOB_GET_CANDIDATURE_STATUS_LOADING',
    JOB_GET_CANDIDATURE_STATUS_ERROR = 'JOB_GET_CANDIDATURE_STATUS_ERROR',
    JOB_GET_CANDIDATURE_STATUS_SUCCESS = 'JOB_GET_CANDIDATURE_STATUS_SUCCESS',
    JOBS_EXPORT_TO_XML_LOADING = 'JOBS_EXPORT_TO_XML_LOADING',
    JOBS_EXPORT_TO_XML_SUCCESS = 'JOBS_EXPORT_TO_XML_SUCCESS',
    JOBS_EXPORT_TO_XML_ERROR = 'JOBS_EXPORT_TO_XML_ERROR',
    JOBS_IMPORT_TO_XML_LOADING = 'JOBS_IMPORT_TO_XML_LOADING',
    JOBS_IMPORT_TO_XML_SUCCESS = 'JOBS_IMPORT_TO_XML_SUCCESS',
    JOBS_IMPORT_TO_XML_ERROR = 'JOBS_IMPORT_TO_XML_ERROR',
    JOBS_IMPORT_TO_XML_CHANGE_STATUS = 'JOBS_IMPORT_TO_XML_CHANGE_STATUS',
    JOBS_ACTIVITY_LOADING = 'JOBS_ACTIVITY_LOADING',
    JOBS_ACTIVITY_SUCCESS = 'JOBS_ACTIVITY_SUCCESS',
    JOBS_ACTIVITY_ERROR = 'JOBS_ACTIVITY_ERROR',
    JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING = 'JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING',
    JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_SUCCESS = 'JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_SUCCESS',
    JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR = 'JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR',
    JOBS_STATUS_FILTER = 'JOBS_STATUS_FILTER',
    JOBS_PUSHED_FILTER = 'JOBS_PUSHED_FILTER',
    JOBS_REGIONS_FILTER = 'JOBS_REGIONS_FILTER',
    JOBS_MARKETS_FILTER = 'JOBS_MARKETS_FILTER',
    JOBS_SKILLS_FILTER = 'JOBS_SKILLS_FILTER',
    JOBS_CHANGE_FILTER = 'JOBS_GROUPS_CHANGE_FILTER',
    JOBS_CANDIDATES_CHANGE_FILTER = 'JOBS_CANDIDATES_GROUPS_CHANGE_FILTER'
}

export interface actionJobsFormNew {
    type: ActionJobsType.JOBS_FORM_NEW    
}

export interface actionJobsFormUpdate {
    type: ActionJobsType.JOBS_FORM_UPDATE,
    value: JobsForm  
}

export interface actionJobsSendArrayValues {
    type: ActionJobsType.JOBS_FORM_SEND_ARRAY_VALUES,
    value: {
        name: string,
        value: number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionJobsSendBooleanValues {
    type: ActionJobsType.JOBS_FORM_SEND_BOOLEAN_VALUES,
    value: {
        name: string,
        value: boolean,
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionJobsSendValues {
    type: ActionJobsType.JOBS_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string,
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionPushJobsSendValues {
    type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        rules?: {
            required: boolean,
            format: boolean
        },
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionJobsErrorRequired {
    type: ActionJobsType.JOBS_ERROR_REQUIRED,
    value: string
}

export interface actionJobsPushErrorRequired {
    type: ActionJobsType.JOBS_PUSH_ERROR_REQUIRED,
    value: string
}

export interface actionJobsLoading {
    type: ActionJobsType.JOBS_LOADING
    value: {reset: boolean}
}
export interface actionJobsSuccess {
    type: ActionJobsType.JOBS_SUCCESS,
    value: {data: JobResumeListDto[], reset: boolean}
}
export interface actionJobsError {
    type: ActionJobsType.JOBS_ERROR,
    value: string 
}

export interface actionGetJobLoading {
    type: ActionJobsType.JOBS_GET_JOB_LOADING
    value: number
}
export interface actionGetJobSuccess {
    type: ActionJobsType.JOBS_GET_JOB_SUCCESS,
    value: JobsResponseDto | null
}
export interface actionGetJobError {
    type: ActionJobsType.JOBS_GET_JOB_ERROR,
    value: string 
}

export interface actionJobsAddLoading {
    type: ActionJobsType.JOBS_ADD_LOADING,
    value: JobsDto
}
export interface actionJobsAddSuccess {
    type: ActionJobsType.JOBS_ADD_SUCCESS,
    value: boolean
}
    
export interface actionJobsAddError {
    type: ActionJobsType.JOBS_ADD_ERROR   
}

export interface actionJobsEditLoading {
    type: ActionJobsType.JOBS_EDIT_LOADING,
    value: JobsDto
}
export interface actionJobsEditSuccess {
    type: ActionJobsType.JOBS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionJobsEditError {
    type: ActionJobsType.JOBS_EDIT_ERROR   
}

export interface actionJobsDeleteLoading {
    type: ActionJobsType.JOBS_DELETE_LOADING,
    value: number
}
export interface actionJobsDeleteSuccess {
    type: ActionJobsType.JOBS_DELETE_SUCCESS,
    value: boolean
}

export interface actionJobsSendPushLoading {
    type: ActionJobsType.JOBS_SEND_PUSH_LOADING,
    value: PushJobDto
}

export interface actionJobsSendPushSuccess {
    type: ActionJobsType.JOBS_SEND_PUSH_SUCCESS,
    value: boolean
}

export interface actionJobsHistoryPushLoading {
    type: ActionJobsType.JOBS_HISTORY_PUSH_LOADING,
    value: number
}

export interface actionJobsHistoryPushSuccess {
    type: ActionJobsType.JOBS_HISTORY_PUSH_SUCCESS,
    value: PushHistoryJobDto[]
}

export interface actionJobsHistoryPushError {
    type: ActionJobsType.JOBS_HISTORY_PUSH_ERROR
}

export interface actionJobsSendPushFormNew {
    type: ActionJobsType.JOBS_SEND_PUSH_FORM_NEW
}

export interface actionAmbassadorListForPushLoading {
    type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_LOADING,
    value: number
}

export interface actionAmbassadorListForPushSuccess {
    type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_SUCCESS,
    value: AmbassadorWithKpisDto[]
}

export interface actionAmbassadorListForPushError {
    type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_ERROR
}

export interface actionAmbassadorContactsNetworkerPushLoading {
    type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING,
    value: {pushId: number, contactLinks: ContactLink[], networkerId: number,localPath: number[]}
}

export interface actionAmbassadorContactsNetworkerPushSuccess {
    type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_SUCCESS,
    value: {
        data: AmbassadorWithKpisDto[],
        networker: number,
        localPath: number[]      
    }
}

export interface actionAmbassadorContactsNetworkerPushError {
    type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR
}

export interface actionAmbassadorContactsNetworkerPushReset {
    type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_RESET
}

export interface actionAmbassadorUpdateListForPush {
    type: ActionJobsType.JOBS_AMBASSADOR_UPDATE_LIST_PUSH,
    value: number
}
    
export interface actionJobsDeleteError {
    type: ActionJobsType.JOBS_DELETE_ERROR   
}

export interface actionJobsUpdatePushStatusLoading {
    type: ActionJobsType.JOBS_UPDATE_PUSH_STATUS_LOADING,
    value: {
        status: {
            pushId: number,
            status: number,
        },        
        jobId: number
    }
}

export interface actionJobsUpdatePushStatusSuccess {
    type: ActionJobsType.JOBS_UPDATE_PUSH_STATUS_SUCCESS
}

export interface actionJobsUpdatePushStatusError {
    type: ActionJobsType.JOBS_UPDATE_PUSH_STATUS_ERROR
}

export interface actionJobsCandidatesLoading {
    type: ActionJobsType.JOBS_CANDIDATES_LOADING,
    value: number
}

export interface actionJobsCandidatesSuccess {
    type: ActionJobsType.JOBS_CANDIDATES_SUCCESS,
    value: ApplicationJob[]
}

export interface actionJobsCandidatesError {
    type: ActionJobsType.JOBS_CANDIDATES_ERROR
}

export interface actionJobsApplicationStatusLoading {
    type: ActionJobsType.JOBS_APPLICATION_STATUS_LOADING,
    value: ApplicationStatus
}

export interface actionJobsApplicationStatusSuccess {
    type: ActionJobsType.JOBS_APPLICATION_STATUS_SUCCESS,
    value: boolean
}

export interface actionJobsApplicationStatusError {
    type: ActionJobsType.JOBS_APPLICATION_STATUS_ERROR,
}

export interface actionJobsApplicationPathwayLoading {
    type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_LOADING,
    value: {jobId: number, applicationId: number}
}

export interface actionJobsApplicationPathwaySuccess {
    type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_SUCCESS,
    value: PathwayCandidate[]
}

export interface actionJobsApplicationPathwayError {
    type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_ERROR   
}

export interface actionJobsApplicationSelectedJobLoading {
    type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING,
    value: {
        applicationId: number,
        jobId: number,
        selectedForJob: boolean
      }    
}

export interface actionJobsApplicationSelectedJobSuccess {
    type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_SUCCESS,
    value: boolean
}

export interface actionJobsApplicationSelectedJobError {
    type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR,
    value: {error: boolean, message: string}
}

export interface actionJobsApplicationDownloadFileLoading {
    type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_LOADING,
    value: {
        jobId: number,
        urlFile: string,
        fileName: string
    }
}

export interface actionJobsApplicationDownloadFileSuccess {
    type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_SUCCESS    
}

export interface actionJobsApplicationDownloadFileError {
    type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_ERROR,
    value: boolean
}

export interface actionJobChangeSerachTable {
    type: ActionJobsType.JOBS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionJobChangePageSizeTable {
    type: ActionJobsType.JOBS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionJobChangePageTable {
    type: ActionJobsType.JOBS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionJobCandidatesChangeSerachTable {
    type: ActionJobsType.JOBS_CANDIDATES_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionJobCandidatesChangePageSizeTable {
    type: ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionJobCandidatesChangePageTable {
    type: ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionJobKpisLoading {
    type: ActionJobsType.JOB_KPI_LOADING,
    value: number   
}
export interface actionJobKpisSuccess {
    type: ActionJobsType.JOB_KPI_SUCCESS
    value: Kpis
}
    
export interface actionJobKpisError {
    type: ActionJobsType.JOB_KPI_ERROR  
}

export interface actionJobsApplicationExportToXmlLoading {
    type: ActionJobsType.JOBS_EXPORT_TO_XML_LOADING    
}

export interface actionJobsApplicationExportToXmlSuccess {
    type: ActionJobsType.JOBS_EXPORT_TO_XML_SUCCESS,
    value: boolean   
}

export interface actionJobsApplicationExportToXmlError {
    type: ActionJobsType.JOBS_EXPORT_TO_XML_ERROR,
    value: boolean   
}

export interface actionJobsApplicationImportToXmlLoading {
    type: ActionJobsType.JOBS_IMPORT_TO_XML_LOADING,
    value: File    
}

export interface actionJobsApplicationImportToXmlSuccess {
    type: ActionJobsType.JOBS_IMPORT_TO_XML_SUCCESS,
    value: UploadJobsXMLResult
}

export interface actionJobsApplicationImportToXmlError {
    type: ActionJobsType.JOBS_IMPORT_TO_XML_ERROR,
    value: boolean   
}

export interface actionJobsApplicationImportToXmlChangeStatus {
    type: ActionJobsType.JOBS_IMPORT_TO_XML_CHANGE_STATUS,
    value: boolean
}

export interface actionJobsActivityLoading {
    type: ActionJobsType.JOBS_ACTIVITY_LOADING
    value: number    
}

export interface actionJobsActivitySuccess {
    type: ActionJobsType.JOBS_ACTIVITY_SUCCESS,
    value: JobActivityDto   
}

export interface actionJobsActivityError {
    type: ActionJobsType.JOBS_ACTIVITY_ERROR   
}

export interface actionJobsActivityContactsNetworkerPushLoading {
    type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING
    value: {pushId: number, contactLinks: ContactLink[], pathId: number[], type: string}    
}

export interface actionJobsActivityContactsNetworkerPushSuccess {
    type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_SUCCESS,
    value: {data : ContactData[], pathId: number[], pushId: number, type: string}
}

export interface actionJobsActivityContactsNetworkerPushError {
    type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR   
}

export interface actionJobUpdateStatusExtendedLoading {
    type: ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING,
    value: number,      
}

export interface actionJobUpdateStatusExtendedError {
    type: ActionJobsType.JOB_GET_STATUS_EXTENDED_ERROR 
}

export interface actionJobUpdateStatusExtendedSuccess {
    type: ActionJobsType.JOB_GET_STATUS_EXTENDED_SUCCESS
    value: { jobId: number, data: JobStatusExtendedDto }
}

export interface actionJobCandidatureStatusLoading{
    type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_LOADING,
    value: number,      
}

export interface actionJobCandidatureStatusSuccess{
    type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_SUCCESS
    value: { jobId: number, data: JobCandidatureStatusDto }
}

export interface actionJobCandidatureStatusError{
    type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_ERROR 
}

export interface actionJobStatusFilter{
    type: ActionJobsType.JOBS_STATUS_FILTER,
    value: number
}

export interface actionJobPushedFilter{
    type: ActionJobsType.JOBS_PUSHED_FILTER,
    value: number
}

export interface actionJobMarketsFilter{
    type: ActionJobsType.JOBS_MARKETS_FILTER,
    value: string
}

export interface actionJobSkillsFilter{
    type: ActionJobsType.JOBS_SKILLS_FILTER,
    value: string
}

export interface actionJobRegionFilter{
    type: ActionJobsType.JOBS_REGIONS_FILTER,
    value: string
}

export interface actionJobChangeFilterTable {
    type: ActionJobsType.JOBS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export interface actionJobsCandidatesChangeFilterTable {
    type: ActionJobsType.JOBS_CANDIDATES_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionJobsActions = actionJobsFormNew | actionJobsFormUpdate |
                            actionJobsSendValues | actionJobsSendBooleanValues | actionJobsSendArrayValues | actionJobsErrorRequired |  
                            actionPushJobsSendValues | actionJobsPushErrorRequired |
                            actionJobsSendPushLoading | actionJobsSendPushSuccess | actionJobsSendPushFormNew |
                            actionJobsLoading | actionJobsSuccess | actionJobsError |
                            actionGetJobLoading | actionGetJobSuccess | actionGetJobError |
                            actionJobsAddLoading | actionJobsAddSuccess | actionJobsAddError |
                            actionJobsEditLoading | actionJobsEditSuccess | actionJobsEditError |
                            actionJobsDeleteLoading | actionJobsDeleteSuccess | actionJobsDeleteError |
                            actionJobsHistoryPushLoading | actionJobsHistoryPushSuccess | actionJobsHistoryPushError |
                            actionAmbassadorListForPushLoading | actionAmbassadorListForPushSuccess | actionAmbassadorListForPushError | actionAmbassadorUpdateListForPush |
                            actionAmbassadorContactsNetworkerPushLoading | actionAmbassadorContactsNetworkerPushSuccess | actionAmbassadorContactsNetworkerPushError |
                            actionAmbassadorContactsNetworkerPushReset | actionJobsCandidatesLoading | actionJobsCandidatesSuccess | actionJobsCandidatesError |
                            actionJobsUpdatePushStatusLoading | actionJobsUpdatePushStatusSuccess | actionJobsUpdatePushStatusError |
                            actionJobsApplicationStatusLoading | actionJobsApplicationStatusSuccess | actionJobsApplicationStatusError |
                            actionJobsApplicationSelectedJobLoading | actionJobsApplicationSelectedJobSuccess | actionJobsApplicationSelectedJobError |
                            actionJobsApplicationDownloadFileLoading | actionJobsApplicationDownloadFileSuccess | actionJobsApplicationDownloadFileError |
                            actionJobsApplicationPathwayLoading | actionJobsApplicationPathwaySuccess | actionJobsApplicationPathwayError |
                            actionJobChangeSerachTable | actionJobChangePageTable | actionJobChangePageSizeTable |
                            actionJobCandidatesChangeSerachTable | actionJobCandidatesChangePageSizeTable | actionJobCandidatesChangePageTable |
                            actionJobKpisLoading | actionJobKpisSuccess | actionJobKpisError |
                            actionJobsApplicationExportToXmlLoading | actionJobsApplicationExportToXmlSuccess | actionJobsApplicationExportToXmlError |
                            actionJobsApplicationImportToXmlLoading | actionJobsApplicationImportToXmlSuccess | actionJobsApplicationImportToXmlError | actionJobsApplicationImportToXmlChangeStatus |
                            actionJobsActivityLoading | actionJobsActivitySuccess | actionJobsActivityError |
                            actionJobsActivityContactsNetworkerPushLoading | actionJobsActivityContactsNetworkerPushSuccess | actionJobsActivityContactsNetworkerPushError |
                            actionJobUpdateStatusExtendedLoading | actionJobUpdateStatusExtendedError | actionJobUpdateStatusExtendedSuccess | actionJobCandidatureStatusLoading |
                            actionJobCandidatureStatusSuccess | actionJobCandidatureStatusError |
                            actionJobStatusFilter | actionJobPushedFilter | actionJobChangeFilterTable | actionJobsCandidatesChangeFilterTable |
                            actionJobMarketsFilter | actionJobSkillsFilter | actionJobRegionFilter;
                            