import { ApplicationJobQuestionsDto } from '../dto/ApplicationJobQuestionsDto';
import { ApplicationPublicJobDto } from '../dto/ApplicationPublicJobDto';
import JobForwaredDto from '../dto/JobForwaredDto';
import JobForwaredResponseDto from '../dto/JobForwaredResponseDto';
import JobPublicResponseDto from '../dto/JobPublicResponseDto';
import LinkOpenedDto from '../dto/LinkOpenedDto';

export enum ActionPublicJobType {
    
    PUBLIC_JOB_LOADING = 'PUBLIC_JOB_LOADING',
    PUBLIC_JOB_SUCCESS = 'PUBLIC_JOB_SUCCESS',
    PUBLIC_JOB_ERROR = 'PUBLIC_JOB_ERROR',    
    
    PUBLIC_RESEND_SMS_CODE = 'PUBLIC_RESEND_SMS_CODE',
    PUBLIC_JOB_LINK_OPENED_LOADING = 'PUBLIC_JOB_LINK_OPENED_LOADING',
    PUBLIC_JOB_LINK_OPENED_SUCCESS = 'PUBLIC_JOB_LINK_OPENED_SUCCESS',
    PUBLIC_JOB_LINK_OPENED_ERROR = 'PUBLIC_JOB_LINK_OPENED_ERROR',
    PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING = 'PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING',
    PUBLIC_UPDATE_LINK_NETWORKED_ID_SUCCESS = 'PUBLIC_UPDATE_LINK_NETWORKED_ID_SUCCESS',
    PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR = 'PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR',
    PUBLIC_JOB_FORWARED_LOADING = 'PUBLIC_JOB_FORWARED_LOADING',
    PUBLIC_JOB_FORWARED_SUCCESS = 'PUBLIC_JOB_FORWARED_SUCCESS',
    PUBLIC_JOB_FORWARED_ERROR = 'PUBLIC_JOB_FORWARED_ERROR',
    PUBLIC_JOB_FORWARED_UPDATE_LOADING = 'PUBLIC_JOB_FORWARED_UPDATE_LOADING',
    PUBLIC_JOB_FORWARED_UPDATE_SUCCESS = 'PUBLIC_JOB_FORWARED_UPDATE_SUCCESS',
    PUBLIC_JOB_FORWARED_UPDATE_ERROR = 'PUBLIC_JOB_FORWARED_UPDATE_ERROR',
    PUBLIC_JOB_REJECT_JOB_LOADING = 'PUBLIC_JOB_REJECT_JOB_LOADING',
    PUBLIC_JOB_REJECT_JOB_SUCCESS = 'PUBLIC_JOB_REJECT_JOB_SUCCESS',
    PUBLIC_JOB_REJECT_JOB_ERROR = 'PUBLIC_JOB_REJECT_JOB_ERROR',
    SEND_PASSWORD_BY_SMS_LOADING = 'SEND_PASSWORD_BY_SMS_LOADING',
    SEND_PASSWORD_BY_SMS_SUCCESS = 'SEND_PASSWORD_BY_SMS_SUCCESS',
    SEND_PASSWORD_BY_SMS_ERROR = 'SEND_PASSWORD_BY_SMS_ERROR',

    APPLY_QUESTIONS_LOADING = 'APPLY_QUESTIONS_LOADING',
    APPLY_QUESTIONS_SUCCESS = 'APPLY_QUESTIONS_SUCCESS',
    APPLY_QUESTIONS_ERROR = 'APPLY_QUESTIONS_ERROR',

    APPLY_JOB_LOADING = 'APPLY_JOB_LOADING',
    APPLY_JOB_SUCCESS = 'APPLY_JOB_SUCCESS',
    APPLY_JOB_ERROR = 'APPLY_JOB_ERROR',

    CHECK_IF_LINK_CAN_BE_SHARED_LOADING = 'CHECK_IF_LINK_CAN_BE_SHARED_LOADING',
    CHECK_IF_LINK_CAN_BE_SHARED_SUCCESS = 'CHECK_IF_LINK_CAN_BE_SHARED_SUCCESS',
    CHECK_IF_LINK_CAN_BE_SHARED_ERROR = 'CHECK_IF_LINK_CAN_BE_SHARED_ERROR'
}

export interface actionPublicJobLoading {
    type: ActionPublicJobType.PUBLIC_JOB_LOADING,
    value: {
        linkId: number,
        sharedLinkCode: string 
    }
}
export interface actionPublicJobSuccess {
    type: ActionPublicJobType.PUBLIC_JOB_SUCCESS,
    value: JobPublicResponseDto
}
export interface actionPublicJobError {
    type: ActionPublicJobType.PUBLIC_JOB_ERROR,
    value: string  
}

export interface actionPublicResendCodeSMS {
    type: ActionPublicJobType.PUBLIC_RESEND_SMS_CODE   
}

export interface actionLinkOpenedLoading {
    type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_LOADING
    value: {
        networkerId: number,
        sharedLinkCode: string
      }     
}
export interface actionLinkOpenedSuccess {
    type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_SUCCESS,
    value: LinkOpenedDto
}    
export interface actionLinkOpenedError {
    type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_ERROR,
    value: string 
}

export interface actionUpdateLinkNetworkerIdLoading {
    type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING
    value: {
        linkId: number,
        networkerId: number
      }     
}
export interface actionUpdateLinkNetworkerIdSuccess {
    type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_SUCCESS,
    value: number   
}

export interface actionUpdateLinkNetworkerIdError {
    type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR   
}

export interface actionJobForwaredLoading {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_LOADING
    value: JobForwaredDto 
}
export interface actionJobForwaredSuccess {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_SUCCESS,
    value: JobForwaredResponseDto
}    
export interface actionJobForwaredError {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_ERROR   
}

export interface actionJobForwaredUpdateLoading {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_LOADING
    value: {
        forwardId: number,
        reason: string,
        referralName: string
      } 
}
export interface actionJobForwaredUpdateSuccess {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_SUCCESS    
}

export interface actionJobForwaredUpdateError {
    type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_ERROR   
}

export interface actionRejectJobLoading {
    type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_LOADING
    value: {
        jobId: number,
        linkId: number,
        forwardId: number
    }
}
export interface actionRejectJobSuccess {
    type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_SUCCESS   
}    
export interface actionRejectJobError {
    type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_ERROR   
}

export interface actionSendPasswordBySMSLoading {
    type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_LOADING,
    value: {
        phoneOrEmail: string
    }
}
export interface actionSendPasswordBySMSSuccess {
    type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_SUCCESS    
}
export interface actionSendPasswordBySMSError {
    type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_ERROR   
}

export interface actionApplyQuestionsLoading {
    type: ActionPublicJobType.APPLY_QUESTIONS_LOADING,
    value: number
}
export interface actionApplyQuestionsSuccess {
    type: ActionPublicJobType.APPLY_QUESTIONS_SUCCESS,
    value: ApplicationJobQuestionsDto[]
}
export interface actionApplyQuestionsError {
    type: ActionPublicJobType.APPLY_QUESTIONS_ERROR,
    value: string
}

export interface actionApplyJobLoading {
    type: ActionPublicJobType.APPLY_JOB_LOADING,
    value: ApplicationPublicJobDto
}
export interface actionApplyJobSuccess {
    type: ActionPublicJobType.APPLY_JOB_SUCCESS    
}
export interface actionApplyJobError {
    type: ActionPublicJobType.APPLY_JOB_ERROR,
    value: string
}

export interface actionCheckIfLinkCanBeSharedLoading {
    type: ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_LOADING,
    value: {
        forwardId: number,
        pushId: number
    }
}
export interface actionCheckIfLinkCanBeSharedSuccess {
    type: ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_SUCCESS,
    value: boolean 
}
export interface actionCheckIfLinkCanBeSharedError {
    type: ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_ERROR,
    value: string
}

export type TypePublicJobActions =  actionPublicJobLoading | actionPublicJobSuccess | actionPublicJobError |
                                    actionLinkOpenedLoading | actionLinkOpenedSuccess | actionLinkOpenedError |
                                    actionUpdateLinkNetworkerIdLoading | actionUpdateLinkNetworkerIdSuccess | actionUpdateLinkNetworkerIdError |
                                    actionJobForwaredLoading | actionJobForwaredSuccess | actionJobForwaredError |
                                    actionRejectJobLoading | actionRejectJobSuccess | actionRejectJobError |
                                    actionJobForwaredUpdateLoading | actionJobForwaredUpdateSuccess | actionJobForwaredUpdateError |
                                    actionPublicResendCodeSMS |
                                    actionSendPasswordBySMSLoading | actionSendPasswordBySMSSuccess | actionSendPasswordBySMSError |
                                    actionApplyQuestionsLoading | actionApplyQuestionsSuccess | actionApplyQuestionsError |
                                    actionApplyJobLoading | actionApplyJobSuccess | actionApplyJobError |
                                    actionCheckIfLinkCanBeSharedLoading | actionCheckIfLinkCanBeSharedSuccess | actionCheckIfLinkCanBeSharedError;
                                