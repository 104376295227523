import MarketDto from "../dto/MarketDto"

export enum ActionMarketsType {    
    MARKETS_ERROR_REQUIRED = 'MARKETS_ERROR_REQUIRED',
    MARKETS_LOADING = 'MARKETS_LOADING',
    MARKETS_SUCCESS = 'MARKETS_SUCCESS',
    MARKETS_ERROR = 'MARKETS_ERROR',
    MARKETS_ADD_LOADING = 'MARKETS_ADD_LOADING',
    MARKETS_ADD_SUCCESS = 'MARKETS_ADD_SUCCESS',
    MARKETS_ADD_ERROR = 'MARKETS_ADD_ERROR',
    MARKETS_EDIT_LOADING = 'MARKETS_EDIT_LOADING',
    MARKETS_EDIT_SUCCESS = 'MARKETS_EDIT_SUCCESS',
    MARKETS_EDIT_ERROR = 'MARKETS_EDIT_ERROR',
    MARKETS_DELETE_LOADING = 'MARKETS_DELETE_LOADING',
    MARKETS_DELETE_SUCCESS = 'MARKETS_DELETE_SUCCESS',
    MARKETS_DELETE_ERROR = 'MARKETS_DELETE_ERROR',
    MARKETS_CHANGE_SEARCH_TABLE = 'MARKETS_CHANGE_SEARCH_TABLE',
    MARKETS_CHANGE_PAGE_TABLE = 'MARKETS_CHANGE_PAGE_TABLE',
    MARKETS_CHANGE_PAGE_SIZE = 'MARKETS_CHANGE_PAGE_SIZE',
    MARKETS_CHANGE_FILTER = 'MARKETS_CHANGE_FILTER'
}

export interface actionMarketsErrorRequired {
    type: ActionMarketsType.MARKETS_ERROR_REQUIRED,
    value: string
}

export interface actionMarketsLoading {
    type: ActionMarketsType.MARKETS_LOADING,
    value: {reset: boolean} 
}
export interface actionMarketsSuccess {
    type: ActionMarketsType.MARKETS_SUCCESS,
    value: {data: MarketDto[], reset: boolean}
}
export interface actionMarketsError {
    type: ActionMarketsType.MARKETS_ERROR   
}

export interface actionMarketsAddLoading {
    type: ActionMarketsType.MARKETS_ADD_LOADING,
    value: string
}
export interface actionMarketsAddSuccess {
    type: ActionMarketsType.MARKETS_ADD_SUCCESS
    value: boolean
}
    
export interface actionMarketsAddError {
    type: ActionMarketsType.MARKETS_ADD_ERROR   
}

export interface actionMarketsEditLoading {
    type: ActionMarketsType.MARKETS_EDIT_LOADING,
    value: MarketDto
}
export interface actionMarketsEditSuccess {
    type: ActionMarketsType.MARKETS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionMarketsEditError {
    type: ActionMarketsType.MARKETS_EDIT_ERROR   
}

export interface actionMarketsDeleteLoading {
    type: ActionMarketsType.MARKETS_DELETE_LOADING,
    value: number
}
export interface actionMarketsDeleteSuccess {
    type: ActionMarketsType.MARKETS_DELETE_SUCCESS
    value: boolean
}
    
export interface actionMarketsDeleteError {
    type: ActionMarketsType.MARKETS_DELETE_ERROR   
}

export interface actionMarketsChangeSerachTable {
    type: ActionMarketsType.MARKETS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionMarketsChangePageSizeTable {
    type: ActionMarketsType.MARKETS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionMarketsChangePageTable {
    type: ActionMarketsType.MARKETS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionMarketsChangeFilterTable {
    type: ActionMarketsType.MARKETS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionMarketsActions = actionMarketsErrorRequired |  
                            actionMarketsLoading | actionMarketsSuccess | actionMarketsError |
                            actionMarketsAddLoading | actionMarketsAddSuccess | actionMarketsAddError |
                            actionMarketsEditLoading | actionMarketsEditSuccess | actionMarketsEditError |
                            actionMarketsDeleteLoading | actionMarketsDeleteSuccess | actionMarketsDeleteError |
                            actionMarketsChangeSerachTable | actionMarketsChangePageSizeTable | actionMarketsChangePageTable |
                            actionMarketsChangeFilterTable;