import React, {useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionPublicJobType } from '../../models/actions/typePublicJobActions';
import logo from '../../assest/swapp_logo.svg';
import PublicJobOfferDescription from './PublicJobOfferDescription';
import SMSRegistration from './SMSRegistration';
import NotificationsJob from './NotificationsJob';
import SkeketonJob from './SkeletonJob';
import './PublicJob.scss';
import { REJECT_JOB } from '../../constanst/GlobalConstants';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import TypeState from '../../models/initialState/typeState';
import logoDark from '../../assest/swapp_logo_dark.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTE_HOME } from '../../router/routes'
import NotificationApplicationJob from './NotificationApplicationJob';


function getSharedLinkCode() {
  const url = window.location.href.split('/');      
  return url[url.length - 1];
}

function PublicJob() {
  const [smsRegistration, setSmsRegistration] = useState(false);
  const [typeOfApply, setTypeOfApply] = useState(0);
  const { t } = useTranslation();
  const state:TypePublicJobState = useSelector((state:TypeState) => state.publicJobReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
      //dispatch para recoger los datos de la oferta          
      const sharedLinkCode = getSharedLinkCode();
      dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_LOADING, value: {sharedLinkCode, networkerId: 0}});            
  }, []);

  useEffect(()=>{
    //dispatch para recoger los datos de la oferta una vez hemos hecho LinkOpened
    if (state.linkOpened.linkOpenedData !== null && !state.linkOpened.error && !state.publicJob.loading) {
      const sharedLinkCode = getSharedLinkCode();
      dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_LOADING, value: {sharedLinkCode, linkId: state.linkOpened.linkOpenedData.linkId}});
    }
          
}, [state.linkOpened.linkOpenedData]);

  const sendSMSRegistrationCode = (typeApply: number) => {
    if (typeApply === REJECT_JOB) {
      if (state.linkOpened.linkOpenedData !== null) {
        dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_LOADING, value: {
          jobId: state.linkOpened.linkOpenedData.jobId,
          linkId: state.linkOpened.linkOpenedData.linkId,
          forwardId: state.linkOpened.linkOpenedData.forwardId
        }});
      }      
    } else {
      setSmsRegistration(true);
      setTypeOfApply(typeApply);
    }
  }

  const showPublicJob = !state.publicJob.loading && 
                        !state.publicJob.error && 
                        state.publicJob.data !== null && 
                        !state.rejectJob.ok &&
                        !state.linkOpened.error &&
                        !state.rejectJob.error;

  // const showError = (state.linkOpened.error || state.publicJob.error || state.rejectJob.error);
  const showError = (state.linkOpened.error || state.publicJob.error);
  const showSkeleton = ((state.publicJob.loading && !state.publicJob.error) || (state.linkOpened.loading && !state.linkOpened.error));

  return <>
          {showSkeleton && <SkeketonJob />}
          {showError && <NotificationsJob message={state.publicJob.messageError ? state.publicJob.messageError : t('publicJob.errorLoadJob')}/>}
          {showPublicJob &&
            <div className="job-layout">
              <div className='job-layout__header'>
                <div className='header'>
                  <img className='header__logo' src={logoDark} alt=""></img>
                  <div className='header__cta'>
                    <p className='header__title'>{t('publicJob.headerTitle')}</p>
                    <a className='header__button'  href='/' target="_blank">{t('publicJob.buttonMore')}</a>
                  </div>
                </div>
              </div>
              <div className='job-layout__content'>
                {!smsRegistration && !state.applyJob.error && <PublicJobOfferDescription sendSMSRegistrationCode={sendSMSRegistrationCode}/>}
                {smsRegistration && !state.applyJob.error && <SMSRegistration type={typeOfApply}/>}
                {state.applyJob.error && <NotificationApplicationJob message={state.applyJob.messageError}/>}
              </div>
              <div className='job-layout__footer'>
                <div className='header'>
                  <img className='header__logo' src={logoDark} alt=""></img>
                  <div className='header__cta'>
                    <p className='header__title'>{t('publicJob.headerTitle')}</p>
                    <a className='header__button' href='/' target="_blank">{t('publicJob.buttonMore')}</a>
                  </div>
                </div>
              </div> 
            </div>
          }
          {state.rejectJob.ok && <NotificationsJob message={t('publicJob.rejectJob')}/>}
  </>
}

export default PublicJob;