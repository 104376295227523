export const ROUTE_LOGIN = '/login';
export const ROUTE_OFFER = '/vacancy/*'
export const ROUTE_PRIVATE_ZONE = '/admin';
export const ROUTE_PRIVATE_COMPANYGROUPS = '/admin/company-groups';
export const ROUTE_PRIVATE_COMPANY = '/admin/company';
export const ROUTE_PRIVATE_JOBS = '/admin/jobs';
export const ROUTE_PRIVATE_NETWORKERS = '/admin/networkers';
export const ROUTE_PRIVATE_CLIENTS = '/admin/clients';
export const ROUTE_PRIVATE_TEMPLATES = '/admin/templates';
export const ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST = '/admin/networkers-request-list';
export const ROUTE_PRIVATE_POLICY = '/private-policy-swapp';
export const ROUTE_TERMS_AND_CONDITIONS = '/terms-and-conditions-swapp';
export const ROUTE_PRIVATE_SKILLS = '/admin/skills';
export const ROUTE_PRIVATE_SKILL_GROUP = '/admin/skill-group';
export const ROUTE_PRIVATE_MARKETS = '/admin/markets';
export const ROUTE_PRIVATE_LANGUAGES = '/admin/languages';
export const ROUTE_PRIVATE_COUNTRIES = '/admin/countries';
export const ROUTE_PRIVATE_REGIONS = '/admin/regions';
export const ROUTE_PRIVATE_CITIES = '/admin/cities';
export const ROUTE_PRIVATE_FEED_TEMPLATES = '/admin/feeds';
export const ROUTE_PRIVATE_PAYMENTS = '/admin/payments';
export const ROUTE_FAQ = '/faq';
export const ROUTE_HOME = '/';

