
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
//import './ModalInfo.scss';
import NetworkerDto from '../../models/dto/NetworkerDto';
import { useState, useEffect, ChangeEvent } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CompanyDto from '../../models/dto/CompanyDto';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
  };

interface Props {    
    index: NetworkerDto | null,
    show: boolean,
    companies: CompanyDto[],
    closeReport(): void,
    sendReport(company: number, startDate: string, endDate: string ): void
}

const defaultStartDate = ()=>{
  const today = new Date();    
  const day = today.getDate();
  let mounth = today.getMonth();
  let year = today.getFullYear();

  if (mounth >= 3) {
    mounth = mounth - 3;
  } else if (mounth < 3) {
    mounth = 9 + mounth;
    year = year - 1;
  } 
  
  return (new MomentUtils().moment([year,mounth, day]))
}

function NetWorkersModalReport(props: Props) {
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(defaultStartDate());
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(new MomentUtils().moment);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [company, setCompany] = useState(0);
  const { t } = useTranslation();
  
  useEffect(()=>{
    if ((startDate && startDate.isValid()) && (endDate && endDate.isValid())) {
      if (startDate.format() > endDate.format()) {
        setErrorStartDate(true);
        setErrorEndDate(true);
      } else {
        setErrorStartDate(false);
        setErrorEndDate(false);
      } 
    }   
 
  }, [startDate, endDate])

  const onChangeDate = (date: MaterialUiPickersDate, field: string) => {
    if (date) {
      if (field === 'startDate') {
        setStartDate(date);       
      } else {
        setEndDate(date);        
      }
    }
  }

  const sendReport = ()=>{
    props.sendReport(company,startDate ? startDate.toISOString() : defaultStartDate().toISOString(), endDate ? endDate.toISOString() : new Date().toISOString())
  }

  return <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.show}
            onClose={()=>props.closeReport()}
        >                            
            <Box sx={style}>
                <div className='swissin-modal-close'>
                  <span onClick={()=>props.closeReport()}>X</span>
                </div>   
                <div className='swissin-modal-info report'>
                  <Info />
                  <h2>{t('admin.networkers.report_title')}</h2>  
                  <p>{t('admin.networkers.report_description')}</p>              
                  <div className='swissin-modal-info-data'>
                    <div className="swissinx-field field-company">
                      <TextField
                        id="company"
                        select={true}
                        label={'Company'}
                        value={company}
                        error={false}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>{
                          setCompany(parseInt(event.target.value))
                        }}
                        
                      //helperText={state.jobs.jobsForm.clientId.error.required && t('admin.jobs.client_error_required')}
                      >
                        <MenuItem key={0} value={0}>
                          {'All companies'}
                        </MenuItem>
                        {props.companies.map((company) => (
                          <MenuItem key={company.companyId} value={company.companyId}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="swissinx-field-container">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className="swissinx-field">
                          <KeyboardDatePicker
                              error={errorStartDate}
                              label={t('admin.networkers.dateFrom')}                              
                              inputVariant="outlined"
                              format="DD/MM/yyyy"
                              value={startDate}
                              onChange={(date: MaterialUiPickersDate) => onChangeDate(date as MaterialUiPickersDate, "startDate")}
                              onError={(error, value)=>{
                                if (error !== '') {
                                  setErrorStartDate(true);
                                  setStartDate(value as moment.Moment);  
                                }
                                
                              }}
                                                                        
                          />
                        </div>
                        <div className="swissinx-field">
                          <KeyboardDatePicker 
                            error={errorEndDate}
                            label={t('admin.networkers.dateTo')}
                            format="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={endDate}
                            onChange={(date: MaterialUiPickersDate) =>
                              onChangeDate(date, "endDate")
                            }
                            onError={(error, value) => { 
                              if (error !== '') {
                                  setErrorEndDate(true); 
                                  setEndDate(value as moment.Moment);  
                              } 
                                                       
                            }}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className='swissin-modal-info-buttons'>
                      <button onClick={sendReport} disabled={errorStartDate || errorEndDate}>{t('admin.networkers.report_button')}</button>
                  </div>                    
              </div>
            </Box>
        </Modal>           
       
  }

export default NetWorkersModalReport;
