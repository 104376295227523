import LanguageDto from "../dto/LanguageDto"

export enum ActionLanguagesType {    
    LANGUAGES_ERROR_REQUIRED = 'LANGUAGES_ERROR_REQUIRED',
    LANGUAGES_LOADING = 'LANGUAGES_LOADING',
    LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS',
    LANGUAGES_ERROR = 'LANGUAGES_ERROR',
    LANGUAGES_ADD_LOADING = 'LANGUAGES_ADD_LOADING',
    LANGUAGES_ADD_SUCCESS = 'LANGUAGES_ADD_SUCCESS',
    LANGUAGES_ADD_ERROR = 'LANGUAGES_ADD_ERROR',
    LANGUAGES_EDIT_LOADING = 'LANGUAGES_EDIT_LOADING',
    LANGUAGES_EDIT_SUCCESS = 'LANGUAGES_EDIT_SUCCESS',
    LANGUAGES_EDIT_ERROR = 'LANGUAGES_EDIT_ERROR',
    LANGUAGES_DELETE_LOADING = 'LANGUAGES_DELETE_LOADING',
    LANGUAGES_DELETE_SUCCESS = 'LANGUAGES_DELETE_SUCCESS',
    LANGUAGES_DELETE_ERROR = 'LANGUAGES_DELETE_ERROR',
    LANGUAGES_CHANGE_SEARCH_TABLE = 'LANGUAGES_CHANGE_SEARCH_TABLE',
    LANGUAGES_CHANGE_PAGE_TABLE = 'LANGUAGES_CHANGE_PAGE_TABLE',
    LANGUAGES_CHANGE_PAGE_SIZE = 'LANGUAGES_CHANGE_PAGE_SIZE',
    LANGUAGES_CHANGE_FILTER = 'LANGUAGES_CHANGE_FILTER'
}

export interface actionLanguagesErrorRequired {
    type: ActionLanguagesType.LANGUAGES_ERROR_REQUIRED,
    value: string
}

export interface actionLanguagesLoading {
    type: ActionLanguagesType.LANGUAGES_LOADING,
    value: {reset: boolean} 
}
export interface actionLanguagesSuccess {
    type: ActionLanguagesType.LANGUAGES_SUCCESS,
    value: {data: LanguageDto[], reset: boolean}
}
export interface actionLanguagesError {
    type: ActionLanguagesType.LANGUAGES_ERROR,
    value: string  
}

export interface actionLanguagesAddLoading {
    type: ActionLanguagesType.LANGUAGES_ADD_LOADING,
    value: string
}
export interface actionLanguagesAddSuccess {
    type: ActionLanguagesType.LANGUAGES_ADD_SUCCESS
    value: boolean
}
    
export interface actionLanguagesAddError {
    type: ActionLanguagesType.LANGUAGES_ADD_ERROR   
}

export interface actionLanguagesEditLoading {
    type: ActionLanguagesType.LANGUAGES_EDIT_LOADING,
    value: LanguageDto
}
export interface actionLanguagesEditSuccess {
    type: ActionLanguagesType.LANGUAGES_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionLanguagesEditError {
    type: ActionLanguagesType.LANGUAGES_EDIT_ERROR   
}

export interface actionLanguagesDeleteLoading {
    type: ActionLanguagesType.LANGUAGES_DELETE_LOADING,
    value: number
}
export interface actionLanguagesDeleteSuccess {
    type: ActionLanguagesType.LANGUAGES_DELETE_SUCCESS
    value: boolean
}
    
export interface actionLanguagesDeleteError {
    type: ActionLanguagesType.LANGUAGES_DELETE_ERROR   
}

export interface actionLanguagesChangeSerachTable {
    type: ActionLanguagesType.LANGUAGES_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionLanguagesChangePageSizeTable {
    type: ActionLanguagesType.LANGUAGES_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionLanguagesChangePageTable {
    type: ActionLanguagesType.LANGUAGES_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionLanguagesChangeFilterTable {
    type: ActionLanguagesType.LANGUAGES_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionLanguagesActions = actionLanguagesErrorRequired |  
                            actionLanguagesLoading | actionLanguagesSuccess | actionLanguagesError |
                            actionLanguagesAddLoading | actionLanguagesAddSuccess | actionLanguagesAddError |
                            actionLanguagesEditLoading | actionLanguagesEditSuccess | actionLanguagesEditError |
                            actionLanguagesDeleteLoading | actionLanguagesDeleteSuccess | actionLanguagesDeleteError |
                            actionLanguagesChangeSerachTable | actionLanguagesChangePageSizeTable | actionLanguagesChangePageTable |
                            actionLanguagesChangeFilterTable;