
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { CityDto, CountryDto, RegionDto } from "../../models/dto/LocationDto";
import { MANDATORY_CHARACTER } from '../../constanst/GlobalConstants';

interface Props {
  inputLabel:string,
  inputValue:CountryDto | CityDto | RegionDto | null, 
  onChange(event: React.SyntheticEvent<Element, Event>, value: CountryDto | CityDto | RegionDto | null, reason: any):any,
  inputHelperText?:any,
  inputError?:boolean,
  options:any,
  required: boolean
}

function AutocompleteSelect(props: Props) {
  const label = props.required ? props.inputLabel + MANDATORY_CHARACTER : props.inputLabel
  return (
  <div className="swissinx-field field-country">
    <Autocomplete
        id="country"       
        sx={{ width: 300 }}
        options={props.options}
        autoHighlight
        getOptionLabel={(option) => option.description }
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>                    
            {option.description}                    
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}  
            error={props.inputError} 
            helperText={props.inputHelperText || null}                  
            inputProps={{
              ...params.inputProps,
              autoComplete: 'country', // disable autocomplete and autofill
            }}
          />
        )}
        value={props.inputValue}
        onChange={props.onChange}/>   
    </div>)
  }

export default AutocompleteSelect;
