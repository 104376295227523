import SkillDto from "../dto/SkillDto"

export enum ActionSkillsType {    
    SKILLS_ERROR_REQUIRED = 'SKILLS_ERROR_REQUIRED',
    SKILLS_LOADING = 'SKILLS_LOADING',
    SKILLS_SUCCESS = 'SKILLS_SUCCESS',
    SKILLS_ERROR = 'SKILLS_ERROR',
    SKILLS_ADD_LOADING = 'SKILLS_ADD_LOADING',
    SKILLS_ADD_SUCCESS = 'SKILLS_ADD_SUCCESS',
    SKILLS_ADD_ERROR = 'SKILLS_ADD_ERROR',
    SKILLS_EDIT_LOADING = 'SKILLS_EDIT_LOADING',
    SKILLS_EDIT_SUCCESS = 'SKILLS_EDIT_SUCCESS',
    SKILLS_EDIT_ERROR = 'SKILLS_EDIT_ERROR',
    SKILLS_DELETE_LOADING = 'SKILLS_DELETE_LOADING',
    SKILLS_DELETE_SUCCESS = 'SKILLS_DELETE_SUCCESS',
    SKILLS_DELETE_ERROR = 'SKILLS_DELETE_ERROR',
    SKILLS_CHANGE_SEARCH_TABLE = 'SKILLS_CHANGE_SEARCH_TABLE',
    SKILLS_CHANGE_PAGE_TABLE = 'SKILLS_CHANGE_PAGE_TABLE',
    SKILLS_CHANGE_PAGE_SIZE = 'SKILLS_CHANGE_PAGE_SIZE',
    SKILLS_CHANGE_FILTER = 'SKILLS_CHANGE_FILTER'
}

export interface actionSkillsErrorRequired {
    type: ActionSkillsType.SKILLS_ERROR_REQUIRED,
    value: string
}

export interface actionSkillsLoading {
    type: ActionSkillsType.SKILLS_LOADING,
    value: {reset: boolean} 
}
export interface actionSkillsSuccess {
    type: ActionSkillsType.SKILLS_SUCCESS,
    value: {data: SkillDto[], reset: boolean}
}
export interface actionSkillsError {
    type: ActionSkillsType.SKILLS_ERROR,
    value: string  
}

export interface actionSkillsAddLoading {
    type: ActionSkillsType.SKILLS_ADD_LOADING,
    value: {name: string, skillGroupId: number}
}
export interface actionSkillsAddSuccess {
    type: ActionSkillsType.SKILLS_ADD_SUCCESS
    value: boolean
}
    
export interface actionSkillsAddError {
    type: ActionSkillsType.SKILLS_ADD_ERROR,
    value: string  
}

export interface actionSkillsEditLoading {
    type: ActionSkillsType.SKILLS_EDIT_LOADING,
    value: SkillDto
}
export interface actionSkillsEditSuccess {
    type: ActionSkillsType.SKILLS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionSkillsEditError {
    type: ActionSkillsType.SKILLS_EDIT_ERROR,
    value: string  
}

export interface actionSkillsDeleteLoading {
    type: ActionSkillsType.SKILLS_DELETE_LOADING,
    value: number
}
export interface actionSkillsDeleteSuccess {
    type: ActionSkillsType.SKILLS_DELETE_SUCCESS
    value: boolean
}
    
export interface actionSkillsDeleteError {
    type: ActionSkillsType.SKILLS_DELETE_ERROR,
    value: string  
}

export interface actionSkillsChangeSerachTable {
    type: ActionSkillsType.SKILLS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionSkillsChangePageSizeTable {
    type: ActionSkillsType.SKILLS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionSkillsChangePageTable {
    type: ActionSkillsType.SKILLS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionSkillsChangeFilterTable {
    type: ActionSkillsType.SKILLS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionSkillsActions = actionSkillsErrorRequired |  
                            actionSkillsLoading | actionSkillsSuccess | actionSkillsError |
                            actionSkillsAddLoading | actionSkillsAddSuccess | actionSkillsAddError |
                            actionSkillsEditLoading | actionSkillsEditSuccess | actionSkillsEditError |
                            actionSkillsDeleteLoading | actionSkillsDeleteSuccess | actionSkillsDeleteError |
                            actionSkillsChangeSerachTable | actionSkillsChangePageSizeTable | actionSkillsChangePageTable |
                            actionSkillsChangeFilterTable;