import { CUSTOM_TEMPLATE, FORWARD_TIME_USED_LIMIT, MANDATORY_CHARACTER, PREFIX_DEFAULT } from "../constanst/GlobalConstants";
import ClientDto from "../models/dto/ClientDto";
import CompanyDto from "../models/dto/CompanyDto";
import JobsDto from "../models/dto/JobsDto";
import JobsResponseDto from "../models/dto/JobsResponseDto";
import { Question, TemplateJobResponse } from "../models/dto/JobTemplateDto";
import LanguageJobResponseDto from "../models/dto/LanguageJobResponseDto";
import MarketResponseDto from "../models/dto/MarketResponseDto";
import NetworkerDto from "../models/dto/NetworkerDto";
import PushJobDto from "../models/dto/PushJobDto";
import SkillResponseDto from "../models/dto/SkillResponseDto";
import ClientForm from "../models/forms/ClientForm";
import CompanyForm from "../models/forms/CompanyForm";
import JobsForm from "../models/forms/JobsForm";
import NetworkerForm from "../models/forms/NetworkerForm";
import PushJobForm from "../models/forms/PushJobForm";
import { SnackbarOrigin } from '@material-ui/core';
import { ACTIVE_STATUS, INACTIVE_STATUS, Status, StatusJob } from "../models/jobs/JobStatus";
import { AUTO_SEND_PUSH, JobModePush, MANUAL_SEND_PUSH, ModePushJob } from "../models/jobs/JobModePush";
import TypeAmbassadorsState from "../models/initialState/typeAmbassadorsState";
import { CountryAutocomplete, CountryDto, CountryException } from "../models/dto/LocationDto";
import SkillDto, { skillAutocomplete } from "../models/dto/SkillDto";
import MarketDto from "../models/dto/MarketDto";
import LanguageDto from "../models/dto/LanguageDto";
import CompanyUpdateDto from "../models/dto/CompanyUpdateDto";
import NetworkerCreateDto from "../models/dto/NetworkerCreateDto";
import FeeForm from "../models/forms/FeeForm";
import FeeDto from "../models/dto/FeeDto";
import SkillGroupDto from "../models/dto/SkillGroupDto";
import JobResumeListDto from "../models/dto/JobResumeListDto";

export const SNACBARPOSITION: SnackbarOrigin= {vertical: 'top', horizontal:'center' };
export const SNACKBARTIMETOHIDE = 3000;
export const DEFAULTITEMSPERPAGE = 20;
export const PAYMENT_STATUS_PAID = 'Paid';
export const PAYMENT_STATUS_NOT_PAID = 'Pending';
export const ALL = 'All';


export function convertDteIso(date: string) {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
}

export function convertDateHourIso(date: string) {
  let dateAux = date;
  const length = date.length;
  if (length > 0 && date[length - 1] !== 'Z') {
    dateAux += 'Z'
  }
  const newDate = new Date(dateAux);
  return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
}

export function dateHourIso(date: string) {
  let dateAux = date;
  const length = date.length;
  if (length > 0 && date[length - 1] !== 'Z') {
    dateAux += 'Z'
  }  
  return new Date(dateAux);
}

export function dateHour_time(initialDate: string, time: number) {
  const miliseconds = time * 60 * 60 * 1000;
  const date = dateHourIso(initialDate).getTime() + miliseconds;
  const newDate = new Date(date)      
  return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
}

export function newFormField(value: string | number | boolean | number[], required:boolean, format: boolean) {
    return {
      value: value,
      rules: {
        required: required,
        format: format
      },
      error: {
        required: false,
        format: false
      }
    }
  }

export function newFormJob():JobsForm {
    return {
      title: newFormField('',true, false),
      status: newFormField('1',true, false),
      description: newFormField('',false, false),
      marketId: newFormField([],true, false),
      skillId: newFormField([],true, false),
      languageId: newFormField([],true, false),
      seniority: newFormField('',false, false),
      salaryMin: newFormField('',true, true),
      salaryMax: newFormField('',true, true),
      salaryCurrency: newFormField('',true, false),
      salaryCompetitive: newFormField(false,false, false),
      salaryDisclose: newFormField(false,false, false),
      clientDisclose: newFormField(false,false, false),
      countryId: newFormField('',true, false),
      regionId: newFormField('',true, false),
      cityId: newFormField('',false, false),
      clientId: newFormField('',false, false),
      templateId: newFormField('0',false, false),
      countryExceptionIds: newFormField([],false, false)
    }
}

export function jobFormToDto(jobsForm: JobsForm, addJob: boolean, taId: number, companyId: number, jobSelected: JobsResponseDto | null, questions: Question[] | null):JobsDto {
  //Si opcion elegida es -1 y originalmente el template description es Custom, entonces es un template personalizado y el templateId es el que nos vino originalemte
  //En cualquier otro caso enviamos como templateId el id del combo  
  
  let templateId = parseInt(jobsForm.templateId.value as string); 
  if (questions && questions.length > 0 && jobSelected !== null && jobSelected.template && jobSelected.template.description === CUSTOM_TEMPLATE && templateId === -1) {
    templateId = jobSelected.template.id;   
  }
  const client = parseInt(jobsForm.clientId.value as string);

  return {
      jobId: jobSelected === null ? undefined : jobSelected.jobId, 
      title: jobsForm.title.value as string,
      status: parseInt(jobsForm.status.value as string),
      description: jobsForm.description.value as string,
      marketId: jobsForm.marketId.value as number[],
      skillId: jobsForm.skillId.value as number[],
      languageId: jobsForm.languageId.value as number[],
      seniority: jobsForm.seniority.value as string,
      salaryMin: parseInt(jobsForm.salaryMin.value as string),
      salaryMax: parseInt(jobsForm.salaryMax.value as string),
      salaryCurrency: jobsForm.salaryCurrency.value as string,
      salaryCompetitive: jobsForm.salaryCompetitive.value as boolean,
      salaryDisclose: jobsForm.salaryDisclose.value as boolean,
      clientDisclose: jobsForm.clientDisclose.value as boolean,
      countryId: parseInt(jobsForm.countryId.value as string),
      regionId: parseInt(jobsForm.regionId.value as string),
      cityId: parseInt(jobsForm.cityId.value as string),
      clientId: client === 0 ? null : client,
      taId: taId,
      companyId: companyId,
      templateId: templateId,
      questions:  questions,
      countryExceptionIds: jobsForm.countryExceptionIds.value as number[]

    }
}

function languageDtoToLanguageForm(languages: LanguageJobResponseDto[]):number[] {
  return languages.map(language => language.id);
}

function marketDtoToMarketForm(markets: MarketResponseDto[]):number[] {
  return markets.map(market => market.id);
}

function skillDtoToSkillForm(skills: SkillResponseDto[]):number[] {
  return skills.map(skill => skill.id);
}

function countryExceptionsDtoToCountryExceptionsForm(countryExceptionIds: CountryException[]):number[] {
  return countryExceptionIds.map(countryExceptionId => countryExceptionId.id);
}

function getTemplateId(template: TemplateJobResponse | null,  questions: Question[] | null): string {
  let templateId = '0';
  if (template !== null) {
    if (questions !== null && template.id > 0) { // template personalizado
      templateId = '-1';
    } else if (questions === null && template.id > 0) {
      templateId = template.id.toString();
    }
  }
  return templateId;
}

export function jobDtoResponseToForm(jobResponseDto: JobsResponseDto):JobsForm {  
  return {    
    title: newFormField(jobResponseDto.title,true, false),
    status: newFormField(jobResponseDto.status.id.toString(),true, false),
    description: newFormField(jobResponseDto.description,false, false),
    marketId: newFormField(marketDtoToMarketForm(jobResponseDto.markets),true, false),
    skillId: newFormField(skillDtoToSkillForm(jobResponseDto.skills),true, false),
    languageId: newFormField(languageDtoToLanguageForm(jobResponseDto.languages),true, false),
    seniority: newFormField(jobResponseDto.seniority,false, false),
    salaryMin: newFormField(jobResponseDto.salaryMin.toString(),true, true),
    salaryMax: newFormField(jobResponseDto.salaryMax.toString(),true, true),
    salaryCurrency: newFormField(jobResponseDto.salaryCurrency,true, false),
    salaryCompetitive: newFormField(jobResponseDto.salaryCompetitive,false, false),
    salaryDisclose: newFormField(jobResponseDto.salaryDisclose,false, false),
    clientDisclose: newFormField(jobResponseDto.clientDisclose,false, false),
    countryId: newFormField(jobResponseDto.country.id.toString(),true, false),
    regionId: newFormField(jobResponseDto.region.id.toString(),true, false),
    cityId: newFormField(jobResponseDto.city.id.toString(),false, false),
    clientId: newFormField(jobResponseDto.clientId!== null ? jobResponseDto.clientId.toString() : '',false, false),
    templateId: newFormField(getTemplateId(jobResponseDto.template, jobResponseDto.questions),false, false),
    countryExceptionIds: newFormField(countryExceptionsDtoToCountryExceptionsForm(jobResponseDto.jobCountryExceptions),false, false)
    }
}

export function newPushJobForm():PushJobForm {
  return {
      veryHighFee: newFormField('',true, true),
      highFee: newFormField('',true, true),
      lowFee: newFormField('',true, true),  
      feeCurrency: newFormField('',true, false),
      timeToEmbassadors: newFormField('',true, true),
      timeToNetwork: newFormField('',true, true),
      autoreply: newFormField(false, false, false),
      createdDate: newFormField(new Date().toISOString(),false, true),
      status: newFormField(StatusJob.ACTIVE_STATUS.toString(),true, false),
      endDateTime: newFormField(new Date().toISOString(),false, true),
      amabassadorId: newFormField([], false, false),
      mode: newFormField('',true, false),
      countryExceptions: newFormField([], false, false),
      forwardTimeUsedLimit: newFormField(FORWARD_TIME_USED_LIMIT.toString(), false, true),
  }
}

export function pushJobFormToPushJobDto(pushJobForm: PushJobForm, jobId: number, taId: number, stateAmbassadors:TypeAmbassadorsState):PushJobDto {
  const ambassadors: number[] = stateAmbassadors.listAmbassadorsFiltered.filter(item=>item.isInsider).map(ambassador => ambassador.ambassadorId)
  const networkers: number[] = stateAmbassadors.listAmbassadorsFiltered.filter(item=>!item.isInsider).map(networker => networker.networkerId)
  
  return {
    jobId: jobId,
    taId: taId,
    veryHighFee: parseInt(pushJobForm.veryHighFee.value as string),
    highFee: parseInt(pushJobForm.highFee.value as string),
    lowFee:	parseInt(pushJobForm.lowFee.value as string),
    feeCurrency: pushJobForm.feeCurrency.value as string,    
    timeToEmbassadors: parseFloat(pushJobForm.timeToEmbassadors.value as string),
    timeToNetwork: parseFloat(pushJobForm.timeToNetwork.value as string),
    autoreply: pushJobForm.autoreply.value as boolean,
    createdDate: pushJobForm.createdDate.value as string,
    status:	parseInt(pushJobForm.status.value as string),    
    endDateTime: pushJobForm.endDateTime.value as string,
    amabassadorId: ambassadors,
    networkerId:networkers,
    automaticMatch: pushJobForm.mode.value === '1',
    countryExceptions: pushJobForm.countryExceptions.value as number[],
    forwardTimeUsedLimit: parseInt(pushJobForm.forwardTimeUsedLimit.value as string)
  }
}

export function newFormCompany():CompanyForm {
  return {    
    name: newFormField('',true, false),
    notifications: newFormField('',false, false),
    credit: newFormField('',true, true),
    address: newFormField('',false, true),
    cityId: newFormField('',true, false),
    countryId: newFormField('',true, false),
    postalCode: newFormField('',false, true),
    regionId: newFormField('',true, false),
    signature: newFormField('',false, true),
    companyGroupId: newFormField('',true, false)
    }
}

export function companyDtoToForm(companyDto: CompanyDto):CompanyForm {  
  return {    
    name: newFormField(companyDto.name, true, false),
    notifications: newFormField(companyDto.notifications, false, false),
    credit: newFormField(companyDto.credit.toString(),true, true),
    companyGroupId: newFormField(companyDto.companyGroupId.toString(),true, true),
    address: newFormField(companyDto.address ? companyDto.address : '',false, true),
    cityId: newFormField(companyDto.city ? companyDto.city.id : '',true, false),
    countryId: newFormField(companyDto.country ? companyDto.country.id : '',true, false),
    postalCode: newFormField(companyDto.postalCode ? companyDto.postalCode : '',false, true),
    regionId: newFormField(companyDto.region ? companyDto.region.id : '',true, false),
    signature: newFormField(companyDto.signature ? companyDto.signature : '',false, true),   
    }
}

export function companyFormToDto(companyForm: CompanyForm, companyId: number | undefined):CompanyUpdateDto {  
  return {
    companyId: companyId,
    name: companyForm.name.value as string,
    notifications: companyForm.notifications.value as string,
    credit: parseInt(companyForm.credit.value as string),
    address: companyForm.address.value as string,
    cityId: parseInt(companyForm.cityId.value as string),
    countryId: parseInt(companyForm.countryId.value as string),
    postalCode: companyForm.postalCode.value as string,
    regionId: parseInt(companyForm.regionId.value as string),
    signature: companyForm.signature.value as string,
    companyGroupId: parseInt(companyForm.companyGroupId.value as string)    
    }
}  

export function newFormClient():ClientForm {
  return {    
    name: newFormField('',true, false),    
    companyGroupId: newFormField('',true, false)
    }
}

export function clientDtoToForm(clientDto: ClientDto):ClientForm {  
  return {    
    name: newFormField(clientDto.name, true, false),    
    companyGroupId: newFormField(clientDto.companyGroupId.toString(),true, true)    
    }
}

export function clientFormToDto(clientForm: ClientForm, clientId: number | undefined):ClientDto {  
  return {
    clientId: clientId,
    name: clientForm.name.value as string,    
    companyGroupId: parseInt(clientForm.companyGroupId.value as string)    
    }
}

export function newFeeForm():FeeForm {
  return {
    description: newFormField('',true, false),
    languageIds: newFormField([],false, false),    
    skillIds: newFormField([],false, false),
    marketIds: newFormField([],false, false),
    veryHighFee: newFormField('',true, false),
    highFee: newFormField('',true, false),
    lowFee: newFormField('',true, false),   
    feeCurrency: newFormField('',true, false),
    timeToEmbassadors: newFormField('',true, false),
    timeToNetwork: newFormField('',true, false)    
  }
}

export function FeedDtoToForm(fee: FeeDto):FeeForm {
  return {
    description: newFormField(fee.description,true, false),
    languageIds: newFormField(fee.languageIds,false, false),    
    skillIds: newFormField(fee.skillIds,false, false),
    marketIds: newFormField(fee.marketIds,false, false),
    veryHighFee: newFormField(fee.veryHighFee.toString(),true, false),
    highFee: newFormField(fee.highFee.toString(),true, false),
    lowFee: newFormField(fee.lowFee.toString(),true, false),   
    feeCurrency: newFormField(fee.feeCurrency,true, false),
    timeToEmbassadors: newFormField(fee.timeToEmbassadors.toString(),true, false),
    timeToNetwork: newFormField(fee.timeToNetwork.toString(),true, false)    
  }
}

export function newFormNetworker():NetworkerForm {
  return {    
    name: newFormField('',true, false),
    surname: newFormField('',true, false),
    phone: newFormField('',true, false),
    prefix: newFormField(PREFIX_DEFAULT,true, false),
    email: newFormField('',true, false),
    languageId: newFormField([],false, false),
    marketId: newFormField([],false, false),
    skillId: newFormField([],false, false),   
    countryId: newFormField('',false, false),
    countryIds: newFormField([],true, false),
    regionId: newFormField('',false, false),
    cityId: newFormField('',false, false),
    password: newFormField('',true, false),
    repeatPassword: newFormField('',true, false),
    birthDay: newFormField('',false, false),
    address: newFormField('',false, false),
    postalCode: newFormField('',false, false)
    }
}

export function networkerDtoToForm(networker: NetworkerDto):NetworkerForm {
  return {    
    name: newFormField(networker.name,true, false),
    surname: newFormField(networker.surname,true, false),
    phone: newFormField(networker.phone,true, false),
    prefix: newFormField('',false, false),
    email: newFormField(networker.email,true, false),
    languageId: newFormField([],true, false),
    marketId: newFormField([],true, false),
    skillId: newFormField([],true, false),    
    countryIds: newFormField([],true, false),
    countryId: newFormField('',true, false),
    regionId: newFormField('',true, false),
    cityId: newFormField('',true, false),
    password: newFormField('',false, false),
    repeatPassword: newFormField('',false, false),
    birthDay: newFormField('',false, false),
    address: newFormField('',false, false),
    postalCode: newFormField('',false, false)
    }
}

export function networkersFormToDto(networkerForm: NetworkerForm, companyId: number, netWorkerId: number | undefined):NetworkerCreateDto { 
  const phoneValue = networkerForm.prefix.value as string + networkerForm.phone.value as string;
  return {
    networkerId: netWorkerId,
    phone: phoneValue,
    prefix: networkerForm.prefix.value as string,
    name: networkerForm.name.value as string,
    surname: networkerForm.surname.value as string,
    email: networkerForm.email.value as string,
    credit: 0,   
    companyId: companyId,
    password: networkerForm.password.value as string,
    marketIds: networkerForm.marketId.value as number[],
    skillIds: networkerForm.skillId.value as number[],
    countryIds: networkerForm.countryIds.value as number[],
    countryId: networkerForm.countryId.value !== 0 ? parseInt(networkerForm.countryId.value as string) : NaN,
    regionId: networkerForm.regionId.value !== 0 ? parseInt(networkerForm.regionId.value as string) : NaN,
    cityId: networkerForm.cityId.value !== 0 ? parseInt(networkerForm.cityId.value as string) : NaN,
    languageIds: networkerForm.languageId.value as number[],
    birthDay: (networkerForm.birthDay.value !== null && networkerForm.birthDay.value !== '') ? networkerForm.birthDay.value as string : null,
    address: networkerForm.address.value as string,
    postalCode: networkerForm.postalCode.value as string
    }
}

export function listCurrency():string[] {
  return [
    '',
    'EUR',
    'CHF',
    'USD'
  ]
}

export function listStatus():Status[] {
  return [
    {id: StatusJob.INACTIVE_STATUS, name: INACTIVE_STATUS},
    {id: StatusJob.ACTIVE_STATUS, name: ACTIVE_STATUS}
  ]

}

export function listModePush():JobModePush[] {
  return [
    {id: ModePushJob.MANUAL, name: MANUAL_SEND_PUSH},
    {id: ModePushJob.AUTO, name: AUTO_SEND_PUSH}
  ]
}

export function downloadFile(data: Blob, fileName: string) {
  const link = document.createElement('a');            
  link.href = window.URL.createObjectURL(new Blob([data]));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);  
}

export const getLabelSkill = (value: number, skills: SkillDto[], loading: boolean, error: boolean):string => {
  let label = '';
  if(loading) {
    label = 'Loading';
  } else if (error) {
    label = 'Error'
  } else {
    const labelAux = skills.find(skill => skill.skillId === value);
    if (labelAux) {
      label = labelAux.name;
    }
  }
  return label;
}

export const getLabelMarket = (value: number, markets: MarketDto[], loading: boolean, error: boolean):string => {
  let label = '';
  if(loading) {
    label = 'Loading';
  } else if (error) {
    label = 'Error'
  } else {
    const labelAux = markets.find(market => market.marketId === value);
    if (labelAux) {
      label = labelAux.name;
    }
  }
  return label;
}

export const getLabelCountry = (value: number, countries: CountryDto[], loading: boolean, error: boolean):string => {
  let label = '';  
  if(loading) {
    label = 'Loading';
  } else if (error) {
    label = 'Error'
  } else {
    const labelAux = countries.find(country => country.id === value);
    if (labelAux) {
      label = labelAux.description;
    }
  }
  return label;
}

export const getLabelLanguage = (value: number, languages: LanguageDto[], loading: boolean, error: boolean):string => {
  let label = '';    
  if(loading) {
    label = 'Loading';
  } else if (error) {
    label = 'Error'
  } else {
    const labelAux = languages.find(language => language.languageId === value);
    if (labelAux) {
      label = labelAux.name;
    }
  }    
  return label;
}

export const getLabelFormatted = (label: string, required: boolean) => required ? label + MANDATORY_CHARACTER : label

export const getSkillOptions = (skills: SkillDto[], groupSkills: SkillGroupDto[])=>{
  const skillOptions = skills.map(skill=>{
    const name = groupSkills.find(skillGroup=> skillGroup.id === skill.skillGroupId);
    let nameGroup = '';
    if (name) {
      nameGroup = name.name;      
    }
    return {
     id: skill.skillId, description: `${nameGroup} | ${skill.name}`     
   }
    
 }).sort((a:any,b:any)=>{
  if (a.description < b.description) {
    return -1;
  } 

  if (a.description > b.description) {
    return 1;
  }
  return 0
})
 return skillOptions
}

export const getCountriesOptions = (countries: CountryDto[]) => {
  const countriesOptions = countries.map(country=>{
    return {id: country.id, description: country.description}
  })

  return countriesOptions;
}

export const sortSkills = (skills: SkillDto[]) => {
  const skillsSorted = skills.sort((a:SkillDto,b:SkillDto)=>{
    if (a.skillGroupId > b.skillGroupId) {
      return -1;
    }   
    if (a.skillGroupId < b.skillGroupId) {
      return 1;
    }
    return 0
  })
  return skillsSorted;
}

export const getDefaultSkills = (skillsSelected: skillAutocomplete[], skills: SkillDto[], groupSkills: SkillGroupDto[])=>{
  const skillsDefault:any = skillsSelected.map(skill=>{
    const skillDto = skills.find(stateSkill=>stateSkill.skillId === skill.id)
    let nameGroup = '';
    if (skillDto) {
      const name = groupSkills.find(skillGroup=> skillGroup.id === skillDto.skillGroupId);          
      if (name) {
        nameGroup = name.name;    
      }         
    }
    return {
      id: skill.id, description: `${nameGroup} | ${skill.description}`     
    }
    
  })
  return skillsDefault
}

export const getDefaultCountries = (countriesSelected: CountryAutocomplete[])=>{
  return countriesSelected.map(country=>{
    return {
      id: country.id,
      description: country.description
    }
  })
}

export const jobsFilter = (jobs: JobResumeListDto[], status:number, pushed: number, skill: string, market: string, region: string)=>{
  let jobsFiltered: JobResumeListDto[] = jobs;
  if (status !== -1) {
    jobsFiltered = jobsFiltered.filter(job=>job.jobStatusExtended.id === status);   
  }
  if (pushed !== -1) {
    const jobPushed = (pushed === 1) 
    jobsFiltered = jobsFiltered.filter(job=>job.hasBeenPushed === jobPushed);
  }
  if (skill !== ALL) {
    jobsFiltered = jobsFiltered.filter(job=>job.skills.indexOf(skill) !== -1);
  }
  if (market !== ALL) {
    jobsFiltered = jobsFiltered.filter(job=>job.markets.indexOf(market) !== -1);
  }
  if (region !== ALL) {
    jobsFiltered = jobsFiltered.filter(job=>job.region === region);
  }
  return jobsFiltered;
}
