import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { useMemo } from 'react';
import './PublicJob.scss';
import TypeLocationState from '../../models/initialState/typeLocationState';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FieldText from '../Form/FieldText/FieldText';
import GooglePlay from '../../assest/GooglePlay.svg';
import AppStore from '../../assest/AppStore.svg';
import TypeState from '../../models/initialState/typeState';
import Flag from 'react-flagpack';
import MenuItem from '@mui/material/MenuItem';
import DownloadApp from './DownloadApp';


function ApplyJobFinish() {
  const { t } = useTranslation();
  const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
  const stateLocation: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);

  const codesOut = useMemo(() => {
    //Estos codigos dan error, no muestro las banderas.
    return ['AN', 'ZR', 'GB'];
  }, [])


  return <div>
      <div>
        <p className='swissinx-job-container-column__title'>{t('publicJob.applyJobFinishTitle')}</p>
        <p className='swissinx-job-container-column__subtitle'>{t('publicJob.applyJobFinishSubtitle')}</p>
        <p className='swissinx-job-container-column__description'>{t('publicJob.applyJobFinishDescription1')}</p>
        <p className='swissinx-job-container-column__description'>
          {t('publicJob.applyJobFinishDescription2')}
          {/* <span className='swissinx-job-finish-block__descriptionHl'>{t('publicJob.applyJobFinishDescriptionHighlighted')}</span> */}
        </p>

      </div>
      <div>
        <p className='swissinx-job-container-column__description'>{t('publicJob.applyJobFinishDescription3')}</p>
        <div className='swissinx-job-container-phone send-password'>
          <div className='swissinx-job-container-prefix'>
            <FormControl fullWidth>
              <Select
                labelId="prefix"
                id="prefix"
                value={state.lightSignup.prefix}
                disabled={true}
              >
                {stateLocation.countriesWithoutPushExceptions.data.map(country => <MenuItem key={country.id} value={country.phoneCode}>
                  {country.phoneCode} {codesOut.indexOf(country.code) === -1 && <Flag code={country.code} size="m" gradient="real-linear" />} {country.code}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className='swissinx-job-container-number'>
            <FieldText
              label=''
              placeholder=''
              name='phone'
              required={true}
              requiredError={t('publicJob.phoneErrorRequired')}
              value={state.lightSignup.phone}
              error={false}
              disabled={true}
              onChange={({ value, error }) => { }}
            />
          </div>
        </div>
      </div>
      <div>
       <DownloadApp />
      </div>

  </div>
}

export default ApplyJobFinish;