
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CompanyGroupsDto from '../../models/dto/CompanyGroupsDto'; 

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
//import './CompanyGroups.scss';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import TypeState from '../../models/initialState/typeState';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';

interface Props {
    edit(event: any, index:CompanyGroupsDto): void,
    remove(event: any, index:CompanyGroupsDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void
}

function CompanyGroupsList(props: Props) {
  const { t } = useTranslation();
  const state:TypeCompanyGroupsState = useSelector((state:TypeState) => state.companyGroupsReducer);
  const stateCompany:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);

  const filterCompanies = (companyId: number) => stateCompany.data.filter(company => company.companyGroupId === companyId)
  
const headers = [{title: t('admin.companyGroups.tableHeader1'), field: 'name', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''},
                  {title: t('admin.companyGroups.tableHeader2'), field: 'companyGroupId', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: '',
                  render: (rowData: CompanyGroupsDto) => {
                    const total = filterCompanies(rowData.companyGroupId).length;                    
                    return total
                  }}];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

const detailPanel = (rowData: CompanyGroupsDto) =><ul className='company-groups-company-list'>
    {filterCompanies(rowData.companyGroupId).map(company=><li>{company.name}</li>)}
  </ul>
console.log(stateCompany)
  return <div className='swissinx-container'>   
            <h2>{t('admin.companyGroups.listTitle')}</h2>
            <Table<CompanyGroupsDto> 
              data={state.data} 
              headers={headers} 
              actions={actions} 
              new={true}
              initialPage={state.initialPage}
              serchText={state.searchText}
              pageSize={state.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}
              detailPanel={detailPanel}/>
        </div>;
  }

export default CompanyGroupsList;