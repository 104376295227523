
import { TypeActions } from '../../models/actions/typeActions';
import { ActionAmbasadorsType } from '../../models/actions/typeAmbassadorActions';
import TypeAmbassadorsState from '../../models/initialState/typeAmbassadorsState';
import { initialAmbassadorsState } from '../inititalState/initialAmbassadorsState';

const ambassadorsReducer = (state:TypeAmbassadorsState = initialAmbassadorsState, action:TypeActions):TypeAmbassadorsState => {
    switch (action.type) {
        case ActionAmbasadorsType.AMBASADORS_SUCCESS: {            
            return {...state, loading: false, ambassadorsData: action.value, error: false}
        }

        case ActionAmbasadorsType.AMBASADORS_ERROR: {         
            return {...state, loading: false, error: false}
        }

        case ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_LOADING:
        case ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING: {
            const automaticList = {...state.automaticList}; 
            return {...state, automaticList: {...automaticList, loading: true, data: [], error: false}}
        }

        case ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_SUCCESS:
        case ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_SUCCESS: {
            const automaticList = {...state.automaticList}; 
            return {...state, automaticList: {...automaticList, loading: false, data: action.value, error: false}}
        }

        case ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_ERROR:
        case ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR: {         
            const automaticList = {...state.automaticList}; 
            return {...state, automaticList: {...automaticList, loading: false, data: [], error: true}}
        }

        case  ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES: {
            const name = action.value.name;            
            let pushFilter = {...state.pushFilter};
            if (name === 'languages') {
                pushFilter.languages = action.value.value;
            } else if (name === 'skills') {
                pushFilter.skills = action.value.value;
            } else if (name === 'markets') {
                pushFilter.markets = action.value.value;
            } else if (name === 'countries') {
                pushFilter.countries = action.value.value;
            }      
            return {...state, pushFilter: { ...pushFilter} }
        }

        case  ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES_SEARCH: {                      
            let pushFilter = {...state.pushFilter};            
            pushFilter.search = action.value.value;
            return {...state, pushFilter: { ...pushFilter} }
        }

        case ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED_ADD: {
            const tabla:boolean[] = [];
            const listAmbassadorsFiltered = state.listAmbassadorsFiltered.concat(action.value).filter((indice) => {
                return tabla[indice.networkerId] ? false : (tabla[indice.networkerId] = true);
            });            
            return {...state, listAmbassadorsFiltered: listAmbassadorsFiltered}
        }

        case ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED: {                                
            return {...state, listAmbassadorsFiltered: action.value}
        }

        case ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_RESET : {
            let pushFilter = {...state.pushFilter};
            pushFilter.languages = [];
            pushFilter.markets = [];
            pushFilter.skills = [];
            pushFilter.countries = [];
            pushFilter.search = '';
            const automaticList = {...state.automaticList};
            return {...state, automaticList: {...automaticList, data:[]}, pushFilter: { ...pushFilter} }
        }

        
        
    }
    return {...state};
}

export default ambassadorsReducer;
