import TypeSkillsState from '../../models/initialState/typeSkillsState';
import { DEFAULTITEMSPERPAGE, newFormField } from '../../utils/utils';

export const initialSkillsState: TypeSkillsState = {
      loading: false,
      error: false,
      messageError: '',
      data: [],
      skillsForm: {
            name: newFormField('',true, false),
            skillGroupId: newFormField('',true, false),
      },
      filters: {orderBy: 1, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false
}