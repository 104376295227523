import React, {useEffect, SyntheticEvent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import TypeMainState from '../../models/initialState/typeMainState';
import { useSelector, useDispatch } from 'react-redux';
import NetworkersList from './NetworkersList';
import NetworkerDto from '../../models/dto/NetworkerDto';
import { ActionNetworkersType, TypeNetworkersActions } from '../../models/actions/typeNetworkersActions';
import { TypeActions } from '../../models/actions/typeActions';
import Loading from '../commons/Loading';
import ComponentError from '../commons/ComponentError';
import NetworkersEdit from './NetworkersEdit';
import { networkerDtoToForm, networkersFormToDto } from '../../utils/utils';
import NetworkerCreateDto from '../../models/dto/NetworkerCreateDto';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
import RewardsList from './RewardsList';
import NetWorkersModalAnonymizer from './NetWorkersModalAnonymizer';
import './Networkers.scss';
import NetWorkersModalReport from './NetWorkersModalReport';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Networkers(props: Props) {
  const [editNetworker, setEditNetworker] = useState(false);
  const [addNetworker, setAddNetworker] = useState(false);
  const [networkerSelected, setNetworkerSelected] = useState<NetworkerDto | null>(null);
  const [showModalDeleteNetworker, setModalDeleteNetworker] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [showModalAnonymizer, setShowModalAnonymizer] = useState(false);
  const [networkerAnonymizer, setNetworkerAnonymizer] = useState<NetworkerDto | null>(null);
  const [showModalReport, setShowModalReport] = useState(false);
  
  const state:TypeNetworkersState = useSelector((state:TypeState) => state.networkersReducer);
  const stateMain:TypeMainState = useSelector((state:TypeState) => state.mainReducer);
  const stateCompany:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadNetworkers = () => {
    if (stateMain.companySelected !== null) {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: true}});      
    } else {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_SUCCESS, value: {data: [], reset: true}}); 
    }
  }

  useEffect(() => {    
    if (props.reload === true) {
      loadNetworkers();
      setEditNetworker(false);
      props.setReload(false);   
    }   
  }, [dispatch, props.reload]);

  useEffect(() => {    
    if (!props.reload) {
      loadNetworkers();        
    }   
  }, []);

  
  const registerDeleteAmbassador = (event: SyntheticEvent, index: NetworkerDto)=>{    
    if (index.ambassadorId === null) {
      const data = {
        networkerId: index.networkerId,
        companyId: index.companyId,
        credit: 0
      }
      dispatch<TypeNetworkersActions>({type: ActionNetworkersType.NETWORKER_REGISTER_AMBASSADOR, value: data});
    } else {
      dispatch<TypeNetworkersActions>({type: ActionNetworkersType.NETWORKER_DELETE_AMBASSADOR, value: index.ambassadorId});
    }    
  }
  
  const registerDeleteTA = (event: SyntheticEvent, index: NetworkerDto)=>{
    if (index.taId === null) {
      const data = {
        networkerId: index.networkerId,
        companyId: index.companyId,
        credit: 0
      }
      dispatch<TypeNetworkersActions>({type: ActionNetworkersType.NETWORKER_REGISTER_TA, value: data});
    } else {
      dispatch<TypeNetworkersActions>({type: ActionNetworkersType.NETWORKER_DELETE_TA, value: index.taId});
    }    
  };

  const edit = (event:SyntheticEvent, index:NetworkerDto) => {   
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_LOADING, value: {networkerId: index.networkerId, companyId: index.companyId}});
    setNetworkerSelected(index);
    const data = networkerDtoToForm(index);    
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_FORM_UPDATE, value: data});
    
    setEditNetworker(true);
    setAddNetworker(false);
  }

  const removeConfirmation = (event: SyntheticEvent, index: NetworkerDto)=>{    
    setNetworkerSelected(index);   
    setModalDeleteNetworker(true);
  };

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_FORM_NEW});
    setEditNetworker(true);
    setAddNetworker(true);
  }

  const update = () => {   
    const data:NetworkerCreateDto = networkersFormToDto(state.networkersForm, stateMain.companyIdSelected, networkerSelected ? networkerSelected.networkerId : undefined);
    if (addNetworker) {      
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_ADD_LOADING, value: data});
    } else {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_EDIT_LOADING, value: data});
    }
    setEditNetworker(false);
  }

  const deleteNetworker = () => {
    setModalDeleteNetworker(false);
    if (networkerSelected !== null) {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_DELETE_LOADING, value: networkerSelected.networkerId});
    }    
  }

  const back = () => setEditNetworker(false);

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  const rewards = (event:SyntheticEvent, index:NetworkerDto) => {
    setNetworkerSelected(index);
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_YEARS_LOADING, value: {companyId: index.companyId, networkerId: index.networkerId}});
    setShowReward(true);
  }

  const rewardsBack = () => {
    setShowReward(false);
  }

  const anonynizer = (event:SyntheticEvent, index:NetworkerDto) => {   
    setNetworkerAnonymizer(index);  
    setShowModalAnonymizer(true);    
  }

  const confirmAnonynizer = (index:NetworkerDto) => {
    setShowModalAnonymizer(false); 
    if (index && networkerAnonymizer?.networkerId) {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_ANONYMIZER_LOADING, value: networkerAnonymizer?.networkerId});
    }        
  }
  const report = (event:SyntheticEvent, index:NetworkerDto) => {
    setNetworkerSelected(index);
    if (stateCompany.data.length === 0) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}}); 
    }
    
    setShowModalReport(true);
  }

  const closeReport = () => {
    setShowModalReport(false);
  }

  const sendReport = (company: number, starDate: string, endDate: string) => {
    setShowModalReport(false);
    if (networkerSelected) {
      dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_LOADING, value: {
        CompanyId: company === 0 ? null : company,
        NetworkerId: networkerSelected.networkerId,
        DateFrom: starDate,
        DateTo: endDate
      }});
    } 
  }

  return <>     
      {state.loading && <Loading />}
      {!state.loading && !state.error && !editNetworker && !showReward &&
            <NetworkersList 
              registerDeleteAmbassador={registerDeleteAmbassador} 
              registerDeleteTA={registerDeleteTA} 
              edit={edit}
              remove={removeConfirmation}
              newRegister={newRegister} 
              changePage={changePage}
              changeSearchText={changeSearchText}
              changeRowsPerPage={changeRowsPerPage}
              rewards={rewards}
              onOrderChange={onOrderChange}
              anonymizer={anonynizer}
              report={report}
              />}
      {!state.loading && !state.error && editNetworker && <NetworkersEdit update={update} back={back} add={addNetworker}/>}        
      {showReward && !state.error && <RewardsList rewardsBack={rewardsBack} networker={networkerSelected} />}
      
      {state.error && <ComponentError
                              title={t('admin.networkers.error_title')} 
                              message={state.messageError} 
                              buttonMessage={t('admin.networkers.error_button')} 
                              onClick={()=>loadNetworkers()}/>}
      {showModalDeleteNetworker && <ModalConfirmDelete show={showModalDeleteNetworker} title={t('admin.networkers.modal_confirm_delete_title')} back={()=>setModalDeleteNetworker(false)} confirm={deleteNetworker} textButtonBack={t('admin.networkers.modal_confirm_delete_back')} textButtonConfirm={t('admin.networkers.modal_confirm_delete_confirm')} />}
      <Notification
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.networkers.editMessage')}
              addMessage={t('admin.networkers.addMessage')}
              delteMessage={t('admin.networkers.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_DELETE_SUCCESS, value: false})}/> 
      {showModalAnonymizer &&  <NetWorkersModalAnonymizer 
                                  title={t('admin.networkers.anonymizerTitle')} 
                                  description= {t('admin.networkers.anonymizerDescription')} 
                                  label={t('admin.networkers.anonymizerConfirmLabel')}
                                  textButtonConfirm={t('admin.networkers.anonymizerConfirm')}
                                  index={networkerAnonymizer} 
                                  show={showModalAnonymizer} 
                                  confirm={confirmAnonynizer}/>}
      {showModalReport &&  <NetWorkersModalReport                                 
                                  index={networkerAnonymizer} 
                                  show={showModalReport}
                                  companies={stateCompany.data}
                                  closeReport={closeReport}
                                  sendReport={sendReport}/>}                        
                                  
  </>
  
 
}

export default Networkers;