
import React, {useState, useEffect, SyntheticEvent, ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../../models/actions/typeActions';
import { useSelector, useDispatch } from 'react-redux';
import {RegionDto} from '../../../models/dto/LocationDto'; 
import RegionsList from './RegionsList';
import ModalConfirmDelete from '../../commons/ModalConfirmDelete';
import ComponentError from '../../commons/ComponentError';
import Loading from '../../commons/Loading';
import TypeState from '../../../models/initialState/typeState';
import Notification from '../../commons/Notification';
import RegionsEdit from './RegionsEdit';
//import './Regions.scss';
import { ActionLocationType } from '../../../models/actions/typeLocationActions';
import TypeLocationState from '../../../models/initialState/typeLocationState';
import { COUNTRY_DEFAULT } from '../../../constanst/GlobalConstants';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Regions(props: Props) {
  const [editRegion, setEditRegion] = useState(false);
  const [showModaldeleteRegion, setShowModalDeleteRegion] = useState(false);
  const [addRegion, setAddRegion] = useState(false);  
  const [regionSelected, setRegionSelected] = useState<RegionDto>({id: 0, description: ''});
  
  const { t } = useTranslation();
  const state:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const dispatch = useDispatch();
  const [countrySelected, setCountrySelected] = useState(COUNTRY_DEFAULT);
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}}); 
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: countrySelected});      
      setEditRegion(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}}); 
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: countrySelected});      
    }

  }, []);  

  const edit = (event:SyntheticEvent, index:RegionDto) => {    
    setAddRegion(false)
    setRegionSelected(index);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_FORM_UPDATE, value: index}); 
    setEditRegion(true); 
  }

  const removeConfirmation = (event:SyntheticEvent, region:RegionDto) => {
    setRegionSelected(region);
    setShowModalDeleteRegion(true);
  }

  const remove = () => {
    setShowModalDeleteRegion(false);
    if (regionSelected !== null && regionSelected.id !== undefined) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_DELETE_LOADING, value: {idRegion : regionSelected.id, idCountry: countrySelected}});
    }  
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_FORM_NEW});
    setEditRegion(true);
    setAddRegion(true);
  }  

  const back = () => {
    setEditRegion(false);
  }

  const update = () => {
    setEditRegion(false);    
    if (addRegion) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ADD_LOADING, value: {
        name: state.regions.regionsForm.name.value as string, countryId: countrySelected }
      });      
    } else {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_EDIT_LOADING, value: {
        id: regionSelected.id, name: state.regions.regionsForm.name.value as string, countryId: countrySelected }
      });
    } 
  }  

  const changeCountry = (event:ChangeEvent<HTMLInputElement>) => {
    const country = parseInt(event.target.value);
    setCountrySelected(country);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: country});   
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.regions.loading && !state.regions.error && <Loading />}
            {!state.regions.loading && !state.regions.error && !editRegion && <RegionsList 
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        country={countrySelected}
                                                                        changeCountry={changeCountry}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.regions.loading && !state.regions.error && editRegion && <RegionsEdit update={update} back={back} add={addRegion}/>} 
           
            {showModaldeleteRegion && <ModalConfirmDelete show={showModaldeleteRegion} title={t('admin.regions.modal_delete_title')} back={()=>setShowModalDeleteRegion(false)} confirm={remove} textButtonBack={t('admin.regions.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.regions.modal_delete_buttonDelete')} />}
            
            {state.regions.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: countrySelected})}/>}
            
            <Notification 
              edit={state.regions.edit}
              add={state.regions.add}
              delete={state.regions.delete}
              editMessage={t('admin.regions.editMessage')}
              addMessage={t('admin.regions.addMessage')}
              delteMessage={t('admin.regions.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Regions;
