import { TypeActions } from '../../models/actions/typeActions';
import { newFormCompany } from '../../utils/utils';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import CompanyForm from '../../models/forms/CompanyForm';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
import { initialCompaniesState } from '../inititalState/initialCompaniesStateState';

const companiesReducer = (state:TypeCompaniesState = initialCompaniesState, action:TypeActions):TypeCompaniesState => {
    switch (action.type) {

        case  ActionCompanyType.COMPANY_FORM_SEND_VALUES: {
            const name = action.value.name;            
            let companyForm:CompanyForm = {...state.companyForm};
            companyForm[name as keyof CompanyForm] = {...companyForm[name as keyof CompanyForm], value: action.value.value, error: action.value.error};         
            return {...state, companyForm: { ...companyForm} }
        }

        case ActionCompanyType.COMPANY_ERROR_REQUIRED : {
            const name = action.value;            
            let companyForm:CompanyForm = {...state.companyForm};
            companyForm[name as keyof CompanyForm] =  {...companyForm[name as keyof CompanyForm], error: {required: true, format: companyForm[name as keyof CompanyForm].error.format}};
            return {...state, companyForm: { ...companyForm}  }      
        }

        case ActionCompanyType.COMPANY_FORM_NEW: {
            let companyForm:CompanyForm = newFormCompany();           
            return {...state, companyForm: { ...companyForm} }
        }

        case ActionCompanyType.COMPANY_FORM_UPDATE: {
            let companyForm:CompanyForm = newFormCompany();           
            return {...state, companyForm: { ...companyForm, ...action.value} }
        }

        case ActionCompanyType.COMPANY_EDIT_LOADING:
        case ActionCompanyType.COMPANY_LOADING: {           
            return {...state, loading: true, error: false}
        }

        case ActionCompanyType.COMPANY_SUCCESS: {           
            return {...state, loading: false, data: action.value.data, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionCompanyType.COMPANY_ERROR: {           
            return {...state, loading: false, error: true, data: []}
        }

        case ActionCompanyType.COMPANY_ADD_LOADING: {           
            return {...state, loading: false, error: false, add: false}
        }

        case ActionCompanyType.COMPANY_ADD_SUCCESS: {           
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionCompanyType.COMPANY_EDIT_SUCCESS: {           
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionCompanyType.COMPANY_DELETE_LOADING: {           
            return {...state, loading: false, error: false, delete: false}
        }

        case ActionCompanyType.COMPANY_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }
        
        case ActionCompanyType.COMPANY_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionCompanyType.COMPANY_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionCompanyType.COMPANY_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionCompanyType.COMPANY_UPLOAD_PICTURE_LOADING: {
            const img = state.image;        
            return {...state, image: {...img, loading: true, error: false, ok: false}}
        }

        case ActionCompanyType.COMPANY_UPLOAD_PICTURE_SUCCESS: {           
            const img = state.image;        
            return {...state, image: {...img, loading: false, error: false, ok: true}}
        }

        case ActionCompanyType.COMPANY_UPLOAD_PICTURE_ERROR: {           
            const img = state.image;        
            return {...state, image: {...img, loading: false, error: true, ok: false}}
        }

        case ActionCompanyType.COMPANY_SEND_IMAGE_VALUES: {
            const img = state.image;        
            return {...state, image: {...img, data: action.value}}
        }

        case ActionCompanyType.COMPANY_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
    }
    return {...state};
}
export default companiesReducer;