export const eng = {
    label: 'Name',
    text_logout_button: 'Logout',
    page_not_found: 'Page not found',
    publicJob: {
        swisslinx: 'Swisslinx',
        posted: 'Posted on',
        shares: 'Shared',
        chf: 'CHF',
        total: 'Total Network Reward',
        details: 'Details',
        Location: 'Location',
        seniority: 'Seniority',
        skills: 'Skills',
        description: 'Description',
        referBy: 'Share by',
        whatsapp: 'Whatsapp',
        email: 'Email',
        sms: 'SMS',
        clipboard: 'Clipboard',
        buttonPrimary: 'APPLY',
        buttonSecondary: 'Dismiss',
        wellcome: 'Let´s get you onboard',
        descriptionGetSMSCodeApply: 'All we need is your mobile number and we will send you an SMS registration code.',
        descriptionGetSMSCodeShare: 'To get you onboard our referral scheme, we only need your mobile number. This will identify you on the platform, and allow us to contact you should your referrals be successful.',
        conditions: 'I consent that the Swisslinx Group of companies stores my personal information on their internal or external servers, uses my information and supplies it to third parties to inform me of potential job opportunities. My personal information may be transferred upon request to a country outside of the EU/EFTA. I also agree to the',
        terms: 'Swapp Privacy Policy',
        termsUrl: '/private-policy-swapp',
        conditions2: ' and the ',
        terms2: 'Terms of Use.',
        termsUrl2: '/terms-of-use',
        getMySMSCode: 'Get my SMS code',
        loadingSMSCode: 'Sending SMS Code...',
        getMyPassword: 'Get my Password',
        checkMySMSCode: 'Continue',
        chekingSMSCode: 'Checking SMS code...',
        sendingMyPassword: 'Sending password by SMS...',
        termsError: 'you must accept the conditions',
        phoneErrorFormat: 'Wrong telephone number format',
        phoneErrorRequired: 'Number phone is required',
        descriptionCheckSMSCode3: 'Please, enter the SMS code we just sent you !',
        descriptionCheckSMSCode1: 'Your sign up code has been sent by SMS',
        notRecived: 'Not received?',
        resendCode: 'Click here to resend the code',
        referralDetails: 'Here is your link to share',
        generatingLinkLoading: 'Generating Link...',
        generatingLinkError: 'An error occurred while generating the link',
        defaultMessage: 'I have found this job offer that might interest you...',
        messageCopied: 'Message Copied',
        SMSCodeError: 'The code entered is not correct',
        downloadApp: 'Now you can download the app and use this telephone number and the code we just sent you to log in',
        downloadApp2Old: 'Get the app, access more jobs, grow your network, get more rewards.',
        downloadApp21: 'Want to give Swapp a go?',
        downloadApp22: 'Download our app now to start refering and reaping the rewards',
        activationAccountErrorServer: 'An error occurred',
        resendActivationCodeError: 'Error to resend activation code',
        resendActivationCodeLoading: 'Trying to send a new code',
        resendActivationCodeOk: 'New activation code sent',
        serverError: 'An error occurred',
        checkCodeErrorRequired: 'You must enter the code sent by SMS',
        rejectJob: 'You have declined the offer',
        errorLoadJob: 'An error occurred while retrieving the offer data',
        altImgAppStore: 'Image App store',
        altImgGooglePlay: 'Image google play',
        referalName: 'Save this share as ...',
        urlAppStore: 'https://apps.apple.com/ch/app/swapp-pro/id1610088530',
        urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.swisslinx.swapp',
        //urlAppStore: '#',
        //urlGooglePlay: '#',
        descriptionJobRefer: 'Thank you for sharing the job offer',
        forwardUpdateError: 'An error occurred',
        referralNameErrorRequired: 'Referral name is required',
        createReferral: 'SHARE',
        descriptionJobApply: 'To apply for this offer, you must download the app to continue the process',
        linkWarning: 'We have prepared a short message and a link for you to share with your friends. Please, be mindful to not share the link on portals or social media, as only the first ',
        linkWarning2: ' follow-ups will be linked to your ID.',
        sendPasswordTitle: 'Download our app',
        sendPasswordDescription: 'Use this telephone number as my user name for Swapp',
        prefixError: 'Prefix is required',
        backButton: 'Back',
        nextButton: 'Next',
        finishButton: 'Finish',
        filePlaceHolder: 'Add file',
        addAttachments: 'Add attachments',
        addPofile: "Your web profiles",
        addPofilePlaceHolder: "Paste Url…",
        buttonApply: "Apply",
        buttonCancel: "Cancel",
        applyJobFinishTitle: "Your application has been submitted",
        applyJobFinishSubtitle: "Thank you for applying for this position",
        applyJobFinishDescription1: "Your application will be reviewed by one of our specialists and you will be contacted at the earliest opportunity.",
        applyJobFinishDescription2: "You are now registered in SWAPP PRO. Download our app and check how you can make money out of our referral scheme. ",
        applyJobFinishDescriptionHighlighted: "Find out what makes us different here.",
        applyJobFinishDescription3: "You can use this telephone number to log in into the app.",
        applyJobFinishSubtitleFooter: "Get the app, access more jobs, grow your network, get more rewards.",
        reward: 'Reward',
        rewardDescription: 'Total reward for the referral chain',
        subtitleGetSMSCode: "Application",
        boarding: 'Boarding',
        boardingComplete: 'Boarding complete !',
        shareJobFinishTitle: 'Thank you for sharing.',
        shareJobFinishDescription1: 'You are registered now at Swapp with this telephone number',
        shareJobFinishDescription2: 'You can manage your account, track your referrals and see well they are doing from our mobile app.',
        shareJobFinishSubtitle1: 'Your Swapp account',
        shareJobFinishSubtitle2: 'Download Swapp Pro',
        fileNotAllowed: 'File not allowed',
        fileNotAllowedTooGig: 'File not allowed Max: ',
        shareAgain: 'Share again',
        attachments_error_required: 'It is mandatory to upload a file or a web profile',
        formatProfile: 'Wrong web profile format (Ej: https://www.domain.com)',
        profileActionText: 'Click to Add Weblink',
        modalInfo: 'We collect statistics for this link so if you give it a recognizable name, it is easier to find it un the Stats page. Only you can see this',
        questionsYes: 'Yes',
        questionsNo: 'No',
        shareTitle: 'JOB NOT FOR YOU?',
        shareSubTitle: 'SHARE IT WITH YOUR NETWORK AND YOU CAN <strong>EARN UP TO </strong>',
        shareDescription: 'You don’t have to think about the perfect person for the perfect role. Just share it with your network. If someone from your chain gets hired, <strong>you get a fair share of the reward!</strong>',
        buttonMore: 'FIND OUT MORE',
        downloadAppTitle: 'Want to give Swapp a go?',
        downloadAppDescription: 'Download our app now to start referring and reaping the rewards!',
        headerTitle: 'IT PAYS TO NETWORK'
    },
    page_login: {
        title: 'IT PAYS TO NETWORK',
        subTitle: 'LOG IN TO SWAPP',
        label_login: 'Email or Phone number',
        label_password: 'Password',
        text_button: 'LOGIN',
        login_error_required: 'Email or Phone number is required',
        login_error_format: 'Login error format',
        password_error_required: 'Password is required',
        password_error_format: 'The password must have between 6 and 15 characters',
        errorAccess: 'Incorrect user or password',
        errorServer: 'an error has occurred try again later',
        errorPermissions: 'This user can not access',
        loading: 'Loading...',
        recoverPassword: 'Forgotten password?',
        sendPasswordSuccessfully: 'password sent to device successfully',
        recoverPasswordErrorUser: 'you must enter the Email or Phone number',
        noAccount: `Don't have an account? `,
        noAccountLink: 'Sign up here'
    },
    table: {
        tooltip: {
            ambassador_create: 'Create Insider',
            ambassador_delete: 'Delete Insider',
            TA_create: 'Create TA',
            TA_delete: 'Delete TA',
            actionEdit: 'Edit',
            actionDelete: 'Delete',
            actionPush: 'Push',
            actionHistoryPush: 'History push',
            actionHistoryAmbassadors: 'Insiders',
            actionAnonimizer: 'Anonymizer',
            candidate: 'Candidates',
            actionLike: 'Like',
            actionReject: 'Reject',
            actionCandidateSelected: 'Selected',
            actionCandidateNoSelected: 'No Selected',
            actionCandidateFile: 'View candidate',
            actionCountriesExcluded: 'Countries to exclude',
            actionStatusPushActive: 'Enable Push',
            actionStatusPushDesactive: 'Disable Push',
            kpi: 'KPIS',
            actionRewards: 'Rewards',
            dashboardJobActivity: 'Job Activity',
            actionReport: 'Performance Report'
        }
    },
    admin: {
        error: {
            errorServerTextButton: 'Back',
            errorServerTitle: 'Error',
            errorServerDescription: 'An error has occurred',
        },
        filter: 'Filter',
        addNew: 'Add new',
        menu: {
            dashboard: 'Dashboard',
            company_groups: 'Company Groups',
            company: 'Company',
            jobs: 'Jobs',
            networkers: 'Networkers',
            clients: 'Clients',
            templates: 'Questionary Templates',
            networkersRequestList: 'Networkers Request List',
            skillGroup: 'Skill Groups',
            skills: 'Skills',
            markets: 'Markets',
            languages: 'Languages',
            location: 'Location',
            fees: 'Fee Templates',
            payments: 'Payments'
        },
        companyGroups: {
            tableHeader1: 'Name',
            tableHeader2: 'Companies',
            listTitle: 'Company Groups',
            labelName: 'Name',
            nameErrorRequired: 'Name is required',
            editButtonText: 'Save',
            addButtonText: 'Create',
            cancelButtonText: 'Cancel',
            title_page_edit: 'Edit Company Group',
            title_page_new: 'New Company Group',
            modal_delete_title: 'Are you sure?',
            modal_delete_buttonDelete: 'Delete',
            modal_delete_title_buttonCancel: 'Cancel',
            errorServerTextButton: 'Back to List',
            errorServerTitle: 'Error',
            errorServerDescription: 'An error has occurred',
            editMessage: 'Company Group Edited!',
            addMessage: 'Company Group Created!',
            deleteMessage: 'Company group Deleted!'
        },
        company: {
            title: 'Company',
            listTitle: 'Companies List',
            tableHeader1: 'Name',
            tableHeader2: 'Notifications',
            tableHeader3: 'Credit',
            tableHeader4: 'Company Group',
            label_image: 'Company Logo',
            label_name: 'Name',
            label_notifications: 'Notifications',
            label_credit: 'Credit',
            label_country: 'Country',
            label_companyGroup: 'Company Group',
            label_signature: 'Signature',
            label_address: 'Address',
            label_postalCode: 'Postal Code',
            modal_confirm_delete_title: 'Are you sure?',
            modal_confirm_delete_back: 'Back',
            modal_confirm_delete_confirm: 'Confirm',
            error_title: 'Upppps... An error has occurred',
            error_button: 'Back to companies list',
            credit_error_format: 'Credit error format',
            editTitle: 'Edit company',
            createTitle: 'Create company',
            text_button_back: 'Back',
            text_button_submit_create: 'Create a company',
            text_button_submit_edit: 'Save',
            editMessage: 'Company Edited!',
            addMessage: 'Company Created!',
            deleteMessage: 'Company Deleted!',
            errorServerTextButton: 'Back',
            errorServerTitle: 'Error',
            errorServerDescription: 'An error has occurred',
            noImage: 'Press to upload a image',
            uploading: 'Uploading...',
            errorUploadImage: 'Error to upload image',
            errorFormatSize: 'The file must be an image and must be less than 5 MB',
            name_error_required: 'Name is required',
            credit_error_required: 'Credit is required'
        },
        clients: {
            listTitle: 'Clients list',
            error_title: 'Upppps... An error has occurred',
            tableHeader1: 'Name',
            tableHeader2: 'Company Group',
            label_name: 'Name',
            name_error_required: 'Name is required',
            error_button: 'Back to clients list',
            editTitle: 'Edit client',
            text_button_back: 'Back',
            text_button_submit_create: 'Create client',
            text_button_submit_edit: 'Save',
            label_companyGroup: 'Company group',
            modal_confirm_delete_title: 'Are you sure?',
            modal_confirm_delete_back: 'Back',
            modal_confirm_delete_confirm: 'Confirm',
            createTitle: 'Create client',
            editMessage: 'Client Edited!',
            addMessage: 'Client Created!',
            deleteMessage: 'Client Deleted!'
        },
        jobs: {
            label_mainInformation: 'Main information',
            label_title: 'Title',
            label_status: 'Status',
            label_description: 'Description',
            label_seniority: 'Seniority',
            label_salaryMin: 'Salary min',
            label_salaryMax: 'Salary max',
            label_salaryCurrency: 'Salary currency',
            label_salaryCompetitive: 'Salary competitive',
            label_salaryDisclose: 'Show Salary',
            title_error_required: 'Title is required',
            label_clientDisclose: 'Show Client',
            label_language: 'Language',
            label_languages: 'Languages',
            language_error_required: 'Select a Language',
            label_skills: 'Skills',
            skills_error_required: 'Select a Skill',
            label_markets: 'Markets',
            markets_error_required: 'Select a Market',
            label_country: 'Country',
            label_countries: 'Countries',
            label_region: 'Region',
            label_city: 'City',
            label_client: 'Client',
            label_ambassador: 'Insider',
            text_button_submit_create: 'Create a Job',
            text_button_submit_edit: 'Save Job',
            text_button_back: 'Back',
            listTitle: 'Jobs List',
            createTitle: 'Create a Job',
            editTitle: 'Edit a Job',
            salaryMin_error_required: 'Salary min is required',
            salaryMin_error_format: 'Salary min error format',
            status_error_required: 'Select a status',
            country_error_required: 'Select a Country',
            region_error_required: 'Select a Region',
            city_error_required: 'Select a City',
            description_error_required: 'Description is required',
            seniority_error_required: 'Seniority is required',
            salaryCurrency_error_required: 'Salary Currency is required',
            salaryMax_error_required: 'Salary Max is required',
            currency_error_required: 'Currency is required',
            salaryMax_error_format: 'Salary max error format',
            salaryMax_error_value: 'Salary max must be greater than Salary min',
            client_error_required: 'Select a Client',
            status_default: 'Select a Status',
            modal_confirm_delete_title: 'Are you sure?',
            modal_confirm_delete_back: 'Back',
            modal_confirm_delete_confirm: 'Confirm',
            pushTitle: 'Send Push',
            label_veryHighFee: 'Very High Fee',
            label_feeCurrency: 'Fee Currency',
            feeCurrency_error_required: 'Fee Currency is required',
            label_highFee: 'High Fee',
            label_lowFee: 'Low Fee',
            label_createdDate: 'Created Date',
            label_endDateTime: 'End Date Time',
            label_timeToEmbassadors: 'Available to Insiders',
            label_timeToNetwork: 'Available to Network',
            label_autoreply: 'Autoreply',
            text_button_submit_send_push: 'Send Push',
            error_format: 'Format error',
            modal_send_Push_title: 'Push sent',
            modal_confirm_info: 'Ok',
            error_title: 'Upppps... An error has occurred',
            error_button: 'Back to jobs list',
            veryHighFee_error_required: 'Very High Fee is required',
            veryHighFee_error_value: 'Very High Fee must be greater than High Fee',
            highFee_error_required: 'High Fee is required',
            highFee_error_value: 'High Fee must be less than Very High Fee and it must be greather than Low Fee',
            lowFee_error_required: 'Low Fee is required',
            lowFee_error_value: 'Low Fee must be less than High Fee',
            timeToEmbassadors_error_required: 'Time To Insiders is required',
            timeToNetwork_error_required: 'Time To Network is required',
            timeToEmbassadors_error_value: 'Available to insider must be less than Available to Network',
            timeToNetwork_error_value: 'Available to Network must be greater than Available to insider',
            referalName: 'Save this share as ...',
            listHistoryPushTitle: 'History push list',
            label_questions: 'Questions',
            label_template: 'Template',
            templateNone: 'None',
            templateCustom: 'Custom',
            questionCategory: 'Category',
            questionType: 'Type',
            questionText: 'Text',
            questionOrder: 'Order',
            modal_status_job_title: 'Status changed successfully',
            modal_edit_title: 'Job edited successfully',
            optionDefault: 'Select an Option',
            editMessage: 'Job Saved!',
            addMessage: 'Job Created!',
            deleteMessage: 'Job Deleted!',
            tooltip_Applied: 'Applied',
            tooltip_Pinned: 'Pinned',
            tooltip_Shared: 'Shared',
            tooltip_Rejected: 'Rejected',
            tooltip_Seen: 'Seen',
            label_mode: 'Mode',
            mode_push_manual: 'Manual',
            mode_push_auto: 'Auto',
            listAutomaticAmbassadors: 'Automatic insiders list',
            label_name: 'Name',
            label_surname: 'Surname',
            listAutomaticAmbassadorsLoading: 'loading automatic insiders list...',
            listAutomaticAmbassadorsEmpty: 'There are no insiders that meet the criteria. Try manual submission',
            listAutomaticAmbassadorsError: 'Error loading automatic insiders list.Try again',
            mode_error_required: 'Mode is required',
            label_countryExceptionIds: 'Countries to exclude',
            upload: 'Upload',
            download: 'Export to XML',
            pushListShares: 'Shares',
            pushListReshares: 'Reshares',
            pushListApplies: 'Applies',
            pushListViews: 'Views',
            pushListNetworkers: 'Networkers',
            pushListRejects: 'Rejects',
            loading: 'Loading ...',
            uploading: 'Uploading ...',
            error: 'Error',
            text_button_filter: 'Filter',
            noAmbassadorsMessage: 'There are no insiders for this push',
            listAmbassadors: 'Insiders List',
            label_match: 'Match Percentage',
            text_button_reset: 'Reset',
            label_search: 'Search',
            modal_push_no_ambassadors: 'You have not selected any insiders for this push.',
            pushesTitleTree: 'PUSHES',
            pikedUpsTitleTree: 'PICKED UPS',
            jobActivityTitle: 'Job Activity',
            jobActivityMap: 'Map',
            ambassadorFilter: 'Insiders Filter',
            label_forwardTimeUsedLimit: 'Maximum number of forwards',
            listAmbassadorsToSendPush: 'Insiders List  to send push',
            listAmbassadorsFiltered: 'Insiders List filtered',
            label_feeListTemplate: 'Select a Fee template',
            noTemplatesAvailable: 'No templates Available',
            showNetworkers: 'Show networkers',
            networkerTooltip: 'Networker',
            sendToPush: 'Send to push list',
            importXMLTitle: 'Import XML Jobs',
            label_reason: 'Reason',
            numVancanciesAdded: 'Num vancancies added',           
            numVancanciesInXML: 'Num vancancies in XML',
            numVancanciesUpdated: 'Num vancancies updated',
            marketsNotFound: 'Markets not found',
            skillsNotFound: 'Skills not found',
            vacanciesNotImported: 'Vacancies not imported',
            allVacanciesImported: 'All vacancies have been imported',
            location: 'Location',
            owner: 'Owner',
            label_id: 'Id',
            label_pushed: 'Job pushed/Job not pushed'
        },
        networkers: {
            listTitle: 'Networkers List',
            label_name: 'Name',
            label_surname: 'Surname',
            label_ambassador: 'Insider',
            label_company: 'Company',
            label_phone: 'Phone',
            label_email: 'Email',
            label_password: 'Password',
            label_repeatPassword: 'Repeat Password',
            label_TA: 'TA',
            label_yes: 'Yes',
            label_no: 'No',
            label_address: 'Address',
            label_postalCode: 'Postal Code',
            label_birthDay: 'Birthday',
            error_title: 'Upppps... An error has occurred',
            error_button: 'Back to networkers list',
            createTitle: 'Create Networker',
            editTitle: 'Edit Networker',
            text_button_back: 'Back',
            text_button_submit_create: 'Create Networker',
            text_button_submit_edit: 'Save',
            modal_confirm_delete_title: 'Are you sure?',
            modal_confirm_delete_back: 'Back',
            modal_confirm_delete_confirm: 'Confirm',
            name_error_required: 'Name is required',
            surname_error_required: 'Surname is required',
            phone_error_required: 'Phone is required',
            email_error_required: 'Email is required',
            password_error_required: 'Password is required',
            repeatPassword_error_required: 'You must repeat the password',
            passwordError: 'Passwords do not match',
            phone_error_format: 'Phone error format',
            email_error_format: 'Email error format',
            editMessage: 'Networker Edited!',
            addMessage: 'Networker Created!',
            deleteMessage: 'Networker Deleted!',
            label_reward: 'Reward',
            label_fee: 'Fee',
            label_currency: 'Currency',
            label_job: 'Job',
            label_dateApplication: 'Date Application',
            label_dateSelectedForJob: 'Date Selected For Job',
            rewardsLabelYear: 'Year',
            rewardsListTitle: 'Rewards List',
            downloadPdf: 'Download PDF',
            none: 'None',
            downloading: 'Downloading',
            anonymizerTitle: 'Anonymizer Networker',
            anonymizerDescription: 'Attention! . The data will be anonymised. This process is not reversible.',
            anonymizerConfirmLabel: 'Confirm number phone',
            anonymizerConfirm: 'Confirm',
            report_title: 'Performance Report',
            report_description: '',
            report_button: 'Download Report',
            dateTo: 'Date to',
            dateFrom: 'Date from'
        },
        candidates: {
            listTitle: 'Candidates list',
            label_name: 'Name',
            label_surname: 'Surname',
            label_email: 'Email',
            label_phone: 'Phone',
            label_date: 'Date',
            label_status: 'Status',
            label_level: 'Level',
            label_reward: 'Reward',
            titleData: 'Candidate data',
            errorDownloadFile: 'Error to download file',
            sectionData: 'DATA',
            sectionQuestions: 'QUESTIONS',
            sectionWebProfiles: 'WEB PROFILES',
            sectionAttachedFiles: 'ATTACHED FILES',
            noFile: 'No File',
            pathway: 'Pathway',
            modal_confirm_deselected_title: 'Are you sure you want to deselect this candidate?',
            modal_confirm_selected_title: 'Are you sure you want to select this candidate?',
            modal_confirm_delete_back: 'NO',
            modal_confirm_delete_confirm: 'YES'
        },
        templates: {
            listTitle: 'Templates list',
            createTitle: 'Create Template',
            editTitle: 'Edit Template',
            mainInformation: 'Main information',
            questions: 'Questions',
            label_name: 'Name',
            name_error_required: 'Name is required',
            error_questionRequired: 'You must enter at least one question',
            text_button_back: 'Back',
            text_button_submit_create: 'Create',
            text_button_submit_edit: 'Save',
            error_title: 'Upppps... An error has occurred',
            error_button: 'Back to templates list',
            modal_confirm_delete_title: 'Are you sure?',
            modal_confirm_delete_back: 'Back',
            modal_confirm_delete_confirm: 'Confirm',
            label_no: 'No',
            surname_error_required: 'Surname is required',
            phone_error_required: 'Phone is required',
            email_error_required: 'Email is required',
            password_error_required: 'Password is required',
            repeatPassword_error_required: 'You must repeat the password',
            passwordError: 'Passwords do not match',
            phone_error_format: 'Phone error format',
            email_error_format: 'Email error format',
            editMessage: 'Template Edited!',
            addMessage: 'Template Created!',
            deleteMessage: 'Template Deleted!'
        },
        networkersRequestList: {
            label_name: 'Name',
            label_surname: 'Surname',
            label_company: 'Company',
            label_date: 'Date',
            label_credit: 'Credit',
            label_status: 'Status',
            listTitle: 'Networkers Request List',
            error_button: 'Back',
            error_title: ''
        },
        skillGroup: {
            listTitle: 'Skill Groups',           
            title_page_new: 'New Skill Group',
            title_page_edit: 'Edit Skill Group',            
            editMessage: 'Skill Group Edited!',
            addMessage: 'Skill Group Created!',
            deleteMessage: 'Skill Group Deleted!',
        },
        skills: {
            name: 'Name',
            listTitle: 'Skills',
            title_page_new: 'New Skill',
            title_page_edit: 'Edit Skill',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'Skill Edited!',
            addMessage: 'Skill Created!',
            deleteMessage: 'Skill Deleted!',
            nameErrorRequired: 'Name is required',
            errorServerTitle: 'Error',
            errorServerDescription: 'An error has occurred',
            errorServerTextButton: 'Back',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add',
            group: 'Skill Group'
        },
        markets: {
            name: 'Name',
            listTitle: 'Markets',
            title_page_new: 'New Market',
            title_page_edit: 'Edit Markets',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'Market Edited!',
            addMessage: 'Market Created!',
            deleteMessage: 'Market Deleted!',
            nameErrorRequired: 'Name is required',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add'
        },
        language: {
            name: 'Name',
            listTitle: 'Languages',
            title_page_new: 'New Language',
            title_page_edit: 'Edit Language',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'Language Edited!',
            addMessage: 'Language Created!',
            deleteMessage: 'Language Deleted!',
            nameErrorRequired: 'Name is required',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add'
        },
        countries: {
            description: 'Country',
            code: 'Code',
            language: 'Language',
            listTitle: 'Countries',
            phoneCode: 'Phone Code',
            title_page_new: 'New Country',
            title_page_edit: 'Edit Country',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'Country Edited!',
            addMessage: 'Country Created!',
            deleteMessage: 'Country Deleted!',
            nameErrorRequired: 'Name is required',
            languageErrorRequired: 'Language is required',
            phoneCodeErrorRequired: 'Phone Cose is required',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add'
        },
        regions: {
            description: 'Region',
            listTitle: 'Regions',
            phoneCode: 'Phone Code',
            title_page_new: 'New Region',
            title_page_edit: 'Edit Region',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'Region Edited!',
            addMessage: 'Region Created!',
            deleteMessage: 'Region Deleted!',
            nameErrorRequired: 'Region is required',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add',
            label_country: 'Country'
        },
        cities: {
            description: 'City',
            listTitle: 'Cities',
            phoneCode: 'Phone Code',
            title_page_new: 'New City',
            title_page_edit: 'Edit City',
            modal_delete_title: 'Are you sure?',
            modal_delete_title_buttonCancel: 'Back',
            modal_delete_buttonDelete: 'Confirm',
            editMessage: 'City Edited!',
            addMessage: 'City Created!',
            deleteMessage: 'City Deleted!',
            nameErrorRequired: 'City is required',
            cancelButtonText: 'Cancel',
            editButtonText: 'Save',
            addButtonText: 'Add',
            label_country: 'Country',
            label_region: 'Region'
        },
        dashboard: {
            title: 'Dashboard',
            filter: 'Filters',
            kpi: 'KPIs',
            performanceIndicator: 'Performance indicator',
            titleUserDashboard: 'User Dashboard',
            titleNetworkDashboard: 'Network Dashboard',
            receivedVacancies: 'Received Vacancies',
            forwards: 'Forwards',
            timeToForward: 'Time to Forward',
            secondaryForwards: 'Secondary Forwards',
            rejections: 'Rejections',
            clicks: 'Clicks',
            applicationsProduced: 'Applications Produced',
            applicationsWithAnswers: 'Applications With Answers',
            likedApplications: 'liked Applications',
            dislikedApplications: 'Disliked Applications',
            placements: 'Placements',
            pickedUpJobs: 'Picked Up Jobs',
            maxApplicationsPerJob: 'Max Applications Per Job',
            directApplications: 'Direct Applications',
            timesTheUserStartsTheApp: 'Times The User Starts The App',
            reactionTime: 'Reaction Time',
            preferability: 'Preferability',
            engagement: 'Engagement',
            networkAccuracy: 'Network Accuracy',
            networkQuality: 'Network Quality',
            networkResponseRate: 'Network Response Rate',
            pushAccuracy: 'Push Accuracy',
            rejectionRate: 'Rejection Rate',
            proactivityRate: 'Proactivity Rate',
            fillRate: 'Fill Rate',
            pushedVacancies: 'Pushed Vacancies',
            loading: 'Loading...',
            error: 'Error',
            titleUsers: 'Users',
            titleJobs: 'Jobs',
            selected: 'Selected',
            usersList: 'Users List',
            jobsList: 'Jobs List',
            dateStart: 'Start',
            dateEnd: 'End',
            dashboardUserDescription: 'KPIs by markets and skills',
            dashboardNetworkDescription: 'KPIs by markets, skills and regions',
            network: 'Network'
        },
        fee: {
            listTitle: 'Fee Template',
            title_page_new: 'New Fee Template',
            title_page_edit: 'Edit Fee Template',
            name: 'Name',
            language: 'Language',
            skill: 'Skills',
            market: 'Markets',
            veryHighFee: 'Very High Fee',
            highFee: 'High Fee',
            lowFee: 'Low Fee',
            editMessage: 'Fee Edited!',
            addMessage: 'Fee Created!',
            deleteMessage: 'Fee Deleted!',
        },
        payments: {
            listTitle: 'Payments',
            label_company: 'Select Company',
            name: 'Name',
            surname: 'Surname',
            contact: 'Contact',
            amount: 'Amount',
            method: 'Method',
            dealDate: 'Deal Date',
            currency: 'Currency',
            paymentDate: 'Payment Date',
            status: 'Status',
            button_update_date: 'Update pay date for all selected',
            modal_title: 'Update payment date',
            modal_description: '',
            modal_button: 'Update'
        }
    },
    home: {
        header: {
            about: 'About',
            howItWorks: 'How it works',
            benefits: 'Benefits',
            getStarted: 'Get started'
        },
        fold: {
            title: 'SWAPP IS THE HIGH-ENERGY JOB REFERRAL APP WHERE YOU CAN EARN UP TO <strong>€3K</strong> FOR A SUCCESSFUL HIRE.',
            description: 'Connect people with their dream jobs, grow your network, join an exclusive community of trusted referrers - and Swapp 30 seconds on your phone, for a nice cash reward.',
            cta: 'START REFERRING',
            bannerTitle: 'JOIN THE NETWORKING WITH BENEFITS REVOLUTION.',
            bannerDescription: 'JOIN SWAPP.',
            bannerCta: 'JOIN SWAPP'
        },
        about: {
            title1: 'SHARING IS CARING WITH SWAPP.',
            description1: 'Swapp is about spreading the word, sharing jobs and connecting people in your extended network with great job opportunities.',
            title2: 'DIFFERENT INDUSTRIES, DIFFERENT NETWORKS.',
            description2: 'You don’t have to think about the perfect person for the perfect role. Just share the link with contacts in your network who might know someone suitable.',
            title3: 'EACH SHARE BECOMES A NEW LINK IN THE CHAIN.',
            description3: 'Every time someone in your network re-shares a job, they add another link to your chain. Use in-app data to monitor re-shares, applicants and jobs views.',
            title4: 'THEN EARN REWARDS FROM EVERY SUCCESSFUL CHAIN.',
            description4: 'As soon as someone in your chain gets hired, everyone involved gets a cash reward. And with rewards up to €3k, it pays to network with Swapp.'
        },
        testimonial: {
            testimonial1: `<strong>“I didn’t know anyone specific for the job, but my friend did.</strong> She re-shared my link and we both earned money in the process!”`,
            cta1: 'SIGN UP',
            testimonial2: `<strong>“This is amazing!</strong> It brought me some income while being a stay-at-home mom”`,
            cta2: 'SIGN UP',
            testimonial3: `<strong>“While waiting for my plane to take off I shared a job and earnt 600 CHF in the process.</strong> It was the easiest and most profitable way of killing time”`,
            cta3: 'SIGN UP'
        },
        howItWorks: {
            title: 'HOW IT WORKS',
            ready: 'READY?',
            cta: 'START HERE',
            title1: 'STEP ONE<br>DEFINE YOUR NETWORK',
            description1: 'Tell us what your professional network looks like, so we can tailor job listings to your field.',
            title2: 'STEP TWO<br>GET ALERTS',
            description2: `We'll send you an alert every time we have a job you might be able to help with. Or check the job board at any time.`,
            title3: 'STEP THREE<br>SHARE LINKS',
            description3: 'Start sharing job links with your network - via email, WhatsApp or URL.',
            title4: 'STEP FOUR<br>CHECK ACTIVITY',
            description4: 'Track re-shares, job views and applicants through our in-app dashboard.',
            title5: 'STEP FIVE<br>EARN REWARDS',
            description5: 'When someone in your chain gets hired, everyone involved receives a cash reward.'
        },
        bannerDownload1: {
            title: 'IT PAYS TO NETWORK WITH SWAPP',
            description1: '<strong>Everyone</strong> involved in the referral chain leading to the successful hire gets a share of the reward. This could be two people, four people or more. No matter how many people, the final reward gets split fairly.',
            description2: 'Download Swapp and start earning rewards right away.'
        },
        bannerDownload2: {
            title: 'NETWORKING WITH BENEFITS',
            description: 'Join the networking with benefits revolution.<br>Join Swapp.',
        },
        whySwapp: {
            title: 'WHY SWAPP',
            title1: 'BE INFORMED',
            description1: 'We’ll alert you with jobs relevant to your network - so you can keep up-to-date at all times.',
            title2: 'JUST SHARE',
            description2: 'Don’t know the perfect candidate? It doesn’t matter! Just share the job with someone who might know them.',
            title3: 'YOUR WAY',
            description3: 'Join when you want, share what you want, use it as much as you want. Swapp is made for you.',
            title4: `DON'T WAIT UP`,
            description4: 'You can also browse the job board and pick up an interesting job to start a referral chain.',
            title5: 'SUPER ACCESSIBLE',
            description5: 'Available on Android, iOS and online for maximum accessibility. Network with benefits, wherever you are.',
            cta: 'JOIN SWAPP'
        },
        footer: {
            title: 'Swisslinx AG.<br>It pays to network.',
            rights: '© 2022 Swisslinx AG.<br>All rights reserved',
            privacy: 'Privacy and cookie policy',
            terms: 'Terms and conditions',
            address: 'Löwenstrasse 29<br>CH-8001 Zurich',
            phone: 'Phone number goes here',
            mail: 'hello@swapp.biz'
        }
    }
}
