import React, { useState, useEffect, ChangeEvent } from 'react';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import TypeMainState from '../../models/initialState/typeMainState';
import { TypeActions, ActionsType } from '../../models/actions/typeActions';
import { ActionLoginType } from '../../models/actions/typeLoginActions';

import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDesktop, faBuilding, faCity, faFileAlt, faUsers, faBars, faChevronCircleLeft,
    faDharmachakra, faGlobe, faChartArea, faGlobeEurope, faPaste, faLocationArrow, faCoins
} from '@fortawesome/free-solid-svg-icons';

import { ROUTE_PRIVATE_ZONE, ROUTE_PRIVATE_COMPANYGROUPS, ROUTE_PRIVATE_COMPANY, ROUTE_PRIVATE_JOBS, ROUTE_PRIVATE_NETWORKERS, ROUTE_PRIVATE_CLIENTS, ROUTE_PRIVATE_TEMPLATES, ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST, ROUTE_PRIVATE_SKILLS, ROUTE_PRIVATE_MARKETS, ROUTE_PRIVATE_LANGUAGES, ROUTE_PRIVATE_COUNTRIES, ROUTE_PRIVATE_REGIONS, ROUTE_PRIVATE_CITIES, ROUTE_PRIVATE_FEED_TEMPLATES, ROUTE_PRIVATE_SKILL_GROUP, ROUTE_PRIVATE_PAYMENTS } from '../../router/routes';
import CompanyGroups from '../companyGroups/CompanyGroups';
import Dashboard from '../dashboard/Dashboard';
import Company from '../company/Company';
import Jobs from '../jobs/Jobs';
import Networkers from '../networkers/Networkers';
import { CompanyRole } from '../../models/dto/LoginResponseDto';
import Drawer from '@mui/material/Drawer';

import './PrivateZone.scss';
import Client from '../client/Client';
import Templates from '../templates/Templates';
import { isAdmin, isAdminTAAdmin } from './utilsRoles';
import TypeState from '../../models/initialState/typeState';
import NetworkersRequest from '../networkersRequestList/NetworkersRequest';
import Skills from '../skills/Skills';
import Markets from '../markets/Markets';
import Languages from '../languages/Languages';
import Countries from '../location/countries/Countries';
import Regions from '../location/regions/Regions';
import Cities from '../location/cities/Cities';
import Fee from '../fee/Fee';
import logo from '../../assest/swapp_logo.svg';
import { MenuItem, TextField } from '@material-ui/core';
import SkillGroup from '../skillGroup/SkillGroup';
import Payments from '../payments/payments';

function PrivateZone({ path = '' }) {
    const [openMenu, setOpenMenu] = useState(true);
    const [companiesAllowed, setCompaniesAllowed] = useState<CompanyRole[]>([]);
    const [reloadOption, setReloadOption] = useState(false);
    const [subMenu, setSubMenu] = useState(false);
    const { t } = useTranslation();
    const state: TypeMainState = useSelector((state: TypeState) => state.mainReducer);
    const dispatch = useDispatch();
    const TIME_RENEWTOKEN = 1080000;

    let element: JSX.Element = <Dashboard option={0} reload={reloadOption} setReload={setReloadOption} />
    switch (path) {
        case ROUTE_PRIVATE_COMPANYGROUPS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <CompanyGroups reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_COMPANY: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Company reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_CLIENTS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Client reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_JOBS: {
            element = <Jobs reload={reloadOption} setReload={setReloadOption} />
            break;
        }
        case ROUTE_PRIVATE_NETWORKERS: {
            element = <Networkers reload={reloadOption} setReload={setReloadOption} />
            break;
        }
        case ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST: {
            if (isAdminTAAdmin(state.roleCompanySelected)) {
                element = <NetworkersRequest reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_TEMPLATES: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Templates reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_SKILLS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Skills reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_SKILL_GROUP: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <SkillGroup reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_MARKETS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Markets reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_LANGUAGES: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Languages reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_COUNTRIES: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Countries reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_REGIONS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Regions reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_CITIES: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Cities reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_FEED_TEMPLATES: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Fee reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
        case ROUTE_PRIVATE_PAYMENTS: {
            if (isAdmin(state.roleCompanySelected)) {
                element = <Payments reload={reloadOption} setReload={setReloadOption} />
            }
            break;
        }
    }

    const changeCompany = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const company = companiesAllowed.find(elem => elem.company.id === value);
        if (company !== undefined) {
            dispatch<TypeActions>({ type: ActionsType.SEND_COMPANY_SELECTED, value: company });
            
        } else {
            dispatch<TypeActions>({ type: ActionsType.SEND_COMPANY_SELECTED, value: null });
        }
        setReloadOption(true);
    }

    const logout = () => dispatch<TypeActions>({ type: ActionLoginType.ACTION_LOOUT });

    useEffect(() => {
        if (state.loginResponseDto !== null) {
            setCompaniesAllowed(state.loginResponseDto.companyRole);
            /* if (state.loginResponseDto.companyRole.length > 0) {
                dispatch<TypeActions>({ type: ActionsType.SEND_COMPANY_SELECTED, value: state.loginResponseDto.companyRole[0] });
            } */
        }
    }, []);

    useEffect(() => {
        const renovarToken = () => {
            dispatch<TypeActions>({ type: ActionLoginType.LOGIN_RENEWTOKEN_LOADING });
        }
        if (state.renovarToken) {
            //const expirateTime = new Date(state.tokenExpiration as string);
            //const today = new Date();
            //const time = expirateTime.getTime() - today.getTime();
            /* setTimeout(renovarToken, time - 100000); */
            setTimeout(renovarToken, TIME_RENEWTOKEN);
            dispatch<TypeActions>({ type: ActionLoginType.LOGIN_RENEWTOKEN_VALUE, value: false });
        }
    }, [state.renovarToken]);

    useEffect(() => {
        if (state.auth) {
            dispatch<TypeActions>({ type: ActionLoginType.LOGIN_RENEWTOKEN_LOADING });
        }
    }, []);

    const optionsCompanies = companiesAllowed.map((option) => (
        <option key={option.company.id} value={option.company.id}>
            {option.company.description}
        </option>
    ))

    return <div className="swissinx-admin-container">
        <div className="swissinx-admin-dashboard-header">
            <FontAwesomeIcon icon={faBars} onClick={() => setOpenMenu(!openMenu)} />
            <div className='swissinx-admin-dashboard-header__right'>
                <TextField
                    id="ta-company"
                    select={true}
                    value={state.companyIdSelected ? state.companyIdSelected : -1}
                    error={false}
                    onChange={changeCompany}
                >
                    <MenuItem key={-1} value={-1}>Select a company</MenuItem>
                    {companiesAllowed.map(option => <MenuItem key={option.company.id} value={option.company.id}>{option.company.description}</MenuItem>)
                    }
                </TextField>
                <button className="swissinx-admin-menu-logout" onClick={logout}>{t('text_logout_button')}</button>
            </div>
        </div>
        <Drawer
            anchor={'left'}
            open={openMenu}
            onClose={() => setOpenMenu(false)}
        >
            <div className="swissinx-admin-menu">
                <div className="swissinx-admin-menu-logo">
                    <img src={logo} alt=""></img>
                </div>
                <FontAwesomeIcon icon={faChevronCircleLeft} onClick={() => setOpenMenu(!openMenu)} />
                <div className="swissinx-admin-menu-options" onClick={() => setOpenMenu(false)}>
                    <nav className="swissinx-admin-nav">
                        <ul>
                            <li onClick={() => setReloadOption(true)}>
                                <Link to={ROUTE_PRIVATE_ZONE}>
                                    <FontAwesomeIcon icon={faDesktop} />
                                    {t('admin.menu.dashboard')}
                                </Link>
                            </li>
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_CLIENTS}>
                                        <FontAwesomeIcon icon={faUsers} />
                                        {t('admin.menu.clients')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_COMPANY}>
                                        <FontAwesomeIcon icon={faBuilding} />
                                        {t('admin.menu.company')}
                                    </Link>

                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_COMPANYGROUPS}>
                                        <FontAwesomeIcon icon={faCity} />
                                        {t('admin.menu.company_groups')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_FEED_TEMPLATES}>
                                        <FontAwesomeIcon icon={faPaste} />
                                        {t('admin.menu.fees')}
                                    </Link>
                                </li>
                            }
                            <li onClick={() => setReloadOption(true)}>
                                <Link to={ROUTE_PRIVATE_JOBS}>
                                    <FontAwesomeIcon icon={faFileAlt} />
                                    {t('admin.menu.jobs')}
                                </Link>
                            </li>
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_LANGUAGES}>
                                        <FontAwesomeIcon icon={faGlobe} />
                                        {t('admin.menu.languages')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li >
                                    <p onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSubMenu(!subMenu);
                                    }
                                    }>
                                        <FontAwesomeIcon icon={faGlobeEurope} />
                                        {t('admin.menu.location')}
                                    </p>
                                    {subMenu && <ul>
                                        <li onClick={() => setReloadOption(true)}>

                                            <Link to={ROUTE_PRIVATE_COUNTRIES}>
                                                <FontAwesomeIcon icon={faLocationArrow} />
                                                Country
                                            </Link>
                                        </li>
                                        <li onClick={() => setReloadOption(true)}>
                                            <Link to={ROUTE_PRIVATE_REGIONS}>
                                                <FontAwesomeIcon icon={faLocationArrow} />
                                                Region
                                            </Link>
                                        </li>
                                        <li onClick={() => setReloadOption(true)}>
                                            <Link to={ROUTE_PRIVATE_CITIES}>
                                                <FontAwesomeIcon icon={faLocationArrow} />
                                                City
                                            </Link>
                                        </li>
                                    </ul>}
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_MARKETS}>
                                        <FontAwesomeIcon icon={faChartArea} />
                                        {t('admin.menu.markets')}
                                    </Link>
                                </li>
                            }
                            <li onClick={() => setReloadOption(true)}>
                                <Link to={ROUTE_PRIVATE_NETWORKERS}>
                                    <FontAwesomeIcon icon={faUsers} />
                                    {t('admin.menu.networkers')}
                                </Link>
                            </li>
                            {isAdminTAAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST}>
                                        <FontAwesomeIcon icon={faUsers} />
                                        {t('admin.menu.networkersRequestList')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_TEMPLATES}>
                                        <FontAwesomeIcon icon={faPaste} />
                                        {t('admin.menu.templates')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_SKILL_GROUP}>
                                        <FontAwesomeIcon icon={faDharmachakra} />
                                        {t('admin.menu.skillGroup')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_SKILLS}>
                                        <FontAwesomeIcon icon={faDharmachakra} />
                                        {t('admin.menu.skills')}
                                    </Link>
                                </li>
                            }
                            {isAdmin(state.roleCompanySelected) &&
                                <li onClick={() => setReloadOption(true)}>
                                    <Link to={ROUTE_PRIVATE_PAYMENTS}>
                                        <FontAwesomeIcon icon={faCoins} />
                                        {t('admin.menu.payments')}
                                    </Link>
                                </li>
                            }

                        </ul>

                    </nav>
                </div>
            </div>
        </Drawer>
        <div className="swissinx-admin-dashboard">
            <div className="swissinx-admin-dashboard-working-area">
                {element}
            </div>
        </div>

    </div>
}

export default PrivateZone;
