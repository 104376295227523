import loginResponseDto, { CompanyRole } from '../../models/dto/LoginResponseDto';
import TypeMainState from '../../models/initialState/typeMainState';
import { newFormField } from '../../utils/utils';

function getLoginResponse(): loginResponseDto | null {
  const loginResponse = localStorage.getItem('LOGINDATA');
  if (loginResponse !== null) {
    return JSON.parse(loginResponse) ;
  } else {
    return null;
  }
}

function getInitialCompanySelected(): CompanyRole | null {
  const loginResponse = localStorage.getItem('LOGINDATA');
  if (loginResponse !== null && loginResponse !== 'null') {
    const response:loginResponseDto = JSON.parse(loginResponse);
    return response.companyRole.length > 0 ? response.companyRole[0] : null;
  } else {
    return null;
  }
}

function isAuth() {
  let auth = false;
  //const isToken = localStorage.getItem('TOKEN') !== 'null' && localStorage.getItem('TOKEN') !== null;
  const loginData = getLoginResponse();
  if (loginData !== null) {
    auth = loginData.role.id < 3;
  }
  return auth;
}

export const initialState: TypeMainState = {
    loginDto: {
        username: '',
        password: ''
    },
    loginForm: {
        username: newFormField('',true, true),
        password: newFormField('',true, true)   
    },
    loginResponseDto: getLoginResponse() ,
    errorServerLogin: false,
    errorServerLoginMessage: '',
    loading: false,
    auth: isAuth(),
    token: localStorage.getItem('TOKEN'),
    renovarToken: isAuth(),
    //NetworkerId: localStorage.getItem('NETWORKERID') != null ? parseInt(localStorage.getItem('NETWORKERID')) : 0,
    tokenExpiration: localStorage.getItem('TOKENEXPIRATION'),
    //companySelected: getInitialCompanySelected(),
    companySelected: null,
    companyIdSelected: -1,
    roleCompanySelected: -1,
    recoverPassword: {
      loading: false,
      ok: false,
      error: false
  } 
}