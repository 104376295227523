
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import JobTemplatesDto from '../../models/dto/JobTemplateDto';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';

interface Props {
    edit(event: any, index:JobTemplatesDto): void,
    remove(event: any, index:JobTemplatesDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function TemplateList(props: Props) {
  const { t } = useTranslation();
  //const state:TypeMainState = useSelector((state:TypeMainState) => state);
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  
const headers = [{title: t('admin.company.tableHeader1'), field: 'name', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''}                 
];

const actions = [
  {
    icon: Edit,
    tooltip: t('table.tooltip.actionEdit'),
    disabled: false,
    onClick: props.edit
  },
  {
    icon: DeleteOutline,
    tooltip: t('table.tooltip.actionDelete'),
    disabled: false,
    onClick: props.remove
  },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.templates.listTitle')}</h2>
            <Table<JobTemplatesDto> 
              data={state.templates.data} 
              headers={headers} 
              actions={actions} 
              new={true} 
              initialPage={state.templates.initialPage}
              serchText={state.templates.searchText}
              pageSize={state.templates.pageSize}
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}/>
        </div>;
  }

export default TemplateList;