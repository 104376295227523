import React, {SyntheticEvent, useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import JobsForm from '../../models/forms/JobsForm';
import JobsResponseDto from '../../models/dto/JobsResponseDto';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import JobsEdit from './JobsEdit';
import JobsList from './JobsList';
import JobsPush from './JobsPush';
import JobsDto from '../../models/dto/JobsDto';
import { jobFormToDto, pushJobFormToPushJobDto } from '../../utils/utils';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import { ActionLocationType, TypeActionLocationActions } from '../../models/actions/typeLocationActions';
import { ActionClientsType, TypeClientsActions } from '../../models/actions/typeClientsActions';
import PushJobForm from '../../models/forms/PushJobForm';
import ModalInfo from '../commons/ModalInfo';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import JobsHistoryPushList from './JobsHistoryPushList';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionTemplateJobType } from '../../models/actions/typeTemplateJobActions';
import { Question } from '../../models/dto/JobTemplateDto';
import CandidatesList from './CandidatesList';
import { APPROVED_APPLICATION_JOB, OPTION_CUSTOM_TEMPLATE, REJECT_APPLICATION_JOB } from '../../constanst/GlobalConstants';
import ApplicationJob from '../../models/dto/ApplicationsDto';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import TypeLocationState from '../../models/initialState/typeLocationState';
import TypeMainState from '../../models/initialState/typeMainState';
import Notification from '../commons/Notification';
import TypeAmbassadorsState from '../../models/initialState/typeAmbassadorsState';
import { ActionSkillsType, TypeActionSkillsActions } from '../../models/actions/typeSkillsActions';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import { ActionMarketsType, TypeActionMarketsActions } from '../../models/actions/typeMarketsActions';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import { ActionLanguagesType, TypeActionLanguagesActions } from '../../models/actions/typeLanguagesActions';
import DashboardJobActivityTabs from './DashboardJobActivityTabs';
import { ActionFeeType, TypeFeeActions } from '../../models/actions/typeFeeActions';
import { ActionSkillGroupType, TypeActionSkillGroupActions } from '../../models/actions/typeSkillGroupActions';
import typeSkillGroupState from '../../models/initialState/typeSkillGroupState';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Jobs(props: Props) {
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const stateLocation:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const stateMain:TypeMainState = useSelector((state:TypeState) => state.mainReducer);
  const stateAmbassadors:TypeAmbassadorsState = useSelector((state:TypeState) => state.ambassadorsReducer);
  const stateSkills: TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);
  const stateLanguages:TypeLanguagesState = useSelector((state:TypeState) => state.languagesReducer);
  const stateSkillGroup: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);

  const [jobSelected, setjobSelected] = useState<JobsResponseDto | null>(state.jobsResponseDto);  
  const [editJob, setEditJob] = useState(false); 
  const [addJob, setAddJob] = useState(false);
  const [showModalDeleteJob, setShowModalDeleteJob] = useState(false);  
  const [showFormPush, setShowFormPush] = useState(false);
  const [showHistoryPush, setShowHistoryPush] = useState(false);
  const [showCandidates, setShowCandidates] = useState(false);
  const [showDashboardJobActivity, setShowDashboardJobActivity] = useState(false);
  
  const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const loadInitialData = () => {
    if(stateLanguages.data.length === 0) {
      dispatch<TypeActionLanguagesActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}});
    }
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}});
    }    
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}});
    }
    if (state.templates.data.length === 0) {
      dispatch<TypeActions>({type:ActionTemplateJobType.TEMPLATE_JOB_LOADING});
    }    
    
    /* if (!addJob && data !== undefined) {
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: parseInt(data.countryId.value as string)});
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: parseInt(data.regionId.value as string)});
    } */
  }

  const edit = (event: SyntheticEvent, index: JobsResponseDto)=>{ 
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_GET_JOB_LOADING, value: index.jobId});
    setjobSelected(index);    
    loadInitialData();    
    //dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_FORM_UPDATE, value: data});
    setEditJob(true);
    setAddJob(false);
  }

  const removeConfirmation = (event: SyntheticEvent, index: JobsResponseDto)=>{
    setjobSelected(index);   
    setShowModalDeleteJob(true);
  };
  
  const newRegister = () => {
    loadInitialData();
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_GET_JOB_SUCCESS, value: null});    
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_FORM_NEW});  
    setjobSelected(null);   
    setEditJob(true);
    setAddJob(true);
  }

  const back = () => {
    setEditJob(false);
  }

  const backPush = () => {
    setShowFormPush(false);
  }

  const backCandidates = () => {
    setShowCandidates(false);
  }

  const reloadCandidates = () => {    
    if (jobSelected){
      candidates(null, jobSelected);
    }
    
  }

  const backDashboardJobActivity = () => {
    setShowDashboardJobActivity(false);
  }

  const reloadDashboardJobActivity = () => {
    if (jobSelected){
      dashboardJobActivity(null, jobSelected);
    }
  }

  const reloadHistoryPushList = () => {
    if (jobSelected){
      history(null, jobSelected);
    }
  }  

  const update = (jobForm:JobsForm, questions: Question[], taId: number) => {
    if (stateMain.companySelected) {
      //Solo se envían las question si el array es personalizado (-1) 
      if (addJob) {
          const data:JobsDto = jobFormToDto(jobForm, true, stateMain.companySelected.taId, stateMain.companyIdSelected, null, jobForm.templateId.value === OPTION_CUSTOM_TEMPLATE ? questions : null);
          dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_ADD_LOADING, value: data});
      } else {
          const data:JobsDto = jobFormToDto(jobForm, false, taId, stateMain.companyIdSelected, jobSelected, jobForm.templateId.value === OPTION_CUSTOM_TEMPLATE ? questions : null);
          dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_EDIT_LOADING, value: data});
      }
    setEditJob(false);
    }    
  }

  const deleteJob = () => {
    setShowModalDeleteJob(false);
    if (jobSelected !== null){
      dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_DELETE_LOADING, value: jobSelected.jobId});
    }
    
  }

  const push = (event: SyntheticEvent, index: JobsResponseDto) => {
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_GET_JOB_LOADING, value: index.jobId});
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_SEND_PUSH_FORM_NEW});
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActionLocationActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: true}});
    }
    if(stateLanguages.data.length === 0) {
      dispatch<TypeActionLanguagesActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}});
    }
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}});
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}});
    }
    dispatch<TypeFeeActions>({type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_LOADING, value: index.jobId});
    setjobSelected(index); 
    setShowFormPush(true);
  }

  const history = (event: SyntheticEvent | null, index: JobsResponseDto)=>{
    setjobSelected(index);
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_HISTORY_PUSH_LOADING, value: index.jobId});   
    setShowHistoryPush(true);
  }  

  const sendPush = (pushJobForm: PushJobForm) => {
    if (jobSelected !== null && stateMain.companySelected){
      const data = pushJobFormToPushJobDto(pushJobForm, jobSelected.jobId, stateMain.companySelected.taId, stateAmbassadors);
      dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_SEND_PUSH_LOADING, value: data});
      setShowFormPush(false);
    }
  }

  const candidates = (event: SyntheticEvent | null, index: JobsResponseDto)=>{
    setjobSelected(index);
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CANDIDATES_LOADING, value: index.jobId});   
    setShowCandidates(true);
  }

  const dashboardJobActivity = (event: SyntheticEvent | null, index: JobsResponseDto)=>{
    setjobSelected(index);
    setShowDashboardJobActivity(true);
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_ACTIVITY_LOADING, value: index.jobId});
  }

  const closeModalPush = () => {     
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_SEND_PUSH_SUCCESS, value: false});
  }

  const closeModalStatusJob = () => {     
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_STATUS_SUCCESS, value: false});
  }
  
  const onError = () => {   
    setShowHistoryPush(false);
    setShowCandidates(false);
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_LOADING, value:{reset: false}});
  }

  const changeStatus = (index:ApplicationJob, value: number) => {
    if (jobSelected !== null){
      dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_STATUS_LOADING, 
        value: {applicationId: index.applicationId ,jobId: jobSelected.jobId, status: value}});
    }
  }

  const changeSelected = (event: any, index: ApplicationJob) => {   
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING, 
      value: {applicationId:index.applicationId, jobId: index.job.id, selectedForJob: !index.selectedForJob}});   
  }
  
  useEffect(()=> {
    if (props.reload === true && stateMain.companySelected) {
      dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: true}});
      dispatch<TypeClientsActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: true}});     
      setEditJob(false);
      setShowFormPush(false);
      setShowHistoryPush(false);
      setShowCandidates(false);
      setShowDashboardJobActivity(false);
      props.setReload(false);
    }    
  },[props.reload, stateMain.companySelected]);

  //Para cargar los jobs si se pulsa directamente al boton de recargar del navegador
  useEffect(()=> {  
    if (!props.reload && stateMain.companySelected) {  
      dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: true}});
      dispatch<TypeClientsActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: true}});
    }   
  }, [stateMain.companySelected]);  

  useEffect(()=> {  
    if (stateSkillGroup.data.length === 0) {
      dispatch<TypeActionSkillGroupActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: false}});
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}});
    }
  }, [stateMain.companySelected]);  

  

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const changeCandidatePage = (page: number, total: number) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_TABLE, value: page});
  }

  const changeCandidateSearchText = (searchText: string) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CANDIDATES_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeCandidateRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CANDIDATES_CHANGE_PAGE_SIZE, value: pageSize});
  }
  
  const onOrderChangeJobs = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionJobsType.JOBS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  const onOrderChangeCandidates = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  const showLoading = (state.loading || state.historyPush.loading || state.candidates.loading || state.candidates.status.loading);
  const showJobsList = !state.loading && !state.error && !editJob && !showFormPush && !showHistoryPush && !showCandidates && !showDashboardJobActivity;
  const showJobsEdit = editJob;
  const showJobsPush = showFormPush;
  const showHistoryPushList = showHistoryPush && !state.historyPush.loading && !state.error; 
  const showCandidatesList = showCandidates && !showLoading && !state.error;
  
  return <>
      { showLoading && <Loading /> }
      { showJobsList && <JobsList 
                            edit={edit} 
                            remove={removeConfirmation} 
                            newRegister={newRegister} 
                            push={push} 
                            history={history} 
                            candidates={candidates}
                            dashboardJobActivity={dashboardJobActivity}                                                  
                            changePage={changePage}
                            changeSearchText={changeSearchText}
                            changeRowsPerPage={changeRowsPerPage}
                            onOrderChange={onOrderChangeJobs}
                            />}
      { showJobsEdit && <JobsEdit update={update} back={back} add={addJob}/>}
      { showJobsPush && <JobsPush back={backPush} jobSelected={jobSelected} sendPush={sendPush} /> }
      { showCandidatesList && <CandidatesList 
                                  jobTitle={jobSelected ? jobSelected.title : ''} 
                                  back={backCandidates}
                                  reload={reloadCandidates}
                                  like={(event: SyntheticEvent, index:ApplicationJob)=>changeStatus(index, APPROVED_APPLICATION_JOB)} 
                                  reject={(event: SyntheticEvent, index:ApplicationJob)=>changeStatus(index, REJECT_APPLICATION_JOB)}
                                  changeSelected={changeSelected}
                                  changePage={changeCandidatePage}
                                  changeSearchText={changeCandidateSearchText}
                                  changeRowsPerPage={changeCandidateRowsPerPage}
                                  onOrderChange={onOrderChangeCandidates}/>}
                                  
      { showHistoryPushList && <JobsHistoryPushList title={jobSelected ? jobSelected.title : ''} back={()=>setShowHistoryPush(false)} reload={reloadHistoryPushList}/> }
      {showDashboardJobActivity && <DashboardJobActivityTabs back={backDashboardJobActivity} reload={reloadDashboardJobActivity} job={jobSelected}/>}
      
      { state.error && <ComponentError 
                              title={t('admin.jobs.error_title')} 
                              message={state.messageError} 
                              buttonMessage={t('admin.jobs.error_button')} 
                              onClick={onError} />}
      {showModalDeleteJob && <ModalConfirmDelete show={showModalDeleteJob} title={t('admin.jobs.modal_confirm_delete_title')} back={()=>setShowModalDeleteJob(false)} confirm={deleteJob} textButtonBack={t('admin.jobs.modal_confirm_delete_back')} textButtonConfirm={t('admin.jobs.modal_confirm_delete_confirm')} />}
      {state.pushJobSuccess && <ModalInfo show={state.pushJobSuccess} title={t('admin.jobs.modal_send_Push_title')} confirm={closeModalPush} textButtonConfirm={t('admin.jobs.modal_confirm_info')} />}  

      {state.candidates.status.ok && <ModalInfo show={state.candidates.status.ok} title={t('admin.jobs.modal_status_job_title')} confirm={closeModalStatusJob} textButtonConfirm={t('admin.jobs.modal_confirm_info')} />}
           
      <Notification
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.jobs.editMessage')}
              addMessage={t('admin.jobs.addMessage')}
              delteMessage={t('admin.jobs.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionJobsType.JOBS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionJobsType.JOBS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionJobsType.JOBS_DELETE_SUCCESS, value: false})}/>  
  </> 
 
}

export default Jobs;