import { TypeActions} from '../../models/actions/typeActions';
import { ActionNetworkersRequestListType } from '../../models/actions/typeNetworkersRequestListActions';
import TypeNetworkersRequestListState from '../../models/initialState/typeNetworkersRequestListState';
import { initialNetworkersRequestListState } from '../inititalState/initialNetworkersRequestListState';

const networkersRequestListReducer = (state:TypeNetworkersRequestListState = initialNetworkersRequestListState, action:TypeActions):TypeNetworkersRequestListState => {
    switch (action.type) {        

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_LOADING: {            
            return {...state, loading: true, error: false}
        }

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_SUCCESS: {           
            return {...state, data: action.value.data, loading: false, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR: {           
            return {...state, loading: false, error: true, messageError: action.value}
        }
        
        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
    }
    return {...state};
}
export default networkersRequestListReducer;