import TypeFeeState from '../../models/initialState/typeFeeState';
import { DEFAULTITEMSPERPAGE, newFeeForm } from '../../utils/utils';

export const initialFeeState: TypeFeeState = {
    loading: false,
    error: false,
    data: [],
    feeForm: newFeeForm(),
    filters: {orderBy: 0, orderDirection: 'asc'},
    initialPage: 0,
    searchText: '',
    pageSize: DEFAULTITEMSPERPAGE,
    edit: false,
    add: false,
    delete: false,
    pushListFee: {
        data: [],
        loading: false,
        error: false
    }
}