import NetworkerDto from '../dto/NetworkerDto';
import NetworkerCreateDto from '../dto/NetworkerCreateDto';
import NetworkerForm from '../forms/NetworkerForm';
import RewardDto from '../dto/RewardDto';
import NetworkerExtraFieldsDto from '../dto/NetworkerExtraFieldsDto';

export enum ActionNetworkersType {
    
    NETWORKERS_LOADING = 'NETWORKERS_LOADING',
    NETWORKERS_SUCCESS = 'NETWORKERS_SUCCESS',
    NETWORKERS_ERROR = 'NETWORKERS_ERROR',
    NETWORKER_REGISTER_AMBASSADOR = 'NETWORKER_REGISTER_AMBASSADOR',
    NETWORKER_DELETE_AMBASSADOR = 'NETWORKER_DELETE_AMBASSADOR',
    NETWORKER_REGISTER_TA = 'NETWORKER_REGISTER_TA',
    NETWORKER_DELETE_TA = 'NETWORKER_DELETE_TA',

    NETWORKERS_FORM_SEND_VALUES = 'NETWORKERS_FORM_SEND_VALUES', 
    NETWORKERS_FORM_NEW = 'NETWORKERS_FORM_NEW',
    NETWORKERS_FORM_UPDATE = 'NETWORKERS_FORM_UPDATE',
    NETWORKERS_ERROR_REQUIRED = 'NETWORKERS_ERROR_REQUIRED',

    NETWORKERS_ADD_LOADING = 'NETWORKERS_ADD_LOADING',
    NETWORKERS_ADD_SUCCESS = 'NETWORKERS_ADD_SUCCESS',
    NETWORKERS_ADD_ERROR = 'NETWORKERS_ADD_ERROR',
    NETWORKERS_EDIT_LOADING = 'NETWORKERS_EDIT_LOADING',
    NETWORKERS_EDIT_SUCCESS = 'NETWORKERS_EDIT_SUCCESS',
    NETWORKERS_EDIT_ERROR = 'NETWORKERS_EDIT_ERROR',
    NETWORKERS_DELETE_LOADING = 'NETWORKERS_DELETE_LOADING',
    NETWORKERS_DELETE_SUCCESS = 'NETWORKERS_DELETE_SUCCESS',
    NETWORKERS_DELETE_ERROR = 'NETWORKERS_DELETE_ERROR',
    NETWORKERS_CHANGE_SEARCH_TABLE = 'NETWORKERS_CHANGE_SEARCH_TABLE',
    NETWORKERS_CHANGE_PAGE_TABLE = 'NETWORKERS_CHANGE_PAGE_TABLE',
    NETWORKERS_CHANGE_PAGE_SIZE = 'NETWORKERS_CHANGE_PAGE_SIZE',

    NETWORKERS_REWARDS_DATA_LOADING = 'NETWORKERS_REWARDS_DATA_LOADING',
    NETWORKERS_REWARDS_DATA_SUCCESS = 'NETWORKERS_REWARDS_DATA_SUCCESS',
    NETWORKERS_REWARDS_DATA_ERROR = 'NETWORKERS_REWARDS_DATA_ERROR',

    NETWORKERS_REWARDS_YEARS_LOADING = 'NETWORKERS_REWARDS_YEARS_LOADING',
    NETWORKERS_REWARDS_YEARS_SUCCESS = 'NETWORKERS_REWARDS_YEARS_SUCCESS',
    NETWORKERS_REWARDS_YEARS_ERROR = 'NETWORKERS_REWARDS_YEARS_ERROR',

    NETWORKERS_REWARDS_SEND_YEAR_SELECTED = 'NETWORKERS_REWARDS_SEND_YEAR_SELECTED',

    NETWORKERS_REWARDS_PDF_LOADING = 'NETWORKERS_REWARDS_PDF_LOADING',
    NETWORKERS_REWARDS_PDF_SUCCESS = 'NETWORKERS_REWARDS_PDF_SUCCESS',
    NETWORKERS_REWARDS_PDF_ERROR = 'NETWORKERS_REWARDS_PDF_ERROR',

    NETWORKERS_EXTRA_FIELDS_LOADING = 'NETWORKERS_EXTRA_FIELDS_LOADING',
    NETWORKERS_EXTRA_FIELDS_SUCCESS = 'NETWORKERS_EXTRA_FIELDS_SUCCESS',
    NETWORKERS_EXTRA_FIELDS_ERROR = 'NETWORKERS_EXTRA_FIELDS_ERROR',

    NETWORKERS_CHANGE_FILTER = 'NETWORKERS_CHANGE_FILTER',

    NETWORKERS_ANONYMIZER_LOADING = 'NETWORKERS_ANONYMIZER_LOADING',
    NETWORKERS_ANONYMIZER_SUCCESS = 'NETWORKERS_ANONYMIZER_SUCCESS',
    NETWORKERS_ANONYMIZER_ERROR = 'NETWORKERS_ANONYMIZER_ERROR',

    NETWORKERS_PERFORMANCE_REPORT_LOADING = 'NETWORKERS_PERFORMANCE_REPORT_LOADING',
    NETWORKERS_PERFORMANCE_REPORT_SUCCESS = 'NETWORKERS_PERFORMANCE_REPORT_SUCCESS',
    NETWORKERS_PERFORMANCE_REPORT_ERROR = 'NETWORKERS_PERFORMANCE_REPORT_ERROR',
}

export interface actionNetworkersSendValues {
    type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[] | null,
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionactionNetworkersErrorRequired {
    type: ActionNetworkersType.NETWORKERS_ERROR_REQUIRED,
    value: string
}

export interface actionNetworkersFormNew {
    type: ActionNetworkersType.NETWORKERS_FORM_NEW    
}

export interface actionNetworkersFormUpdate {
    type: ActionNetworkersType.NETWORKERS_FORM_UPDATE,
    value: NetworkerForm  
}

export interface actionNetworkersLoading {
    type: ActionNetworkersType.NETWORKERS_LOADING,
    value: {reset: boolean}
}

export interface actionNetworkersSuccess {
    type: ActionNetworkersType.NETWORKERS_SUCCESS,
    value: {data: NetworkerDto[], reset: boolean}    
}

export interface actionNetworkersError {
    type: ActionNetworkersType.NETWORKERS_ERROR,
    value: string 
}

export interface actionNetworkersAddLoading {
    type: ActionNetworkersType.NETWORKERS_ADD_LOADING,
    value: NetworkerCreateDto    
}

export interface actionNetworkersAddSuccess {
    type: ActionNetworkersType.NETWORKERS_ADD_SUCCESS,
    value: boolean
}

export interface actionNetworkersAddError {
    type: ActionNetworkersType.NETWORKERS_ADD_ERROR   
}

export interface actionNetworkersEditLoading {
    type: ActionNetworkersType.NETWORKERS_EDIT_LOADING,
    value: NetworkerCreateDto    
}

export interface actionNetworkersEditSuccess {
    type: ActionNetworkersType.NETWORKERS_EDIT_SUCCESS,
    value: boolean
}

export interface actionNetworkersEditError {
    type: ActionNetworkersType.NETWORKERS_EDIT_ERROR   
}

export interface actionNetworkersDeleteLoading {
    type: ActionNetworkersType.NETWORKERS_DELETE_LOADING,
    value: number    
}

export interface actionNetworkersDeleteSuccess {
    type: ActionNetworkersType.NETWORKERS_DELETE_SUCCESS,
    value: boolean
}

export interface actionNetworkersDeleteError {
    type: ActionNetworkersType.NETWORKERS_DELETE_ERROR   
}

export interface actionNetworkersRegisterAmbassador {
    type: ActionNetworkersType.NETWORKER_REGISTER_AMBASSADOR,
    value: {
        networkerId: number,
        companyId: number,
        credit: number
    }   
}

export interface actionNetworkersRegisterTA {
    type: ActionNetworkersType.NETWORKER_REGISTER_TA,
    value: {
        networkerId: number,
        companyId: number,
        credit: number
    }   
}

export interface actionNetworkersDeleteAmbassador {
    type: ActionNetworkersType.NETWORKER_DELETE_AMBASSADOR,
    value: number
}

export interface actionNetworkersDeleteTA {
    type: ActionNetworkersType.NETWORKER_DELETE_TA,
    value: number
}

export interface actionNetworkersChangeSerachTable {
    type: ActionNetworkersType.NETWORKERS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionNetworkersChangePageSizeTable {
    type: ActionNetworkersType.NETWORKERS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionNetworkersChangePageTable {
    type: ActionNetworkersType.NETWORKERS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionNetworkersRewardsLoading {
    type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_LOADING,
    value: {companyId: number,
            networkerId: number,
            year: number}
}

export interface actionNetworkersRewardsSuccess {
    type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_SUCCESS,
    value:RewardDto
}

export interface actionNetworkersRewardsError {
    type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_ERROR     
}

export interface actionNetworkersRewardsYearsLoading {
    type: ActionNetworkersType.NETWORKERS_REWARDS_YEARS_LOADING,
    value: {
        companyId: number,
        networkerId: number
    }   
}

export interface actionNetworkersRewardsYearsSuccess {
    type: ActionNetworkersType.NETWORKERS_REWARDS_YEARS_SUCCESS,
    value: number[]   
}

export interface actionNetworkersRewardsYearsError {
    type: ActionNetworkersType.NETWORKERS_REWARDS_YEARS_ERROR     
}

export interface actionNetworkersRewardsYearSelected {
    type: ActionNetworkersType.NETWORKERS_REWARDS_SEND_YEAR_SELECTED,
    value: number
}

export interface actionNetworkersRewardsPdfLoading {
    type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_LOADING,
    value: {companyId: number,
            networkerId: number,
            year: number}
}

export interface actionNetworkersRewardsPdfSuccess {
    type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_SUCCESS,
    value:boolean
}

export interface actionNetworkersRewardsPdfError {
    type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_ERROR,
    value:boolean   
}

export interface actionNetworkersExtraFieldsLoading {
    type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_LOADING,
    value: {companyId: number,
            networkerId: number}
}

export interface actionNetworkersExtraFieldsSuccess {
    type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_SUCCESS,
    value:NetworkerExtraFieldsDto
}

export interface actionNetworkersExtraFieldsError {
    type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_ERROR
}

export interface actionNetworkersChangeFilterTable {
    type: ActionNetworkersType.NETWORKERS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export interface actionNetworkersAnonymizerLoading {
    type: ActionNetworkersType.NETWORKERS_ANONYMIZER_LOADING,
    value:number
}

export interface actionNetworkersAnonymizerSuccess {
    type: ActionNetworkersType.NETWORKERS_ANONYMIZER_SUCCESS   
}

export interface actionNetworkersAnonymizerError {
    type: ActionNetworkersType.NETWORKERS_ANONYMIZER_ERROR,
    value: string
}

export interface actionNetworkersPerformanceReportLoading {
    type: ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_LOADING,
    value:{CompanyId: number | null, NetworkerId: number, DateFrom: string, DateTo: string}
}

export interface actionNetworkersPerformanceReportSuccess {
    type: ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_SUCCESS
}

export interface actionNetworkersPerformanceReportError {
    type: ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_ERROR
    value: string
}

export type TypeNetworkersActions =  actionNetworkersLoading | actionNetworkersSuccess | actionNetworkersError |
                                     actionNetworkersRegisterAmbassador | actionNetworkersRegisterTA |
                                     actionNetworkersDeleteAmbassador | actionNetworkersDeleteTA |
                                     actionNetworkersAddLoading | actionNetworkersAddSuccess | actionNetworkersAddError |
                                     actionNetworkersEditLoading | actionNetworkersEditSuccess | actionNetworkersEditError | 
                                     actionNetworkersDeleteLoading | actionNetworkersDeleteSuccess | actionNetworkersDeleteError |
                                     actionNetworkersSendValues | actionactionNetworkersErrorRequired | actionNetworkersFormNew | actionNetworkersFormUpdate |
                                     actionNetworkersChangeSerachTable | actionNetworkersChangePageSizeTable | actionNetworkersChangePageTable |
                                     actionNetworkersRewardsLoading | actionNetworkersRewardsSuccess | actionNetworkersRewardsError |
                                     actionNetworkersRewardsYearsLoading | actionNetworkersRewardsYearsSuccess | actionNetworkersRewardsYearsError |
                                     actionNetworkersRewardsPdfLoading | actionNetworkersRewardsPdfSuccess | actionNetworkersRewardsPdfError |
                                     actionNetworkersRewardsYearSelected |
                                     actionNetworkersExtraFieldsLoading | actionNetworkersExtraFieldsSuccess | actionNetworkersExtraFieldsError |
                                     actionNetworkersChangeFilterTable |
                                     actionNetworkersAnonymizerLoading | actionNetworkersAnonymizerSuccess | actionNetworkersAnonymizerError |
                                     actionNetworkersPerformanceReportLoading | actionNetworkersPerformanceReportSuccess | actionNetworkersPerformanceReportError;
                                           