import { useTranslation } from 'react-i18next';
import { useSelector} from 'react-redux';
import NetworkerDto from '../../models/dto/NetworkerDto';
import PersonAdd from '@material-ui/icons/PersonAdd';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import GpsOffSharp from '@material-ui/icons/GpsOffSharp';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';

import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import TypeState from '../../models/initialState/typeState';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
//import './Networkers.scss';
import { isAdmin, isAdminTAAdmin, isTA, isTAAdmin } from '../privateZone/utilsRoles';
import TypeMainState from '../../models/initialState/typeMainState';
import Table from '../table/Table';
import DeviceUnknown from '@material-ui/icons/DeviceUnknown';
import Description from '@material-ui/icons/Description';


interface Props {
    registerDeleteAmbassador(event: any, index:NetworkerDto): void,
    registerDeleteTA(event: any, index:NetworkerDto): void,
    edit(event: any, index:NetworkerDto): void,
    remove(event: any, index:NetworkerDto): void,
    newRegister():void,
    anonymizer(event: any, index:NetworkerDto):void,
    report(event: any, index:NetworkerDto):void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,    
    rewards(event: any, index:NetworkerDto): void,
  }

function NetworkersList(props:Props) {
  const { t } = useTranslation();
  const state:TypeNetworkersState = useSelector((state:TypeState) => state.networkersReducer);
  const mainState:TypeMainState = useSelector((state:TypeState) => state.mainReducer);
  const stateCompanies:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);

  const headers = [
    {title: t('admin.networkers.label_phone'), field: 'phone', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''},
    {title: t('admin.networkers.label_email'), field: 'email', defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: ''},
    {title: t('admin.networkers.label_name'), field: 'name', defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: ''},
    {title: t('admin.networkers.label_surname'), field: 'surname', defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: ''},
    {title: t('admin.networkers.label_company'), field: 'companyId', render: (rowData:NetworkerDto) => {
      const company = stateCompanies.data.find(company => company.companyId === rowData.companyId);
                    return company !== undefined ? company.name : '';
    }, defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: ''},    
    {title: t('admin.networkers.label_ambassador'), field: 'ambassadorId', render: (rowData:NetworkerDto) => {
        return (rowData.ambassadorId !== null) ? t('admin.networkers.label_yes') : t('admin.networkers.label_no')
    }, defaultSort: state.filters.orderBy === 5 ? state.filters.orderDirection: ''},
    {title: t('admin.networkers.label_TA'), field: 'taId', render: (rowData:NetworkerDto) => {
        return (rowData.taId !== null) ? t('admin.networkers.label_yes') : t('admin.networkers.label_no')
    }, defaultSort: state.filters.orderBy === 6 ? state.filters.orderDirection: ''}
  ];

  const actions=[
        (rowData:NetworkerDto) => {
        const row = rowData as NetworkerDto;                    
        return {
            icon: row.ambassadorId === null ? PersonAdd : PersonAddDisabled,
            /* disabled: row.ambassadorId !== null,  */                                                         
            tooltip: row.ambassadorId === null ? t('table.tooltip.ambassador_create') : t('table.tooltip.ambassador_delete'),
            onClick: row.ambassadorId === null ? props.registerDeleteAmbassador : props.registerDeleteAmbassador,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)                       
        }},
        /* (rowData:NetworkerDto) => {
        const row = rowData as NetworkerDto;
        return {
            icon: PersonAddDisabled,
            disabled: row.ambassadorId === null,                                                         
            tooltip: t('table.tooltip.ambassador_delete'),
            onClick: props.registerDeleteAmbassador,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)                                                         
        }}, */
        (rowData:NetworkerDto) => {
        const row = rowData as NetworkerDto;
        return {
            icon: row.taId === null ? VerifiedUser : GpsOffSharp,
            /* disabled: row.taId !== null,  */                   
            tooltip: row.taId === null ? t('table.tooltip.TA_create') : t('table.tooltip.TA_delete'),
            onClick: row.taId === null ? props.registerDeleteTA : props.registerDeleteTA,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        }},
        /* (rowData:NetworkerDto) => {
        const row = rowData as NetworkerDto;
        return {
            icon: GpsOffSharp,
            disabled: row.taId === null,                    
            tooltip: t('table.tooltip.TA_delete'),
            onClick: props.registerDeleteTA,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        }}, */
        {
            icon: MonetizationOn,
            tooltip: t('table.tooltip.actionRewards'),
            disabled: false,
            onClick: props.rewards,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        },
        {
            icon: Edit,
            tooltip: t('table.tooltip.actionEdit'),
            disabled: false,
            onClick: props.edit,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        },
        {
            icon: DeleteOutline,
            tooltip: t('table.tooltip.actionDelete'),
            disabled: false,
            onClick: props.remove,
            hidden: !isAdminTAAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        },
        {
            icon: DeviceUnknown,
            tooltip: t('table.tooltip.actionAnonimizer'),
            disabled: false,
            onClick: props.anonymizer,
            hidden: !isAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        },
        {
            icon: Description,
            tooltip: t('table.tooltip.actionReport'),
            disabled: false,
            onClick: props.report,
            hidden: !isAdmin(mainState.companySelected ? mainState.companySelected.role.id : -1)
        }       
] 
      
return <div className='swissinx-container'>   
            <h2>{t('admin.networkers.listTitle')}</h2>
            <Table<NetworkerDto> 
                data={state.networkersData} 
                headers={headers} 
                actions={actions}                
                new={true}
                initialPage={state.initialPage}
                serchText={state.searchText}
                pageSize={state.pageSize}
                newRegister={props.newRegister}
                changePage={props.changePage}
                changeSearchText={props.changeSearchText}
                changeRowsPerPage={props.changeRowsPerPage}
                onOrderChange={props.onOrderChange}
            />
        </div>;
  
 
}

export default NetworkersList;