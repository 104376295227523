import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './components/login/Login';
import PublicJob from "./components/publicJob/PublicJob";
import PrivateRoute from './router/PrivateRoute';
import PrivateZone from './components/privateZone/PrivateZone';
import { ROUTE_LOGIN, 
         ROUTE_PRIVATE_ZONE, 
         ROUTE_PRIVATE_COMPANYGROUPS, 
         ROUTE_PRIVATE_COMPANY, 
         ROUTE_PRIVATE_JOBS, 
         ROUTE_PRIVATE_NETWORKERS, 
         ROUTE_OFFER, 
         ROUTE_PRIVATE_CLIENTS, 
         ROUTE_PRIVATE_TEMPLATES, 
         ROUTE_PRIVATE_POLICY,
         ROUTE_TERMS_AND_CONDITIONS,
         ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST, 
         ROUTE_PRIVATE_SKILLS, 
         ROUTE_PRIVATE_MARKETS,
         ROUTE_PRIVATE_LANGUAGES,
         ROUTE_PRIVATE_COUNTRIES,
         ROUTE_PRIVATE_REGIONS,
         ROUTE_PRIVATE_CITIES,
         ROUTE_PRIVATE_FEED_TEMPLATES,
         ROUTE_PRIVATE_SKILL_GROUP,
         ROUTE_FAQ,
         ROUTE_HOME,
         ROUTE_PRIVATE_PAYMENTS} from './router/routes';

import './App.scss';
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions";
import FAQ from "./components/faq/faq";
import HOME from "./components/home/home";

function App() {  

  return <>
    <BrowserRouter >
      <Routes> 
        <Route path={ROUTE_LOGIN} element={ <Login />} />                 
        <Route path={ROUTE_PRIVATE_ZONE} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_ZONE}/>} />} />
        <Route path={ROUTE_PRIVATE_COMPANYGROUPS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_COMPANYGROUPS}/>} />} />
        <Route path={ROUTE_PRIVATE_COMPANY} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_COMPANY}/>} />} />
        <Route path={ROUTE_PRIVATE_JOBS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_JOBS}/>} />} />
        <Route path={ROUTE_PRIVATE_NETWORKERS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_NETWORKERS}/>} />} />
        <Route path={ROUTE_PRIVATE_CLIENTS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_CLIENTS}/>} />} />
        <Route path={ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_NETWORKERS_REQUEST_LIST}/>} />} />
        <Route path={ROUTE_PRIVATE_TEMPLATES} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_TEMPLATES}/>} />} />
        <Route path={ROUTE_PRIVATE_SKILLS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_SKILLS}/>} />} />
        <Route path={ROUTE_PRIVATE_SKILL_GROUP} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_SKILL_GROUP}/>} />} />
        <Route path={ROUTE_PRIVATE_MARKETS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_MARKETS}/>} />} />
        <Route path={ROUTE_PRIVATE_LANGUAGES} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_LANGUAGES}/>} />} />    
        <Route path={ROUTE_PRIVATE_COUNTRIES} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_COUNTRIES}/>} />} />  
        <Route path={ROUTE_PRIVATE_REGIONS} element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_REGIONS}/>} />} />
        <Route path={ROUTE_PRIVATE_CITIES } element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_CITIES }/>} />} />    
        <Route path={ROUTE_PRIVATE_FEED_TEMPLATES } element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_FEED_TEMPLATES }/>} />} />  
        <Route path={ROUTE_PRIVATE_PAYMENTS } element={<PrivateRoute children={<PrivateZone path={ROUTE_PRIVATE_PAYMENTS }/>} />} />          
        <Route path={ROUTE_OFFER} element={ <PublicJob />} />
        <Route path={ROUTE_PRIVATE_POLICY} element={ <PrivacyPolicy />} />
        <Route path={ROUTE_TERMS_AND_CONDITIONS} element={ <TermsAndConditions />} />
        <Route path={ROUTE_FAQ} element={ <FAQ />} />
        <Route path={ROUTE_HOME} element={ <HOME />} />   
      </Routes>   
    </BrowserRouter>
</>
}

export default App;
