
import {forwardRef, useRef} from 'react';
import MaterialTable, {Icons} from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import './table.scss';
import { Question } from '../../models/dto/JobTemplateDto';

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

interface Colums {
    title: string,
    field: string
}
  
interface Props {
    data: Question[],
    headers: Colums[],
    setData(rows: Question[]):void
}

function TableTemplates (props:Props) {
    let indexQuestion = useRef<number>(0);
    return <div className='swissinx-templates-table-container'>        
        <MaterialTable
            icons={tableIcons} 
            columns={props.headers}
            data={props.data}
            
            options={{
                draggable: true,
                filtering: false,
                sorting: true,
                actionsColumnIndex: -1                
            }}
            localization={
                {
                    header: {
                        actions: ''
                    }
                }
            }
           title=''
           editable={{
            onRowAdd: (newData:Question) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {                
                newData.questionId = indexQuestion.current++;                
                newData.questionCategory = parseInt(newData.questionCategory.toString());
                newData.questionType = parseInt(newData.questionType.toString());                      
                props.setData([...props.data, newData]);
                resolve(newData);
                }, 500)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (oldData !== undefined) {
                        const dataUpdate = [...props.data];
                        let indexEdit = 0;
                        props.data.find((elem, index) => {
                            indexEdit = index;
                          return elem.questionId === oldData.questionId
                        });
                        const editQuestion = {...newData}
                        editQuestion.questionType = parseInt(editQuestion.questionType.toString())
                        editQuestion.questionCategory = parseInt(editQuestion.questionCategory.toString())
                        dataUpdate[indexEdit] = editQuestion;

                        props.setData([...dataUpdate]);
                    }
                  resolve(newData);
                }, 500)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {                  
                  const dataDelete = [...props.data];
                  let indexDelete = 0;
                  props.data.find((elem, index) => {
                      indexDelete = index;
                      return elem.questionId === oldData.questionId
                    });
                  dataDelete.splice(indexDelete, 1);
                  props.setData([...dataDelete]);
                  resolve(oldData);
                }, 500)
              }),
          }}      
        />
    </div>
}

export default TableTemplates;