import React, { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Form from '../Form/Form/Form';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionClientsType } from '../../models/actions/typeClientsActions';
import TypeClientsState from '../../models/initialState/typeClientsState';
import TypeState from '../../models/initialState/typeState';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import { getLabelFormatted } from '../../utils/utils';

interface Props {
  back(): void,
  update(): void,
  add: boolean
}

function ClientEdit(props: Props) {
  const { t } = useTranslation();
  const stateCompanyGroups: TypeCompanyGroupsState = useSelector((state: TypeState) => state.companyGroupsReducer);
  const state: TypeClientsState = useSelector((state: TypeState) => state.clientsReducer);
  const dispatch = useDispatch();

  const onChangeSelect = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    dispatch<TypeActions>({ type: ActionClientsType.CLIENTS_FORM_SEND_VALUES, value: { name: name, value: event.target.value, error: { required: false, format: false } } })
  }

  function onSubmit() {
    props.update();
  }


  return <div className='swissinx-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
      {props.add ? t('admin.clients.createTitle') : t('admin.clients.editTitle')}
    </h2>
    {<Form contex={state.clientForm} errorRequerired={(key) => dispatch<TypeActions>({ type: ActionClientsType.CLIENTS_ERROR_REQUIRED, value: key })} onSubmit={onSubmit}
      fields={
        <div className="swissinx-container-fields-form">
          <div className="swissinx-container-fields">
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-name">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.clients.label_name')}
                  name='name'
                  required={state.clientForm.name.rules.required}
                  requiredError={t('admin.clients.name_error_required')}
                  value={state.clientForm.name.value as string}
                  fullWidth={true}
                  error={state.clientForm.name.error.required || state.clientForm.name.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionClientsType.CLIENTS_FORM_SEND_VALUES, value: { name, value, error } })}
                />
              </div>
            </div>

            <div className='swissinx-field-container'>
              <div className="swissinx-field field-companyGroupId">
                <TextField
                  id="companyGroupId"
                  select={true}
                  label={getLabelFormatted(t('admin.clients.label_companyGroup'),state.clientForm.companyGroupId.rules.required)}
                  value={state.clientForm.companyGroupId.value as string}
                  error={state.clientForm.companyGroupId.error.required}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSelect(event, 'companyGroupId')}
                  helperText={state.clientForm.companyGroupId.error.required && t('admin.jobs.client_error_required')}                            
                >
                  {stateCompanyGroups.data.map(companyGroup => <MenuItem key={companyGroup.companyGroupId} value={companyGroup.companyGroupId}>{companyGroup.name}</MenuItem>)
                  }
                </TextField>
              </div>

            </div>
          </div>
          <div className='swissinx-container-buttons'>
            <button className="swissinx-button-back" type="button" onClick={props.back}>{t('admin.clients.text_button_back')}</button>
            <button className="swissinx-button-submit" type="submit">{props.add ? t('admin.clients.text_button_submit_create') : t('admin.clients.text_button_submit_edit')}</button>
          </div>
        </div>
      }>
    </Form>}
  </div>
}

export default ClientEdit;