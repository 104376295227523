import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cached from '@material-ui/icons/Cached';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import Visibility from '@material-ui/icons/Visibility';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import ApplicationJob from '../../models/dto/ApplicationsDto';
import { convertDateHourIso } from '../../utils/utils';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { SyntheticEvent, useState } from 'react';
import CandidateData from './CandidateData';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import { APPROVED_APPLICATION_JOB, REJECT_APPLICATION_JOB } from '../../constanst/GlobalConstants';
import Table from '../table/Table';
import ModalInfo from '../commons/ModalInfo';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';

interface Props {
    jobTitle: string,
    back():void,
    reload():void,
    like(event: any, index: ApplicationJob):void,
    reject(event: any, index: ApplicationJob):void,
    changeSelected(event: any, index: ApplicationJob):void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
  }

function CandidatesList(props: Props) {
  const { t } = useTranslation();
  const [showCandidateData, setShowCandidateData] = useState(false);
  const [candidate, setCandidate] = useState<ApplicationJob | null>(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [event, setEvent] = useState(null);
  const [index, setIndex] = useState<ApplicationJob | null>(null);
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const dispatch = useDispatch();

  const renderStatusDescription=(rowData:ApplicationJob)=>{
    if(rowData.selectedForJob){
      return <span style={{color:'gold'}}>{t('admin.dashboard.selected')}</span>
    }else if(rowData.status.id===0){
      return <span style={{color:'orange'}}>{rowData.status.description}</span>
    
    }else if(rowData.status.id===1){
      return <span style={{color:'green'}}>{rowData.status.description}</span>
    
    }
    else if(rowData.status.id===2){
      return <span style={{color:'red'}}>{rowData.status.description}</span>
    }
  }

    const headers = [
      {title: t('admin.candidates.label_name'), field: 'networker.name', defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: ''},
      {title: t('admin.candidates.label_surname'), field: 'networker.surname', defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: ''},
      {title: t('admin.candidates.label_date'), field: 'date', render: (rowData:ApplicationJob) => convertDateHourIso(rowData.date), defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: ''},
      {title: t('admin.candidates.label_status'), field: 'status.description', render: (rowData:ApplicationJob) => renderStatusDescription(rowData), defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: ''}
    ];

  const candidateData = (event: SyntheticEvent, index:ApplicationJob) => {
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_LOADING, 
      value: {jobId: index.job.id, applicationId: index.applicationId}});    
    setCandidate(index);
    setShowCandidateData(true);
  }

  const like = (rowData:ApplicationJob | null)=>{
    if (rowData !== null) {
      props.like(null, rowData);
    }    
  }

  const reject = (rowData:ApplicationJob | null)=>{
    if (rowData !== null) {
      props.reject(null, rowData);
    }    
  }

  const changeSelected = (rowData:ApplicationJob | null)=>{  
    console.log('candidate')
    if (rowData !== null) {
      props.changeSelected(null, rowData);
    }
  }

  const changeSelectedForJobConfirm = (event: any, index: ApplicationJob) => {
    setShowModalConfirm(true); 
    setIndex(index);
    setEvent(event);   
  }

  const changeSelectedForJob = () => {
    setShowModalConfirm(false);
    if (index) {
      props.changeSelected(event, index);
    }    
  }

  const closeModalCandidateSelected = ()=>{
    dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR, value: {
      error: false,
      message: ''
    }}); 
  }
    
  const actions = [                
    (rowData:ApplicationJob) => {
        const row = rowData as ApplicationJob;
        return {
            icon: row.selectedForJob ? Star : StarBorder,
            disabled: row.status.id === REJECT_APPLICATION_JOB,                                     
            tooltip: row.selectedForJob ? t('table.tooltip.actionCandidateNoSelected') : t('table.tooltip.actionCandidateSelected'),
            onClick: changeSelectedForJobConfirm
        }},
    (rowData:ApplicationJob) => {
        const row = rowData as ApplicationJob;
        return {
            icon: ThumbUpAlt,
            disabled: row.status.id === APPROVED_APPLICATION_JOB || row.selectedForJob,                    
            tooltip: t('table.tooltip.actionLike'),
            onClick: props.like
        }},
    (rowData:ApplicationJob) => {
        const row = rowData as ApplicationJob;
        return {
            icon: ThumbDownAlt,
            disabled: row.status.id === REJECT_APPLICATION_JOB || row.selectedForJob,                    
            tooltip: t('table.tooltip.actionReject'),
            onClick: props.reject
        }},
    {
        icon: Visibility,
        tooltip: t('table.tooltip.actionCandidateFile'),
        disabled: false,
        onClick: candidateData
    }           
]
          
return <div className='swissinx-container'>
        {!showCandidateData && <>
          <h2 className="swissinx__reload"> <div>
                <FontAwesomeIcon icon={faArrowLeft} onClick={props.back}/> 
                {t('admin.candidates.listTitle')}
              </div>              
              <Cached onClick={props.reload} titleAccess="Reload"/> 
          </h2>
          
          <h3>{props.jobTitle}</h3>            
              <Table<ApplicationJob> 
                data={state.candidates.data} 
                headers={headers}
                actions={actions} 
                initialPage={state.candidates.initialPage}
                serchText={state.candidates.searchText}
                pageSize={state.candidates.pageSize}              
                changePage={props.changePage}
                changeSearchText={props.changeSearchText}
                changeRowsPerPage={props.changeRowsPerPage}
                onOrderChange={props.onOrderChange}/>                
          </>}
          {showCandidateData && <CandidateData 
            data={candidate} 
            back={()=>setShowCandidateData(false)} 
            like={like} 
            reject={reject}
            changeSelected={changeSelected}
          />}
          {showModalConfirm && <ModalConfirmDelete 
            show={showModalConfirm} 
            title={index && index.selectedForJob ? t('admin.candidates.modal_confirm_deselected_title') : t('admin.candidates.modal_confirm_selected_title')} 
            back={()=>setShowModalConfirm(false)} 
            confirm={changeSelectedForJob} 
            textButtonBack={t('admin.candidates.modal_confirm_delete_back')} 
            textButtonConfirm={t('admin.candidates.modal_confirm_delete_confirm')} />}

          {state.candidates.selected.error && <ModalInfo show={state.candidates.selected.error} title={state.candidates.selected.messageError} confirm={closeModalCandidateSelected} textButtonConfirm={t('admin.jobs.modal_confirm_info')} />} 
        </div>; 
 
}

export default CandidatesList;