import TypeNetworkersRequestListState from '../../models/initialState/typeNetworkersRequestListState';
import { DEFAULTITEMSPERPAGE } from '../../utils/utils';

export const initialNetworkersRequestListState: TypeNetworkersRequestListState = {
    loading: false,
    error: false,
    data: [],    
    messageError: '',
    filters: {orderBy: 0, orderDirection: 'asc'},
    initialPage: 0,
    searchText: '',
    pageSize: DEFAULTITEMSPERPAGE,
    reset: false
}