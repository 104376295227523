import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Form from '../Form/Form/Form';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import { validateNumber } from '../Form/validators';
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
import TypeState from '../../models/initialState/typeState';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import { ActionLocationType, TypeActionLocationActions } from '../../models/actions/typeLocationActions';
import TypeLocationState from '../../models/initialState/typeLocationState';
import CompanyDto from '../../models/dto/CompanyDto';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getLabelFormatted, SNACBARPOSITION, SNACKBARTIMETOHIDE } from '../../utils/utils';
import { MAX_SIZE_FILE } from '../../constanst/GlobalConstants';
import AutocompleteSelect from '../autocompleteSelect/AutocompleteSelect'
import { CityDto, CountryDto, RegionDto } from "../../models/dto/LocationDto";

interface Props {
  back(): void,
  update(): void,
  add: boolean,
  company: CompanyDto | null
}

function CompanyEdit(props: Props) {
  const [country, setCountry] = useState<CountryDto | null>(null);
  const [region, setRegion] = useState<RegionDto | null>(null);
  const [city, setCity] = useState<CityDto | null>(null);
  const { t } = useTranslation();
  const state: TypeCompaniesState = useSelector((state: TypeState) => state.companiesReducer);
  const stateCompanyGroups: TypeCompanyGroupsState = useSelector((state: TypeState) => state.companyGroupsReducer);
  const stateLocation: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);
  const [openNotification, setOpenNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');
  const dispatch = useDispatch();

  const onChangeSelect = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: name, value: event.target.value, error: { required: false, format: false } } })
  }

  function onSubmit() {
    props.update();
  }

  useEffect(() => {
    setCountry(props.company?.country || null);
    setRegion(props.company?.region || null);
    setCity(props.company?.city || null);
    if (state.companyForm.countryId.value) {
      dispatch<TypeActionLocationActions>({ type: ActionLocationType.LOCATION_REGIONS_LOADING, value: parseInt(state.companyForm.countryId.value as string) });
    }
    if (state.companyForm.cityId.value) {
      dispatch<TypeActionLocationActions>({ type: ActionLocationType.LOCATION_CITIES_LOADING, value: parseInt(state.companyForm.regionId.value as string) });
    }
  }, []);

  useEffect(() => {
    if (state.image.error) {
      setMessageNotification(t('admin.company.errorUploadImage'));
      setOpenNotification(true);
      /* Quito el error del estado */
      dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_SUCCESS, value: true });
    }

  }, [state.image.error]);

  const changeCountry = (
    event: React.SyntheticEvent<Element, Event>,
    value: CountryDto | null,
    reason: any
  ) => {
    setCountry(value);
    setRegion(null);
    setCity(null);
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'countryId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'regionId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'cityId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActionLocationActions>({
      type: ActionLocationType.LOCATION_REGIONS_LOADING,
      value: value && value.id ? value.id : 0,
    });
  };

  const changeRegion = (
    event: React.SyntheticEvent<Element, Event>,
    value: RegionDto | null,
    reason: any
  ) => {
    setRegion(value);
    setCity(null);
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'regionId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'cityId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActionLocationActions>({ type: ActionLocationType.LOCATION_CITIES_LOADING, value: value ? value.id : 0 });
  };

  const changeCity = (
    event: React.SyntheticEvent<Element, Event>,
    value: CityDto | null,
    reason: any
  ) => {
    setCity(value);
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name: 'cityId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
  };

  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const image = event.currentTarget.files && event.currentTarget.files.length > 0 && event.currentTarget.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      if (image && reader.result) {
        const file = reader.result as string;
        const dataImage = {
          companyId: props.company && props.company.companyId ? props.company.companyId : 0,
          fileName: image.name,
          file: file.split(',')[1] as string,
          fileComplete: file,
          contentType: image.type
        }
        if (!props.add) {
          dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_LOADING, value: dataImage });
        } else {
          dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_SEND_IMAGE_VALUES, value: dataImage });
        }
      }
    }
    if (image && image.type.match(/image.*/) && image.size <= MAX_SIZE_FILE) {
      reader.readAsDataURL(image);
    } else {
      setOpenNotification(true);
      setMessageNotification(t('admin.company.errorFormatSize'));
    }
  }

  const back = () => {
    dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_SEND_IMAGE_VALUES, value: null });
    props.back();
  }

  return <div className='swissinx-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={back} />
      {props.add ? t('admin.company.createTitle') : t('admin.company.editTitle')}
    </h2>


    {<Form contex={state.companyForm} errorRequerired={(key) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_ERROR_REQUIRED, value: key })} onSubmit={onSubmit}
      fields={
        <div className="swissinx-container-fields-form">
          <div className="swissinx-container-fields">
            <div className='swissinx-img-container'>
              <label htmlFor='company-image'>
                <span>{t('admin.company.label_image')}</span>
                {state.image.data || (props.company && props.company.urlImage)
                  ? <img src={state.image.data ? state.image.data.fileComplete : (props.company && props.company.urlImage) ? props.company.urlImage : ''} alt="logo company"></img>
                  : <div className="swissinx-img">
                    {!state.image.loading && !state.image.error && <p>{t('admin.company.noImage')}</p>}
                    {state.image.loading && <p>{t('admin.company.uploading')}</p>}
                  </div>
                }
              </label>
              <input id="company-image" type='file' accept="image/*" onChange={uploadImage}></input>
            </div>
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-address">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_name')}
                  name='name'
                  required={state.companyForm.name.rules.required}
                  requiredError={t('admin.company.name_error_required')}
                  value={state.companyForm.name.value as string}
                  fullWidth={true}
                  error={state.companyForm.name.error.required || state.companyForm.name.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>

              <div className="swissinx-field field-notifications">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_notifications')}
                  name='notifications'
                  required={state.companyForm.notifications.rules.required}
                  requiredError={t('admin.company.notifications_error_required')}
                  value={state.companyForm.notifications.value as string}
                  fullWidth={true}
                  error={state.companyForm.notifications.error.required || state.companyForm.notifications.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>

            </div>
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-credit">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_credit')}
                  name='credit'
                  required={state.companyForm.credit.rules.required}
                  requiredError={t('admin.company.credit_error_required')}
                  value={state.companyForm.credit.value as string}
                  error={state.companyForm.credit.error.required || state.companyForm.credit.error.format}
                  fullWidth={true}
                  validators={[
                    { validator: validateNumber, messageError: t('admin.company.credit_error_format') }
                  ]}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}
                />
              </div>
            </div>
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-companyGroupId">
                <TextField
                  id="companyGroupId"
                  select={true}
                  label={getLabelFormatted(t('admin.company.label_companyGroup'),state.companyForm.companyGroupId.rules.required)}
                  value={state.companyForm.companyGroupId.value as string}
                  error={state.companyForm.companyGroupId.error.required}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSelect(event, 'companyGroupId')}
                  helperText={state.companyForm.companyGroupId.error.required && t('admin.jobs.client_error_required')}
                >
                  {stateCompanyGroups.data.map(companyGroup => <MenuItem key={companyGroup.companyGroupId} value={companyGroup.companyGroupId}>{companyGroup.name}</MenuItem>)
                  }
                </TextField>
              </div>
            </div>
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-address">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_address')}
                  name='address'
                  required={state.companyForm.name.rules.required}
                  requiredError={t('admin.company.address_error_required')}
                  value={state.companyForm.address.value as string}
                  fullWidth={true}
                  error={state.companyForm.address.error.required || state.companyForm.address.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>

              <div className="swissinx-field field-postalCode">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_postalCode')}
                  name='postalCode'
                  required={state.companyForm.postalCode.rules.required}
                  requiredError={t('admin.company.postalCode_error_required')}
                  value={state.companyForm.postalCode.value as string}
                  fullWidth={true}
                  error={state.companyForm.postalCode.error.required || state.companyForm.postalCode.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>

            </div>

            <div className='swissinx-field-container'>
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_country')}
                required={state.companyForm.cityId.rules.required}
                inputValue={country}
                options={stateLocation.countries.data}
                onChange={changeCountry}
                inputHelperText={
                  state.companyForm.countryId.error.required && t('admin.jobs.country_error_required')
                }
                inputError={state.companyForm.countryId.error.required}
              />
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_region')}
                required={state.companyForm.regionId.rules.required}
                inputValue={region}
                options={stateLocation.regions.data}
                onChange={changeRegion}
                inputHelperText={
                  state.companyForm.regionId.error.required && t('admin.jobs.region_error_required')
                }
                inputError={state.companyForm.regionId.error.required}
              />
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_city')}
                required={state.companyForm.cityId.rules.required}
                inputValue={city}
                options={stateLocation.cities.data}
                onChange={changeCity}
                inputHelperText={
                  state.companyForm.cityId.error.required && t('admin.jobs.city_error_required')
                }
                inputError={state.companyForm.cityId.error.required}
              />
            </div>
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-signature">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.company.label_signature')}
                  name='signature'
                  required={state.companyForm.signature.rules.required}
                  requiredError={t('admin.company.signature_error_required')}
                  value={state.companyForm.signature.value as string}
                  fullWidth={true}
                  error={state.companyForm.signature.error.required || state.companyForm.signature.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionCompanyType.COMPANY_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>
            </div>
          </div>
          <div className='swissinx-container-buttons'>
            <button className="swissinx-button-back" type="button" onClick={back}>{t('admin.company.text_button_back')}</button>
            <button className="swissinx-button-submit" type="submit">{props.add ? t('admin.company.text_button_submit_create') : t('admin.company.text_button_submit_edit')}</button>
          </div>
        </div>
      }>
    </Form>}
    <Snackbar anchorOrigin={SNACBARPOSITION} open={openNotification} autoHideDuration={SNACKBARTIMETOHIDE} onClose={() => setOpenNotification(false)}>
      <Alert onClose={() => setOpenNotification(false)} severity={'error'} sx={{ width: '100%' }} >
        {messageNotification}
      </Alert>
    </Snackbar>
  </div>
}

export default CompanyEdit;