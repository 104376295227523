import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChangeEvent, ClipboardEvent, useRef, useState } from 'react';

import './CheckSMSRegistration.scss';
import FiledError from '../Form/common/FieldError/FiledError';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import TypeState from '../../models/initialState/typeState';
import { APPLY_JOB } from '../../constanst/GlobalConstants';

interface Props {
  checkCode(code: string): void,
  backToSendSMSCode(): void,
  type: number
}

function CheckSMSRegistration(props: Props) {
  const [code, setCode] = useState(['', '', '', '']);
  const [errorRequired, setErrorRequired] = useState(false);

  const { t } = useTranslation();
  const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
  const refInput = useRef<(HTMLInputElement | null)[]>([]);

  const onChangeCode = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const charCode = event.target.value.charCodeAt(0);
    if ((charCode >= 48 && charCode <= 57) || event.target.value === '') {
      const cod = [...code];
      cod[index] = event.target.value;
      setCode([...cod]);
      refInput.current[index + 1]?.focus();
      if (errorRequired) {
        setErrorRequired(false);
      }
    }
  }

  const onCheckCode = () => {
    const codeJoin = code.join('');
    if (codeJoin.length === 4 && !state.activateAccount.loading) {
      props.checkCode(codeJoin);
    } else {
      setErrorRequired(true);
    }
  }

  const onPasteCode = (event: ClipboardEvent<HTMLInputElement>) => {
    const codePaste = event.clipboardData.getData('Text');
    if (parseInt(codePaste) && codePaste.length === 4) {
      const cod = [...code];
      let i = 0;
      while (i < 4) {
        cod[i] = codePaste[i];
        i++;
      }
      setCode([...cod]);
    }
  }
  const jobName = state.publicJob.data && state.publicJob.data.jobTitle ? state.publicJob.data.jobTitle : '';

  return <>
      <div>
        <h2 className='swissinx-job-container-column__title'>{props.type === APPLY_JOB ? jobName : t('publicJob.boarding')}</h2>
        {props.type === APPLY_JOB && <p className='swissinx-job-container-phone__subtitle'>{t('publicJob.subtitleGetSMSCode')}</p>}
        <p className='swissinx-job-container-column__description'>{t('publicJob.descriptionCheckSMSCode3')}</p>
        <div className='swissinx-job-container-code-SMS'>
          {code.map((cod, index) => <input name='' type="tel" ref={(ref) => refInput.current[index] = ref} key={index} maxLength={1} minLength={1} onPaste={(event) => onPasteCode(event)} onChange={(event) => onChangeCode(event, index)} value={cod} />)}
        </div>
      </div>
      <div className="swissinx-job-container-column__buttons">
        <p className='swissinx-job-container-column__description'>{t('publicJob.notRecived')} <span className='swissinx-job-container-column__description--link' onClick={props.backToSendSMSCode}>{t('publicJob.resendCode')}</span></p>
        <button className='public-job-button' type="button" onClick={onCheckCode} disabled={state.activateAccount.loading}>{state.activateAccount.loading ? t('publicJob.chekingSMSCode') : t('publicJob.checkMySMSCode')}</button>
        {state.activateAccount.error && <FiledError messageError={t('publicJob.SMSCodeError')} />}
        {state.resendActivationCode.error && <FiledError messageError={t('publicJob.resendActivationCodeError')} />}
        {errorRequired && <FiledError messageError={t('publicJob.checkCodeErrorRequired')} />}
      </div>
  </>
}

export default CheckSMSRegistration;