import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import logoDark from '../../assest/swapp_logo_dark.svg';
import newLinkInChain2 from '../../assest/home/NewLinkInChain2.png';
import downloadAppStore from '../../assest/home/DownloadAppStore.png';
import downloadGooglePlay from '../../assest/home/DownloadGooglePlay.png';
import { APPLY_JOB, REJECT_JOB } from '../../constanst/GlobalConstants';
import './PublicJobOfferDescription.scss';
import { convertDteIso } from '../../utils/utils';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { useMemo } from 'react';
import DownloadApp from './DownloadApp';
import { useNavigate } from 'react-router-dom';
import { ROUTE_HOME } from '../../router/routes'

interface Props {
    sendSMSRegistrationCode(type: number): void
}


function PublicJobOfferDescription(props: Props) {
    const { t } = useTranslation();
    const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
    const navigate = useNavigate();

    const description = useMemo(() => state.publicJob.data && state.publicJob.data.description ? state.publicJob.data.description.split('\n') : [], [state.publicJob.data])

    return <>{state.publicJob.data !== null && <div className='job-public-offer'>
        
        <div className='job-public-offer__container job-public-offer__container--main'>
            <p className='job-public-offer__title job-public-offer__title--main'>{state.publicJob.data.jobTitle}</p>
            <div className='job-public-offer__company-container'>
                {state.publicJob.data.companyImageUrl && <div className='job-public-offer__logo-company'><img className='job-public-offer__logo-company-img' src={state.publicJob.data.companyImageUrl} alt="logo company"></img></div>}
                <div>
                    <p className='job-public-offer__company-name'>{state.publicJob.data.companyName}</p>
                    <p className='job-public-offer__date'>{t('publicJob.posted')} {convertDteIso(state.publicJob.data.postedOnDate)}</p>
                </div>
            </div>
            <div className='job-public-offer__shares-container'>
                <div className='job-public-offer__shares'>
                    <div className='job-public-offer__shares-icon'></div>
                    <p className='job-public-offer__shares-data'><span className='job-public-offer__shares-data-strong'>{state.publicJob.data.timesHasBeenShared}</span> {t('publicJob.shares')}</p>
                </div>
                <div className='job-public-offer__fee'>
                    <p className='job-public-offer__shares-data'><span className='job-public-offer__shares-data-strong'>{state.publicJob.data.fee} {state.publicJob.data.feeCurrency}</span></p>
                    <p className='job-public-offer__shares-data'>{t('publicJob.total')}</p>
                </div>
            </div>
        </div>

        <div className='job-public-offer__container'>
            <p className='job-public-offer__title'>{t('publicJob.details')}</p>
            {state.publicJob.data.details.map((detail, index) => <div key={index} className='job-public-offer__detail'>
                <p className='job-public-offer__detail-title'>{detail.title}</p>
                <p className='job-public-offer__detail-description' >{detail.description.replaceAll(',', ', ')}</p>
            </div>)}
        </div>

        <div className='job-public-offer__skills-container'>
            <p className='job-public-offer__title'>{t('publicJob.skills')}</p>
            <div className='job-public-offer__skills-list'>
                {state.publicJob.data.skills.map(skill => <div key={skill} className='job-public-offer__skill'>
                    {skill}
                </div>)}
            </div>
        </div>

        <div className='public-job-buttons'>
            <button className='public-job-button' onClick={() => props.sendSMSRegistrationCode(APPLY_JOB)}>{t('publicJob.buttonPrimary')}</button>
            <button className='public-job-button' onClick={() => props.sendSMSRegistrationCode(2)}>{t('publicJob.createReferral')}</button>
            {/* <button className='public-job-button' disabled={state.rejectJob.loading} onClick={() => props.sendSMSRegistrationCode(REJECT_JOB)}>{t('publicJob.buttonSecondary')}</button> */}
        </div>

        <div className='job-public-offer__description-container'>
            <p className='job-public-offer__title'>{t('publicJob.description')}</p>
            {description?.map((line, index) => <p className='job-public-offer__description' key={index}>{line}</p>)}
        </div>

        <div className='public-job-buttons'>
            <button className='public-job-button' onClick={() => props.sendSMSRegistrationCode(APPLY_JOB)}>{t('publicJob.buttonPrimary')}</button>
            <button className='public-job-button' onClick={() => props.sendSMSRegistrationCode(2)}>{t('publicJob.createReferral')}</button>
        </div>

        <div className='job-public-offer__share-block'>
            <h2 className='job-public-offer__share-title'>{t('publicJob.shareTitle')}</h2>
            <h2 className='job-public-offer__share-subtitle'><div dangerouslySetInnerHTML={{__html: t('publicJob.shareSubTitle')}}></div><strong className='job-public-offer__strong'>{state.publicJob.data.fee} {state.publicJob.data.feeCurrency}</strong></h2>
            <img className='job-public-offer__share-icon' src={newLinkInChain2} alt="Share icon"></img>
            <p className='job-public-offer__share-description'><div dangerouslySetInnerHTML={{__html: t('publicJob.shareDescription')}}></div></p>
            <div className='public-job-buttons'>
                <button className='public-job-button' onClick={() => props.sendSMSRegistrationCode(2)}>{t('publicJob.createReferral')}</button>
                <a  href='/' target="_blank"><button className='public-job-button public-job-button__secondary'>{t('publicJob.buttonMore')}</button></a>
            </div>
        </div>

        <div className='job-public-offer__share-block'>
            <div className='job-public-offer__shares-container-block'>
                <div className='job-public-offer__number-shares'>
                    <div className='job-public-offer__number-shares-icon'></div>
                    <p><span>{state.publicJob.data.timesHasBeenShared}</span> {t('publicJob.shares')}</p>
                </div>
                <div className='job-public-offer__shares-fee'>
                    <p><span>{state.publicJob.data.fee} {state.publicJob.data.feeCurrency}</span></p>
                    <p>{t('publicJob.total')}</p>
                </div>
            </div>
        </div>

        <DownloadApp />

    </div>}
    </>
}

export default PublicJobOfferDescription;