import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Form from '../Form/Form/Form';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import { validateEmail, validatePassword, validatePhone } from '../Form/validators';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TypeActions } from '../../models/actions/typeActions';
import NetworkerForm from '../../models/forms/NetworkerForm';
import { ActionNetworkersType } from '../../models/actions/typeNetworkersActions';
import Flag from 'react-flagpack';
import { ActionLocationType, TypeActionLocationActions } from '../../models/actions/typeLocationActions';
import FiledError from '../Form/common/FieldError/FiledError';
import TypeLocationState from '../../models/initialState/typeLocationState';
import TypeState from '../../models/initialState/typeState';
import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import { Box, Chip, InputLabel, OutlinedInput } from '@mui/material';
import { skillAutocomplete } from '../../models/dto/SkillDto';
import MarketDto from '../../models/dto/MarketDto';
import LanguageDto from '../../models/dto/LanguageDto';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import { ActionSkillsType, TypeActionSkillsActions } from '../../models/actions/typeSkillsActions';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import { ActionMarketsType, TypeActionMarketsActions } from '../../models/actions/typeMarketsActions';
import { ActionLanguagesType, TypeActionLanguagesActions } from '../../models/actions/typeLanguagesActions';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import { getCountriesOptions, getDefaultCountries, getDefaultSkills, getLabelFormatted, getLabelLanguage, getLabelMarket, getSkillOptions } from '../../utils/utils';
import AutocompleteSelect from '../autocompleteSelect/AutocompleteSelect'
import { CityDto, CountryAutocomplete, CountryDto, RegionDto } from "../../models/dto/LocationDto";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import typeSkillGroupState from '../../models/initialState/typeSkillGroupState';
import { ActionSkillGroupType, TypeActionSkillGroupActions } from '../../models/actions/typeSkillGroupActions';
import ErrorFieldForm from '../../models/forms/ErrorFieldForm';
import { count } from 'console';

interface Props {
  back(): void,
  update(companyUpdate: NetworkerForm): void,
  add: boolean
}

function NetworkersEdit(props: Props) {
  const { t } = useTranslation();
  const [errorPassword, setErrorPassword] = useState(false);
  const state: TypeNetworkersState = useSelector((state: TypeState) => state.networkersReducer);
  const stateLocation: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);
  const stateSkillGroup: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);
  const stateSkills: TypeSkillsState = useSelector((state: TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state: TypeState) => state.marketsReducer);
  const stateLanguages: TypeLanguagesState = useSelector((state: TypeState) => state.languagesReducer);
  const [country, setCountry] = useState<CountryDto | null>(null);
  const [region, setRegion] = useState<RegionDto | null>(null);
  const [city, setCity] = useState<CityDto | null>(null);
  const [skill, setSkill] = useState<skillAutocomplete[] | undefined>(undefined);
  const [countryIds, setCountryIds] = useState<CountryAutocomplete[]>([])  
  const dispatch = useDispatch();
  const PREFIXNOZERO = ['+41', '+44'];

  const codesOut = useMemo(() => {
    //Estos codigos dan error, no muestro las banderas.
    return ['AN', 'ZR', 'GB'];
  }, []);

  useEffect(() => {
    //dispatch para recoger los datos de los prefijos
    if (stateLocation.countries.data.length === 0) {
      dispatch<TypeActions>({ type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: { reset: true } });
    }
    if (stateLanguages.data.length === 0) {
      dispatch<TypeActionLanguagesActions>({ type: ActionLanguagesType.LANGUAGES_LOADING, value: { reset: false } });
    }
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({ type: ActionMarketsType.MARKETS_LOADING, value: { reset: false } });
    }
    if (stateSkillGroup.data.length === 0) {
      dispatch<TypeActionSkillGroupActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: false}});
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({ type: ActionSkillsType.SKILLS_LOADING, value: { reset: false } });
    }

  }, []);

  useEffect(() => {
    if (state.extraFields.data && state.extraFields.data.countries) {     
      setCountryIds([...state.extraFields.data.countries]);
    }
  }, [state.extraFields.data]);
 
  const changePrefix = (event: SelectChangeEvent) => {
    const prefix = event.target.value;
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'prefix', value: prefix, error: { required: false, format: false } } })
    const phone = state.networkersForm.phone.value as string;
    if (PREFIXNOZERO.indexOf(prefix) !== -1 && phone && phone[0] === '0') {
      dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'phone', value: phone.slice(1), error: { required: false, format: false } } })
    } 
  }

  function onSubmit() {
    if (!errorPassword) {
      props.update(state.networkersForm);
    }
  }

  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    const value = event.target.value;
    let sendValue: number[];
    typeof value === 'string' ? sendValue = [parseInt(value)] : sendValue = value;
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: name, value: sendValue, error: { required: false, format: false } } });
  };

  const changeCountry = (
    event: React.SyntheticEvent<Element, Event>,
    value: CountryDto | null,
    reason: any
  ) => {
    setCountry(value);
    setRegion(null);
    setCity(null);
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'countryId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'regionId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'cityId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActionLocationActions>({ type: ActionLocationType.LOCATION_REGIONS_LOADING, value: value && value.id ? value.id : 0 });
  };

  const changeRegion = (
    event: React.SyntheticEvent<Element, Event>,
    value: RegionDto | null,
    reason: any
  ) => {
    setRegion(value);
    setCity(null);
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'regionId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'cityId', value: '', error: { required: false, format: false } } })
    dispatch<TypeActionLocationActions>({ type: ActionLocationType.LOCATION_CITIES_LOADING, value: value ? value.id : 0 });
  };

  const changeCity = (
    event: React.SyntheticEvent<Element, Event>,
    value: CityDto | null,
    reason: any
  ) => {
    setCity(value);
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'cityId', value: value && value.id ? value.id.toString() : "", error: { required: false, format: false } } })
  };

  const onChangeDate = (date: MaterialUiPickersDate, name: string) => {
    const newDate = date !== null ? date.toISOString() : null;
    const error = {
      required: false,
      format: false
    }
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: name, value: newDate, error } })
  }

  const minDateNetworker = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear() - 18;
    return new Date(`${month}-${day}-${year}`)
  }

  const changeSkill = (
    event: React.SyntheticEvent<Element, Event>,
    value: skillAutocomplete[] | null,
    reason: any
  ) => {
    let skillIds:number[]=[];
    if (value) {
      setSkill([...value]);
      skillIds = value.map(skill=>skill.id)
    }     
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'skillId', value: skillIds, error: { required: false, format: false } } });
  };
  

  const changeCountries = (
    event: React.SyntheticEvent<Element, Event>,
    value: CountryAutocomplete[] | null,
    reason: any
  ) => {    
    let countries:number[]=[];
    if (value) {
      setCountryIds([...value]);
      countries = value.map(country=>country.id)
    }     
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'countryIds', value: countries, error: { required: false, format: false } } });
  };

  const changePhone = (name: string, value: string | null, error: ErrorFieldForm) => {    
    let phone = value;
    if (PREFIXNOZERO.indexOf(state.networkersForm.prefix.value as string) !== -1 && value && value[0] === '0') {
      phone = value.slice(1);
    }    
    dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value: phone, error } })
  }

  const showSkills = stateSkillGroup.data && ((!props.add && state.extraFields.data) || (props.add));

  return <div className='swissinx-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
      {props.add ? t('admin.networkers.createTitle') : t('admin.networkers.editTitle')}
    </h2>
    {<Form contex={state.networkersForm} errorRequerired={(key) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_ERROR_REQUIRED, value: key })} onSubmit={onSubmit}
      fields={
        <div className="swissinx-container-fields-form">
          <div className="swissinx-container-fields">
            <div className='swissinx-field-container'>
              <div className="swissinx-field field-name">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.networkers.label_name')}
                  name='name'
                  required={state.networkersForm.name.rules.required}
                  requiredError={t('admin.networkers.name_error_required')}
                  value={state.networkersForm.name.value as string}
                  fullWidth={true}
                  error={state.networkersForm.name.error.required || state.networkersForm.name.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>
              <div className="swissinx-field field-surname">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.networkers.label_surname')}
                  name='surname'
                  required={state.networkersForm.surname.rules.required}
                  requiredError={t('admin.networkers.surname_error_required')}
                  value={state.networkersForm.surname.value as string}
                  fullWidth={true}
                  error={state.networkersForm.surname.error.required || state.networkersForm.surname.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}
                />
              </div>
            </div>
            
            <div className='swissinx-field-container'>
              {props.add && <div className="swissinx-field field-prefix">
                <FormControl fullWidth>
                  <Select
                    labelId="prefix"
                    id="prefix"
                    value={state.networkersForm.prefix.value as string}
                    onChange={changePrefix}
                  >
                    {stateLocation.countries.data.map(country => <MenuItem key={country.id} value={country.phoneCode}>
                      {country.phoneCode} {codesOut.indexOf(country.code) === -1 && <Flag code={country.code} size="m" gradient="real-linear" />} {country.code}</MenuItem>)}

                  </Select>
                </FormControl>
              </div>}
              <div className="swissinx-field field-phone">
                <FieldTextMui
                  label={t('admin.networkers.label_phone')}
                  placeholder=''
                  name='phone'
                  fullWidth={true}
                  disabled={!props.add}
                  required={state.networkersForm.phone.rules.required}
                  requiredError={t('admin.networkers.phone_error_required')}
                  value={state.networkersForm.phone.value as string}
                  error={state.networkersForm.phone.error.required || state.networkersForm.phone.error.format}
                  validators={[
                    { validator: validatePhone, messageError: t('admin.networkers.phone_error_format') }
                  ]}
                  onChange={({ name, value, error }) => changePhone(name, value, error)}
                />
              </div>
            </div>

            <div className='swissinx-field-container'>
              <div className="swissinx-field field-email">
                <FieldTextMui
                  label={t('admin.networkers.label_email')}
                  placeholder=''
                  name='email'
                  disabled={false}
                  fullWidth={true}
                  required={state.networkersForm.email.rules.required}
                  requiredError={t('admin.networkers.email_error_required')}
                  value={state.networkersForm.email.value as string}
                  error={state.networkersForm.email.error.required || state.networkersForm.email.error.format}
                  validators={[
                    { validator: validateEmail, messageError: t('admin.networkers.email_error_format') }
                  ]}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}
                />
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
              
                <div className="wissinx-field field-birthDay">
                  <KeyboardDatePicker
                    label={t('admin.networkers.label_birthDay')}
                    format="DD/MM/yyyy"
                    maxDate={minDateNetworker()}
                    value={state.networkersForm.birthDay.value === '' ? null : new Date(state.networkersForm.birthDay.value as string)}
                    onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'birthDay')}
                    inputVariant="outlined"
                    onError={(error, value) => {
                      if (error !== '' && !state.networkersForm.birthDay.error.format) {
                        const val = state.networkersForm.birthDay.value as string;
                        dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name: 'birthDay', value: val, error: { required: false, format: true } } })
                      }
                    }}
                  />
                </div>
             
            </MuiPickersUtilsProvider>
            </div>

            <div className='swissinx-field-container'>
              <div className="swissinx-field field-language">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="languageId">{t('admin.jobs.label_language')}</InputLabel>
                  <Select
                    labelId="languageId"
                    id="languageId"
                    label={t('admin.jobs.label_language')}
                    multiple
                    value={state.networkersForm.languageId.value as number[]}
                    onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'languageId')}
                    input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_language')} />}
                    error={state.networkersForm.languageId.error.required}
                    renderValue={(selected: number[]) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value: number) => (
                          <Chip key={value} label={getLabelLanguage(value, stateLanguages.data, stateLanguages.loading, stateLanguages.error)} />
                        ))}
                      </Box>
                    )}
                  >
                    {stateLanguages.data.map((language: LanguageDto) => (
                      <MenuItem
                        key={language.languageId}
                        value={language.languageId}
                      >
                        {language.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
             
                {showSkills &&  <div className="swissinx-field field-autocomplete-multiple">
                  <Autocomplete
                    multiple
                    id="skills"
                    sx={{ width: 300 }}
                    options={getSkillOptions(stateSkills.data, stateSkillGroup.data)}
                    getOptionLabel={(option) =>option.description}
                    value={skill}
                    isOptionEqualToValue={(option, value) => option.id === value.id}                           
                    defaultValue={getDefaultSkills(state.extraFields.data ? state.extraFields.data.skills :[], stateSkills.data, stateSkillGroup.data)}
                    onChange={changeSkill}
                    /* renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>                    
                        {option.description}
                      </Box>
                    )} */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabelFormatted(t("admin.jobs.label_skills"), state.networkersForm.skillId.rules.required)}  
                        error={state.networkersForm.skillId.error.required} 
                        helperText={state.networkersForm.skillId.error.required &&
                          t("admin.jobs.skills_error_required")}                  
                        /* inputProps={{
                          ...params.inputProps,
                          autoComplete: 'country', // disable autocomplete and autofill
                        }} */                              
                      />
                    )}
                  />   
                </div>}

              <div className="swissinx-field field-market">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="marketId">{t('admin.jobs.label_markets')}</InputLabel>
                  <Select
                    labelId="marketId"
                    id="marketId"
                    label={t('admin.jobs.label_markets')}
                    multiple
                    value={state.networkersForm.marketId.value as number[]}
                    onChange={(e) => handleChange(e as SelectChangeEvent<number[]>, 'marketId')}
                    input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_markets')} />}
                    error={state.networkersForm.marketId.error.required}
                    renderValue={(selected: number[]) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value: number) => (
                          <Chip key={value} label={getLabelMarket(value, stateMarkets.data, stateMarkets.loading, stateMarkets.error)} />
                        ))}
                      </Box>
                    )}
                  >
                    {stateMarkets.data.map((market: MarketDto) => (
                      <MenuItem
                        key={market.marketId}
                        value={market.marketId}

                      >
                        {market.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="swissinx-field-container field-autocomplete-multiple">                  
                  <Autocomplete
                    multiple
                    id="countryIds"
                    sx={{ width: 300 }}
                    options={getCountriesOptions(stateLocation.countries.data)}
                    getOptionLabel={(option)=>option.description}
                    value={countryIds}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={changeCountries}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabelFormatted(t("admin.jobs.label_countries"), state.networkersForm.countryIds.rules.required)}  
                        error={state.networkersForm.countryIds.error.required} 
                        helperText={state.networkersForm.countryIds.error.required &&
                          t("admin.jobs.countries_error_required")}
                      />
                    )}
                  />   
            </div>

            <div className='swissinx-field-container'>
              <div className="swissinx-field field-address">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.networkers.label_address')}
                  name='address'
                  required={state.networkersForm.address.rules.required}
                  requiredError={t('admin.networkers.address_error_required')}
                  value={state.networkersForm.address.value as string}
                  fullWidth={true}
                  error={state.networkersForm.address.error.required || state.networkersForm.address.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}

                />
              </div>

              <div className="swissinx-field field-postalCode">
                <FieldTextMui
                  placeholder=''
                  label={t('admin.networkers.label_postalCode')}
                  name='postalCode'
                  required={state.networkersForm.postalCode.rules.required}
                  requiredError={t('admin.networkers.postalCode_error_required')}
                  value={state.networkersForm.postalCode.value as string}
                  fullWidth={true}
                  error={state.networkersForm.postalCode.error.required || state.networkersForm.postalCode.error.format}
                  onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}
                />
              </div>
            </div>
            <div className='swissinx-field-container'>
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_country')}
                required={state.networkersForm.countryId.rules.required}
                inputValue={country}
                options={stateLocation.countries.data}
                onChange={changeCountry}
                inputHelperText={
                  state.networkersForm.countryId.error.required && t('admin.jobs.country_error_required')
                }
                inputError={state.networkersForm.countryId.error.required}
              />
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_region')}
                required={state.networkersForm.regionId.rules.required}
                inputValue={region}
                options={stateLocation.regions.data}
                onChange={changeRegion}
                inputHelperText={
                  state.networkersForm.regionId.error.required && t('admin.jobs.region_error_required')
                }
                inputError={state.networkersForm.regionId.error.required}
              />
              <AutocompleteSelect
                inputLabel={t('admin.jobs.label_city')}
                required={state.networkersForm.cityId.rules.required}
                inputValue={city}
                options={stateLocation.cities.data}
                onChange={changeCity}
                inputHelperText={
                  state.networkersForm.cityId.error.required && t('admin.jobs.city_error_required')
                }
                inputError={state.networkersForm.cityId.error.required}
              />
            </div>
            {props.add &&
              <div className='swissinx-field-container'>
                <div className="swissinx-field field-password">
                  <FieldTextMui
                    label={t('admin.networkers.label_password')}
                    placeholder=''
                    name='password'
                    fullWidth={true}
                    required={state.networkersForm.password.rules.required}
                    requiredError={t('admin.networkers.password_error_required')}
                    value={state.networkersForm.password.value as string}
                    error={state.networkersForm.password.error.required || state.networkersForm.password.error.format}
                    validators={[
                      { validator: validatePassword, messageError: t('page_login.password_error_format') }
                    ]}
                    onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })}
                  />
                </div>
              </div>}
            {props.add &&
              <div className='swissinx-field-container'>
                <div className="swissinx-field field-repeatPassword">
                  <FieldTextMui
                    label={t('admin.networkers.label_repeatPassword')}
                    placeholder=''
                    name='repeatPassword'
                    fullWidth={true}
                    required={state.networkersForm.repeatPassword.rules.required}
                    requiredError={t('admin.networkers.repeatPassword_error_required')}
                    value={state.networkersForm.repeatPassword.value as string}
                    error={state.networkersForm.repeatPassword.error.required || state.networkersForm.repeatPassword.error.format}
                    validators={[
                      { validator: validatePassword, messageError: t('page_login.password_error_format') }
                    ]}
                    onChange={({ name, value, error }) => {
                      setErrorPassword(value !== state.networkersForm.password.value);
                      return dispatch<TypeActions>({ type: ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES, value: { name, value, error } })
                    }
                    }
                  />
                  {errorPassword && <FiledError messageError={t('admin.networkers.passwordError')} />}
                </div>

              </div>}
          </div>
          <div className='swissinx-container-buttons'>
            <button className="swissinx-button-back" type="button" onClick={props.back}>{t('admin.networkers.text_button_back')}</button>
            <button className="swissinx-button-submit" type="submit">{props.add ? t('admin.networkers.text_button_submit_create') : t('admin.networkers.text_button_submit_edit')}</button>
          </div>
        </div>
      }>
    </Form>}
  </div>
}

export default NetworkersEdit;