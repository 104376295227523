import { useTranslation } from 'react-i18next';

import whatsapp from '../../assest/whatsapp.svg';
import mail from '../../assest/mail.svg';
import clipboard from '../../assest/clipboard.svg';
import { REFER_BY_CLIPBOARD, REFER_BY_MAIL, REFER_BY_WHATSAPP } from '../../constanst/GlobalConstants';

import './PublicJob.scss';

interface Props {
    sendSMSRegistrationCode(type: number): void
}

function PublicJobOfferRefer(props: Props) {  
  const { t } = useTranslation();  
    
  return <div className='swissinx-job-body-block'>
            <p className='swissinx-job__title'>{t('publicJob.referBy')}</p>
            <div className='swissinx-job-refer-container'>
                <div className='swissinx-job-refer__block' onClick={()=>props.sendSMSRegistrationCode(REFER_BY_WHATSAPP)}>
                    <img src={whatsapp} alt=""></img>
                    <p>{t('publicJob.whatsapp')}</p>
                </div>
                <div className='swissinx-job-refer__block' onClick={()=>props.sendSMSRegistrationCode(REFER_BY_MAIL)}>
                    <img src={mail} alt=""></img>
                    <p>{t('publicJob.email')}</p>
                </div>               
                <div className='swissinx-job-refer__block' onClick={()=>props.sendSMSRegistrationCode(REFER_BY_CLIPBOARD)}>
                    <img src={clipboard} alt=""></img>
                    <p>{t('publicJob.clipboard')}</p>
                </div>

            </div>
        </div>      
}

export default PublicJobOfferRefer;