import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SyntheticEvent, useEffect, useState } from 'react';

import { TypeActions } from '../../models/actions/typeActions';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';

import TemplateList from './TemplatesList';
import JobTemplatesDto, { Question } from '../../models/dto/JobTemplateDto';
import { ActionTemplateJobType } from '../../models/actions/typeTemplateJobActions';
import TemplateEdit from './TemplateEdit';
//import './Templates.scss';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Templates(props: Props) {
  const { t } = useTranslation();  
  //const state:TypeMainState = useSelector((state:TypeMainState) => state);
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const dispatch = useDispatch();
  const [templateSelected, setTemplateSelected] = useState<JobTemplatesDto | null>(null);
  const [editTemplate, setEditTemplate] = useState(false);
  const [showModalDeleteTemplate, setShowModalDeleteTemplate] = useState(false);
  const [addTemplate, setAddTemplate] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [nameTemplate, setNameTemplate] = useState('');
 
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});      
      setEditTemplate(false);
      props.setReload(false);  
    }
  }, [props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});
    }
  }, []);

  const edit = (event:SyntheticEvent, index:JobTemplatesDto) => {
    setTemplateSelected(index); 
    setNameTemplate(index.name);
    setQuestions(index.questions);
    setEditTemplate(true);
    setAddTemplate(false);
  }

  const removeConfirmation = (event: SyntheticEvent, index: JobTemplatesDto)=>{
    setTemplateSelected(index);   
    setShowModalDeleteTemplate(true);
  };

  const deleteTemplate = () => {
    setShowModalDeleteTemplate(false);
    if (templateSelected && templateSelected.templateId) {
      dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_LOADING, value: templateSelected.templateId});
    }    
  }

  const newRegister = () => {    
    setQuestions([]);
    setNameTemplate(''); 
    setEditTemplate(true);
    setAddTemplate(true);
  }

  const update = () => {
    const data:JobTemplatesDto = {
      templateId: undefined,
      name: nameTemplate,
      questions: questions
    } 
    if (addTemplate) {      
      dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ADD_LOADING, value: data});
    } else if (templateSelected && templateSelected.templateId){
      data.templateId = templateSelected.templateId
      dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_LOADING, value: data});
    }
    setEditTemplate(false);
  }
 
  const back = () => setEditTemplate(false);

  const onError = () => {    
    dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_LOADING});
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }


  return <>
      {state.templates.loading && !state.templates.error && <Loading />}
      {!state.templates.loading && !state.templates.error && !editTemplate && <TemplateList 
                                                                                edit={edit} 
                                                                                remove={removeConfirmation} 
                                                                                newRegister={newRegister}
                                                                                changePage={changePage}
                                                                                changeSearchText={changeSearchText}
                                                                                changeRowsPerPage={changeRowsPerPage}
                                                                                onOrderChange={onOrderChange}/>}
      {!state.templates.loading && !state.templates.error && editTemplate && <TemplateEdit back={back} update={update} add={addTemplate} name={nameTemplate} setName={setNameTemplate} data={questions} setData={setQuestions}/>}
      {showModalDeleteTemplate && <ModalConfirmDelete show={showModalDeleteTemplate} title={t('admin.templates.modal_confirm_delete_title')} back={()=>setShowModalDeleteTemplate(false)} confirm={deleteTemplate} textButtonBack={t('admin.templates.modal_confirm_delete_back')} textButtonConfirm={t('admin.templates.modal_confirm_delete_confirm')} />}

      { state.templates.error && <ComponentError 
                              title={t('admin.templates.error_title')} 
                              message={''} 
                              buttonMessage={t('admin.templates.error_button')} 
                              onClick={onError} />}
      <Notification
              edit={state.templates.edit}
              add={state.templates.add}
              delete={state.templates.delete}
              editMessage={t('admin.templates.editMessage')}
              addMessage={t('admin.templates.addMessage')}
              delteMessage={t('admin.templates.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_SUCCESS, value: false})}/> 
  </>
}

export default Templates;