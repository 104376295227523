import ActivateAccountResponseDto from '../dto/ActivateAccountResponseDto';
import LightSignupDto from '../dto/LightSignupDto';
import LightSignupResponseDto from '../dto/LightSignupResponseDto';
import LoginDto from '../dto/LoginDto';
import LoginResponseDto from '../dto/LoginResponseDto';

export enum ActionLoginType {
    
    LOGIN_FORM_SEND_VALUES = 'LOGIN_FORM_SEND_VALUES',
    LOGIN_FORM_ERROR_REQUIRED = 'LOGIN_FORM_ERROR_REQUIRED',
    LOGIN_SERVER_SEND_DATA = 'LOGIN_SEND_DATA',
    LOGIN_SERVER_SUCCESS = 'LOGIN_SERVER_SUCCESS',
    LOGIN_SERVER_ERROR = 'LOGIN_SERVER_ERROR',
    LOGIN_RENEWTOKEN_VALUE = 'LOGIN_RENEWTOKEN_VALUE',
    LOGIN_RENEWTOKEN_LOADING = 'LOGIN_RENEWTOKEN_LOADING',

    LIGHTSIGNUP_LOADING = 'LIGHTSIGNUP_LOADING',
    LIGHTSIGNUP_SUCCESS = 'LIGHTSIGNUP_SUCCESS',
    LIGHTSIGNUP_ERROR = 'LIGHTSIGNUP_ERROR',

    ACTIVATE_ACCOUNT_LOADING = 'ACTIVATE_ACCOUNT_LOADING',
    ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS',
    ACTIVATE_ACCOUNT_ERROR = 'ACTIVATE_ACCOUNT_ERROR',

    RESEND_ACTIVATION_CODE_LOADING = 'RESEND_ACTIVATION_CODE_LOADING',
    RESEND_ACTIVATION_CODE_SUCCESS = 'RESEND_ACTIVATION_CODE_SUCCESS',
    RESEND_ACTIVATION_CODE_ERROR = 'RESEND_ACTIVATION_CODE_ERROR',
    
    RECOVER_ACCOUNT_PASSWORD_LOADING = 'RECOVER_ACCOUNT_PASSWORD_LOADING',
    RECOVER_ACCOUNT_PASSWORD_SUCCESS = 'RECOVER_ACCOUNT_PASSWORD_SUCCESS',    
    RECOVER_ACCOUNT_PASSWORD_ERROR = 'RECOVER_ACCOUNT_PASSWORD_ERROR',
    ACTION_LOOUT = 'ACTION_LOOUT'
    
}

export enum KeysLoginForm {
    login = 'username',
    password = 'password'
}

interface actionLoginFormSendValues {
    type: ActionLoginType.LOGIN_FORM_SEND_VALUES,
    value: {
        name: KeysLoginForm,
        value: string,
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionLoginFormErrorRequired {
    type: ActionLoginType.LOGIN_FORM_ERROR_REQUIRED,
    value: string
}

export interface actionLoginServerSendData {
    type: ActionLoginType.LOGIN_SERVER_SEND_DATA,
    value: LoginDto
}
export interface actionLoginServerSuccess {
    type: ActionLoginType.LOGIN_SERVER_SUCCESS,
    value: LoginResponseDto
}
export interface actionLoginServerError {
    type: ActionLoginType.LOGIN_SERVER_ERROR,
    value: string 
}

export interface actionLogout {
    type: ActionLoginType.ACTION_LOOUT   
}

export interface actionRenewTokenValue {
    type: ActionLoginType.LOGIN_RENEWTOKEN_VALUE,
    value: boolean   
}

export interface actionRenewTokenLoading {
    type: ActionLoginType.LOGIN_RENEWTOKEN_LOADING   
}

export interface actionLightSignupLoading {
    type: ActionLoginType.LIGHTSIGNUP_LOADING,
    value: LightSignupDto
}
export interface actionLightSignupSuccess {
    type: ActionLoginType.LIGHTSIGNUP_SUCCESS,
    value: {data: LightSignupResponseDto,
            prefix: string,
            phone: string}
}
export interface actionLightSignupError {
    type: ActionLoginType.LIGHTSIGNUP_ERROR,
    value: {error: boolean, alreadyExist: boolean}
}

export interface actionActivateAccountLoading {
    type: ActionLoginType.ACTIVATE_ACCOUNT_LOADING,
    value: {
        phone: string,
        activationCode: string
    }     
}
export interface actionActivateAccountSuccess {
    type: ActionLoginType.ACTIVATE_ACCOUNT_SUCCESS
    value: ActivateAccountResponseDto
}
export interface actionActivateAccountError {
    type: ActionLoginType.ACTIVATE_ACCOUNT_ERROR    
}

export interface actionResendActivationCodeLoading {
    type: ActionLoginType.RESEND_ACTIVATION_CODE_LOADING,
    value: {phone: string}
}
export interface actionResendActivationCodeSuccess {
    type: ActionLoginType.RESEND_ACTIVATION_CODE_SUCCESS    
}
export interface actionResendActivationCodeError {
    type: ActionLoginType.RESEND_ACTIVATION_CODE_ERROR   
}

export interface actionRecoverAccountPasswordLoading {
    type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_LOADING,
    value: {phoneOrEmail: string}
}
export interface actionRecoverAccountPasswordSuccess {
    type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_SUCCESS    
}
export interface actionRecoverAccountPasswordError {
    type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_ERROR   
}

export type TypeLoginActions =  actionLogout | 
                                actionLoginFormSendValues | actionLoginFormErrorRequired |
                                actionLoginServerSendData | actionLoginServerSuccess | actionLoginServerError |
                                actionRenewTokenValue | actionRenewTokenLoading |
                                actionLightSignupLoading | actionLightSignupSuccess | actionLightSignupError |
                                actionActivateAccountLoading | actionActivateAccountSuccess | actionActivateAccountError |
                                actionResendActivationCodeLoading | actionResendActivationCodeSuccess | actionResendActivationCodeError |
                                actionRecoverAccountPasswordLoading | actionRecoverAccountPasswordSuccess | actionRecoverAccountPasswordError;
                                