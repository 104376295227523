import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import { DEFAULTITEMSPERPAGE, newFormNetworker } from '../../utils/utils';



export const initialNetworkersState: TypeNetworkersState = {
    loading: false,
    error: false,
    networkersData: [],
    networkersForm: newFormNetworker(),
    messageError: '',
    filters: {orderBy: 2, orderDirection: 'asc'},
    initialPage: 0,
    searchText: '',
    pageSize: DEFAULTITEMSPERPAGE,
    edit: false,
    add: false,
    delete: false,
    rewards: {
        data: {
            rewards: [],
            totalRewards: 0,
            totalRewardCurrency: []
          },
        loading: false,
        error: false,
        years: [],
        yearSelected: 0,
        downloadPdfLoading: false,
        downloadPdfSuccess: false,
        downloadPdfError: false,
    },
    extraFields: {
      data: null,
      loading: false,
      error: false
    },
    anonymizer: {
      loading: false,
      ok: false,
      error: false,
      textError: ''
    },
    performanceReport: {
      loading: false,
      ok: false,
      error: false,
      textError: ''
    }
}