import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Form from "../Form/Form/Form";
import {
  ActionJobsType,
  TypeActionJobsActions,
} from "../../models/actions/typeJobsActions";
import TextField from "@mui/material/TextField";
import FieldTextMui from "../Form/FieldTextMui/FieldTextMui";
import { validateNumber, validateSalaryMax } from "../Form/validators";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Delete from "@material-ui/icons/Delete";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUsers } from "@fortawesome/free-solid-svg-icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import PushJobForm from "../../models/forms/PushJobForm";
import { ChangeEvent, SyntheticEvent, useEffect, useState, useMemo } from "react";
import { TypeActions } from "../../models/actions/typeActions";
import {
  ActionAmbasadorsType,
  TypeAmbasadorsActions,
} from "../../models/actions/typeAmbassadorActions";
import { getLabelFormatted, listCurrency } from "../../utils/utils";
import TypeJobsState from "../../models/initialState/typeJobsState";
import TypeAmbassadorsState from "../../models/initialState/typeAmbassadorsState";
import TypeState from "../../models/initialState/typeState";
import JobsResponseDto from "../../models/dto/JobsResponseDto";
import Table from "../table/Table";
import AmbassadorDto from "../../models/dto/AmbassadorDto";
import TypeLocationState from "../../models/initialState/typeLocationState";
import JobsPushFilterAmbassadors from "./JobsPushFilterAmbassadors";
import ModalInfo from "../commons/ModalInfo";
import TypeFeeState from "../../models/initialState/typeFeeState";

interface Props {
  jobSelected: JobsResponseDto | null;
  sendPush(pushJobForm: PushJobForm): void;
  back(): void;
}

function JobsPush(props: Props) {
  const [showAlert, setShowAlert] = useState(false);
  const [showNetworkers, setShowNetworkers] = useState(false);
  const [insidersListFiltered, setInsidersListFiltered] = useState<
    AmbassadorDto[]
  >([]);
  const [feeTemplate, setFeeTemplate] = useState("");
  const { t } = useTranslation();
  const state: TypeJobsState = useSelector(
    (state: TypeState) => state.jobsReducer
  );
  const stateAmbassadors: TypeAmbassadorsState = useSelector(
    (state: TypeState) => state.ambassadorsReducer
  );
  const stateLocation: TypeLocationState = useSelector(
    (state: TypeState) => state.locationReducer
  );
  const stateFee: TypeFeeState = useSelector(
    (state: TypeState) => state.feeReducer
  );
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (stateAmbassadors.listAmbassadorsFiltered.length > 0) {
      props.sendPush(state.pushJobForm);
    } else {
      setShowAlert(true);
    }
  };

  const dataFeeTemplate = (
    feeCurrency: string,
    veryHighFee: string,
    highFee: string,
    lowFee: string,
    timeToEmbassadors: string,
    timeToNetwork: string
  ) => {
    const error = { required: false, format: false };
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "feeCurrency", value: feeCurrency, error },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "veryHighFee", value: veryHighFee, error },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "highFee", value: highFee, error },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "lowFee", value: lowFee, error },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "timeToEmbassadors", value: timeToEmbassadors, error },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: "timeToNetwork", value: timeToNetwork, error },
    });
  };

  const onChangeFee = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    const feeSelected = stateFee.pushListFee.data.find(
      (fee) => fee.id === value
    );
    if (feeSelected) {
      dataFeeTemplate(
        feeSelected.feeCurrency,
        feeSelected.veryHighFee.toString(),
        feeSelected.highFee.toString(),
        feeSelected.lowFee.toString(),
        feeSelected.timeToEmbassadors.toString(),
        feeSelected.timeToNetwork.toString()
      );
    } else {
      dataFeeTemplate("", "", "", "", "", "");
    }
    setFeeTemplate(event.target.value);
  };

  const onChangeDate = (date: MaterialUiPickersDate, name: string) => {
    const newDate =
      date !== null ? date.toISOString() : new Date().toISOString();
    const error = {
      required: false,
      format: false,
    };
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name: name, value: newDate, error },
    });
  };

  const onChangeSelect = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const value = event.target.value;
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: {
        name: name,
        value: event.target.value,
        error: { required: false, format: false },
      },
    });

    if (name === "mode" && value === "1" && state.jobSelected.data) {
      const markets = state.jobSelected.data.markets.map((market) => market.id);
      const skills = state.jobSelected.data.skills.map((skill) => skill.id);
      const languages = state.jobSelected.data.languages.map(
        (language) => language.id
      );
      dispatch<TypeAmbasadorsActions>({
        type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING,
        value: {
          companyId: state.jobSelected.data.companyId,
          jobId: state.jobSelected.data.jobId,
          skillIds: skills,
          marketIds: markets,
          languageIds: languages,
          includeNetworkers: showNetworkers,
        },
      });
    } else if (name === "mode" && value === "0") {
      dispatch<TypeActions>({
        type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_SUCCESS,
        value: [],
      });
      //dispatch<TypeActions>({type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED, value: []});
    }
  };

  const onChangeTime = (name: string, value: string, error: any) => {
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: { name, value, error },
    });
    const endDatePush = new Date(state.pushJobForm.endDateTime.value as string);
    let maxValue = 0;
    if (!error.requerired && !error.format) {
      if (
        name === "timeToNetwork" &&
        state.pushJobForm.timeToEmbassadors.value
      ) {
        if (
          parseFloat(value) >=
          parseFloat(state.pushJobForm.timeToEmbassadors.value as string)
        ) {
          maxValue = parseFloat(value);
        } else {
          maxValue = parseFloat(
            state.pushJobForm.timeToEmbassadors.value as string
          );
        }
      }

      if (
        name === "timeToEmbassadors" &&
        state.pushJobForm.timeToNetwork.value
      ) {
        if (
          parseFloat(value) >=
          parseFloat(state.pushJobForm.timeToNetwork.value as string)
        ) {
          maxValue = parseFloat(value);
        } else {
          maxValue = parseFloat(
            state.pushJobForm.timeToNetwork.value as string
          );
        }
      }
      const now = new Date();
      const minDatePush = now.getTime() + maxValue * 60 * 60 * 1000 + 3600000;
      if (endDatePush.getTime() < minDatePush) {
        const minDatePusDate = new Date(minDatePush);
        dispatch<TypeActionJobsActions>({
          type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
          value: {
            name: "endDateTime",
            value: minDatePusDate.toISOString(),
            error: {
              required: false,
              format: false,
            },
          },
        });
      }
    }
  };
  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a the stringified value.
    let sendValue: number[];
    typeof value === "string"
      ? (sendValue = [parseInt(value)])
      : (sendValue = value);

    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
      value: {
        name: name,
        value: sendValue as number[],
        error: { required: false, format: false },
      },
    });
  };

  const sendDataFilter = (showNetworkersValue: boolean) => {
    dispatch<TypeAmbasadorsActions>({
      type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_LOADING,
      value: {
        search: stateAmbassadors.pushFilter.search,
        marketIds: stateAmbassadors.pushFilter.markets.slice(),
        skillIds: stateAmbassadors.pushFilter.skills.slice(),
        languageIds: stateAmbassadors.pushFilter.languages.slice(),
        countryIds: stateAmbassadors.pushFilter.countries.slice(),
        companyId: state.jobSelected.data
          ? state.jobSelected.data.companyId
          : 0,
        jobId: state.jobSelected.data ? state.jobSelected.data.jobId : 0,
        includeNetworkers: showNetworkersValue,
      },
    });
  };

  const onSelectionChange = (data: AmbassadorDto[]) => {
    //dispatch<TypeAmbasadorsActions>({type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED, value: data});
    setInsidersListFiltered(data);
  };

  const getLabelCountriesToExclude = (value: number): string => {
    let label = "";

    if (stateLocation.countries.loading) {
      label = "Loading";
    } else if (stateLocation.countries.error) {
      label = "Error";
    } else {
      const labelAux = stateLocation.countries.data.find(
        (country) => country.id === value
      );
      if (labelAux) {
        label = labelAux.description;
      }
    }
    return label;
  };

  const sendDataPush = () => {
    dispatch<TypeAmbasadorsActions>({
      type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED_ADD,
      value: insidersListFiltered.filter(elem => !elem.alreadyPushesThisJob).map((elem) => ({ ...elem, tableData: null })),
    });
  };

  const deleteDataPush = (event: SyntheticEvent, index: AmbassadorDto[]) => {
    let data = stateAmbassadors.listAmbassadorsFiltered.slice();
    index.forEach((elem) => {
      data = data.filter(
        (ambassador) => ambassador.ambassadorId !== elem.ambassadorId
      );
    });
    dispatch<TypeAmbasadorsActions>({
      type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED,
      value: data,
    });
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNetworkers(event.target.checked);
    sendDataFilter(event.target.checked)
  };

  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_SUCCESS,
      value: [],
    });
    dispatch<TypeActions>({
      type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED,
      value: [],
    });
    dispatch<TypeActions>({
      type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_RESET,
    });
  }, []);

  useEffect(() => {
    if (state.jobSelected.data) {
      const countriesToExclude =
        state.jobSelected.data.jobCountryExceptions.map(
          (country) => country.id
        );
      dispatch<TypeActionJobsActions>({
        type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
        value: {
          name: "countryExceptions",
          value: countriesToExclude,
          error: { required: false, format: false },
        },
      });
    }
  }, [state.jobSelected.data]);

  const tableHeader = [
    {
      title: t("admin.jobs.label_name"),
      field: "name",
      render: (rowData: AmbassadorDto) => rowData.isInsider ? (<span>{rowData.name}</span>) : (
        <div className="swissinx-table-row-with-icon">
          <div title={t("admin.jobs.networkerTooltip")} className="swissinx-table-row-icon-container">
            <FontAwesomeIcon size="xs" icon={faUsers} />
          </div>
          <span>{rowData.name}</span>
        </div>
      ),
    },
    { title: t("admin.jobs.label_surname"), field: "surname" },
    { title: t("admin.jobs.label_match"), field: "match" },
  ];

  const calPageSize = useMemo(()=>(size: number)=>{
    let tableSize = 5;
    if (size >=20) {
      tableSize = 20;
    } else if (size >= 10) {
      tableSize = 10;
    }
    return tableSize;
  }, [stateAmbassadors.automaticList.data])

  return (
    <div className="swissinx-container">
      <h2>
        <FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
        {t("admin.jobs.pushTitle")}
      </h2>
      <h3>{props.jobSelected ? props.jobSelected.title : ""}</h3>

      {
        <Form
          contex={state.pushJobForm}
          errorRequerired={(key) =>
            dispatch<TypeActionJobsActions>({
              type: ActionJobsType.JOBS_PUSH_ERROR_REQUIRED,
              value: key,
            })
          }
          onSubmit={onSubmit}
          fields={
            <div className="swissinx-container-fields-form">
              <div className="swissinx-container-fields">
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-push-feeCurrency">
                    <TextField
                      id="feeListTemplate"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_feeListTemplate"), false)}
                      value={feeTemplate}
                      error={false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeFee(event)
                      }
                      helperText={
                        stateFee.pushListFee.loading
                          ? t("admin.jobs.loading")
                          : stateFee.pushListFee.error
                            ? t("admin.jobs.error")
                            : stateFee.pushListFee.data.length === 0
                              ? t("admin.jobs.noTemplatesAvailable")
                              : ""
                      }
                    >
                      <MenuItem key={0} value={0}>
                        {t("admin.jobs.label_feeListTemplate")}
                      </MenuItem>
                      {stateFee.pushListFee.data.map((fee) => (
                        <MenuItem key={fee.id} value={fee.id}>
                          {fee.description}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-push-feeCurrency">
                    <TextField
                      id="feeCurrency"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_feeCurrency"), state.pushJobForm.feeCurrency.rules.required)}
                      value={state.pushJobForm.feeCurrency.value as string}
                      error={state.pushJobForm.feeCurrency.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "feeCurrency")
                      }
                      helperText={state.pushJobForm.feeCurrency.error.required && t("admin.jobs.currency_error_required")}
                    >
                      {listCurrency().map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>                  
                </div>

                <div className="swissinx-field-container">                 
                  <div className="swissinx-field field-push-veryHighFee">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_veryHighFee")}
                      name="veryHighFee"
                      required={state.pushJobForm.veryHighFee.rules.required}
                      requiredError={t("admin.jobs.veryHighFee_error_required")}
                      value={state.pushJobForm.veryHighFee.value as string}
                      fullWidth={true}
                      error={
                        state.pushJobForm.veryHighFee.error.required ||
                        state.pushJobForm.veryHighFee.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              value,
                              state.pushJobForm.highFee.value as string
                            ),
                          messageError: t("admin.jobs.veryHighFee_error_value"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
                          value: { name, value, error },
                        })
                      }
                    />
                  </div>
                  <div className="swissinx-field field-push-highFee">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_highFee")}
                      name="highFee"
                      required={state.pushJobForm.highFee.rules.required}
                      requiredError={t("admin.jobs.highFee_error_required")}
                      value={state.pushJobForm.highFee.value as string}
                      fullWidth={true}
                      error={
                        state.pushJobForm.highFee.error.required ||
                        state.pushJobForm.highFee.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              state.pushJobForm.veryHighFee.value as string,
                              value
                            ),
                          messageError: t("admin.jobs.highFee_error_value"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
                          value: { name, value, error },
                        })
                      }
                    />
                  </div>
                  <div className="swissinx-field field-push-lowFee">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_lowFee")}
                      name="lowFee"
                      required={state.pushJobForm.lowFee.rules.required}
                      requiredError={t("admin.jobs.lowFee_error_required")}
                      value={state.pushJobForm.lowFee.value as string}
                      fullWidth={true}
                      error={
                        state.pushJobForm.lowFee.error.required ||
                        state.pushJobForm.lowFee.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              state.pushJobForm.highFee.value as string,
                              value
                            ),
                          messageError: t("admin.jobs.lowFee_error_value"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
                          value: { name: name as string, value, error },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="swissinx-field-container">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    {/* <div className="swissinx-field field-push-createdDate">
                                <KeyboardDatePicker
                                    label={t('admin.jobs.label_createdDate')}
                                    disablePast
                                    disabled={true} 
                                    format="DD/MM/yyyy"
                                    value={new Date(state.pushJobForm.createdDate.value as string)}
                                    onChange={(date: MaterialUiPickersDate) => onChangeDate(date, 'createdDate')}
                                    onError={(error, value)=>{
                                        if (error !== '' && !state.pushJobForm.createdDate.error.format) {
                                            const val = state.pushJobForm.createdDate.value as string;
                                            dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES, value: {name: 'createdDate' , value: val, error: {required: false, format: true}}})
                                        }
                                    }}                                                             
                                />
                        </div> */}
                    <div className="swissinx-field field-push-endDateTime">
                      <KeyboardDateTimePicker
                        ampm={false}
                        disablePast
                        label={getLabelFormatted(t("admin.jobs.label_endDateTime"), state.pushJobForm.endDateTime.rules.required)}
                        format="DD/MM/yyyy HH:mm"
                        inputVariant="outlined"
                        value={
                          new Date(state.pushJobForm.endDateTime.value as string)
                        }
                        onChange={(date: MaterialUiPickersDate) =>
                          onChangeDate(date, "endDateTime")
                        }
                        onError={(error, value) => {
                          if (
                            error !== "" &&
                            !state.pushJobForm.endDateTime.error.format
                          ) {
                            const val = state.pushJobForm.endDateTime
                              .value as string;
                            dispatch<TypeActionJobsActions>({
                              type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
                              value: {
                                name: "endDateTime",
                                value: val,
                                error: { required: false, format: true },
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-push-timeToEmbassadors">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_timeToEmbassadors")}
                      name="timeToEmbassadors"
                      required={
                        state.pushJobForm.timeToEmbassadors.rules.required
                      }
                      requiredError={t(
                        "admin.jobs.timeToEmbassadors_error_required"
                      )}
                      value={state.pushJobForm.timeToEmbassadors.value as string}
                      fullWidth={true}
                      error={
                        state.pushJobForm.timeToEmbassadors.error.required ||
                        state.pushJobForm.timeToEmbassadors.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              state.pushJobForm.timeToNetwork.value as string,
                              value,
                              
                            ),
                          messageError: t("admin.jobs.timeToEmbassadors_error_value"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        onChangeTime(name, value, error)
                      }
                    />
                  </div>
                  <div className="swissinx-field field-push-timeToNetwork">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_timeToNetwork")}
                      name="timeToNetwork"
                      required={state.pushJobForm.timeToNetwork.rules.required}
                      requiredError={t("admin.jobs.timeToNetwork_error_required")}
                      value={state.pushJobForm.timeToNetwork.value as string}
                      fullWidth={true}
                      error={
                        state.pushJobForm.timeToNetwork.error.required ||
                        state.pushJobForm.timeToNetwork.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax( 
                              value,
                              state.pushJobForm.timeToEmbassadors.value as string,
                            ),
                          messageError: t("admin.jobs.timeToNetwork_error_value"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        onChangeTime(name, value, error)
                      }
                    />
                  </div>
                </div>

                {/*  <div className='swissinx-field-container'>
                        <div className="swissinx-field field-push-autoreply">
                        <FormControlLabel control={<Checkbox 
                                                    checked={state.pushJobForm.autoreply.value as boolean} 
                                                    onChange={(event)=>dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES, value: {name: 'autoreply', value: event.target.checked as boolean, error: {required: false, format: false}}})} />} 
                                                    label={t('admin.jobs.label_autoreply') as string} />
                        </div>
                    </div> */}

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-push-timeToEmbassadors">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_forwardTimeUsedLimit")}
                      name="forwardTimeUsedLimit"
                      required={
                        state.pushJobForm.forwardTimeUsedLimit.rules.required
                      }
                      requiredError={t(
                        "admin.jobs.forwardTimeUsedLimit_error_required"
                      )}
                      value={
                        state.pushJobForm.forwardTimeUsedLimit.value as string
                      }
                      fullWidth={true}
                      error={
                        state.pushJobForm.forwardTimeUsedLimit.error.required ||
                        state.pushJobForm.forwardTimeUsedLimit.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.error_format"),
                        },
                      ]}
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_PUSH_FORM_SEND_VALUES,
                          value: { name, value, error },
                        })
                      }
                    />
                  </div>
                </div>

                {/* <div className='swissinx-field-container'>
                        <div className="swissinx-field field-push-status">
                            <TextField
                                id="status"
                                select={true}
                                label={t('admin.jobs.label_status')}
                                value={state.pushJobForm.status.value as string}
                                error={state.pushJobForm.status.error.required}                    
                                onChange={(event:ChangeEvent<HTMLInputElement>)=>onChangeSelect(event, 'status')}
                                helperText={state.pushJobForm.status.error.required && t('admin.jobs.status_error_required')}                            
                                >
                                {listStatus().map(status => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}                                
                            </TextField>     
                        </div>
                    </div> */}

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-countryExceptionIds">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="countriesToExclude">                      
                        {getLabelFormatted(t("admin.jobs.label_countryExceptionIds"), state.pushJobForm.countryExceptions.rules.required)}
                      </InputLabel>
                      <Select
                        labelId="countryExceptions"
                        id="countryExceptions"
                        label={t("admin.jobs.label_countryExceptionIds")}
                        multiple
                        value={
                          state.pushJobForm.countryExceptions.value as number[]
                        }
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent<number[]>,
                            "countryExceptions"
                          )
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label={t("admin.jobs.label_countryExceptionIds")}
                          />
                        }
                        error={state.pushJobForm.countryExceptions.error.required}
                        renderValue={(selected: number[]) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: number) => (
                              <Chip
                                key={value}
                                label={getLabelCountriesToExclude(value)}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {stateLocation.countries.data.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-push-mode">
                    <TextField
                      id="mode"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_mode"), state.pushJobForm.mode.rules.required)}
                      value={state.pushJobForm.mode.value as string}
                      error={state.pushJobForm.mode.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "mode")
                      }
                      helperText={
                        state.pushJobForm.mode.error.required &&
                        t("admin.jobs.mode_error_required")
                      }
                    >
                      <MenuItem value="0">
                        {t("admin.jobs.mode_push_manual")}
                      </MenuItem>
                      <MenuItem value="1">
                        {t("admin.jobs.mode_push_auto")}
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                {state.pushJobForm.mode.value === "0" && (
                  <JobsPushFilterAmbassadors sendDataFilter={() => sendDataFilter(showNetworkers)} />
                )}

                {stateAmbassadors.automaticList.loading &&
                  !stateAmbassadors.automaticList.error && (
                    <div className="swissinx-table-container">
                      {t("admin.jobs.listAutomaticAmbassadorsLoading")}
                    </div>
                  )}
                {!stateAmbassadors.automaticList.loading &&
                  stateAmbassadors.automaticList.error && (
                    <div className="swissinx-table-container">
                      {t("admin.jobs.listAutomaticAmbassadorsError")}
                    </div>
                  )}
                {state.pushJobForm.mode.value === "1" &&
                  !stateAmbassadors.automaticList.loading &&
                  !stateAmbassadors.automaticList.error &&
                  stateAmbassadors.automaticList.data.length === 0 && (
                    <div className="swissinx-table-container error">
                      {t("admin.jobs.listAutomaticAmbassadorsEmpty")}
                    </div>
                  )}

                {!stateAmbassadors.loading &&
                  !stateAmbassadors.automaticList.error &&
                  state.pushJobForm.mode.value !== "" && (
                    <div className="swissinx-table-container">
                      <div className="swissinx-table">
                        <div className="swissinx-table-title-with-switch">
                          <h4>{t("admin.jobs.listAmbassadorsFiltered")}</h4>
                          <div>
                            <span
                              className={
                                showNetworkers
                                  ? ""
                                  : "swissinx-table-title-switch-off-text"
                              }
                            >
                              {t("admin.jobs.showNetworkers")}
                            </span>
                            <Switch
                              checked={showNetworkers}
                              onChange={handleChangeSwitch}
                              name="checkedNetworkers"
                              color="primary"
                              inputProps={{ "aria-label": "networkers checkbox" }}
                            />
                          </div>
                        </div>
                        <Table<AmbassadorDto>
                          data={stateAmbassadors.automaticList.data}
                          headers={tableHeader}
                          pageSize={10}
                          /* actions = {[
                                  {
                                    icon: Send,
                                    tooltip: t('table.tooltip.actionPush'),                                                                    
                                    onClick: sendDataPush
                                  }]} */
                          onSelectionChange={onSelectionChange}
                          selection={true}
                          selectionProps={(rowData: any) => ({
                            disabled: rowData.alreadyPushesThisJob,
                            color: "primary",
                          })}
                        />
                      </div>
                      <div
                        className="swissinx-table-action"
                        onClick={() => sendDataPush()}
                      >
                        <ArrowForward /> <p>{t("admin.jobs.sendToPush")}</p>
                      </div>
                      <div className="swissinx-table">
                        <h4>{t("admin.jobs.listAmbassadorsToSendPush")}</h4>
                        <Table<AmbassadorDto>
                          data={stateAmbassadors.listAmbassadorsFiltered}
                          headers={tableHeader}
                          pageSize={10}
                          actions={[
                            {
                              icon: Delete,
                              tooltip: t("table.tooltip.actionPush"),
                              onClick: deleteDataPush,
                            },
                          ]}
                          selection={true}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div className="swissinx-container-buttons">
                <button
                  className="swissinx-button-back"
                  type="button"
                  onClick={props.back}
                >
                  {t("admin.jobs.text_button_back")}
                </button>
                <button className="swissinx-button-submit" type="submit">
                  {t("admin.jobs.text_button_submit_send_push")}
                </button>
              </div>
            </div>
          }
        ></Form>
      }
      {showAlert && (
        <ModalInfo
          show={showAlert}
          confirm={() => setShowAlert(false)}
          title={t("admin.jobs.modal_push_no_ambassadors")}
          textButtonConfirm="Ok"
        />
      )}
    </div>
  );
}

export default JobsPush;
