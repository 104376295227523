import {CountryDto, RegionDto, CityDto, CountrySendDto, RegionSendDto, CitySendDto} from '../dto/LocationDto';

export enum ActionLocationType {
    
    LOCATION_COUNTRIES_LOADING = 'LOCATION_COUNTRIES_LOADING',
    LOCATION_COUNTRIES_SUCCESS = 'LOCATION_COUNTRIES_SUCCESS',
    LOCATION_COUNTRIES_ERROR = 'LOCATION_COUNTRIES_ERROR',

    LOCATION_COUNTRIES_FORM_NEW = 'LOCATION_COUNTRIES_FORM_NEW',
    LOCATION_COUNTRIES_FORM_UPDATE = 'LOCATION_COUNTRIES_FORM_UPDATE',

    LOCATION_COUNTRIES_SEND_VALUES = 'LOCATION_COUNTRIES_SEND_VALUES',
    LOCATION_COUNTRIES_ERROR_REQUIRED = 'LOCATION_COUNTRIES_ERROR_REQUIRED',
    LOCATION_COUNTRIES_ADD_LOADING = 'LOCATION_COUNTRIES_ADD_LOADING',
    LOCATION_COUNTRIES_ADD_SUCCESS = 'LOCATION_COUNTRIES_ADD_SUCCESS',
    LOCATION_COUNTRIES_ADD_ERROR = 'LOCATION_COUNTRIES_ADD_ERROR',
    LOCATION_COUNTRIES_EDIT_LOADING = 'LOCATION_COUNTRIES_EDIT_LOADING',
    LOCATION_COUNTRIES_EDIT_SUCCESS = 'LOCATION_COUNTRIES_EDIT_SUCCESS',
    LOCATION_COUNTRIES_EDIT_ERROR = 'LOCATION_COUNTRIES_EDIT_ERROR',
    LOCATION_COUNTRIES_DELETE_LOADING = 'LOCATION_COUNTRIES_DELETE_LOADING',
    LOCATION_COUNTRIES_DELETE_SUCCESS = 'LOCATION_COUNTRIES_DELETE_SUCCESS',
    LOCATION_COUNTRIES_DELETE_ERROR = 'LOCATION_COUNTRIES_DELETE_ERROR',

    LOCATION_COUNTRIES_CHANGE_SEARCH_TABLE = 'LOCATION_COUNTRIES_CHANGE_SEARCH_TABLE',
    LOCATION_COUNTRIES_CHANGE_PAGE_TABLE = 'LOCATION_COUNTRIES_CHANGE_PAGE_TABLE',
    LOCATION_COUNTRIES_CHANGE_PAGE_SIZE = 'LOCATION_COUNTRIES_CHANGE_PAGE_SIZE',

    LOCATION_REGIONS_LOADING = 'LOCATION_REGIONS_LOADING',
    LOCATION_REGIONS_SUCCESS = 'LOCATION_REGIONS_SUCCESS',
    LOCATION_REGIONS_ERROR = 'LOCATION_REGIONS_ERROR',

    LOCATION_REGIONS_FORM_NEW = 'LOCATION_REGIONS_FORM_NEW',
    LOCATION_REGIONS_FORM_UPDATE = 'LOCATION_REGIONS_FORM_UPDATE',

    LOCATION_REGIONS_SEND_VALUES = 'LOCATION_REGIONS_SEND_VALUES',
    LOCATION_REGIONS_ERROR_REQUIRED = 'LOCATION_REGIONS_ERROR_REQUIRED',    
    LOCATION_REGIONS_ADD_LOADING = 'LOCATION_REGIONS_ADD_LOADING',
    LOCATION_REGIONS_ADD_SUCCESS = 'LOCATION_REGIONS_ADD_SUCCESS',
    LOCATION_REGIONS_ADD_ERROR = 'LOCATION_REGIONS_ADD_ERROR',
    LOCATION_REGIONS_EDIT_LOADING = 'LOCATION_REGIONS_EDIT_LOADING',
    LOCATION_REGIONS_EDIT_SUCCESS = 'LOCATION_REGIONS_EDIT_SUCCESS',
    LOCATION_REGIONS_EDIT_ERROR = 'LOCATION_REGIONS_EDIT_ERROR',
    LOCATION_REGIONS_DELETE_LOADING = 'LOCATION_REGIONS_DELETE_LOADING',
    LOCATION_REGIONS_DELETE_SUCCESS = 'LOCATION_REGIONS_DELETE_SUCCESS',
    LOCATION_REGIONS_DELETE_ERROR = 'LOCATION_REGIONS_DELETE_ERROR',
    LOCATION_REGIONS_CHANGE_SEARCH_TABLE = 'LOCATION_REGIONS_CHANGE_SEARCH_TABLE',
    LOCATION_REGIONS_CHANGE_PAGE_TABLE = 'LOCATION_REGIONS_CHANGE_PAGE_TABLE',
    LOCATION_REGIONS_CHANGE_PAGE_SIZE = 'LOCATION_REGIONS_CHANGE_PAGE_SIZE',

    LOCATION_CITIES_LOADING = 'LOCATION_CITIES_LOADING',
    LOCATION_CITIES_SUCCESS = 'LOCATION_CITIES_SUCCESS',
    LOCATION_CITIES_ERROR = 'LOCATION_CITIES_ERROR',

    LOCATION_CITIES_FORM_NEW = 'LOCATION_CITIES_FORM_NEW',
    LOCATION_CITIES_FORM_UPDATE = 'LOCATION_CITIES_FORM_UPDATE',
    LOCATION_CITIES_SEND_VALUES = 'LOCATION_CITIES_SEND_VALUES',
    LOCATION_CITIES_ERROR_REQUIRED = 'LOCATION_CITIES_ERROR_REQUIRED',    
    LOCATION_CITIES_ADD_LOADING = 'LOCATION_CITIES_ADD_LOADING',
    LOCATION_CITIES_ADD_SUCCESS = 'LOCATION_CITIES_ADD_SUCCESS',
    LOCATION_CITIES_ADD_ERROR = 'LOCATION_CITIES_ADD_ERROR',
    LOCATION_CITIES_EDIT_LOADING = 'LOCATION_CITIES_EDIT_LOADING',
    LOCATION_CITIES_EDIT_SUCCESS = 'LOCATION_CITIES_EDIT_SUCCESS',
    LOCATION_CITIES_EDIT_ERROR = 'LOCATION_CITIES_EDIT_ERROR',
    LOCATION_CITIES_DELETE_LOADING = 'LOCATION_CITIES_DELETE_LOADING',
    LOCATION_CITIES_DELETE_SUCCESS = 'LOCATION_CITIES_DELETE_SUCCESS',
    LOCATION_CITIES_DELETE_ERROR = 'LOCATION_CITIES_DELETE_ERROR',
    LOCATION_CITIES_CHANGE_SEARCH_TABLE = 'LOCATION_CITIES_CHANGE_SEARCH_TABLE',
    LOCATION_CITIES_CHANGE_PAGE_TABLE = 'LOCATION_CITIES_CHANGE_PAGE_TABLE',
    LOCATION_CITIES_CHANGE_PAGE_SIZE = 'LOCATION_CITIES_CHANGE_PAGE_SIZE',

    LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING = 'LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING',
    LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_SUCCESS = 'LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_SUCCESS',
    LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR = 'LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR',

    LOCATION_COUNTRIES_CHANGE_FILTER = 'LOCATION_COUNTRIES_CHANGE_FILTER',
    LOCATION_REGIONS_CHANGE_FILTER = 'LOCATION_REGIONS_CHANGE_FILTER',
    LOCATION_CITIES_CHANGE_FILTER = 'LOCATION_CITIES_CHANGE_FILTER',
}

export interface actionLocationCountriesLoading {
    type: ActionLocationType.LOCATION_COUNTRIES_LOADING,
    value: {reset: boolean} 
}
export interface actionLocationCountriesSuccess {
    type: ActionLocationType.LOCATION_COUNTRIES_SUCCESS,
    value: CountryDto[]
}

export interface actionLocationCountriesError {
    type: ActionLocationType.LOCATION_COUNTRIES_ERROR   
}

export interface actionLocationCountriesSendValues {
    type: ActionLocationType.LOCATION_COUNTRIES_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionLocationCountriesFormNew {
    type: ActionLocationType.LOCATION_COUNTRIES_FORM_NEW
}

export interface actionLocationCountriesFormUpdate {
    type: ActionLocationType.LOCATION_COUNTRIES_FORM_UPDATE,
    value: CountryDto
}

export interface actionLocationCountriesErrorRequired {
    type: ActionLocationType.LOCATION_COUNTRIES_ERROR_REQUIRED,
    value: string
}

export interface actionLocationCountriesAddLoading {
    type: ActionLocationType.LOCATION_COUNTRIES_ADD_LOADING,
    value: CountrySendDto
}
export interface actionLocationCountriesAddSuccess {
    type: ActionLocationType.LOCATION_COUNTRIES_ADD_SUCCESS
    value: boolean
}
    
export interface actionLocationCountriesAddError {
    type: ActionLocationType.LOCATION_COUNTRIES_ADD_ERROR   
}

export interface actionLocationCountriesEditLoading {
    type: ActionLocationType.LOCATION_COUNTRIES_EDIT_LOADING,
    value: CountrySendDto
}
export interface actionLocationCountriesEditSuccess {
    type: ActionLocationType.LOCATION_COUNTRIES_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionLocationCountriesEditError {
    type: ActionLocationType.LOCATION_COUNTRIES_EDIT_ERROR   
}

export interface actionLocationCountriesDeleteLoading {
    type: ActionLocationType.LOCATION_COUNTRIES_DELETE_LOADING,
    value: number
}
export interface actionLocationCountriesDeleteSuccess {
    type: ActionLocationType.LOCATION_COUNTRIES_DELETE_SUCCESS
    value: boolean
}
    
export interface actionLocationCountriesDeleteError {
    type: ActionLocationType.LOCATION_COUNTRIES_DELETE_ERROR   
}

export interface actionLocationCountriesChangeSerachTable {
    type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionLocationCountriesChangePageSizeTable {
    type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionLocationCountriesChangePageTable {
    type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionLocationRegionsSendValues {
    type: ActionLocationType.LOCATION_REGIONS_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionLocationRegionsFormNew {
    type: ActionLocationType.LOCATION_REGIONS_FORM_NEW
}

export interface actionLocationRegionsFormUpdate {
    type: ActionLocationType.LOCATION_REGIONS_FORM_UPDATE,
    value: RegionDto
}

export interface actionLocationRegionsLoading {
    type: ActionLocationType.LOCATION_REGIONS_LOADING,
    value: number   
}
export interface actionLocationRegionsSuccess {
    type: ActionLocationType.LOCATION_REGIONS_SUCCESS,
    value: RegionDto[]
}    
export interface actionLocationRegionsError {
    type: ActionLocationType.LOCATION_REGIONS_ERROR   
}

export interface actionLocationRegionsErrorRequired {
    type: ActionLocationType.LOCATION_REGIONS_ERROR_REQUIRED,
    value: string}

export interface actionLocationRegionsAddLoading {
    type: ActionLocationType.LOCATION_REGIONS_ADD_LOADING,
    value: RegionSendDto
}
export interface actionLocationRegionsAddSuccess {
    type: ActionLocationType.LOCATION_REGIONS_ADD_SUCCESS
    value: boolean
}
    
export interface actionLocationRegionsAddError {
    type: ActionLocationType.LOCATION_REGIONS_ADD_ERROR   
}

export interface actionLocationRegionsEditLoading {
    type: ActionLocationType.LOCATION_REGIONS_EDIT_LOADING,
    value: RegionSendDto
}
export interface actionLocationRegionsEditSuccess {
    type: ActionLocationType.LOCATION_REGIONS_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionLocationRegionsEditError {
    type: ActionLocationType.LOCATION_REGIONS_EDIT_ERROR   
}

export interface actionLocationRegionsDeleteLoading {
    type: ActionLocationType.LOCATION_REGIONS_DELETE_LOADING,
    value: {idRegion: number, idCountry: number}
}
export interface actionLocationRegionsDeleteSuccess {
    type: ActionLocationType.LOCATION_REGIONS_DELETE_SUCCESS
    value: boolean
}
    
export interface actionLocationRegionsDeleteError {
    type: ActionLocationType.LOCATION_REGIONS_DELETE_ERROR   
}

export interface actionLocationRegionsChangeSerachTable {
    type: ActionLocationType.LOCATION_REGIONS_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionLocationRegionsChangePageSizeTable {
    type: ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionLocationRegionsChangePageTable {
    type: ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionLocationCitiesLoading {
    type: ActionLocationType.LOCATION_CITIES_LOADING,
    value: number     
}
export interface actionLocationCitiesSuccess {
    type: ActionLocationType.LOCATION_CITIES_SUCCESS,
    value: CityDto[]
}    
export interface actionLocationCitiesError {
    type: ActionLocationType.LOCATION_CITIES_ERROR    
}

export interface actionLocationCitiesFormNew {
    type: ActionLocationType.LOCATION_CITIES_FORM_NEW
}

export interface actionLocationCitiesFormUpdate {
    type: ActionLocationType.LOCATION_CITIES_FORM_UPDATE,
    value: CityDto
}

export interface actionLocationCitiesSendValues {
    type: ActionLocationType.LOCATION_CITIES_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionLocationCitiesErrorRequired {
    type: ActionLocationType.LOCATION_CITIES_ERROR_REQUIRED,
    value: string
}

export interface actionLocationCitiesAddLoading {
    type: ActionLocationType.LOCATION_CITIES_ADD_LOADING,
    value: CitySendDto
}
export interface actionLocationCitiesAddSuccess {
    type: ActionLocationType.LOCATION_CITIES_ADD_SUCCESS
    value: boolean
}
    
export interface actionLocationCitiesAddError {
    type: ActionLocationType.LOCATION_CITIES_ADD_ERROR   
}

export interface actionLocationCitiesEditLoading {
    type: ActionLocationType.LOCATION_CITIES_EDIT_LOADING,
    value: CitySendDto
}
export interface actionLocationCitiesEditSuccess {
    type: ActionLocationType.LOCATION_CITIES_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionLocationCitiesEditError {
    type: ActionLocationType.LOCATION_CITIES_EDIT_ERROR   
}

export interface actionLocationCitiesDeleteLoading {
    type: ActionLocationType.LOCATION_CITIES_DELETE_LOADING,
    value: {idRegion: number, idCountry: number, idCity: number}
}
export interface actionLocationCitiesDeleteSuccess {
    type: ActionLocationType.LOCATION_CITIES_DELETE_SUCCESS
    value: boolean
}
    
export interface actionLocationCitiesDeleteError {
    type: ActionLocationType.LOCATION_CITIES_DELETE_ERROR   
}

export interface actionLocationCitiesChangeSerachTable {
    type: ActionLocationType.LOCATION_CITIES_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionLocationCitiesChangePageSizeTable {
    type: ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionLocationCitiesChangePageTable {
    type: ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionLocationCountriesWithoutPushExceptionsLoading {
    type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING,
    value: {
        pushId: number,
        jobId: number
    }  
}
export interface actionLocationWithoutPushExceptionsSuccess {
    type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_SUCCESS,
    value: CountryDto[]
}    
export interface actionLocationWithoutPushExceptionsError {
    type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR   
}

export interface actionLocationCountriesChangeFilterTable {
    type: ActionLocationType.LOCATION_COUNTRIES_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export interface actionLocationRegionsChangeFilterTable {
    type: ActionLocationType.LOCATION_REGIONS_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export interface actionLocationCitiesChangeFilterTable {
    type: ActionLocationType.LOCATION_CITIES_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionLocationActions = actionLocationCountriesLoading | actionLocationCountriesSuccess | actionLocationCountriesError |
                                        actionLocationCountriesErrorRequired | actionLocationCountriesSendValues | actionLocationCountriesFormNew | actionLocationCountriesFormUpdate |
                                        actionLocationCountriesAddLoading | actionLocationCountriesAddSuccess | actionLocationCountriesAddError |
                                        actionLocationCountriesEditLoading | actionLocationCountriesEditSuccess | actionLocationCountriesEditError |
                                        actionLocationCountriesDeleteLoading | actionLocationCountriesDeleteSuccess | actionLocationCountriesDeleteError |
                                        actionLocationCountriesChangeSerachTable | actionLocationCountriesChangePageSizeTable | actionLocationCountriesChangePageTable |
                                        actionLocationRegionsLoading | actionLocationRegionsSuccess | actionLocationRegionsError |
                                        actionLocationRegionsErrorRequired | actionLocationRegionsSendValues | actionLocationRegionsFormNew | actionLocationRegionsFormUpdate |
                                        actionLocationRegionsAddLoading | actionLocationRegionsAddSuccess | actionLocationRegionsAddError|
                                        actionLocationRegionsEditLoading | actionLocationRegionsEditSuccess| actionLocationRegionsEditError|
                                        actionLocationRegionsDeleteLoading| actionLocationRegionsDeleteSuccess| actionLocationRegionsDeleteError|
                                        actionLocationRegionsChangeSerachTable| actionLocationRegionsChangePageSizeTable| actionLocationRegionsChangePageTable |
                                        actionLocationCitiesLoading | actionLocationCitiesSuccess | actionLocationCitiesError |
                                        actionLocationCitiesErrorRequired | actionLocationCitiesFormNew |actionLocationCitiesFormUpdate | actionLocationCitiesSendValues |
                                        actionLocationCitiesAddLoading | actionLocationCitiesAddSuccess| actionLocationCitiesAddError |
                                        actionLocationCitiesEditLoading | actionLocationCitiesEditSuccess | actionLocationCitiesEditError |
                                        actionLocationCitiesDeleteLoading | actionLocationCitiesDeleteSuccess | actionLocationCitiesDeleteError |
                                        actionLocationCitiesChangeSerachTable | actionLocationCitiesChangePageSizeTable | actionLocationCitiesChangePageTable |
                                        actionLocationCountriesWithoutPushExceptionsLoading | actionLocationWithoutPushExceptionsSuccess | actionLocationWithoutPushExceptionsError |
                                        actionLocationCountriesChangeFilterTable | actionLocationRegionsChangeFilterTable | actionLocationCitiesChangeFilterTable;