
import { TypeActions } from '../../models/actions/typeActions';
import { ActionLoginType } from '../../models/actions/typeLoginActions';
import { ActionPublicJobType } from '../../models/actions/typePublicJobActions';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { initialPublicJobState } from '../inititalState/initialPublicJobState';

const publicJobReducer = (state:TypePublicJobState = initialPublicJobState, action:TypeActions):TypePublicJobState => {
    switch (action.type) {

        case ActionPublicJobType.PUBLIC_JOB_LOADING: {
            const publicJob = state.publicJob;
            return {...state, publicJob: {...publicJob, loading: true, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_SUCCESS: {
            const publicJob = state.publicJob;
            return {...state, publicJob: {...publicJob, loading: false, error: false, data: action.value}}
        }

        case ActionPublicJobType.PUBLIC_JOB_ERROR: {
            const publicJob = state.publicJob;
            return {...state, publicJob: {...publicJob, loading: false, error: true, messageError: action.value}}
        }       

        case ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_LOADING: {
            const linkOpened = state.linkOpened;
            return {...state, linkOpened: {...linkOpened, loading: true, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_SUCCESS: {
            const linkOpened = state.linkOpened;
            return {...state, linkOpened: {...linkOpened, loading: false, linkOpenedData: action.value, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_ERROR: {
            const linkOpened = state.linkOpened;
            return {...state, linkOpened: {...linkOpened, loading: false, error: true}}
        }

        case ActionLoginType.LIGHTSIGNUP_LOADING: {
            const lightSignup = state.lightSignup;
            return {...state, lightSignup: {...lightSignup, loading: true, error: false, alreadyActivate: false}}
        }

        case ActionLoginType.LIGHTSIGNUP_SUCCESS: {
            const lightSignup = state.lightSignup;
            return {...state, lightSignup: {...lightSignup, loading: false, lightSignupData: action.value.data, error: false, alreadyActivate: false, prefix: action.value.prefix, phone: action.value.phone}}
        }

        case ActionLoginType.LIGHTSIGNUP_ERROR: {
            const lightSignup = state.lightSignup;
            return {...state, lightSignup: {...lightSignup, loading: false, error: action.value.error, alreadyActivate: action.value.alreadyExist}}
        }
        case ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING: {
            const updateLinkNetworkerId = state.updateLinkNetworkerId;
            return {...state, updateLinkNetworkerId: {...updateLinkNetworkerId, loading: true, ok: false, error: false}}
        }

        case ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_SUCCESS: {
            const updateLinkNetworkerId = state.updateLinkNetworkerId;            
            return {...state, updateLinkNetworkerId: {...updateLinkNetworkerId, loading: false, ok: true, error: false, newLink: action.value}}
        }

        case ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR: {
            const updateLinkNetworkerId = state.updateLinkNetworkerId;
            return {...state, updateLinkNetworkerId: {...updateLinkNetworkerId, loading: false, ok: false, error: true}}
        }

        case ActionLoginType.ACTIVATE_ACCOUNT_LOADING: {
            const activateAccount = state.activateAccount;
            return {...state, activateAccount: {...activateAccount, loading: true, error: false, ok: false, data: null}}
        }

        case ActionLoginType.ACTIVATE_ACCOUNT_SUCCESS: {
            const activateAccount = state.activateAccount;
            return {...state, activateAccount: {...activateAccount, ok: true, loading: false, error: false, data: action.value}}
        }

        case ActionLoginType.ACTIVATE_ACCOUNT_ERROR: {
            const activateAccount = state.activateAccount;
            return {...state, activateAccount: {...activateAccount, loading: false, error: true, ok: false, data: null}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_LOADING: {
            const jobForwared = state.jobForwared;
            return {...state, jobForwared: {...jobForwared, loading: true, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_SUCCESS: {
            const jobForwared = state.jobForwared;
            return {...state, jobForwared: {...jobForwared, loading: false, data: action.value, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_ERROR: {
            const jobForwared = state.jobForwared;
            return {...state, jobForwared: {...jobForwared, loading: false, error: true}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_LOADING: {
            const jobForwaredUpdate = state.jobForwaredUpdate;
            return {...state, jobForwaredUpdate: {...jobForwaredUpdate, loading: true, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_SUCCESS: {
            const jobForwaredUpdate = state.jobForwaredUpdate;
            return {...state, jobForwaredUpdate: {...jobForwaredUpdate, loading: false, ok: true, error: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_ERROR: {
            const jobForwaredUpdate = state.jobForwaredUpdate;
            return {...state, jobForwaredUpdate: {...jobForwaredUpdate, loading: false, error: true}}
        }

        case ActionLoginType.RESEND_ACTIVATION_CODE_LOADING: {
            const resendActivationCode = state.resendActivationCode;
            return {...state, resendActivationCode: {...resendActivationCode, loading: true, error: false, ok: false}}
        }

        case ActionLoginType.RESEND_ACTIVATION_CODE_SUCCESS: {
            const resendActivationCode = state.resendActivationCode;
            return {...state, resendActivationCode: {...resendActivationCode, loading: false, error: false, ok: true}}
        }

        case ActionLoginType.RESEND_ACTIVATION_CODE_ERROR: {
            const resendActivationCode = state.resendActivationCode;
            return {...state, resendActivationCode: {...resendActivationCode, loading: false, error: true, ok: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_LOADING: {
            const rejectJob = state.rejectJob;
            return {...state, rejectJob: {...rejectJob, loading: true, error: false, ok: false}}
        }

        case ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_SUCCESS: {
            const rejectJob = state.rejectJob;
            return {...state, rejectJob: {...rejectJob, loading: false, error: false, ok: true}}
        }

        case ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_ERROR: {
            const rejectJob = state.rejectJob;
            return {...state, rejectJob: {...rejectJob, loading: false, error: true, ok: false}}
        }
        
        case ActionPublicJobType.SEND_PASSWORD_BY_SMS_LOADING: {
            const sendPassword = state.sendPassword;
            return {...state, sendPassword: {...sendPassword, loading: true, error: false, ok: false}}
        }

        case ActionPublicJobType.SEND_PASSWORD_BY_SMS_SUCCESS: {
            const sendPassword = state.sendPassword;
            return {...state, sendPassword: {...sendPassword, loading: false, error: false, ok: true}}
        }

        case ActionPublicJobType.SEND_PASSWORD_BY_SMS_ERROR: {
            const sendPassword = state.sendPassword;
            return {...state, sendPassword: {...sendPassword, loading: false, error: true, ok: false}}
        }

        case ActionPublicJobType.APPLY_QUESTIONS_LOADING: {
            const applyQuestions = state.applyQuestions;
            return {...state, applyQuestions: {...applyQuestions, loading: true, error: false, data: []}}
        }

        case ActionPublicJobType.APPLY_QUESTIONS_SUCCESS: {
            const applyQuestions = state.applyQuestions;
            return {...state, applyQuestions: {...applyQuestions, loading: false, error: false, data: action.value}}
        }

        case ActionPublicJobType.APPLY_QUESTIONS_ERROR: {
            const applyQuestions = state.applyQuestions;
            return {...state, applyQuestions: {...applyQuestions, loading: false, error: true, data: []}}
        }

        case ActionPublicJobType.APPLY_JOB_LOADING: {
            const applyJob = state.applyJob;
            return {...state, applyJob: {...applyJob, loading: true, error: false, ok: false }}
        }

        case ActionPublicJobType.APPLY_JOB_SUCCESS: {
            const applyJob = state.applyJob
            return {...state, applyJob: {...applyJob, loading: false, error: false, ok: true}}
        }

        case ActionPublicJobType.APPLY_JOB_ERROR: {
            const applyJob = state.applyJob
            return {...state, applyJob: {...applyJob, loading: false, error: true, ok: false, messageError: action.value}}
        }

        case ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_LOADING: {
            const checkLinkIfCanBeShared = state.checkLinkIfCanBeShared;
            return {...state, checkLinkIfCanBeShared: {...checkLinkIfCanBeShared, loading: true, error: false, ok: false }}
        }

        case ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_SUCCESS: {
            const checkLinkIfCanBeShared = state.checkLinkIfCanBeShared;
            return {...state, checkLinkIfCanBeShared: {...checkLinkIfCanBeShared, loading: false, error: false, ok: true}}
        }

        case ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_ERROR: {
            const checkLinkIfCanBeShared = state.checkLinkIfCanBeShared;
            return {...state, checkLinkIfCanBeShared: {...checkLinkIfCanBeShared, loading: false, error: true, ok: false}}
        }
        
    }
    return {...state};
}
export default publicJobReducer;