
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import TypeState from '../../models/initialState/typeState';
import { ChangeEvent, useMemo, useState } from 'react';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
import typePaymentsState from '../../models/initialState/typePaymentsState';
import PaymentDto from '../../models/dto/PaymentDto';
import { ALL, convertDteIso, PAYMENT_STATUS_NOT_PAID, PAYMENT_STATUS_PAID } from '../../utils/utils';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import PaymentsModal from './PaymentsModal';
import { TypeActions } from '../../models/actions/typeActions';
import { actionPaymentsType } from '../../models/actions/typePaymentsActions';

interface ItemFilter {
  id: string,
  name: string
}

interface Props {
  edit(event: any, index: PaymentDto): void,  
  company: number,
  stringFilter: {
    currency: string,
    method: string,
    status: string
  },
  startDate: MaterialUiPickersDate,
  endDate: MaterialUiPickersDate,
  changeCompany(event: ChangeEvent<HTMLInputElement>): void,
  changePage(page: number, pageSize: number): void,
  changeSearchText(searchText: string): void,
  changeRowsPerPage?(pageSize: number): void,
  onOrderChange?(orderBy: number, orderDirection: string): void,
  onChangeStringFilter(id: string, value: string): void
  onChangeStartDate(date: any): any,
  onChangeEndDate(date: any): any
}

function PaymentsList(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /* const [stringFilter, setStringFilter] = useState({
    currency: ALL,
    method: ALL,
    status: ALL
  }) */
  //const [startDate, setStartDate] = useState<MaterialUiPickersDate>(null);
  //const [endDate, setEndDate] = useState<MaterialUiPickersDate>(null);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [showEditPayments, setShowEditPayments] = useState(false);
  const [paymentsSelected, setPaymentsSelected] = useState<PaymentDto[]>([]);

  const state:typePaymentsState = useSelector((state:TypeState) => state.paymentsReducer);
  const stateCompanies:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);
  const headers = [{ title: t('admin.payments.name'), field: 'networkerWhoGetReward.name'},
                   { title: t('admin.payments.surname'), field: 'networkerWhoGetReward.surname'},
                   { title: t('admin.payments.contact'), field: 'networkerWhoGetReward.email', 
                   render: (rowData: PaymentDto)=><span>{rowData.networkerWhoGetReward.email}<br></br>{rowData.networkerWhoGetReward.phone}</span> ,defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: '' },
                   { title: t('admin.payments.method'), field: 'paymentType.paymentType.description', defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: '' },
                   { title: t('admin.payments.dealDate'), field: 'dateApplication', 
                   render: (rowData: PaymentDto)=>convertDteIso(rowData.dateApplication), defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: '' },
                   { title: t('admin.payments.paymentDate'), field: 'paymentDate', 
                   render: (rowData: PaymentDto)=>rowData.paymentDate ? convertDteIso(rowData.paymentDate) : '', defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: '' },
                   { title: t('admin.payments.amount'), field: 'reward', defaultSort: state.filters.orderBy === 5 ? state.filters.orderDirection: '' },
                   { title: t('admin.payments.currency'), field: 'currency', defaultSort: state.filters.orderBy === 6 ? state.filters.orderDirection: '' },
];

  const actions = [
    {
      icon: Edit,
      tooltip: t('table.tooltip.actionEdit'),
      disabled: false,
      onClick: props.edit
    }    
  ]

  const paymentsCurrency = useMemo(()=>{
    const paymentsCurrencyAux: Set<string> = new Set();
    state.data.forEach(payment=>paymentsCurrencyAux.add(payment.currency))
    
    const paymentsCurrencyFilter: ItemFilter[] = [{id: ALL, name: ALL}];
   
    paymentsCurrencyAux.forEach((currency) =>{
      paymentsCurrencyFilter.push({id: currency, name: currency})
    } )
    return paymentsCurrencyFilter;

  }, [state.data])
  

  const paymentsMethod = useMemo(()=>{
    const paymentsMethodAux: Set<string> = new Set();
    state.data.forEach(payment=>{
      if (payment.paymentType && payment.paymentType.paymentType) {
        paymentsMethodAux.add(payment.paymentType.paymentType.description);
      }      
    });
    
    const paymentsMethodFilter: ItemFilter[] = [{id:ALL, name: ALL}];
   
    paymentsMethodAux.forEach((method) =>{     
        paymentsMethodFilter.push({id: method, name: method})
    } )
    return paymentsMethodFilter;

  }, [state.data])

  const onSelectionChange = (data: PaymentDto[]) => {
    setPaymentsSelected(data);
  };

  

  const onFilter = () => {
    let data = state.data;    
    if (props.stringFilter.currency !== ALL) {
      data = data.filter(payment=>payment.currency === props.stringFilter.currency)
    }

    if (props.stringFilter.status !== ALL) {
      data = data.filter(payment=>payment.status === props.stringFilter.status)
    }
    
    if (props.stringFilter.method !== ALL) {
      data = data.filter(payment=>payment.paymentType &&  payment.paymentType.paymentType.description === props.stringFilter.method)
    } 

    if (props.startDate && props.startDate.isValid()) {
      data = data.filter(payment=>{ 
        if (props.startDate) {          
          return payment.dateApplication >= props.startDate.format()
        }
      })
    }

    if (props.endDate && props.endDate.isValid()) {
      if (props.endDate) {
        data = data.filter(payment=>{
          if (props.endDate) { 
            return payment.dateApplication <= props.endDate.format()
          }          
        })
      }      
    }
     
    return data;
  }

  const totPayments = useMemo(() => {
    const tot:any = {};    
    paymentsCurrency.forEach(currency => { 
      if (currency.name !== ALL) {
        tot[currency.name] = 0}
      }      
    );
    onFilter().forEach(payment => tot[payment.currency] += payment.reward);
    return tot;
  }, [paymentsCurrency, state.data, props.stringFilter, props.startDate, props.endDate])

  const onChangeDate = (date: MaterialUiPickersDate, field: string) => {
    if (date) {
      
      if (field === 'startDate') {
        date.hours(0);
        date.minutes(0);
        props.onChangeStartDate(date);       
      } else {
        date.hours(23);
        date.minutes(59);
        props.onChangeEndDate(date);  
      }
    }
  }
  const closeModal = () => setShowEditPayments(false);

  const sendPayments = (date: string) => {
    setShowEditPayments(false);
    console.log(date);
    console.log(paymentsSelected);
    let company = 0;
    paymentsSelected.forEach(payment=>{
      company = payment.company.id;
      dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_EDIT_LOADING, value: {
        companyId: payment.company.id,
        rewardId: payment.rewardId,
        paymentDate: date,
        rewardStatus: PAYMENT_STATUS_PAID,
        appliedReward: payment.appliedReward,
        appliedCurrency: payment.appliedCurrency 
      }}); 
    })
    setTimeout(()=>dispatch<TypeActions>({type: actionPaymentsType.PAYMENTS_LOADING, value: company}), 1500);
       
  }

  return <div className='swissinx-container swissinx-payments'>
    <h2>{t('admin.payments.listTitle')}</h2>
    <div className="swissinx-container-fields-form">      
      <div className="swissinx-container-fields">
        <div className='swissinx-field-container'>
          <div className="swissinx-field field-country">
            <TextField
              id="company"
              select={true}
              label={t('admin.payments.label_company')}
              value={props.company}
              error={false}
              onChange={props.changeCompany}
            >              
              {stateCompanies.data.map(company => <MenuItem key={company.companyId} value={company.companyId}>{company.name}</MenuItem>)
              }
            </TextField>
          </div>
        </div>
      </div>
    </div>

    <div className='swissinx-field-container jobs-filter'>         
          <div className='swissinx-container-filter'>
            <div className="swissinx-field ">
              <TextField
                id="statusFilter"
                select={true}
                label={t('admin.payments.status')}
                value={props.stringFilter.status}
                error={false}
                onChange={(event:ChangeEvent<HTMLInputElement>)=>props.onChangeStringFilter('status', event.target.value)}         
              >
                <MenuItem key={0} value={ALL}>All</MenuItem>
                <MenuItem key={1} value={PAYMENT_STATUS_PAID}>{PAYMENT_STATUS_PAID}</MenuItem>
                <MenuItem key={2} value={PAYMENT_STATUS_NOT_PAID}>{PAYMENT_STATUS_NOT_PAID}</MenuItem>
              </TextField>
            </div>
            <div className="swissinx-field ">
              <TextField
                id="pushedFilter"
                select={true}
                label={t('admin.payments.currency')}
                value={props.stringFilter.currency}
                error={false}
                onChange={(event:ChangeEvent<HTMLInputElement>)=>props.onChangeStringFilter('currency', event.target.value)}    
              >
                {paymentsCurrency.map((currency) => <MenuItem key={currency.id} value={currency.id}>{currency.name}</MenuItem>)
                }
              </TextField>
            </div>
            <div className="swissinx-field ">
              <TextField
                id="pushedFilter"
                select={true}
                label={t('admin.payments.method')}
                value={props.stringFilter.method}
                error={false}
                onChange={(event:ChangeEvent<HTMLInputElement>)=>props.onChangeStringFilter('method', event.target.value)}     
              >
                {paymentsMethod.map((method) => <MenuItem key={method.id} value={method.id}>{method.name}</MenuItem>)
                }
              </TextField>
            </div>
          </div>
      </div>
      <div className='swissinx-field-container jobs-filter'> 
        <div className="swissinx-container-filter">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="swissinx-field">
              <KeyboardDatePicker
                  error={errorStartDate}
                  label={'From'}                              
                  inputVariant="outlined"
                  format="DD/MM/yyyy"
                  value={props.startDate}
                  onChange={(date: MaterialUiPickersDate) => onChangeDate(date as MaterialUiPickersDate, "startDate")}
                  onError={(error, value)=>{
                    if (error !== '') {
                      setErrorStartDate(true);                     
                      props.onChangeStartDate(value as moment.Moment);  
                    }
                    
                  }}
                                                            
              />
            </div>
            <div className="swissinx-field">
              <KeyboardDatePicker 
                error={errorEndDate}
                label={'To'}
                format="DD/MM/yyyy"
                inputVariant="outlined"
                value={props.endDate}
                onChange={(date: MaterialUiPickersDate) =>
                  onChangeDate(date, "endDate")
                }
                onError={(error, value) => { 
                  if (error !== '') {
                      setErrorEndDate(true); 
                      props.onChangeEndDate(value as moment.Moment);  
                  } 
                                            
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {state.data.length > 0 && <div className='swissinx-tot-container'>
        {Object.keys(totPayments).map(payment => <div>{totPayments[payment].toFixed(2)} {payment}</div>)}          
      </div>}        
    <Table<PaymentDto>
      data={onFilter()}
      headers={headers}
      actions={actions}
      new={false}
      initialPage={state.initialPage}
      serchText={state.searchText}
      pageSize={state.pageSize}      
      changePage={props.changePage}
      changeSearchText={props.changeSearchText}
      changeRowsPerPage={props.changeRowsPerPage}
      onOrderChange={props.onOrderChange}
      selection={true} 
      onSelectionChange={onSelectionChange}
      exportButton={true}/>
      <div className="swissinx-payments-button-container">
        <button className="swissinx-payments-button" disabled={paymentsSelected.length === 0} onClick={()=>setShowEditPayments(true)}>{t('admin.payments.button_update_date')}</button>  
      </div>
      

      {showEditPayments &&  <PaymentsModal
                              show={showEditPayments}                             
                              close={closeModal}
                              sendPayments={(date)=>sendPayments(date)}/>}      
  </div>;
}

export default PaymentsList;