import { TypeActions } from '../../models/actions/typeActions';
import { actionPaymentsType } from '../../models/actions/typePaymentsActions';
import typePaymentsState from '../../models/initialState/typePaymentsState';
import { initialPaymentsState } from '../inititalState/initialPaymentsState';

const paymentsReducer = (state:typePaymentsState = initialPaymentsState, action:TypeActions):typePaymentsState => {
    switch (action.type) {        

        case actionPaymentsType.PAYMENTS_LOADING: {           
            return {...state,  loading: true, error: false}
        }

        case actionPaymentsType.PAYMENTS_SUCCESS: {            
            return {...state, loading: false, data: action.value, error: false}
        }

        case actionPaymentsType.PAYMENTS_ERROR: {            
            return {...state,  loading: false, error: true, data: [], messageError: action.value}
        }
        
        case actionPaymentsType.PAYMENTS_EDIT_LOADING: {
            return {...state, loading: true, error: false, edit: false}
        }

        case actionPaymentsType.PAYMENTS_EDIT_SUCCESS: {
            return {...state, loading: false, error: false, edit: action.value}
        }

        case actionPaymentsType.PAYMENTS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case actionPaymentsType.PAYMENTS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case actionPaymentsType.PAYMENTS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }
        case actionPaymentsType.PAYMENTS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
        
    }
    return {...state};
}
export default paymentsReducer;