
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
//import './ModalInfo.scss';
import NetworkerDto from '../../models/dto/NetworkerDto';
import { useState, useEffect, ChangeEvent } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CompanyDto from '../../models/dto/CompanyDto';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
  };

interface Props {
    show: boolean,    
    close(): void,
    sendPayments(date: string ): void
}

function PaymentsModal(props: Props) {  
  const [paymentDate, setPaymentDate] = useState<MaterialUiPickersDate>(new MomentUtils().moment);
  const [errorDate, setErrorDate] = useState(false);
  const { t } = useTranslation();
  
  

  const onChangeDate = (date: MaterialUiPickersDate, field: string) => {
    if (date) {      
        setPaymentDate(date);       
    }
    
    if (date && date?.isValid()) {
      setErrorDate(false);
    }
  }

  const sendPayments = ()=>{
    props.sendPayments(paymentDate ? paymentDate?.toISOString() : new Date().toISOString());
  }

  return <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.show}
            onClose={()=>props.close()}
        >                            
            <Box sx={style}>
                <div className='swissin-modal-close'>
                  <span onClick={()=>props.close()}>X</span>
                </div>   
                <div className='swissin-modal-info report'>
                  <Info />
                  <h2>{t('admin.payments.modal_title')}</h2>  
                  <p>{t('admin.payments.modal_description')}</p>              
                  <div className='swissin-modal-info-data'>
                    
                    <div className="swissinx-field-container">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className="swissinx-field">
                          <KeyboardDatePicker
                              error={errorDate}
                              label={t('admin.payments.paymentDate')}                              
                              inputVariant="outlined"
                              format="DD/MM/yyyy"
                              value={paymentDate}
                              onChange={(date: MaterialUiPickersDate) => onChangeDate(date as MaterialUiPickersDate, "startDate")}
                              onError={(error, value)=>{
                                if (error !== '') {
                                  setErrorDate(true);
                                  setPaymentDate(value as moment.Moment);  
                                }                                
                              }}                                                                        
                          />
                        </div>                        
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className='swissin-modal-info-buttons'>
                      <button onClick={sendPayments} disabled={errorDate}>{t('admin.payments.modal_button')}</button>
                  </div>                    
              </div>
            </Box>
        </Modal>           
       
  }

export default PaymentsModal;
