
import React, { forwardRef, SyntheticEvent, useRef } from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

import MaterialTable, { Icons } from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import './table.scss';
import { t } from 'i18next';
import { DEFAULTITEMSPERPAGE } from '../../utils/utils';
import { JsxFragment } from 'typescript';

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

interface Colums {
    title: string,
    field: string
}

interface Action {
    //icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    icon: any,
    tooltip: string,
    disabled?: boolean,
    isFreeAction?: boolean,
    onClick(e: SyntheticEvent, index: Object): void
}

interface Props<T> {
    data: object[],
    headers: Colums[],
    actions?: (Action | ((rowData: any) => Action))[] | undefined,
    new?: boolean,
    initialPage?: number,
    serchText?: string,
    pageSize?: number,
    selection?: boolean,
    title?: string,
    selectionProps?: (rowData: Object) => ({
        disabled: boolean,
        color: string
    }),
    changePage?(page: number, pageSize: number): void,
    changeSearchText?(searchText: string): void,
    changeRowsPerPage?(pageSize: number): void,
    onSelectionChange?(data: object[]): void,
    onOrderChange?(orderBy: number, orderDirection: any): void,
    newRegister?(): void,
    detailPanel?: (rowData: any) => any,
    exportButton?: boolean
}

function Table<T>(props: Props<T>) {    
    const ref = useRef(null);

    return <>
        {props.new && <div className='swissin-table-add-container'>
            <div onClick={props.newRegister}>
                <span>+</span>{t("admin.addNew")}
            </div>
        </div>}
        <MaterialTable<any>
            ref={ref}
            icons={tableIcons}
            columns={props.headers}
            data={props.data}
            actions={props.actions}
            options={{                
                filtering: false,
                sorting: true,
                actionsColumnIndex: -1,
                pageSize: props.pageSize ? props.pageSize : DEFAULTITEMSPERPAGE,
                initialPage: props.initialPage ? props.initialPage : 0,
                paging: true,
                searchText: props.serchText ? props.serchText : '',
                selection: props.selection ? props.selection : false,
                selectionProps: props.selectionProps,
                exportButton: props.exportButton,                          
            }}
            localization={
                {
                    header: {
                        actions: ''
                    }
                }
            }                  
            onOrderChange={props.onOrderChange}
            title={props.title ? props.title : ''}
            onChangePage={props.changePage}            
            onSearchChange={props.changeSearchText ? props.changeSearchText : (searchText: string) => null}
            onChangeRowsPerPage={props.changeRowsPerPage ? props.changeRowsPerPage : (pageSize: number) => null}
            onSelectionChange={props.onSelectionChange}
            detailPanel={props.detailPanel}
        />


    </>
}

export default Table;