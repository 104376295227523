
import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { ActionMarketsType } from '../../models/actions/typeMarketsActions';
import { useSelector, useDispatch } from 'react-redux';
import MarketDto from '../../models/dto/MarketDto'; 
import MarketsList from './MarketsList';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
import MarketsEdit from './MarketsEdit';
//import './Markets.scss';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Markets(props: Props) {
  const [editMarket, setEditMarket] = useState(false);
  const [deleteMarket, setDeleteMarket] = useState(false);
  const [addMarket, setAddMarket] = useState(false);  
  const [marketEdit, setMarketEdit] = useState({marketId: 0, name: ''});
  const [marketRemove, setMarketRemove] = useState({marketId: 0, name: ''});
  
  const { t } = useTranslation();
  const state:TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: true}});
      setEditMarket(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: true}}); 
    }          
  }, []);  

  const edit = (event:SyntheticEvent, index:MarketDto) => {
    setMarketEdit(index);
    setEditMarket(true);
    setAddMarket(false);
  }

  const removeConfirmation = (event:SyntheticEvent, market:MarketDto) => {
    setMarketRemove(market);
    setDeleteMarket(true);  
  }

  const newRegister = () => {
    setMarketEdit({marketId: 0, name: ''});
    setEditMarket(true);
    setAddMarket(true);
  }  

  const back = () => {
    setEditMarket(false);
  }

  const update = (market: MarketDto) => {
    setEditMarket(false);
    if (addMarket) {
      dispatch<TypeActions>({type: ActionMarketsType.MARKETS_ADD_LOADING, value: market.name});      
    } else {
      dispatch<TypeActions>({type: ActionMarketsType.MARKETS_EDIT_LOADING, value: market});
    }
    //setEditMarket(false);
  }  

  const remove = () => {
    dispatch<TypeActions>({type: ActionMarketsType.MARKETS_DELETE_LOADING, value: marketRemove.marketId});
    setDeleteMarket(false);
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionMarketsType.MARKETS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionMarketsType.MARKETS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionMarketsType.MARKETS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionMarketsType.MARKETS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editMarket && <MarketsList 
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.loading && !state.error && editMarket && <MarketsEdit data={marketEdit} update={update} back={back} add={addMarket}/>}
           
            {deleteMarket && <ModalConfirmDelete show={deleteMarket} title={t('admin.markets.modal_delete_title')} back={()=>setDeleteMarket(false)} confirm={remove} textButtonBack={t('admin.markets.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.markets.modal_delete_buttonDelete')} />}
            
            {state.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.markets.editMessage')}
              addMessage={t('admin.markets.addMessage')}
              delteMessage={t('admin.markets.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionMarketsType.MARKETS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionMarketsType.MARKETS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionMarketsType.MARKETS_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Markets;
