import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import ClientDto from '../models/dto/ClientDto';
import { actionClientsAddLoading, actionClientsDeleteLoading, actionClientsEditLoading, actionClientsLoading, ActionClientsType } from '../models/actions/typeClientsActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';

const baseUrlApi:string = getBaseUrlApi();
const urlClients = baseUrlApi + '/api/Client/getClients';
const urlClientsEdit = baseUrlApi + '/api/Client/updateClient';
const urlClientsCreate = baseUrlApi + '/api/Client/createClient';
const urlClientsDelete = baseUrlApi + '/api/Client/DeleteClient';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* loadClients(action: actionClientsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlClients, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:ClientDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_SUCCESS, value: {data: data, reset: action.value.reset}});
            
        } else {           
            yield call(checkError, response.status, {type: ActionClientsType.CLIENTS_ERROR});  
        }                
        
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionClientsType.CLIENTS_ERROR});
    }
}

function* createClient(action: actionClientsAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlClientsCreate, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_ADD_SUCCESS, value: true});    
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: true}});            
        } else {           
            yield call(checkError, response.status, {type: ActionClientsType.CLIENTS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionClientsType.CLIENTS_ERROR});
    }
}

function* editClient(action: actionClientsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlClientsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_EDIT_SUCCESS, value: true});              
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionClientsType.CLIENTS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionClientsType.CLIENTS_ERROR});
    }
}

function* deleteClient(action: actionClientsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlClientsDelete + '?clientId=' + action.value, getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionClientsType.CLIENTS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionClientsType.CLIENTS_ERROR});
    }
}


function* loadClientsWatcher() {
    yield takeLatest(ActionClientsType.CLIENTS_LOADING, loadClients);
}

function* createClientWatcher() {
    yield takeLatest(ActionClientsType.CLIENTS_ADD_LOADING, createClient);
}

function* editClientWatcher() {
    yield takeLatest(ActionClientsType.CLIENTS_EDIT_LOADING, editClient);
}

function* deleteClientWatcher() {
    yield takeLatest(ActionClientsType.CLIENTS_DELETE_LOADING, deleteClient);
}


export default function* rootClientsSaga() {
    yield all([
        loadClientsWatcher(),
        createClientWatcher(),
        editClientWatcher(),
        deleteClientWatcher()
    ])
}