import { useEffect, useState } from 'react';
import './Dashboard.scss';
import DashboardMenu from './DashboardMenu';
import NetworkDashboard from './NetworkDashboard';
import UserDashboard from './UserDashboard';

interface Props {    
  option: number,
  reload: boolean,
  setReload(reload: boolean):void  
}

function Dashboard(props: Props) {  
  const [option, setOption] = useState(props.option);

  useEffect(()=>{
    if (props.reload === true) {
      setOption(0);
      props.setReload(false);
    }
       
  }, [props.reload]);

  const options = [<DashboardMenu setOption={setOption}/>,
                   <UserDashboard setOption={setOption} />,
                   <NetworkDashboard setOption={setOption} />]

  
  return <>
    {options[option]}    
  </>       
 
}

export default Dashboard;