import { TypeActions } from '../../models/actions/typeActions';
import { ActionDashboardType } from '../../models/actions/typeDashboardActions';
import TypeDashboardState from '../../models/initialState/typeDashboardState';
import {initialDashboardState} from '../inititalState/initialDashboardState';

const dashboardReducer = (state:TypeDashboardState = initialDashboardState, action:TypeActions):TypeDashboardState => {
    switch (action.type) {        

        case ActionDashboardType.USER_DASHBOARD_LOADING: {
            const user = {...state.user}         
            return {...state,  user:{...user, loading: true, error: false, data: null}}
        }

        case ActionDashboardType.USER_DASHBOARD_SUCCESS: {            
            const user = {...state.user}         
            return {...state,  user:{...user, loading: false, error: false, data: action.value}}
        }

        case ActionDashboardType.USER_DASHBOARD_ERROR: {            
            const user = {...state.user}         
            return {...state,  user:{...user, loading: false, error: true, data: null}}
        }

        case ActionDashboardType.NETWORK_DASHBOARD_LOADING: {
            const network = {...state.network}         
            return {...state,  network:{...network, loading: true, error: false, data: null}}
        }

        case ActionDashboardType.NETWORK_DASHBOARD_SUCCESS: {            
            const network = {...state.network}     
            return {...state,  network:{...network, loading: false, error: false, data: action.value}}
        }

        case ActionDashboardType.NETWORK_DASHBOARD_ERROR: {            
            const network = {...state.network}         
            return {...state,  network:{...network, loading: false, error: true, data: null}}
        } 
        
    }
    return {...state};
}
export default dashboardReducer;