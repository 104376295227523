import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import { ActionDashboardType, actionUserDashboardLoading, actionNetworkDashboardLoading } from '../models/actions/typeDashboardActions';
import UserDashboardDto from '../models/dto/UserDashboardDto';
import NetworkDashboardDto from '../models/dto/NetworkDashboardDto';

const baseUrlApi:string = getBaseUrlApi();
const urlUserDashBoard = baseUrlApi + '/api/DashboardController/getUserDashBoard';
const urlNetworkDashBoard = baseUrlApi + '//api/DashboardController/getNetworkDashBoard';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* getUserDashBoard(action: actionUserDashboardLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlUserDashBoard, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            const data:UserDashboardDto = yield call([response, 'json']);           
            const datos = {
                "kpis": {
                  "receivedVacancies": 10,
                  "forwards": 200,
                  "timeToForward": 50,
                  "secondaryForwards": 7,
                  "rejections": 45,
                  "clicks": 0,
                  "applicationsProduced": 0,
                  "applicationsWithAnswers": 0,
                  "likedApplications": 0,
                  "dislikedApplications": 0,
                  "placements": 0,
                  "pickedUpJobs": 0,
                  "maxApplicationsPerJob": 27,
                  "directApplications": 0,
                  "timesTheUserStartsTheApp": 0,
                  "reactionTime": 0
                },
                "performanceIndicators": {
                  "preferability": 0,
                  "engagement": 0,
                  "networkAccuracy": 0,
                  "networkQuality": 0,
                  "networkResponseRate": 0,
                  "pushAccuracy": 0,
                  "rejectionRate": 0,
                  "proactivityRate": 0,
                  "fillRate": 0,
                  "timeToForward": 0
                }
              }
            yield put<TypeActions>({type: ActionDashboardType.USER_DASHBOARD_SUCCESS, value: datos});
            
        } else {           
            yield call(checkError, response.status, {type: ActionDashboardType.USER_DASHBOARD_ERROR});  
        }                
                
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionDashboardType.USER_DASHBOARD_ERROR});
    }
}

function* getNetworkDashBoard(action: actionNetworkDashboardLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlNetworkDashBoard, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            const data:NetworkDashboardDto = yield call([response, 'json']);
            const datos = {
                "kpis": {
                  "pushedVacancies": 0,
                  "forwards": 0,
                  "timeToForward": 0,
                  "secondaryForwards": 0,
                  "rejections": 0,
                  "clicks": 0,
                  "applicationsProduced": 0,
                  "applicationsWithAnswers": 0,
                  "likedApplications": 0,
                  "dislikedApplications": 0,
                  "placements": 0,
                  "pickedUpJobs": 0,
                  "reactionTime": 0
                },
                "performanceIndicators": {
                  "engagement": 0,
                  "networkAccuracy": 0,
                  "networkQuality": 0,
                  "networkResponseRate": 0,
                  "pushAccuracy": 0,
                  "rejectionRate": 0,
                  "proactivityRate": 0,
                  "fillRate": 0,
                  "timeToForward": 0
                }
              }          
            yield put<TypeActions>({type: ActionDashboardType.NETWORK_DASHBOARD_SUCCESS, value: datos});
            
        } else {           
            yield call(checkError, response.status, {type: ActionDashboardType.NETWORK_DASHBOARD_ERROR});  
        }                
                
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionDashboardType.NETWORK_DASHBOARD_ERROR});
    }
}

function* getUserDashBoardWatcher() {
    yield takeLatest(ActionDashboardType.USER_DASHBOARD_LOADING, getUserDashBoard);
}

function* getNetworkDashBoardWatcher() {
    yield takeLatest(ActionDashboardType.NETWORK_DASHBOARD_LOADING, getNetworkDashBoard);
}



export default function* rootDashboardSaga() {
    yield all([
        getUserDashBoardWatcher(),
        getNetworkDashBoardWatcher()      
    ])
}