import TypeDashboardState from "../../models/initialState/typeDashboardState";

export const initialDashboardState: TypeDashboardState = {
    user: {
        loading: false,
        error: false,
        data: null,
    },
    network: {
        loading: false,
        error: false,
        data: null,
    }  
}