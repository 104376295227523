import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import JobsForm from "../../models/forms/JobsForm";
import Form from "../Form/Form/Form";
import FieldTextMui from "../Form/FieldTextMui/FieldTextMui";
import { validateNumber, validateSalaryMax } from "../Form/validators";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  TypeActionJobsActions,
  ActionJobsType,
} from "../../models/actions/typeJobsActions";
import {
  ActionLocationType,
  TypeActionLocationActions,
} from "../../models/actions/typeLocationActions";
import {
  getDefaultSkills,
  getLabelFormatted,
  getLabelLanguage,
  getLabelMarket,  
  getSkillOptions,
  listCurrency,
  listStatus,
} from "../../utils/utils";
import { Question } from "../../models/dto/JobTemplateDto";
import TableTemplates from "../table/TableTemplates";
import JobsResponseDto from "../../models/dto/JobsResponseDto";
import JobTemplatesDto from "../../models/dto/JobTemplateDto";
import FiledError from "../Form/common/FieldError/FiledError";
import {  
  OPTION_CUSTOM_TEMPLATE, OPTION_NO_TEMPLATE,  
} from "../../constanst/GlobalConstants";
import TypeJobsState from "../../models/initialState/typeJobsState";
import TypeState from "../../models/initialState/typeState";
import TypeLocationState from "../../models/initialState/typeLocationState";
import TypeClientsState from "../../models/initialState/typeClientsState";
import TypeSkillsState from "../../models/initialState/typeSkillsState";
import TypeMarketsState from "../../models/initialState/typeMarketsState";
import TypeLanguagesState from "../../models/initialState/typeLanguagesState";
import { CityDto, CountryDto, RegionDto } from "../../models/dto/LocationDto";
import Loading from "../commons/Loading";
import AutocompleteSelect from '../autocompleteSelect/AutocompleteSelect'
import { skillAutocomplete } from "../../models/dto/SkillDto";
import { Autocomplete } from "@mui/material";
import typeSkillGroupState from "../../models/initialState/typeSkillGroupState";

interface Props {
  back(): void;
  update(jobUpdate: JobsForm, questions: Question[], taId: number): void;
  add: boolean;
}

const initQuestions = (
  data: JobsResponseDto | null,
  state: TypeJobsState
): Question[] => {
  //Si nos llega el campo questions vacio es que se trata de un template predefinido y tenemos que buscar las preguntas en el array de templates (si el templateId > 0)
  //Si nos llega el campo questions con un array, entonces se trata de un template personalizado y tenemos que mostrar dichas preguntas
  let questions: Question[] = [];
  if (data !== null) {
    if (data.questions !== null) {
      questions = data.questions;
    } else if (data.template !== null && data.template.id > 0) {
      const templateId = data.template.id;
      const templateAux: JobTemplatesDto | undefined =
        state.templates.data.find(
          (template) => template.templateId === templateId
        );
      questions = templateAux !== undefined ? templateAux.questions : [];
    }
  }

  return questions;
};

function JobsEdit(props: Props) {
  const { t } = useTranslation();
  const state: TypeJobsState = useSelector(
    (state: TypeState) => state.jobsReducer
  );
  const stateLocation: TypeLocationState = useSelector(
    (state: TypeState) => state.locationReducer
  );
  const stateClients: TypeClientsState = useSelector(
    (state: TypeState) => state.clientsReducer
  );
  const stateSkillGroup: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);
  const stateSkills: TypeSkillsState = useSelector(
    (state: TypeState) => state.skillReducer
  );
  const stateMarkets: TypeMarketsState = useSelector(
    (state: TypeState) => state.marketsReducer
  );
  const stateLanguages: TypeLanguagesState = useSelector(
    (state: TypeState) => state.languagesReducer
  );

  const [questions, setQuestions] = useState<Question[]>(
    initQuestions(state.jobSelected.data, state)
  );
  const [country, setCountry] = useState<CountryDto | null>(null)
  const [region, setRegion] = useState<RegionDto | null>(null)
  const [city, setCity] = useState<CityDto | null>(null)
  const [skill, setSkill] = useState<skillAutocomplete[] | undefined>(undefined)  
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.add && state.jobSelected.data && !state.jobSelected.loading) {
      setCountry(state.jobSelected.data.country)
      setRegion(state.jobSelected.data.region)
      setCity(state.jobSelected.data.city)
      dispatch<TypeActionLocationActions>({
        type: ActionLocationType.LOCATION_REGIONS_LOADING,
        value: state.jobSelected.data.country.id,
      });
      dispatch<TypeActionLocationActions>({
        type: ActionLocationType.LOCATION_CITIES_LOADING,
        value: state.jobSelected.data.region.id,
      });     
    }
  }, [state.jobSelected.data]);

  useEffect(() => {
    if (!props.add && state.jobSelected.data && !state.jobSelected.loading) {
      setQuestions(initQuestions(state.jobSelected.data, state));
    }
  }, [state.templates.data, state.jobSelected.data]);

  const headersTemplate = [
    {
      title: t("admin.jobs.questionCategory"),
      field: "questionCategory",
      validate: (rowData: Question) => rowData.questionCategory !== undefined,
      lookup: { 0: "YOU", 1: "SKILL", 2: "PROFILE" },
    },
    {
      title: t("admin.jobs.questionText"),
      field: "questionText",
      validate: (rowData: Question) =>
        rowData.questionText !== undefined && rowData.questionText !== "",
    },
    {
      title: t("admin.jobs.questionType"),
      field: "questionType",
      validate: (rowData: Question) => rowData.questionType !== undefined,
      lookup: { 0: 'Text', 1: 'FileUpload', 2: 'YesNo', 3: 'Number', 4: 'Ratio' },
    },
    {
      title: t("admin.jobs.questionOrder"),
      field: "order",
      validate: (rowData: Question) =>
        rowData.order !== undefined && rowData.order > 0,
      type: "numeric",
    },
  ];

  const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
    const value = event.target.value;
    let sendValue: number[];
    typeof value === "string"
      ? (sendValue = [parseInt(value)])
      : (sendValue = value);
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_ARRAY_VALUES,
      value: {
        name: name,
        value: sendValue,
        error: { required: false, format: false },
      },
    });
  };

  const onChangeCheck = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_BOOLEAN_VALUES,
      value: {
        name: name,
        value: event.target.checked,
        error: { required: false, format: false },
      },
    });
  };

  const onChangeSelect = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    if (name === "templateId") {
      const templateSelected = state.templates.data.find(
        (template) =>
          template.templateId === parseInt(event.target.value as string)
      );
      setQuestions(
        templateSelected !== undefined ? templateSelected.questions : []
      );
    }
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: name,
        value: event.target.value,
        error: { required: false, format: false },
      },
    });
  };

  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "description",
        value: value,
        error: { required: false, format: false },
      },
    });
  };

  function onSubmit() {
    //Comprobacion de si es un template personalizado se obliga a introducir alguna pregunta
    if (
      !(
        state.jobsForm.templateId.value === OPTION_CUSTOM_TEMPLATE &&
        questions.length === 0
      )
    ) {
      if (!props.add && state.jobSelected.data) {
        props.update(state.jobsForm, questions, state.jobSelected.data.taId);
      } else {
        props.update(state.jobsForm, questions, 0);
      }
    }
  }

  const getLabelCountriesToExclude = (value: number): string => {
    let label = "";

    if (stateLocation.countries.loading) {
      label = "Loading";
    } else if (stateLocation.countries.error) {
      label = "Error";
    } else {
      const labelAux = stateLocation.countries.data.find(
        (country) => country.id === value
      );
      if (labelAux) {
        label = labelAux.description;
      }
    }
    return label;
  };

  const changeCountry = (
    event: React.SyntheticEvent<Element, Event>,
    value: CountryDto | null,
    reason: any
  ) => {
    setCountry(value);
    setRegion(null);
    setCity(null);
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "countryId",
        value: value && value.id ? value.id.toString() : "",
        error: { required: false, format: false },
      },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "regionId",
        value: "",
        error: { required: false, format: false },
      },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "cityId",
        value: "",
        error: { required: false, format: false },
      },
    });
    dispatch<TypeActionLocationActions>({
      type: ActionLocationType.LOCATION_REGIONS_LOADING,
      value: value && value.id ? value.id : 0,
    });
  };

  const changeRegion = (
    event: React.SyntheticEvent<Element, Event>,
    value: RegionDto | null,
    reason: any
  ) => {
    setRegion(value);
    setCity(null)
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "regionId",
        value: value && value.id ? value.id.toString() : "",
        error: { required: false, format: false },
      },
    });
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "cityId",
        value: "",
        error: { required: false, format: false },
      },
    });
    dispatch<TypeActionLocationActions>({
      type: ActionLocationType.LOCATION_CITIES_LOADING,
      value: value ? value.id : 0,
    });
  };



  const changeCity = (
    event: React.SyntheticEvent<Element, Event>,
    value: CityDto | null,
    reason: any
  ) => {
    setCity(value);
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_VALUES,
      value: {
        name: "cityId",
        value: value && value.id ? value.id.toString() : "",
        error: { required: false, format: false },
      },
    });
  };

  const changeSkill = (
    event: React.SyntheticEvent<Element, Event>,
    value: skillAutocomplete[] | null,
    reason: any
  ) => {
    let skillIds:number[]=[];
    if (value) {
      setSkill([...value]);
      skillIds = value.map(skill=>skill.id)
    } 
    
    dispatch<TypeActionJobsActions>({
      type: ActionJobsType.JOBS_FORM_SEND_ARRAY_VALUES,
      value: {
        name: "skillId",
        value: skillIds,
        error: { required: false, format: false },
      },
    });
  };
  
  const testSalary = (salaryMin: string, salaryMax: string) => {
    if (validateSalaryMax(salaryMin, salaryMax)) {
      if (state.jobsForm.salaryMin.error.format) {
        dispatch<TypeActionJobsActions>({
          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
          value: {
            name: "salaryMin",
            value: salaryMin as string,
            error: { required: false, format: false },
          },
        });
      } else if (state.jobsForm.salaryMax.error.format) {
        dispatch<TypeActionJobsActions>({
          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
          value: {
            name: "salaryMax",
            value: salaryMax as string,
            error: { required: false, format: false },
          },
        });
      }
    }
  };

  return (
    <div className="swissinx-container">
      <h2>
        <FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
        {props.add ? t("admin.jobs.createTitle") : t("admin.jobs.editTitle")}
      </h2>
      {state.jobSelected.loading && <Loading />}
      {(!state.jobSelected.loading || props.add) && (
        <Form
          contex={state.jobsForm}
          errorRequerired={(key) =>
            dispatch<TypeActionJobsActions>({
              type: ActionJobsType.JOBS_ERROR_REQUIRED,
              value: key,
            })
          }
          onSubmit={onSubmit}
          fields={
            <div className="swissinx-container-fields-form">
              <h3>{t("admin.jobs.label_mainInformation")}</h3>
              <div className="swissinx-container-fields">
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-title">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_title")}
                      name="title"
                      required={state.jobsForm.title.rules.required}
                      requiredError={t("admin.jobs.title_error_required")}
                      value={state.jobsForm.title.value as string}
                      fullWidth={true}
                      error={
                        state.jobsForm.title.error.required ||
                        state.jobsForm.title.error.format
                      }
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
                          value: { name, value, error },
                        })
                      }
                    />
                  </div>

                  <div className="swissinx-field field-status">
                    <TextField
                      id="status"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_status"), state.jobsForm.status.rules.required)}
                      value={state.jobsForm.status.value as string}
                      error={state.jobsForm.status.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "status")
                      }
                      helperText={
                        state.jobsForm.status.error.required &&
                        t("admin.jobs.status_error_required")
                      }
                    >
                      {listStatus().map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-description">
                    <TextareaAutosize
                      placeholder={getLabelFormatted(t("admin.jobs.label_description"), state.jobsForm.description.rules.required)}
                      minRows={5}
                      name="description"
                      required={state.jobsForm.description.rules.required}
                      value={state.jobsForm.description.value as string}
                      onChange={onChangeDescription}
                    />
                  </div>
                </div>

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-client">
                    <TextField
                      id="client"
                      select={true}
                      label={t("admin.jobs.label_client")}
                      value={state.jobsForm.clientId.value as string}
                      error={state.jobsForm.clientId.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "clientId")
                      }
                    //helperText={state.jobs.jobsForm.clientId.error.required && t('admin.jobs.client_error_required')}
                    >
                      <MenuItem key={0} value={0}>
                        {t("admin.jobs.optionDefault")}
                      </MenuItem>
                      {stateClients.clientsData.map((client) => (
                        <MenuItem key={client.clientId} value={client.clientId}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="swissinx-field field-seniority">
                    <FieldTextMui
                      placeholder=""
                      label={t("admin.jobs.label_seniority")}
                      name="seniority"
                      required={state.jobsForm.seniority.rules.required}
                      requiredError={t("admin.jobs.seniority_error_required")}
                      value={state.jobsForm.seniority.value as string}
                      error={
                        state.jobsForm.seniority.error.required ||
                        state.jobsForm.seniority.error.format
                      }
                      fullWidth={false}
                      onChange={({ name, value, error }) =>
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
                          value: { name, value, error },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-salaryCurrency">
                    <TextField
                      id="salaryCurrency"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_salaryCurrency"), state.jobsForm.salaryCurrency.rules.required)}
                      value={state.jobsForm.salaryCurrency.value as string}
                      error={state.jobsForm.salaryCurrency.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "salaryCurrency")
                      }
                    helperText={state.jobsForm.salaryCurrency.error.required && t('admin.jobs.salaryCurrency_error_required')}
                    >
                      <MenuItem key={0} value={""}>
                        {t("admin.jobs.optionDefault")}
                      </MenuItem>
                      {listCurrency().map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="swissinx-field field-salaryMin">
                    <FieldTextMui
                      placeholder=""                      
                      label={t("admin.jobs.label_salaryMin")}
                      name="salaryMin"
                      formatNumber={true}
                      required={state.jobsForm.salaryMin.rules.required}
                      requiredError={t("admin.jobs.salaryMin_error_required")}
                      value={state.jobsForm.salaryMin.value as string}
                      error={
                        state.jobsForm.salaryMin.error.required ||
                        state.jobsForm.salaryMin.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.salaryMin_error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              state.jobsForm.salaryMax.value as string,
                              value
                            ),
                          messageError: t("admin.jobs.salaryMax_error_value"),
                        },
                      ]}
                      fullWidth={false}
                      onChange={({ name, value, error }) => {
                        testSalary(
                          value,
                          state.jobsForm.salaryMax.value as string
                        );
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
                          value: {name, value, error},
                        });
                      }}
                    />
                  </div>
                  <div className="swissinx-field field-salaryMax">
                    <FieldTextMui
                      placeholder=""                      
                      label={t("admin.jobs.label_salaryMax")}
                      name="salaryMax"
                      formatNumber={true}
                      required={state.jobsForm.salaryMax.rules.required}
                      requiredError={t("admin.jobs.salaryMax_error_required")}
                      value={
                        state.jobsForm.salaryMax.value as string
                      }
                      error={
                        state.jobsForm.salaryMax.error.required ||
                        state.jobsForm.salaryMax.error.format
                      }
                      validators={[
                        {
                          validator: validateNumber,
                          messageError: t("admin.jobs.salaryMax_error_format"),
                        },
                        {
                          validator: (value) =>
                            validateSalaryMax(
                              value,
                              state.jobsForm.salaryMin.value as string
                            ),
                          messageError: t("admin.jobs.salaryMax_error_value"),
                        },
                      ]}
                      fullWidth={false}
                      onChange={({ name, value, error }) => {
                        testSalary(
                          state.jobsForm.salaryMin.value as string,
                          value
                        );
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
                          value: {name, value, error},
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-salaryDisclose">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.jobsForm.salaryDisclose.value as boolean}
                          onChange={(event) =>
                            onChangeCheck(event, "salaryDisclose")
                          }
                        />
                      }
                      label={t("admin.jobs.label_salaryDisclose") as string}
                    />
                  </div>
                  <div className="swissinx-field field-clientDisclose">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.jobsForm.clientDisclose.value as boolean}
                          onChange={(event) =>
                            onChangeCheck(event, "clientDisclose")
                          }
                        />
                      }
                      label={t("admin.jobs.label_clientDisclose") as string}
                    />
                  </div>
                </div>
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-language">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="languageId">                      
                        {getLabelFormatted(t("admin.jobs.label_language"), state.jobsForm.languageId.rules.required)}
                      </InputLabel>
                      <Select
                        labelId="languageId"
                        id="languageId"
                        label={t("admin.jobs.label_language")}
                        multiple
                        value={state.jobsForm.languageId.value as number[]}
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent<number[]>,
                            "languageId"
                          )
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label={t("admin.jobs.label_language")}
                          />
                        }
                        error={state.jobsForm.languageId.error.required}
                        renderValue={(selected: number[]) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: number) => (
                              <Chip
                                key={value}
                                label={getLabelLanguage(
                                  value,
                                  stateLanguages.data,
                                  stateLanguages.loading,
                                  stateLanguages.error
                                )}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {stateLanguages.data.map((language) => (
                          <MenuItem
                            key={language.languageId}
                            value={language.languageId}
                          >
                            {language.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {state.jobsForm.languageId.error.required && <FiledError messageError={t("admin.jobs.language_error_required")} />}
                  </div>

                <div className="swissinx-field field-autocomplete-multiple">
                  <Autocomplete
                    multiple
                    id="skills"
                    sx={{ width: 300 }}
                    options={getSkillOptions(stateSkills.data, stateSkillGroup.data)}
                    getOptionLabel={(option) =>option.description}
                    value={skill}
                    isOptionEqualToValue={(option, value) => option.id === value.id}                           
                    defaultValue={getDefaultSkills(state.jobSelected.data ? state.jobSelected.data.skills:[], stateSkills.data, stateSkillGroup.data)}
                    onChange={changeSkill}
                    /* renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>                    
                        {option.description}
                      </Box>
                    )} */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabelFormatted(t("admin.jobs.label_skills"), state.jobsForm.skillId.rules.required)}  
                        error={state.jobsForm.skillId.error.required} 
                        helperText={state.jobsForm.skillId.error.required &&
                          t("admin.jobs.skills_error_required")}                  
                        /* inputProps={{
                          ...params.inputProps,
                          autoComplete: 'country', // disable autocomplete and autofill
                        }} */                              
                      />
                    )}
                  />   
                </div>

                  <div className="swissinx-field field-market">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="marketId">
                        {getLabelFormatted(t("admin.jobs.label_markets"), state.jobsForm.marketId.rules.required)}                       
                      </InputLabel>
                      <Select
                        labelId="marketId"
                        id="marketId"
                        label={t("admin.jobs.label_markets")}
                        multiple
                        value={state.jobsForm.marketId.value as number[]}
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent<number[]>,
                            "marketId"
                          )
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label={t("admin.jobs.label_markets")}
                          />
                        }
                        error={state.jobsForm.marketId.error.required}
                        renderValue={(selected: number[]) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: number) => (
                              <Chip
                                key={value}
                                label={getLabelMarket(
                                  value,
                                  stateMarkets.data,
                                  stateMarkets.loading,
                                  stateMarkets.error
                                )}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {stateMarkets.data.map((market) => (
                          <MenuItem key={market.marketId} value={market.marketId}>
                            {market.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {state.jobsForm.marketId.error.required && <FiledError messageError={t("admin.jobs.markets_error_required")} />}
                  </div>
                </div>
                <div className="swissinx-field-container">
                  <AutocompleteSelect
                    inputLabel={t("admin.jobs.label_country")}
                    required={state.jobsForm.countryId.rules.required}
                    inputValue={country}
                    options={stateLocation.countries.data}
                    onChange={changeCountry}
                    inputHelperText={
                      state.jobsForm.countryId.error.required &&
                      t("admin.jobs.country_error_required")
                    }
                    inputError={state.jobsForm.countryId.error.required}
                  />
                  <AutocompleteSelect
                    inputLabel={t("admin.jobs.label_region")}
                    required={state.jobsForm.regionId.rules.required}
                    inputValue={region}
                    options={stateLocation.regions.data}
                    onChange={changeRegion}
                    inputHelperText={
                      state.jobsForm.regionId.error.required &&
                      t("admin.jobs.region_error_required")
                    }
                    inputError={state.jobsForm.regionId.error.required}
                  />
                  <AutocompleteSelect
                    inputLabel={t("admin.jobs.label_city")}
                    required={state.jobsForm.cityId.rules.required}
                    inputValue={city}
                    options={stateLocation.cities.data}
                    onChange={changeCity}
                    inputHelperText={
                      state.jobsForm.cityId.error.required &&
                      t("admin.jobs.city_error_required")
                    }
                    inputError={state.jobsForm.cityId.error.required}
                  />
                </div>

                <div className="swissinx-field-container">
                  <div className="swissinx-field field-countryExceptionIds">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="countriesToExclude">                       
                        {getLabelFormatted(t("admin.jobs.label_countryExceptionIds"), state.jobsForm.countryExceptionIds.rules.required)} 
                      </InputLabel>
                      <Select
                        labelId="countryExceptionIds"
                        id="countryExceptionIds"
                        label={t("admin.jobs.label_countryExceptionIds")}
                        multiple
                        value={
                          state.jobsForm.countryExceptionIds.value as number[]
                        }
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent<number[]>,
                            "countryExceptionIds"
                          )
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label={t("admin.jobs.label_countryExceptionIds")}
                          />
                        }
                        error={state.jobsForm.countryExceptionIds.error.required}
                        renderValue={(selected: number[]) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: number) => (
                              <Chip
                                key={value}
                                label={getLabelCountriesToExclude(value)}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {stateLocation.countries.data.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <h3>{t("admin.jobs.label_questions")}</h3>

              <div className="swissinx-container-fields">
                <div className="swissinx-field-container">
                  <div className="swissinx-field field-template">
                    <TextField
                      id="templateId"
                      select={true}
                      label={getLabelFormatted(t("admin.jobs.label_template"), state.jobsForm.templateId.rules.required)}
                      value={state.jobsForm.templateId.value as string}
                      error={state.jobsForm.templateId.error.required}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeSelect(event, "templateId")
                      }
                    //helperText={state.jobs.jobsForm.clientId.error.required && t('admin.jobs.client_error_required')}
                    >
                      <MenuItem
                        key={OPTION_NO_TEMPLATE}
                        value={OPTION_NO_TEMPLATE}
                      >
                        {t("admin.jobs.templateNone")}
                      </MenuItem>
                      <MenuItem
                        key={OPTION_CUSTOM_TEMPLATE}
                        value={OPTION_CUSTOM_TEMPLATE}
                      >
                        {t("admin.jobs.templateCustom")}
                      </MenuItem>
                      {state.templates.data.map((template) => (
                        <MenuItem
                          key={template.templateId}
                          value={template.templateId}
                        >
                          {template.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <div className="swissinx-container-questions">
                  {state.jobsForm.templateId.value !== OPTION_NO_TEMPLATE &&
                    state.jobsForm.templateId.value !==
                    OPTION_CUSTOM_TEMPLATE && (
                     /*  <Table data={questions} headers={headersTemplate} /> */
                      <TableTemplates
                      data={questions}
                      headers={headersTemplate}
                      setData={(questions:Question[])=>{
                        dispatch<TypeActionJobsActions>({
                          type: ActionJobsType.JOBS_FORM_SEND_VALUES,
                          value: {
                            name: 'templateId',
                            value: '-1',
                            error: { required: false, format: false },
                          },
                        });
                        setQuestions(questions)
                      }}                     
                    />
                    )}
                  {state.jobsForm.templateId.value === OPTION_CUSTOM_TEMPLATE && (
                    <TableTemplates
                      data={questions}
                      headers={headersTemplate}
                      setData={setQuestions}
                    />
                  )}
                  {state.jobsForm.templateId.value === OPTION_CUSTOM_TEMPLATE &&
                    questions.length === 0 && (
                      <FiledError
                        messageError={t("admin.templates.error_questionRequired")}
                      ></FiledError>
                    )}
                </div>


              </div>
              <div className="swissinx-container-buttons">
                <button
                  className="swissinx-button-back"
                  type="button"
                  onClick={props.back}
                >
                  {t("admin.jobs.text_button_back")}
                </button>
                <button className="swissinx-button-submit" type="submit">
                  {props.add
                    ? t("admin.jobs.text_button_submit_create")
                    : t("admin.jobs.text_button_submit_edit")}
                </button>
              </div>
            </div>
          }
        ></Form>
      )}
    </div>
  );
}

export default JobsEdit;
