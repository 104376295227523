import { TypeActions } from '../../models/actions/typeActions';

import { newFeeForm } from '../../utils/utils';

import { ActionFeeType } from '../../models/actions/typeFeeActions';
import TypeFeeState from '../../models/initialState/typeFeeState';
import { initialFeeState } from '../inititalState/initialFeeState';
import FeeForm from '../../models/forms/FeeForm';

const feeReducer = (state:TypeFeeState = initialFeeState, action:TypeActions):TypeFeeState => {
    switch (action.type) {

        case ActionFeeType.FEE_FORM_SEND_VALUES: {
            const name = action.value.name;           
            let feeForm:FeeForm = {...state.feeForm};
            feeForm[name as keyof FeeForm] = {...feeForm[name as keyof FeeForm], value: action.value.value, error: action.value.error};         
            return {...state, feeForm: { ...feeForm} }
        }

        case ActionFeeType.FEE_ERROR_REQUIRED : {
            const name = action.value;           
            let feeForm:FeeForm = {...state.feeForm};
            feeForm[name as keyof FeeForm] =  {...feeForm[name as keyof FeeForm], error: {required: true, format: feeForm[name as keyof FeeForm].error.format}};
            return {...state, feeForm: { ...feeForm}  }      
        }

        case ActionFeeType.FEE_FORM_NEW: {
            let feeForm:FeeForm = newFeeForm();           
            return {...state, feeForm: { ...feeForm} }
        }

        case ActionFeeType.FEE_FORM_UPDATE: {
            let feeForm:FeeForm = newFeeForm();           
            return {...state, feeForm: { ...feeForm, ...action.value} }
        }
      
        case ActionFeeType.FEE_LOADING: {         
            return {...state, loading: true, error: false}
        }

        case ActionFeeType.FEE_SUCCESS: {           
            return {...state, data: action.value.data, loading: false, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionFeeType.FEE_ERROR: {           
            return {...state, loading: false, error: true}
        }

        case ActionFeeType.FEE_EDIT_LOADING: {           
            return {...state, loading: false, error: false, edit: false}
        }

        case ActionFeeType.FEE_EDIT_SUCCESS: {           
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionFeeType.FEE_ADD_LOADING: {           
            return {...state, loading: false, error: false, add: false}
        }

        case ActionFeeType.FEE_ADD_SUCCESS: {           
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionFeeType.FEE_DELETE_LOADING: {           
            return {...state, loading: false, error: false, delete: false}
        }

        case ActionFeeType.FEE_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionFeeType.FEE_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionFeeType.FEE_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionFeeType.FEE_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_LOADING: {
            const pushListFee = state.pushListFee;     
            return {...state, pushListFee: {...pushListFee, loading: true, error: false, data:[]}}
        }

        case ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_SUCCESS: {           
            const pushListFee = state.pushListFee;     
            return {...state, pushListFee: {...pushListFee, loading: false, error: false, data: action.value}}
        }

        case ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_ERROR: {           
            const pushListFee = state.pushListFee;     
            return {...state, pushListFee: {...pushListFee, loading: false, error: true, data: []}}
        }

        case ActionFeeType.FEE_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
    }
    return {...state};
}
export default feeReducer;