import { TypeActions } from '../../models/actions/typeActions';
import { ActionLanguagesType } from '../../models/actions/typeLanguagesActions';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import { initialLanguagesState } from '../inititalState/initialLanguagesState';

const languagesReducer = (state:TypeLanguagesState = initialLanguagesState, action:TypeActions):TypeLanguagesState => {
    switch (action.type) {        

        case ActionLanguagesType.LANGUAGES_LOADING: {           
            return {...state,  loading: true, error: false, messageError: ''}
        }

        case ActionLanguagesType.LANGUAGES_SUCCESS: {            
            return {...state, loading: false, data: action.value.data, error: false, messageError: '',
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionLanguagesType.LANGUAGES_ERROR: {            
            return {...state,  loading: false, error: true, data: [], messageError: action.value}
        }
        
        case ActionLanguagesType.LANGUAGES_EDIT_LOADING: {
            return {...state, loading: true, error: false, edit: false}
        }

        case ActionLanguagesType.LANGUAGES_EDIT_SUCCESS: {
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionLanguagesType.LANGUAGES_DELETE_LOADING: {           
            return {...state, loading: true, error: false, delete: false}
        }

        case ActionLanguagesType.LANGUAGES_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionLanguagesType.LANGUAGES_ADD_LOADING: {           
            return {...state, loading: true, error: false, add: false}
        }

        case ActionLanguagesType.LANGUAGES_ADD_SUCCESS: {            
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionLanguagesType.LANGUAGES_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionLanguagesType.LANGUAGES_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionLanguagesType.LANGUAGES_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionLanguagesType.LANGUAGES_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
        
    }
    return {...state};
}
export default languagesReducer;