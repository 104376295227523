import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import FeeDto from '../models/dto/FeeDto';
import { actionFeeAddLoading, actionFeeDeleteLoading, actionFeeEditLoading, ActionFeeType, actionFeeLoading, actionFeeTemplateJobsPushLoading } from '../models/actions/typeFeeActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';

const baseUrlApi:string = getBaseUrlApi();
const urlFee = baseUrlApi + '/api/Job/GetFeeTemplates';
const urlFeeEdit = baseUrlApi + '/api/Job/updateFeeTemplate';
const urlFeeCreate = baseUrlApi + '/api/Job/createFeeTemplate';
const urlFeeDelete = baseUrlApi + '/api/Job/DeleteFeeTemplate';
const urlListFeeJobTemplate = baseUrlApi + '/api/Job/GetFeeTemplatesMatchingWithJob';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* loadFee(action: actionFeeLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlFee, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:FeeDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionFeeType.FEE_SUCCESS, value: {data: data, reset: action.value.reset}});           
        } else {           
            yield call(checkError, response.status, {type: ActionFeeType.FEE_ERROR});            
        }                
        
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionFeeType.FEE_ERROR});        
    }
}

function* createFee(action: actionFeeAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlFeeCreate, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionFeeType.FEE_ADD_SUCCESS, value: true});    
            yield put<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: true}});            
        } else {           
            yield call(checkError, response.status, {type: ActionFeeType.FEE_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionFeeType.FEE_ERROR});
    }
}

function* editFee(action: actionFeeEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlFeeEdit, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionFeeType.FEE_EDIT_SUCCESS, value: true});              
            yield put<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionFeeType.FEE_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionFeeType.FEE_ERROR});
    }
}

function* deleteFee(action: actionFeeDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlFeeDelete + '?id=' + action.value, getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionFeeType.FEE_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionFeeType.FEE_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionFeeType.FEE_ERROR});
    }
}

function* listFeeJobTemplate(action: actionFeeTemplateJobsPushLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlListFeeJobTemplate + '?jobId=' + action.value, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:FeeDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_SUCCESS, value: data});            
        } else {           
            yield call(checkError, response.status, {type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_ERROR});            
        }                
        
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_ERROR});        
    }
}


function* loadFeeWatcher() {
    yield takeLatest(ActionFeeType.FEE_LOADING, loadFee);
}

function* createFeeWatcher() {
    yield takeLatest(ActionFeeType.FEE_ADD_LOADING, createFee);
}

function* editFeeWatcher() {
    yield takeLatest(ActionFeeType.FEE_EDIT_LOADING, editFee);
}

function* listFeeJobTemplateWatcher() {
    yield takeLatest(ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_LOADING, listFeeJobTemplate);
}

function* deleteFeeWatcher() {
    yield takeLatest(ActionFeeType.FEE_DELETE_LOADING, deleteFee);
}


export default function* rootFeeSaga() {
    yield all([
        loadFeeWatcher(),
        createFeeWatcher(),
        editFeeWatcher(),
        deleteFeeWatcher(),
        listFeeJobTemplateWatcher()
    ])
}