import JobsTemplateDto from "../dto/JobTemplateDto";

export enum ActionTemplateJobType {
    
    TEMPLATE_JOB_LOADING = 'TEMPLATE_JOB_LOADING',
    TEMPLATE_JOB_SUCCESS = 'TEMPLATE_JOB_SUCCESS',
    TEMPLATE_JOB_ERROR = 'TEMPLATE_JOB_ERROR',
    TEMPLATE_JOB_ADD_LOADING = 'TEMPLATE_JOB_ADD_LOADING',
    TEMPLATE_JOB_ADD_SUCCESS = 'TEMPLATE_JOB_ADD_SUCCESS',
    TEMPLATE_JOB_ADD_ERROR = 'TEMPLATE_JOB_ADD_ERROR',
    TEMPLATE_JOB_EDIT_LOADING = 'TEMPLATE_JOB_EDIT_LOADING',
    TEMPLATE_JOB_EDIT_SUCCESS = 'TEMPLATE_JOB_EDIT_SUCCESS',
    TEMPLATE_JOB_EDIT_ERROR = 'TEMPLATE_JOB_EDIT_ERROR',
    TEMPLATE_JOB_DELETE_LOADING = 'TEMPLATE_JOB_DELETE_LOADING',
    TEMPLATE_JOB_DELETE_SUCCESS = 'TEMPLATE_JOB_DELETE_SUCCESS',
    TEMPLATE_JOB_DELETE_ERROR = 'TEMPLATE_JOB_DELETE_ERROR',
    TEMPLATE_CHANGE_SEARCH_TABLE = 'TEMPLATE_CHANGE_SEARCH_TABLE',
    TEMPLATE_CHANGE_PAGE_TABLE = 'TEMPLATE_CHANGE_PAGE_TABLE',
    TEMPLATE_CHANGE_PAGE_SIZE = 'TEMPLATE_CHANGE_PAGE_SIZE',
    TEMPLATE_CHANGE_FILTER = 'TEMPLATE_CHANGE_FILTER'
}

export interface actionTemplateJobLoading {
    type: ActionTemplateJobType.TEMPLATE_JOB_LOADING      
}

export interface actionTemplateJobSuccess {
    type: ActionTemplateJobType.TEMPLATE_JOB_SUCCESS,
    value: JobsTemplateDto[]    
}

export interface actionTemplateJobError {
    type: ActionTemplateJobType.TEMPLATE_JOB_ERROR   
}

export interface actionTemplateJobAddLoading {
    type: ActionTemplateJobType.TEMPLATE_JOB_ADD_LOADING,
    value: JobsTemplateDto 
}

export interface actionTemplateJobAddSuccess {
    type: ActionTemplateJobType.TEMPLATE_JOB_ADD_SUCCESS,
    value: boolean     
}

export interface actionTemplateJobAddError {
    type: ActionTemplateJobType.TEMPLATE_JOB_ADD_ERROR   
}

export interface actionTemplateJobDeleteLoading {
    type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_LOADING,
    value: number     
}

export interface actionTemplateJobDeleteSuccess {
    type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_SUCCESS,
    value: boolean    
}

export interface actionTemplateJobDeleteError {
    type: ActionTemplateJobType.TEMPLATE_JOB_DELETE_ERROR   
}

export interface actionTemplateJobEditLoading {
    type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_LOADING,
    value: JobsTemplateDto
}

export interface actionTemplateJobEditSuccess {
    type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_SUCCESS,
    value: boolean      
}

export interface actionTemplateJobEditError {
    type: ActionTemplateJobType.TEMPLATE_JOB_EDIT_ERROR   
}

export interface actionTemplateChangeSerachTable {
    type: ActionTemplateJobType.TEMPLATE_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionTemplateChangePageSizeTable {
    type: ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionTemplateChangePageTable {
    type: ActionTemplateJobType.TEMPLATE_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionTemplateChangeFilterTable {
    type: ActionTemplateJobType.TEMPLATE_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeTemplateJobActions =    actionTemplateJobLoading | actionTemplateJobSuccess | actionTemplateJobError |
                                        actionTemplateJobAddLoading | actionTemplateJobAddSuccess | actionTemplateJobAddError |
                                        actionTemplateJobDeleteLoading | actionTemplateJobDeleteSuccess | actionTemplateJobDeleteError |
                                        actionTemplateJobEditLoading | actionTemplateJobEditSuccess | actionTemplateJobEditError |
                                        actionTemplateChangeSerachTable | actionTemplateChangePageSizeTable | actionTemplateChangePageTable |
                                        actionTemplateChangeFilterTable;
                                