
import React, {useState, useEffect, SyntheticEvent, ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../../models/actions/typeActions';
import { useSelector, useDispatch } from 'react-redux';
import {CityDto} from '../../../models/dto/LocationDto'; 
import CitiesList from './CitiesList';
import ModalConfirmDelete from '../../commons/ModalConfirmDelete';
import ComponentError from '../../commons/ComponentError';
import Loading from '../../commons/Loading';
import TypeState from '../../../models/initialState/typeState';
import Notification from '../../commons/Notification';
import CitiesEdit from './CitiesEdit';
import { ActionLocationType } from '../../../models/actions/typeLocationActions';
import TypeLocationState from '../../../models/initialState/typeLocationState';
//import './Cities.scss';
import { COUNTRY_DEFAULT, REGION_DEFAULT } from '../../../constanst/GlobalConstants';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Regions(props: Props) {
  const [editCity, setEditCity] = useState(false);
  const [showModaldeleteCity, setShowModalDeleteCity] = useState(false);
  const [addCity, setAddCity] = useState(false);  
  const [citySelected, setCitySelected] = useState<CityDto>({id: 0, description: ''});
  const [countrySelected, setCountrySelected] = useState(COUNTRY_DEFAULT);
  const [regionSelected, setRegionSelected] = useState(REGION_DEFAULT)

  const { t } = useTranslation();
  const state:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const dispatch = useDispatch();  
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}}); 
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: countrySelected}); 
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: regionSelected});     
      setEditCity(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}}); 
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: countrySelected});
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: regionSelected});       
    }

  }, []);  

  const edit = (event:SyntheticEvent, index:CityDto) => {    
    setAddCity(false)
    setCitySelected(index);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_FORM_UPDATE, value: index}); 
    setEditCity(true);
  }

  const removeConfirmation = (event:SyntheticEvent, city:CityDto) => {
    setCitySelected(city);
    setShowModalDeleteCity(true);
  }

  const remove = () => {
    setShowModalDeleteCity(false);
    if (citySelected !== null && citySelected.id !== undefined) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_DELETE_LOADING, 
        value: {idRegion : regionSelected, idCountry: countrySelected, idCity: citySelected.id}});
    }
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_FORM_NEW});
    setEditCity(true);
    setAddCity(true);
  }  

  const back = () => {
    setEditCity(false);
  }

  const update = () => {
    setEditCity(false);    
    if (addCity) {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ADD_LOADING, value: {
        name: state.cities.citiesForm.name.value as string, regionId: regionSelected }
      });      
    } else {
      dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_EDIT_LOADING, value: {
        id: citySelected.id, name: state.cities.citiesForm.name.value as string, regionId: regionSelected }
      });
    }
  }  

  const changeCountry = (event:ChangeEvent<HTMLInputElement>) => {
    const country = parseInt(event.target.value);
    setCountrySelected(country);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: country});   
  }

  const changeRegion = (event:ChangeEvent<HTMLInputElement>) => {
    const region = parseInt(event.target.value);
    setRegionSelected(region);
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: region});   
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.cities.loading && !state.cities.error && <Loading />}
            {!state.cities.loading && !state.cities.error && !editCity && <CitiesList
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        country={countrySelected}
                                                                        region={regionSelected}
                                                                        changeCountry={changeCountry}
                                                                        changeRegion={changeRegion}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.cities.loading && !state.cities.error && editCity && <CitiesEdit update={update} back={back} add={addCity}/>}
           
            {showModaldeleteCity && <ModalConfirmDelete show={showModaldeleteCity} title={t('admin.cities.modal_delete_title')} back={()=>setShowModalDeleteCity(false)} confirm={remove} textButtonBack={t('admin.cities.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.cities.modal_delete_buttonDelete')} />}
            
            {state.cities.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: 100})}/>}
            
            <Notification 
              edit={state.cities.edit}
              add={state.cities.add}
              delete={state.cities.delete}
              editMessage={t('admin.cities.editMessage')}
              addMessage={t('admin.cities.addMessage')}
              delteMessage={t('admin.cities.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionLocationType.LOCATION_CITIES_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Regions;
