import './home.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import logoDark from '../../assest/swapp_logo_dark.svg';
import logoLight from '../../assest/swapp_logo_light.svg';
import downloadAppStore from '../../assest/home/DownloadAppStore.png';
import downloadGooglePlay from '../../assest/home/DownloadGooglePlay.png';
import sharingIsCaring from '../../assest/home/SharingIsCaring.png';
import differentIndustries from '../../assest/home/DifferentIndustries.png';
import newLinkInChain from '../../assest/home/NewLinkInChain.png';
import earnRewards from '../../assest/home/EarnRewards.png';
import leftArrowBlue from '../../assest/home/LeftArrow_Blue.svg';
import rightArrowBlue from '../../assest/home/RightArrow_Blue.svg';
import appStoreMockup from '../../assest/home/Swapp_TwoPhones_NoBG.png';
import getAlertsBeInformed from '../../assest/home/GetAlertsBeInformed.png';
import justShare from '../../assest/home/JustShare.png';
import yourWay from '../../assest/home/YourWay.png';
import dontWaitUp from '../../assest/home/DontWaitUp.png';
import superAccessible from '../../assest/home/SuperAccessible.png';
import defineYourNetwork from '../../assest/home/DefineYourNetwork.png';
import shareLinks from '../../assest/home/ShareLinks.png';
import checkActivity from '../../assest/home/CheckActivity.png';
import earnRewards_icon from '../../assest/home/EarnRewards_icon.png';

function HOME(){

const { t } = useTranslation();

const [step, setStep] = useState(0); 
const [sliderStep, setSliderStep] = useState(1);
const [marginLeft, setMarginLeft] = useState(1300);
const [increase, setIncrease] = useState(650);

useEffect(()=>{
    const pageWidth  = document.documentElement.scrollWidth;    
    /* if (pageWidth > 1200) {
        setIncrease(570);    
    } else if (pageWidth > 400 && pageWidth < 1200) {
        setIncrease(370);  
    } else if (pageWidth < 400) {
        setIncrease(270); 
    } */
}, [])

const slideToLeft = ()=> {
    if (step>0) {
        setStep(step-1);
    }
}
const slideToRight = ()=> {
    if (step<2) {
        setStep(step+1);
    }
}

const sliderScrollLeft = ()=> {
    if (sliderStep > 1) {
        setSliderStep(sliderStep - 1);
        setMarginLeft(marginLeft + increase);
    }    
}

const sliderScrollRight = ()=> {
    if (sliderStep < 5) {
        setSliderStep(sliderStep + 1);
        setMarginLeft(marginLeft - increase);
    }    
}

    return <div className='swissinx-home'>

        <header>
            <div>
                <img className='logo' src={logoLight} alt="" />
                <nav>
                    <ul>
                        <li><a href='#about'>{t('home.header.about')}</a></li>
                        <li><a href='#how-it-works'>{t('home.header.howItWorks')}</a></li>
                        <li><a href='#benefits'>{t('home.header.benefits')}</a></li>
                        <li><a className='header-cta' href='#get-started'>{t('home.header.getStarted')}</a></li>
                    </ul>
                </nav>
            </div>
        </header>

        <section className='home-fold'>
            <div className='home-fold-image'></div>
            <div className='home-fold-description'>
                <img src={logoLight} alt="" />
                <h2 dangerouslySetInnerHTML={{__html: t('home.fold.title')}}></h2>
                <p>{t('home.fold.description')}</p>
                <a className='home-button' href='#get-started'>{t('home.fold.cta')}</a>
            </div>
        </section>

        <section className='home-fold-banner'>
            <div className='home-container'>
                <div>
                    <h2 className='white'><strong className='green'>{t('home.fold.bannerTitle')}</strong></h2>
                    <h2 className='white'>{t('home.fold.bannerDescription')}</h2>
                </div>
                <div>
                    <a className='home-button green' href='#get-started'>{t('home.fold.bannerCta')}</a>
                </div>
            </div>
        </section>

        <section id='about' className='home-about'>
            <div className='home-container'>
                <div className='home-about-img'>
                    <img src={sharingIsCaring} alt="" />
                </div>
                <div>
                    <h2>{t('home.about.title1')}</h2>
                    <p>{t('home.about.description1')}</p>
                </div>
            </div>
            <div className='home-container'>
                <div>
                    <h2>{t('home.about.title2')}</h2>
                    <p>{t('home.about.description2')}</p>
                </div>
                <div className='home-about-img'>
                    <img src={differentIndustries} alt="" />
                </div>
            </div>
            <div className='home-container'>
                <div className='home-about-img'>
                    <img src={newLinkInChain} alt="" />
                </div>
                <div>
                    <h2>{t('home.about.title3')}</h2>
                    <p>{t('home.about.description3')}</p>
                </div>
            </div>
            <div className='home-container'>
                <div>
                    <h2>{t('home.about.title4')}</h2>
                    <p>{t('home.about.description4')}</p>
                    <div className='store-buttons'>
                        <a href={t('publicJob.urlAppStore')}><img src={downloadAppStore} alt="" /></a>
                        <a href={t('publicJob.urlGooglePlay')}><img src={downloadGooglePlay} alt="" /></a>
                    </div>
                </div>
                <div className='home-about-img'>
                    <img src={earnRewards} alt="" />
                </div>
            </div>
        </section>
        
        <section className='home-about-slider'>
            {step>0 &&<img className='home-about-slider-arrow home-about-slider-arrow-left' src={leftArrowBlue} onClick={slideToLeft} alt="" />}
            {step<2 &&<img className='home-about-slider-arrow home-about-slider-arrow-right' src={rightArrowBlue} onClick={slideToRight} alt="" />}
            {step==0 && <div className='home-about-slider-slide-1'>
                <div className='home-about-slider-description'>
                    <span className='quote'>"</span>
                    <h2 className='white' dangerouslySetInnerHTML={{__html: t('home.testimonial.testimonial1')}}></h2>
                </div>
                <div className='home-about-slider-cta'>
                    <a className='home-button green' href='#get-started'>{t('home.testimonial.cta1')}</a>
                </div>
            </div>}
            {step==1 && <div className='home-about-slider-slide-2'>
                <div className='home-about-slider-description'>
                    <span className='quote'>"</span>
                    <h2 className='white' dangerouslySetInnerHTML={{__html: t('home.testimonial.testimonial2')}}></h2>
                </div>
                <div className='home-about-slider-cta'>
                    <a className='home-button green' href='#get-started'>{t('home.testimonial.cta2')}</a>
                </div>
            </div>}
            {step==2 && <div className='home-about-slider-slide-3'>
                <div className='home-about-slider-description'>
                    <span className='quote'>"</span>
                    <h2 className='white' dangerouslySetInnerHTML={{__html: t('home.testimonial.testimonial3')}}></h2>
                </div>
                <div className='home-about-slider-cta'>
                    <a className='home-button green' href='#get-started'>{t('home.testimonial.cta3')}</a>
                </div>
            </div>}
        </section>

        <section id='how-it-works' className='home-howitworks'>
            <h2>{t('home.howItWorks.title')}</h2>
            <div className='home-howitworks-slider'>
                <div className='home-howitworks-slider-arrow-container'>
                    <img className={`home-howitworks-slider-arrow home-howitworks-slider-arrow-left ${sliderStep === 1 ? 'disabled' : ''}` }  src={leftArrowBlue} alt="" onClick={sliderScrollLeft}/>
                    <img className={`home-howitworks-slider-arrow home-howitworks-slider-arrow-right ${sliderStep === 5 ? 'disabled' : ''}`} src={rightArrowBlue} alt="" onClick={sliderScrollRight}/>
                </div>
                <div className='home-howitworks-slider-container' style={{'marginLeft': marginLeft}}>
                    <div className={`home-howitworks-slider-slide ${sliderStep === 1 ? 'active' : ''}`}>
                        <img src={defineYourNetwork} alt="" />
                        <h2 dangerouslySetInnerHTML={{__html: t('home.howItWorks.title1')}}></h2>
                        <p>{t('home.howItWorks.description1')}</p>
                    </div>
                    <div className={`home-howitworks-slider-slide ${sliderStep === 2 ? 'active' : ''}`}>
                        <img src={getAlertsBeInformed} alt="" />
                        <h2 dangerouslySetInnerHTML={{__html: t('home.howItWorks.title2')}}></h2>
                        <p>{t('home.howItWorks.description2')}</p>
                    </div>
                    <div className={`home-howitworks-slider-slide ${sliderStep === 3 ? 'active' : ''}`}>
                        <img src={shareLinks} alt="" />
                        <h2 dangerouslySetInnerHTML={{__html: t('home.howItWorks.title3')}}></h2>
                        <p>{t('home.howItWorks.description3')}</p>
                    </div>
                    <div className={`home-howitworks-slider-slide ${sliderStep === 4 ? 'active' : ''}`}>
                        <img src={checkActivity} alt="" />
                        <h2 dangerouslySetInnerHTML={{__html: t('home.howItWorks.title4')}}></h2>
                        <p>{t('home.howItWorks.description4')}</p>
                    </div>
                    <div className={`home-howitworks-slider-slide ${sliderStep === 5 ? 'active' : ''}`}>
                        <img src={earnRewards_icon} alt="" />
                        <h2 dangerouslySetInnerHTML={{__html: t('home.howItWorks.title5')}}></h2>
                        <p>{t('home.howItWorks.description5')}</p>
                    </div>
                </div>
            </div>
            <h2>{t('home.howItWorks.ready')}</h2>
            <a className='home-button' href='#get-started'>{t('home.howItWorks.cta')}</a>
        </section>

        <section className='home-download-store'>
            <div className='home-download-store-description'>
                <h2>{t('home.bannerDownload1.title')}</h2>
                <p dangerouslySetInnerHTML={{__html: t('home.bannerDownload1.description1')}}></p>
                <p>{t('home.bannerDownload1.description2')}</p>
                <div className='store-buttons'>
                    <a href={t('publicJob.urlAppStore')}><img src={downloadAppStore} alt="" /></a>
                    <a href={t('publicJob.urlGooglePlay')}><img src={downloadGooglePlay} alt="" /></a>
                </div>
            </div>
            <div className='home-download-store-image'>
                <img src={appStoreMockup} alt="" />
            </div>
        </section>

        <section id='benefits' className='home-benefits'>
            <h2>{t('home.whySwapp.title')}</h2>
            <div className='home-benefits-list'>
                <div className='home-benefits-list-item'>
                    <img src={getAlertsBeInformed} alt="" />
                    <h2>{t('home.whySwapp.title1')}</h2>
                    <p>{t('home.whySwapp.description1')}</p>
                </div>
                <div className='home-benefits-list-item'>
                    <img src={justShare} alt="" />
                    <h2>{t('home.whySwapp.title2')}</h2>
                    <p>{t('home.whySwapp.description2')}</p>
                </div>
                <div className='home-benefits-list-item'>
                    <img src={yourWay} alt="" />
                    <h2>{t('home.whySwapp.title3')}</h2>
                    <p>{t('home.whySwapp.description3')}</p>
                </div>
                <div className='home-benefits-list-item'>
                    <img src={dontWaitUp} alt="" />
                    <h2>{t('home.whySwapp.title4')}</h2>
                    <p>{t('home.whySwapp.description4')}</p>
                </div>
                <div className='home-benefits-list-item'>
                    <img src={superAccessible} alt="" />
                    <h2>{t('home.whySwapp.title5')}</h2>
                    <p>{t('home.whySwapp.description5')}</p>
                </div>
            </div>
            <a className='home-button' href='#get-started'>{t('home.whySwapp.cta')}</a>
        </section>

        <section id='get-started' className='home-download-store'>
            <div className='home-download-store-description'>
                <h2>{t('home.bannerDownload2.title')}</h2>
                <p dangerouslySetInnerHTML={{__html: t('home.bannerDownload2.description')}}></p>
                <div className='store-buttons'>
                    <a href={t('publicJob.urlAppStore')}><img src={downloadAppStore} alt="" /></a>
                    <a href={t('publicJob.urlGooglePlay')}><img src={downloadGooglePlay} alt="" /></a>
                </div>
            </div>
            <div className='home-download-store-image'>
                <img src={appStoreMockup} alt="" />
            </div>
        </section>

        <footer>
            <div>
                <img className='logo' src={logoDark} alt="" />
            </div>
            <div>
                <p dangerouslySetInnerHTML={{__html: t('home.footer.title')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('home.footer.rights')}}></p>
                <p>
                    <a href='/private-policy-swapp' target='blank'>{t('home.footer.privacy')}</a><br/>
                    <a href='/terms-and-conditions-swapp' target='blank'>{t('home.footer.terms')}</a>
                </p>
            </div>
            <div>
                <p  dangerouslySetInnerHTML={{__html: t('home.footer.address')}}></p>
                <p><a href='mailto:hello@swapp.co.uk'>{t('home.footer.mail')}</a></p>
            </div>
        </footer>
    </div>
}

export default HOME;
