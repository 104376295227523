import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import {ActionCompanyGroupsType, actionCompanyGroupsEditLoading, actionCompanyGroupsDeleteLoading, actionCompanyGroupsAddLoading, actionCompanyGroupsLoading} from '../models/actions/typeCompanyGroupActions';
import companyGroupsDto from '../models/dto/CompanyGroupsDto';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';

const baseUrlApi:string = getBaseUrlApi();
const urlCompanyGroupsList = baseUrlApi + '/api/CompanyGroup/getCompanyGroups';
const urlCompanyGroupsEdit = baseUrlApi + '/api/CompanyGroup/updateCompanyGroup';
const urlCompanyGroupsDelete = baseUrlApi + '/api/CompanyGroup/DeleteCompanyGroup';
const urlCompanyGroupsCreate = baseUrlApi + '/api/CompanyGroup/createCompanyGroup';

function* checkError(code:number) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR});
    }
}

function* companyGroupsList(action:actionCompanyGroupsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlCompanyGroupsList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:companyGroupsDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_SUCCESS, value: {data: data, reset: action.value.reset}});
        } else {           
            yield call(checkError, response.status);           
        }                
                
    } catch {        
        yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR});
    }
}

function* companyGroupsEdit(action:actionCompanyGroupsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);      
        const response:Response = yield call(fetch, urlCompanyGroupsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: false}});
        } else {           
            yield call(checkError, response.status);  
        }               
                
    } catch (e){        
        yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR});
    }
}

function* companyGroupsDelete(action:actionCompanyGroupsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        
        const response:Response = yield call(fetch, urlCompanyGroupsDelete + '?companygroupId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_SUCCESS, value: true});     
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: false}});
        } else {            
            yield call(checkError, response.status);   
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR});
    }
}

function* companyGroupsAdd(action:actionCompanyGroupsAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const data = {name: action.value};
        
        const response:Response = yield call(fetch, urlCompanyGroupsCreate, getFetchParams('POST', data, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_SUCCESS, value: true});   
            yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
        } else {           
            yield call(checkError, response.status);   
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ERROR});
    }
}


function* comapanyGroupsWhatcher() {
    yield takeLatest(ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, companyGroupsList);
}

function* companyGroupsEditWatcher() {
    yield takeLatest(ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_LOADING, companyGroupsEdit);
}

function* companyGroupsDeleteWatcher() {
    yield takeLatest(ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_LOADING, companyGroupsDelete);
}

function* companyGroupsAddWatcher() {
    yield takeLatest(ActionCompanyGroupsType.COMPANY_GROUPS_ADD_LOADING, companyGroupsAdd);
}

export default function* rootCompanyGroupsSaga() {
    yield all([
        comapanyGroupsWhatcher(),
        companyGroupsEditWatcher(),
        companyGroupsDeleteWatcher(),
        companyGroupsAddWatcher()
    ])
}