import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import TypeMainState from '../../models/initialState/typeMainState';
import { useSelector, useDispatch } from 'react-redux';
import { TypeActions } from '../../models/actions/typeActions';
import Loading from '../commons/Loading';
import ComponentError from '../commons/ComponentError';

import TypeState from '../../models/initialState/typeState';
import NetworkersRequestList from './NetworkersRequestList';
import { ActionNetworkersRequestListType } from '../../models/actions/typeNetworkersRequestListActions';
import TypeNetworkersRequestListState from '../../models/initialState/typeNetworkersRequestListState';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';
import NetworkerRequestListDto from '../../models/dto/NetworkerRequestListDto';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function NetworkersRequest(props: Props) {
  const state:TypeNetworkersRequestListState = useSelector((state:TypeState) => state.networkersRequestListReducer);
  const stateMain:TypeMainState = useSelector((state:TypeState) => state.mainReducer);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadNetworkers = () => {
    if (stateMain.companySelected !== null) {
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_LOADING, value: {reset: true}});      
    } 
  }

  useEffect(() => {    
    if (props.reload === true) {
      loadNetworkers();     
      props.setReload(false);   
    }   
  }, [dispatch, props.reload]);

  useEffect(() => {    
    if (!props.reload) {
      loadNetworkers();        
    }   
  }, []);

  const changeStatus = (status: boolean, index: NetworkerRequestListDto)=>{
    dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_STATUS, value: {
      requestId: index.requestId,
      companyId: index.companyId,
      response: status

    }});  
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }
   
  return <>     
      {state.loading && <Loading />}
      {!state.loading && !state.error &&
            <NetworkersRequestList
              like={(event: any, index:NetworkerRequestListDto)=>changeStatus(true, index)} 
              reject={(event: any, index:NetworkerRequestListDto)=>changeStatus(false, index)}
              changePage={changePage}
              changeSearchText={changeSearchText}
              changeRowsPerPage={changeRowsPerPage}
              onOrderChange={onOrderChange}
            />}
             
      {state.error && <ComponentError
                              title={t('admin.networkersRequestList.error_title')} 
                              message={state.messageError} 
                              buttonMessage={t('admin.networkersRequestList.error_button')} 
                              onClick={()=>loadNetworkers()}/>}
      
  </>
  
 
}

export default NetworkersRequest;