import TypePublicJobState from '../../models/initialState/typePublicJobState';

export const initialPublicJobState: TypePublicJobState = {    
   
  publicJob: {
    loading: false,
    error: false,
    data: null,
    messageError: '' 
  },    
  linkOpened: {
    loading: false,
    error: false,
    linkOpenedData: null    
  },
  lightSignup: {
    loading: false,
    error: false,
    alreadyActivate: false,
    lightSignupData: null,
    prefix: '',
    phone: ''
  },
  updateLinkNetworkerId: {
    loading: false,
    error: false,
    ok: false,
    newLink: 0
  },
  activateAccount: {
    loading: false,
    error: false,
    messageError: '',
    ok: false,
    data: null 
  },
  jobForwared: {
    loading: false,
    error: false,
    data: {
      forwardId: 0,
      sharedLink: '',
      fee: 0,        
      forwardTimeUsedLimit: 0
    }
  },
  jobForwaredUpdate: {
    loading: false,
    error: false,
    ok: false
  },
  resendActivationCode: {
    loading: false,
    error: false,
    ok: false
  },
  rejectJob: {
    loading: false,
    error: false,
    ok: false
  },
  applyQuestions: {
    loading: false,
    error: false,
    data: []
  },
  sendPassword: {
    loading: false,
    error: false,
    ok: false
  },
  applyJob: {
    loading: false,
    error: false,
    ok: false,
    messageError: ''
  },
  checkLinkIfCanBeShared: {
    loading: false,
    error: false,
    ok: true
  }
}