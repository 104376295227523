import { TypeActions } from '../../models/actions/typeActions';
import { ActionMarketsType } from '../../models/actions/typeMarketsActions';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import { initialMarketsState } from '../inititalState/initialMarketsState';

const marketsReducer = (state:TypeMarketsState = initialMarketsState, action:TypeActions):TypeMarketsState => {
    switch (action.type) {        

        case ActionMarketsType.MARKETS_LOADING: {           
            return {...state,  loading: true, error: false}
        }

        case ActionMarketsType.MARKETS_SUCCESS: {            
            return {...state, loading: false, data: action.value.data, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionMarketsType.MARKETS_ERROR: {            
            return {...state,  loading: false, error: true, data: []}
        }
        
        case ActionMarketsType.MARKETS_EDIT_LOADING: {
            return {...state, loading: true, error: false, edit: false}
        }

        case ActionMarketsType.MARKETS_EDIT_SUCCESS: {
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionMarketsType.MARKETS_DELETE_LOADING: {           
            return {...state, loading: true, error: false, delete: false}
        }

        case ActionMarketsType.MARKETS_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionMarketsType.MARKETS_ADD_LOADING: {           
            return {...state, loading: true, error: false, add: false}
        }

        case ActionMarketsType.MARKETS_ADD_SUCCESS: {            
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionMarketsType.MARKETS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionMarketsType.MARKETS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionMarketsType.MARKETS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionMarketsType.MARKETS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }
        
    }
    return {...state};
}
export default marketsReducer;