import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';

import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import { actionNetworkersAddLoading, actionNetworkersAnonymizerLoading, actionNetworkersDeleteAmbassador, actionNetworkersDeleteTA, actionNetworkersExtraFieldsLoading, actionNetworkersLoading, actionNetworkersPerformanceReportLoading, actionNetworkersRegisterAmbassador, actionNetworkersRegisterTA, actionNetworkersRewardsLoading, actionNetworkersRewardsYearsLoading, ActionNetworkersType } from '../models/actions/typeNetworkersActions';
import NetworkerDto from '../models/dto/NetworkerDto';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import RewardDto from '../models/dto/RewardDto';
import { downloadFile } from '../utils/utils';
import NetworkerExtraFieldsDto from '../models/dto/NetworkerExtraFieldsDto';
import { ActionLocationType } from '../models/actions/typeLocationActions';

const baseUrlApi:string = getBaseUrlApi();

const urlNetworkersList = baseUrlApi + '/api/TA/getNetworkerListWithCompanyGroupOfCompany';
const urlNetworkersAdd = baseUrlApi + '/api/Networker/createNetworker';
const urlNetworkersEdit = baseUrlApi + '/api/Networker/updateNetworker';
const urlNetworkersDelete = baseUrlApi + '/api/Networker/DeleteNetworker';

const urlNetworkerCreateAmbassador = baseUrlApi + '/api/TA/registerNetworkerToCompanyAsAmbassador';
const urlNetworkerDeleteAmbassador = baseUrlApi + '/api/TA/deleteAmbassador';
const urlNetworkerCreateTA = baseUrlApi + '/api/TA/registerNetworkerToCompanyAsTA'
const urlNetworkerDeleteTA = baseUrlApi + '/api/TA/deleteTA';
const urlNetworkerRewarYears = baseUrlApi + '/api/TA/getNetworkerRewardsYearsAtCompany';
const urlNetworkerRewars = baseUrlApi + '/api/TA/getNetworkerRewardsAtCompany';
const urlNetworkerRewarsPdf = baseUrlApi + '/api/TA/downloadNetworkerRewardsPdfFile';
const urlNetworkerExtraField = baseUrlApi + '/api/TA/getNetworkerExtraFields';
const urlNetworkerAnonymizer = baseUrlApi + '/api/Networker/anonymizerNetworker';
const urlNetworkerPerformanceReport = baseUrlApi + '/api/Networker/downloadNetworkerPerformanceReport';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* networkersList(action:actionNetworkersLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersList + '?companyId=' + state.companySelected.company.id, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:NetworkerDto[] = yield call([response, 'json']);                
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_SUCCESS, value: {data: data, reset: action.value.reset}});
            } else {                
                const data:string = yield call([response, 'text']);
                yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
            }    
        }                    
             
    } catch (e) {
        console.log(e);        
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error List Networker'});
    }
}

function* networkersCreate(action:actionNetworkersAddLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersAdd, getFetchParams('POST', action.value, state.token));
            if (response.ok) {
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: true}});
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ADD_SUCCESS, value: true});
            } else if (response.status === 409){
                const textError:string = yield call([response, 'text']);               
                yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: textError});           
            } else {
                const data:string = yield call([response, 'text']);
                yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});
            }   
        }   
    } catch (e) {         
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Create Networker'});
    }
}

function* networkersEdit(action:actionNetworkersAddLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersEdit, getFetchParams('PUT', action.value, state.token));
            if (response.ok) {
                //const data:NetworkerDto[] = yield call([response, 'json']);
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_EDIT_SUCCESS, value: true});
            } else {                
                const data:string = yield call([response, 'text']);
                yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
            }    
        }                    
             
    } catch (e) {                
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Edit Networker'});
    }
}

function* networkersDelete(action:actionNetworkersAddLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlNetworkersDelete + '?networkerId=' + action.value + '&companyId=' + state.companyIdSelected, getFetchParams('DELETE', action.value, state.token));
            if (response.ok) {                
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_DELETE_SUCCESS, value: true});
            } else {     
                const data:string = yield call([response, 'text']);           
                yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
            }    
        }                    
             
    } catch (e) {              
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error delete networker'});
    }
}

function* networkerRegisterAmbassador(action:actionNetworkersRegisterAmbassador) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
           
        const response:Response = yield call(fetch, urlNetworkerCreateAmbassador, getFetchParams('POST', action.value, state.token));
        if (response.ok) {                        
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
        } else { 
            const data:string = yield call([response, 'text']);           
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {             
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Register Ambassador'});
    }
}

function* networkerRegisterTA(action:actionNetworkersRegisterTA) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
           
        const response:Response = yield call(fetch, urlNetworkerCreateTA, getFetchParams('POST', action.value, state.token));
        if (response.ok) {                        
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
        } else {            
            const data:string = yield call([response, 'text']);
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {             
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Register TA'});
    }
}

function* networkerDeleteAmbassador(action:actionNetworkersDeleteAmbassador) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
             
        const response:Response = yield call(fetch, urlNetworkerDeleteAmbassador + '?ambassadorId=' + action.value, getFetchParams('DELETE', null, state.token));
        if (response.ok) {                        
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
        } else { 
            const data:string = yield call([response, 'text']);           
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {               
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Delete Ambassador'});
    }
}

function* networkerDeleteTA(action:actionNetworkersDeleteTA) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
               
        const response:Response = yield call(fetch, urlNetworkerDeleteTA + '?taId=' + action.value, getFetchParams('DELETE', null, state.token));
        if (response.ok) {                        
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
        } else {    
            const data:string = yield call([response, 'text']);        
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {                
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Delete TA'});
    }
}

function* networkerRewarsYears(action:actionNetworkersRewardsYearsLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
               
        const response:Response = yield call(fetch, urlNetworkerRewarYears + '?companyId=' + action.value.companyId + '&networkerId=' + action.value.networkerId, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:number[] = yield call([response, 'json']);                          
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_YEARS_SUCCESS, value: data });
            if (data.length > 0) {
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_SEND_YEAR_SELECTED, value: data[0]});
                yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_LOADING, value: {companyId: action.value.companyId, networkerId: action.value.networkerId, year: data[0]}});
            } 
            
        } else {   
            const data:string = yield call([response, 'text']);         
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: data});          
        }                
             
    } catch (e) {                
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error Rewards years'});
    }
}

function* networkerRewars(action:actionNetworkersRewardsLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
               
        const response:Response = yield call(fetch, urlNetworkerRewars + '?companyId=' + action.value.companyId + '&networkerId=' + action.value.networkerId + '&year=' + action.value.year, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:RewardDto = yield call([response, 'json']);                         
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_SUCCESS, value: data });
        } else {                  
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_ERROR});          
        }                
             
    } catch (e) {                
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_ERROR}); 
    }
}

function* networkerRewarsPdf(action:actionNetworkersRewardsLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
               
        const response:Response = yield call(fetch, urlNetworkerRewarsPdf + '?companyId=' + action.value.companyId + '&networkerId=' + action.value.networkerId + '&year=' + action.value.year, getFetchParams('POST', null, state.token));
        if (response.ok) {
            const data:Blob = yield call([response, 'blob']);
            yield call(downloadFile, data, 'rewards.pdf');                                  
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_SUCCESS, value: true });
        } else {            
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_ERROR, value: true});          
        }             
    } catch (e) {                
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_ERROR, value: true}); 
    }
}

function* networkerExtraFields(action:actionNetworkersExtraFieldsLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
               
        const response:Response = yield call(fetch, urlNetworkerExtraField + '?companyId=' + action.value.companyId + '&networkerId=' + action.value.networkerId, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:NetworkerExtraFieldsDto = yield call([response, 'json']); 
            if (data.country && data.country.id) {
                yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: data.country.id  });
            } else {
                yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_SUCCESS, value: []}); 
            }    
            if (data.region && data.region.id) {
                yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: data.region.id });
            } else {
                yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_SUCCESS, value: []}); 
            }    
            
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_SUCCESS, value: data });

        } else {            
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_ERROR});          
        }                
             
    } catch (e) {
        console.log(e);             
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_ERROR}); 
    }
}

function* networkerAnonymizer(action:actionNetworkersAnonymizerLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
           
        const response:Response = yield call(fetch, urlNetworkerAnonymizer + '?networkerId=' + action.value, getFetchParams('PUT', null, state.token));
        if (response.ok) {                        
            yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
        } else {
            const data:string = yield call([response, 'text']);  
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {             
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error'});
    }
}

function* networkerPerformanceReport(action:actionNetworkersPerformanceReportLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const params = '?NetworkerId=' + action.value.NetworkerId + '&CompanyId=' + action.value.CompanyId + '&DateFrom=' + action.value.DateFrom + '&DateTo=' + action.value.DateTo;
           
        const response:Response = yield call(fetch, urlNetworkerPerformanceReport + params , getFetchParams('POST', null, state.token));
        if (response.ok) {                        
            //yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_LOADING, value: {reset: false}});
            const data:Blob = yield call([response, 'blob']);
            yield call(downloadFile, data, 'performanceReport.pdf'); 
        } else {
            const data:string = yield call([response, 'text']);  
            yield call(checkError, response.status, {type: ActionNetworkersType.NETWORKERS_ERROR, value: data});           
        }                
             
    } catch (e) {             
        yield put<TypeActions>({type: ActionNetworkersType.NETWORKERS_ERROR, value: 'Error'});
    }
}

function* networkersListWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_LOADING, networkersList);
}

function* networkerRegisterAmbassadorWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKER_REGISTER_AMBASSADOR, networkerRegisterAmbassador);
}

function* networkerRegisterTAWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKER_REGISTER_TA, networkerRegisterTA);
}

function* networkerDeleteAmbassadorWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKER_DELETE_AMBASSADOR, networkerDeleteAmbassador);
}

function* networkerDeleteTAWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKER_DELETE_TA, networkerDeleteTA);
}

function* networkersCreateWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_ADD_LOADING, networkersCreate);
}

function* networkersEditWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_EDIT_LOADING, networkersEdit);
}

function* networkersDeleteWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_DELETE_LOADING, networkersDelete);
}

function* networkerRewarsYearsWhatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_REWARDS_YEARS_LOADING, networkerRewarsYears);
}

function* networkerRewarsWatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_REWARDS_DATA_LOADING, networkerRewars);
}

function* networkerRewarsPdfWatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_REWARDS_PDF_LOADING, networkerRewarsPdf);
}

function* networkerExtraFieldsWatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_LOADING, networkerExtraFields);
}

function* networkerAnonymizerWatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_ANONYMIZER_LOADING, networkerAnonymizer);
}

function* networkerPerformanceReportWatcher() {
    yield takeLatest(ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_LOADING, networkerPerformanceReport);
}



export default function* rootNetworkersSaga() {
    yield all([
        networkersListWhatcher(),
        networkerRegisterAmbassadorWhatcher(),
        networkerRegisterTAWhatcher(),
        networkerDeleteAmbassadorWhatcher(),
        networkerDeleteTAWhatcher(),
        networkersCreateWhatcher(),
        networkersEditWhatcher(),
        networkersDeleteWhatcher(),
        networkerRewarsYearsWhatcher(),
        networkerRewarsWatcher(),
        networkerRewarsPdfWatcher(),
        networkerExtraFieldsWatcher(),
        networkerAnonymizerWatcher(),
        networkerPerformanceReportWatcher()
    ])
}