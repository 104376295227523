import { useTranslation } from 'react-i18next';
import TypeMainState from '../../models/initialState/typeMainState';
import { useDispatch, useSelector } from 'react-redux';
import Cached from '@material-ui/icons/Cached';
import Table from '../table/Table';
import JobsResponseDto from '../../models/dto/JobsResponseDto';
import JobResumeListDto from '../../models/dto/JobResumeListDto';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Share from '@material-ui/icons/Share';
import History from '@material-ui/icons/History';
import AssignmentInd from '@material-ui/icons/PlaylistAddCheck';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import AccountTree from '@material-ui/icons/AccountTree';
import { isAdmin, isAdminTAAdmin } from '../privateZone/utilsRoles';
import TypeJobsState from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { AlertColor } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ALL, convertDateHourIso, SNACBARPOSITION, SNACKBARTIMETOHIDE } from '../../utils/utils';
import { DisplayedStatusJob } from "../../models/jobs/JobStatus";
//import './Jobs.scss';
import { TypeActions } from '../../models/actions/typeActions';
import ModalResultImportXMLJobs from '../commons/ModalResultImportXMLJobs';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";

interface ItemFilter {
  id: string,
  name: string
}

interface Props {
  edit(event: any, index: JobsResponseDto): void,
  remove(event: any, index: JobsResponseDto): void,
  push(event: any, index: JobsResponseDto): void,
  history(event: any, index: JobsResponseDto): void,
  candidates(event: any, index: JobsResponseDto): void,
  dashboardJobActivity(event: any, index: JobsResponseDto): void,
  newRegister(): void,
  changePage(page: number, pageSize: number): void,
  changeSearchText(searchText: string): void,
  changeRowsPerPage?(pageSize: number): void,
  onOrderChange?(orderBy: number, orderDirection: string): void,
}


function JobsList(props: Props) {
  const { t } = useTranslation();  
  const state: TypeJobsState = useSelector((state: TypeState) => state.jobsReducer);
  const stateMain: TypeMainState = useSelector((state: TypeState) => state.mainReducer);
  const dispatch = useDispatch();

  const [openNotification, setOpenNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [showModalResultImportXMLJobs, setShowModalResultImportXMLJobs] = useState(false);  
    
  useEffect(() => {
    if (state.downloadToXml.ok) {
      setMessageNotification('XML downloaded');
      setOpenNotification(true);
      setSeverity('success');
    }
    if (state.downloadToXml.error) {
      setMessageNotification('Error to download File');
      setOpenNotification(true);
      setSeverity('error')
    }
  }, [state.downloadToXml.ok, state.downloadToXml.error]);

  useEffect(() => {
    if (state.uploadToXml.ok) {
      setMessageNotification('XML uploaded');
      setOpenNotification(true);
      setSeverity('success');
      //Activar el modal
      setShowModalResultImportXMLJobs(true);
    }
    if (state.uploadToXml.error) {
      setMessageNotification('Error to upload File');
      setOpenNotification(true);
      setSeverity('error')
    }
  }, [state.uploadToXml.ok, state.uploadToXml.error]);

  const closeNotification = () => {
    if (state.downloadToXml.ok) {
      dispatch<TypeActions>({ type: ActionJobsType.JOBS_EXPORT_TO_XML_SUCCESS, value: false });
    }

    if (state.downloadToXml.error) {
      dispatch<TypeActions>({ type: ActionJobsType.JOBS_EXPORT_TO_XML_ERROR, value: false });
    }

    if (state.uploadToXml.ok) {
      dispatch<TypeActions>({ type: ActionJobsType.JOBS_IMPORT_TO_XML_CHANGE_STATUS, value: false });
    }

    if (state.uploadToXml.error) {
      dispatch<TypeActions>({ type: ActionJobsType.JOBS_IMPORT_TO_XML_ERROR, value: false });
    }
    setOpenNotification(false);
  }

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files !== null) {
      let file = event.currentTarget.files[0];
      dispatch<TypeActionJobsActions>({ type: ActionJobsType.JOBS_IMPORT_TO_XML_LOADING, value: file });
    };
  }

  const downloadXmlFile = () => {
    dispatch<TypeActionJobsActions>({ type: ActionJobsType.JOBS_EXPORT_TO_XML_LOADING });
  }

  const getStatusClassname = (statusId: number) => {
    const colorClassName = Object.keys(DisplayedStatusJob)[Object.values(DisplayedStatusJob).indexOf(statusId)];
    return colorClassName
  }

  const getAssignmentIndColor = (candidatureStatus: number) => {
    switch (candidatureStatus) {
      case 0:
        return 'black'
      case 1:
        return 'orange'
      case 2:
        return 'green'
      case 3:
        return 'red'
      default:
        return 'black'
    }
  }

  const headers = [
    { title: t('admin.jobs.label_title'), field: 'title', cellStyle: {
      width: 300
    },width: 200, defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''},
    { title: t('admin.jobs.owner'), field: 'owner', width:  200, defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: '' },
    { title: t('admin.jobs.label_status'), width: 100, field: 'status', 
              render: ((rowData: JobResumeListDto) => <span className={`${getStatusClassname(rowData.jobStatusExtended.id)}`}>{rowData.jobStatusExtended.description}</span>),
              defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: '',
              customSort: (a:JobResumeListDto, b:JobResumeListDto) => {
                if (a.jobStatusExtended.description > b.jobStatusExtended.description) {
                  return -1;
                }   
                if (a.jobStatusExtended.description < b.jobStatusExtended.description) {
                  return 1;
                }
                return 0}
               },
            
    { title: t('admin.jobs.label_createdDate'), width: 50,  field: 'createdDate', 
              render: (rowData: JobResumeListDto) => convertDateHourIso(rowData.createdDate),
              defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: '' },
    { title: t('admin.jobs.tooltip_Applied'), width: 50, field: 'jobKpis.timesApplied', defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: ''},
    { title: t('admin.jobs.tooltip_Shared'), width: 50, field: 'jobKpis.timesShared', defaultSort: state.filters.orderBy === 5 ? state.filters.orderDirection: '' },
    { title: t('admin.jobs.tooltip_Rejected'), width: 50, field: 'jobKpis.timesRejected', defaultSort: state.filters.orderBy === 6 ? state.filters.orderDirection: '' },
    { title: t('admin.jobs.tooltip_Seen'), width: 50, field: 'jobKpis.timesSeen', defaultSort: state.filters.orderBy === 7 ? state.filters.orderDirection: '' },
    { title: t('admin.jobs.location'), width: 150, field: 'location', defaultSort: state.filters.orderBy === 8 ? state.filters.orderDirection: '' },
    
    
    // {
    //   title: '', field: 'jobKpisDto', render: (rowData: JobResumeListDto) => {

    //     return <div className='swissinx-kpis'>
    //       <div className='swissinx-jobs__badge'>
    //         <img src={blackApply} alt={t('admin.jobs.tooltip_Applied')} />
    //         <Tooltip title={t('admin.jobs.tooltip_Applied') as string}>
    //           <span>{rowData.jobKpis.timesApplied}</span>
    //         </Tooltip>
    //       </div>
    //       {/*  <div className='swissinx-jobs__badge'>
    //           <Tooltip title={t('admin.jobs.tooltip_Pinned') as string}>              
    //             <BookmarkIcon titleAccess={t('admin.jobs.tooltip_Pinned')} fontSize="large"  />
    //           </Tooltip>    
    //           {rowData.jobKpis.timesPinned > 0 && <span>{rowData.jobKpis.timesPinned}</span>}
    //       </div> */}
    //       <div className='swissinx-jobs__badge'>
    //         <Share titleAccess={t('admin.jobs.tooltip_Shared')} fontSize="large" />
    //         <Tooltip title={t('admin.jobs.tooltip_Shared') as string}>
    //           <span>{rowData.jobKpis.timesShared}</span>
    //         </Tooltip>
    //       </div>
    //       <div className='swissinx-jobs__badge'>
    //         <Reject titleAccess={t('admin.jobs.tooltip_Rejected')} fontSize="large" />
    //         <Tooltip title={t('admin.jobs.tooltip_Rejected') as string}>
    //           <span>{rowData.jobKpis.timesRejected}</span>
    //         </Tooltip>
    //       </div>
    //       <div className='swissinx-jobs__badge'>
    //         <Visibility titleAccess={t('admin.jobs.tooltip_Seen')} fontSize="large" />
    //         <Tooltip title={t('admin.jobs.tooltip_Seen') as string}>
    //           <span>{rowData.jobKpis.timesSeen}</span>
    //         </Tooltip>
    //       </div>
    //     </div>;
    //   }
    // },
    /* {
      title: t('admin.jobs.label_client'), field: 'clientId', render: (rowData: JobsResponseDto) => {
        const client = stateClients.clientsData.find(client => client.clientId === rowData.clientId);
        return client !== undefined ? client.name : '';
      }
    } */
  ];

  const actions = [
    (rowData: JobResumeListDto) => {
      const row = rowData as JobResumeListDto;
      return {
        icon: Share,
        disabled: isAdmin(stateMain.roleCompanySelected) || row.status.id === 0,
        tooltip: t('table.tooltip.actionPush'),
        onClick: props.push
      }
    },
    {
      icon: History,
      tooltip: t('table.tooltip.actionHistoryPush'),
      disabled: false,
      onClick: props.history
    },
    (rowData: JobResumeListDto) => {
      const row = rowData as JobResumeListDto;
      return {
        icon: () => <AssignmentInd htmlColor={getAssignmentIndColor(row.jobCandidatureStatus.id)} />,
        tooltip: t('table.tooltip.candidate'),
        disabled: false,
        onClick: props.candidates
      }
    },
    {
      icon: AccountTree,
      tooltip: t('table.tooltip.dashboardJobActivity'),
      disabled: false,
      onClick: props.dashboardJobActivity
    },
    {
      icon: Edit,
      tooltip: t('table.tooltip.actionEdit'),
      disabled: false,
      onClick: props.edit
    },
    {
      icon: DeleteOutline,
      tooltip: t('table.tooltip.actionDelete'),
      disabled: false,
      onClick: props.remove
    }
  ]

  const onReload = () => {
    dispatch<TypeActionJobsActions>({ type: ActionJobsType.JOBS_LOADING, value: { reset: false } });
  }
  
  const jobStates = [{id: -1, name: ALL}, {id: 1, name: 'Active'}, {id: 0, name: 'Inactive'},  {id: 2, name: 'Expired'}, {id: 3, name: 'Placement'} ];
  const jobPushed = [{id: -1, name: ALL}, {id: 1, name: 'Pushed'}, {id: 2, name: 'Not Pushed'}];

  const jobSkills = useMemo(()=>{
    const jobSkillsAux: Set<string> = new Set();
    state.jobsData.forEach(job=>{
      job.skills.forEach(skill=>jobSkillsAux.add(skill))
    })
    const jobSkillsFilter: ItemFilter[] = [{id: ALL, name: ALL}];   
    jobSkillsAux.forEach((skill) =>{
      jobSkillsFilter.push({id: skill, name: skill})
    } )
    return jobSkillsFilter;

  }, [state.jobsData])

  const jobMarkets = useMemo(()=>{
    const jobSMarketsAux: Set<string> = new Set();
    state.jobsData.forEach(job=>{
      job.markets.forEach(market=>jobSMarketsAux.add(market))
    })
    const jobMarketsFilter: ItemFilter[] = [{id: ALL, name: ALL}];   
    jobSMarketsAux.forEach((market) =>{
      jobMarketsFilter.push({id: market, name: market})
    } )
    return jobMarketsFilter;

  }, [state.jobsData])

  const jobRegions = useMemo(()=>{
    const jobSRegionsAux: Set<string> = new Set();
    state.jobsData.forEach(job=>jobSRegionsAux.add(job.region))
    
    const jobRegionsFilter: ItemFilter[] = [{id: ALL, name: ALL}];
   
    jobSRegionsAux.forEach((region) =>{
      jobRegionsFilter.push({id: region, name: region})
    } )
    return jobRegionsFilter;

  }, [state.jobsData])

  const goToFirstPage = ()=>{
    //Esto es para volver a la pagina inicial si se cambia algun filtro
    const buttonInitialPage: (HTMLButtonElement | null) = document.querySelector("span[title='First Page']>button");
    if (buttonInitialPage) {
      buttonInitialPage.click();
    }   
  }

  const onChangePushedFilter = (event: ChangeEvent<HTMLInputElement>)=>{   
    dispatch<TypeActions>({ type: ActionJobsType.JOBS_PUSHED_FILTER, value: parseInt(event.target.value) });
    goToFirstPage();
  }

  const onChangeStatusFilter = (event: ChangeEvent<HTMLInputElement>)=>{   
    dispatch<TypeActions>({ type: ActionJobsType.JOBS_STATUS_FILTER, value: parseInt(event.target.value) });    
    goToFirstPage();    
  }

  const onChangeSkillsFilter = (event: ChangeEvent<HTMLInputElement>)=>{   
    dispatch<TypeActions>({ type: ActionJobsType.JOBS_SKILLS_FILTER, value: event.target.value });
    goToFirstPage();    
  }

  const onChangeMarketsFilter = (event: ChangeEvent<HTMLInputElement>)=>{   
    dispatch<TypeActions>({ type: ActionJobsType.JOBS_MARKETS_FILTER, value: event.target.value });    
    goToFirstPage();    
  }

  const onChangeRegionsFilter = (event: ChangeEvent<HTMLInputElement>)=>{   
    dispatch<TypeActions>({ type: ActionJobsType.JOBS_REGIONS_FILTER, value: event.target.value });    
    goToFirstPage();    
  }

  return <div className='swissinx-container'>
    <h2 className="swissinx__reload">
      {t('admin.jobs.listTitle')}
      <Cached onClick={onReload} titleAccess="Reload" />
    </h2>
    
    <div className="swissinx-container-fields">
        <div className='swissinx-field-container jobs-filter'>
          <div className='swissinx-container-filter'>
            <div className="swissinx-field field-country">
              <TextField
                id="statusFilter"
                select={true}
                label={t('admin.jobs.label_status')}
                value={state.jobStatusFilter}
                error={false}
                onChange={onChangeStatusFilter}              
              >
                {jobStates.map((state) => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>)
                }
              </TextField>
            </div>
            <div className="swissinx-field field-country">
              <TextField
                id="pushedFilter"
                select={true}
                label={t('admin.jobs.label_pushed')}
                value={state.jobPushedFilter}
                error={false}
                onChange={onChangePushedFilter}        
              >
                {jobPushed.map((pushed) => <MenuItem key={pushed.id} value={pushed.id}>{pushed.name}</MenuItem>)
                }
              </TextField>
            </div>
          </div>
      </div>
      <div className="swissinx-field-container jobs-filter">

          <div className='swissinx-container-filter'>
            <div className="swissinx-field field-country">
              <TextField
                id="skillsFilter"
                select={true}
                label={t('admin.jobs.label_skills')}
                value={state.jobsSkillsFilter}
                error={false}
                onChange={onChangeSkillsFilter}              
              >
                {jobSkills.map((skill) => <MenuItem key={skill.id} value={skill.id}>{skill.name}</MenuItem>)
                }
              </TextField>
            </div>
            <div className="swissinx-field field-country">
              <TextField
                id="marketFilter"
                select={true}
                label={t('admin.jobs.label_markets')}
                value={state.jobsMarketsFilter}
                error={false}
                onChange={onChangeMarketsFilter}        
              >
                {jobMarkets.map((market) => <MenuItem key={market.id} value={market.id}>{market.name}</MenuItem>)
                }
              </TextField>
            </div>
            <div className="swissinx-field field-country">
              <TextField
                id="regionFilter"
                select={true}
                label={t('admin.jobs.label_region')}
                value={state.jobsRegionsFilter}
                error={false}
                onChange={onChangeRegionsFilter}        
              >
                {jobRegions.map((region) => <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>)
                }
              </TextField>
            </div>
          </div>
          
         
        </div>
        <div className='swissinx-container-filter'>
          {isAdminTAAdmin(stateMain.roleCompanySelected) && <div className='swissinx-botonsFile'>
              <input type='file' id='fileUploadJobs' disabled={state.uploadToXml.loading} onChange={uploadFile}></input>
              <label htmlFor='fileUploadJobs'>{state.uploadToXml.loading ? t('admin.jobs.uploading') : t('admin.jobs.upload')} <CloudUpload /></label>
              <button onClick={downloadXmlFile} disabled={state.downloadToXml.loading}>{state.downloadToXml.loading ? t('admin.jobs.loading') : t('admin.jobs.download')} <CloudDownload /> </button>
            </div>}
        </div>

        

      </div>
    <Table<JobsResponseDto>
      data={state.jobsFiltered}
      headers={headers}
      actions={actions}
      initialPage={state.initialPage}
      serchText={state.searchText}
      pageSize={state.pageSize}
      new={!isAdmin(stateMain.roleCompanySelected) && stateMain.companyIdSelected !== -1}
      newRegister={props.newRegister}
      changePage={props.changePage}
      changeSearchText={props.changeSearchText}
      changeRowsPerPage={props.changeRowsPerPage}
      onOrderChange={props.onOrderChange}
    />

    <Snackbar anchorOrigin={SNACBARPOSITION} open={openNotification} autoHideDuration={SNACKBARTIMETOHIDE} onClose={closeNotification}>
      <Alert onClose={closeNotification} severity={severity} sx={{ width: '100%' }} >
        {messageNotification}
      </Alert>
    </Snackbar>

    {showModalResultImportXMLJobs && <ModalResultImportXMLJobs show={showModalResultImportXMLJobs} data={state.uploadToXml.data} confirm={()=>setShowModalResultImportXMLJobs(false)}/>}
  </div>;


}

export default JobsList;