import { CompanyRole } from '../dto/LoginResponseDto';
import { TypeAmbasadorsActions } from './typeAmbassadorActions';
import { TypeClientsActions } from './typeClientsActions';
import { TypeActionCompanyActions } from './typeCompanyActions';
import { TypeActionCompanyGroupsActions } from './typeCompanyGroupActions';
import { TypeActionUserDashboardActions } from './typeDashboardActions';
import { TypeFeeActions } from './typeFeeActions';
import { TypeActionJobsActions } from './typeJobsActions';
import { TypeActionLanguagesActions } from './typeLanguagesActions';
import { TypeActionLocationActions } from './typeLocationActions';
import { TypeLoginActions } from './typeLoginActions';
import { TypeActionMarketsActions } from './typeMarketsActions';
import { TypeNetworkersActions } from './typeNetworkersActions';
import { TypeNetworkersRequestListActions } from './typeNetworkersRequestListActions';
import { TypeactionPaymentsActions } from './typePaymentsActions';
import { TypePublicJobActions } from './typePublicJobActions';
import { TypeActionSkillGroupActions } from './typeSkillGroupActions';
import { TypeActionSkillsActions } from './typeSkillsActions';
import { TypeTemplateJobActions } from './typeTemplateJobActions';

export enum ActionsType {
    SEND_COMPANY_SELECTED = 'SEND_COMPANY_SELECTED',
    UNAUTHORIZED_USER = 'UNAUTHORIZED_USER'
}

export interface actionSendCompanySelected {
    type: ActionsType.SEND_COMPANY_SELECTED,
    value: CompanyRole | null
}

export interface actionUnauthorizedUser {
    type: ActionsType.UNAUTHORIZED_USER    
}

export type TypeActions =   actionUnauthorizedUser |
                            actionSendCompanySelected |
                            TypeLoginActions |
                            TypeActionCompanyGroupsActions |
                            TypeActionJobsActions |
                            TypeActionLocationActions |
                            TypeClientsActions |
                            TypeNetworkersActions |
                            TypePublicJobActions |
                            TypeAmbasadorsActions |
                            TypeActionCompanyActions |
                            TypeTemplateJobActions |
                            TypeNetworkersRequestListActions |
                            TypeActionSkillsActions |
                            TypeActionMarketsActions |
                            TypeActionLanguagesActions |
                            TypeActionUserDashboardActions |
                            TypeFeeActions |
                            TypeActionSkillGroupActions |
                            TypeactionPaymentsActions;