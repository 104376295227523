import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
import { DEFAULTITEMSPERPAGE, newFormField } from '../../utils/utils';

export const initialLanguagesState: TypeLanguagesState = {
      loading: false,
      error: false,
      messageError: '',
      data: [],
      languagesForm: {
            name: newFormField('',true, false)
      },
      filters: {orderBy: 0, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false
}