import React, {useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ActionLocationType } from '../../models/actions/typeLocationActions';
import GetSMSRegistration from './GetSMSRegistration';

import CheckSMSRegistration from './CheckSMSRegistration';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionPublicJobType } from '../../models/actions/typePublicJobActions';
import EndSMSRegistration from './EndSMSRegistration';
import { ActionLoginType } from '../../models/actions/typeLoginActions';
import Snackbar from '@mui/material/Snackbar';
import './SMSRegistration.scss';
import { APPLY_JOB, REFER_BY_CLIPBOARD, REFER_BY_MAIL, REFER_BY_WHATSAPP, URL_WHATSAPP } from '../../constanst/GlobalConstants';
import TypeLocationState from '../../models/initialState/typeLocationState';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import ApplyJob from './ApplyJob';
import ShareJobFinish from './ShareJobFinish';

interface Props {
  type: number
}

function SMSRegistration(props: Props) {  
  const [sendNotification, setSendNotification] = useState(false);  
  const [sendNotificationMessage, setSendNotificationMessage] = useState('');
  const [showShareJobFinish, setShowShareJobFinish] = useState(false);
  const { t } = useTranslation();
  const state:TypePublicJobState = useSelector((state:TypeState) => state.publicJobReducer);
  const stateLocation:TypeLocationState = useSelector((state:TypeState) => state.locationReducer);
  const dispatch = useDispatch();

  useEffect(()=>{  
    //Actualizar networked    
      if (state.lightSignup.lightSignupData !== null && state.linkOpened.linkOpenedData !== null && state.activateAccount.ok) {
        dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING, value: {
          linkId: state.linkOpened.linkOpenedData.linkId,
          networkerId: state.lightSignup.lightSignupData.networkerId
        }});
      }  
  }, [state.activateAccount.ok]);

useEffect(()=>{
    //dispatch para recoger los datos de los prefijos
    if (stateLocation.countriesWithoutPushExceptions.data.length === 0) {      
      if (state.linkOpened.linkOpenedData) {
        dispatch<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING, value: {
          pushId: state.linkOpened.linkOpenedData.pushId,
          jobId: state.linkOpened.linkOpenedData.jobId
        }});
      }      
    }  
}, []);

useEffect(()=>{  
  if (state.resendActivationCode.ok) {
    setSendNotification(true);
    setSendNotificationMessage(t('publicJob.resendActivationCodeOk'))
  }
}, [state.resendActivationCode.ok]);

useEffect(()=>{  
  //Pedir nuevo link    
    if (props.type !== APPLY_JOB && 
      state.activateAccount.ok && 
      state.linkOpened.linkOpenedData !== null && 
      state.lightSignup.lightSignupData !== null && 
      state.updateLinkNetworkerId.ok &&
      state.checkLinkIfCanBeShared.ok
      ) {
      dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_LOADING, value: {
          originNetworkerId: state.lightSignup.lightSignupData.networkerId,
          parentLinkId: state.updateLinkNetworkerId.newLink
      }});
      setShowShareJobFinish(false);
    }  
}, [state.activateAccount.ok, state.checkLinkIfCanBeShared.ok, state.updateLinkNetworkerId.ok]);

  const sendSMS = (prefix: string, phone: string) => {
    //Llamar al endpoint para enviar el codigo    
    if (state.linkOpened.linkOpenedData !== null) {
      dispatch<TypeActions>({type: ActionLoginType.LIGHTSIGNUP_LOADING, value: {
        phone: prefix + phone,
        phonePrefix: prefix,
        jobId: state.linkOpened.linkOpenedData.jobId  
      }});
    }   
  }

  const backToSendSMSCode = () => {
    //llamar a otro endpoint para reenviar el codigo
    if (state.lightSignup.lightSignupData !== null) {
      dispatch<TypeActions>({type: ActionLoginType.RESEND_ACTIVATION_CODE_LOADING, value: {phone: state.lightSignup.lightSignupData.phone}});
      setSendNotification(true);
      setSendNotificationMessage(t('publicJob.resendActivationCodeLoading'));
    }    
  }

  const checkCode = (code: string) => {
    //Enviar al servidor el codigo introducido para validar. De momento se pasa a la siguiente pantalla    
    if (state.lightSignup.lightSignupData !== null) {
      dispatch<TypeActions>({type: ActionLoginType.ACTIVATE_ACCOUNT_LOADING, value: {
        phone: state.lightSignup.lightSignupData.phone,
        activationCode: code
      }});
    }    
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setSendNotification(false);
  };

  const sendSMSRegistrationCode = (type: number, textDetails: string, referralName: string) => {
    dispatch<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_LOADING, value: {
      forwardId: state.jobForwared.data.forwardId,
      reason: textDetails,
      referralName: referralName
    }});
    
    switch (type) {
      case REFER_BY_WHATSAPP: {
        window.open(URL_WHATSAPP + encodeURIComponent(textDetails + ' ' + state.jobForwared.data.sharedLink));
        break;
      }
      case REFER_BY_MAIL: {      
        window.open('mailto:?subject=' + t('publicJob.defaultMessage') + '&body=' + textDetails + ' ' + state.jobForwared.data.sharedLink);
        break;
      }
      case REFER_BY_CLIPBOARD: {
        var data = new Blob([textDetails + ' ' + state.jobForwared.data.sharedLink], {type : "text/plain"});        
        var item = new ClipboardItem({'text/plain': data});
        navigator.clipboard.write([item]).then(()=>{
          setSendNotification(true);
          setSendNotificationMessage(t('publicJob.messageCopied'))
        });
        break;
      }
    }
    setShowShareJobFinish(true);
  }

  const shareAgain=()=>setShowShareJobFinish(false);

  const showEndRegistration = state.activateAccount.ok && !showShareJobFinish;
  const showGetSMSRegristation = state.lightSignup.lightSignupData === null && stateLocation.countriesWithoutPushExceptions.data.length > 0 && !showEndRegistration;
  const showCheckSMSRegistration = state.lightSignup.lightSignupData !== null && !showEndRegistration && !showShareJobFinish;
  
  return <div className='swissinx-job-container-column'>

              {showGetSMSRegristation && <GetSMSRegistration type={props.type} sendSMS={sendSMS}/>}
              {showCheckSMSRegistration && <CheckSMSRegistration type={props.type} checkCode={checkCode} backToSendSMSCode={backToSendSMSCode}/>}
              {showEndRegistration && props.type !== APPLY_JOB && <EndSMSRegistration type={props.type} referBy={sendSMSRegistrationCode}/>}              
              {showEndRegistration && props.type === APPLY_JOB && <ApplyJob />}
              {showShareJobFinish && !state.jobForwaredUpdate.error && <ShareJobFinish shareAgain={shareAgain}/>}             

              <Snackbar
                  open={sendNotification}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={sendNotificationMessage}                  
                />

          </div>
}

export default SMSRegistration;