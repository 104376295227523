import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import { actionMarketsAddLoading, actionMarketsDeleteLoading, actionMarketsEditLoading, actionMarketsLoading, ActionMarketsType } from '../models/actions/typeMarketsActions';
import MarketDto from '../models/dto/MarketDto';

const baseUrlApi:string = getBaseUrlApi();
const urlMarketsList = baseUrlApi + '/api/Market/getMarkets';
const urlMarketsEdit = baseUrlApi + '/api/Market/updateMarket';
const urlMarketsDelete = baseUrlApi + '/api/Market/DeleteMarket';
const urlMarketsCreate = baseUrlApi + '/api/Market/createMarket';

function* checkError(code:number) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>({type: ActionMarketsType.MARKETS_ERROR});
    }
}

function* marketsList(action:actionMarketsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlMarketsList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:MarketDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_SUCCESS, value: {data: data, reset: action.value.reset}});           
        } else {           
            yield call(checkError, response.status);           
        }                
                
    } catch {        
        yield put<TypeActions>({type: ActionMarketsType.MARKETS_ERROR});
    }
}

function* marketsEdit(action:actionMarketsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);      
        const response:Response = yield call(fetch, urlMarketsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}});
        } else {           
            yield call(checkError, response.status);  
        }               
                
    } catch (e){        
        yield put<TypeActions>({type: ActionMarketsType.MARKETS_ERROR});
    }
}

function* marketsDelete(action:actionMarketsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        
        const response:Response = yield call(fetch, urlMarketsDelete + '?marketId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_DELETE_SUCCESS, value: true});     
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}});
        } else {            
            yield call(checkError, response.status);   
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionMarketsType.MARKETS_ERROR});
    }
}

function* marketsAdd(action:actionMarketsAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const data = {name: action.value};
        
        const response:Response = yield call(fetch, urlMarketsCreate, getFetchParams('POST', data, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_ADD_SUCCESS, value: true});   
            yield put<TypeActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: true}});
        } else {           
            yield call(checkError, response.status);   
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionMarketsType.MARKETS_ERROR});
    }
}


function* marketsWhatcher() {
    yield takeLatest(ActionMarketsType.MARKETS_LOADING, marketsList);
}

function* marketsEditWatcher() {
    yield takeLatest(ActionMarketsType.MARKETS_EDIT_LOADING, marketsEdit);
}

function* marketsDeleteWatcher() {
    yield takeLatest(ActionMarketsType.MARKETS_DELETE_LOADING, marketsDelete);
}

function* marketsAddWatcher() {
    yield takeLatest(ActionMarketsType.MARKETS_ADD_LOADING, marketsAdd);
}

export default function* rootMarketsSaga() {
    yield all([
        marketsWhatcher(),
        marketsEditWatcher(),
        marketsDeleteWatcher(),
        marketsAddWatcher()
    ])
}