import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import SkillGroupDto from '../models/dto/SkillGroupDto';
import { ActionSkillGroupType, actionSkillGroupAddLoading, actionSkillGroupDeleteLoading, actionSkillGroupEditLoading, actionSkillGroupLoading } from '../models/actions/typeSkillGroupActions'

const baseUrlApi:string = getBaseUrlApi();
const urlSkillGroupList = baseUrlApi + '/api/SkillGroup/getSkillGroups';
const urlSkillGroupEdit = baseUrlApi + '/api/SkillGroup/updateSkillGroup';
const urlSkillGroupDelete = baseUrlApi + '/api/SkillGroup/DeleteSkillGroup';
const urlSkillGroupCreate = baseUrlApi + '/api/SkillGroup/createSkillGroup';

function* checkError(code:number) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ERROR});
    }
}

function* skillGroupList(action:actionSkillGroupLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlSkillGroupList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:SkillGroupDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_SUCCESS, value: {data: data, reset: action.value.reset}});           
        } else {           
            yield call(checkError, response.status);           
        }                
                
    } catch {        
        yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ERROR});
    }
}

function* skillGroupEdit(action:actionSkillGroupEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);      
        const response:Response = yield call(fetch, urlSkillGroupEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: false}});
        } else {           
            yield call(checkError, response.status);  
        }               
                
    } catch (e){        
        yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ERROR});
    }
}

function* skillGroupDelete(action:actionSkillGroupDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        
        const response:Response = yield call(fetch, urlSkillGroupDelete + '?skillGroupId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_DELETE_SUCCESS, value: true});     
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: false}});
        } else {            
            yield call(checkError, response.status);   
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ERROR});
    }
}

function* skillGroupAdd(action:actionSkillGroupAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const data = {name: action.value};
        
        const response:Response = yield call(fetch, urlSkillGroupCreate, getFetchParams('POST', data, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ADD_SUCCESS, value: true});   
            yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_LOADING, value: {reset: true}});
        } else {           
            yield call(checkError, response.status);   
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionSkillGroupType.SKILL_GROUP_ERROR});
    }
}


function* skillGroupWhatcher() {
    yield takeLatest(ActionSkillGroupType.SKILL_GROUP_LOADING, skillGroupList);
}

function* skillGroupEditWatcher() {
    yield takeLatest(ActionSkillGroupType.SKILL_GROUP_EDIT_LOADING, skillGroupEdit);
}

function* skillGroupDeleteWatcher() {
    yield takeLatest(ActionSkillGroupType.SKILL_GROUP_DELETE_LOADING, skillGroupDelete);
}

function* skillGroupAddWatcher() {
    yield takeLatest(ActionSkillGroupType.SKILL_GROUP_ADD_LOADING, skillGroupAdd);
}

export default function* rootSkillGroupSaga() {
    yield all([
        skillGroupWhatcher(),
        skillGroupEditWatcher(),
        skillGroupDeleteWatcher(),
        skillGroupAddWatcher()
    ])
}