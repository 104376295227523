import { TypeActions } from '../../models/actions/typeActions';

import { newFormClient } from '../../utils/utils';

import { ActionClientsType } from '../../models/actions/typeClientsActions';
import ClientForm from '../../models/forms/ClientForm';
import TypeClientsState from '../../models/initialState/typeClientsState';
import { initialClientsState } from '../inititalState/initialClientsState';

const clientsReducer = (state:TypeClientsState = initialClientsState, action:TypeActions):TypeClientsState => {
    switch (action.type) {

        case  ActionClientsType.CLIENTS_FORM_SEND_VALUES: {
            const name = action.value.name;           
            let clientForm:ClientForm = {...state.clientForm};
            clientForm[name as keyof ClientForm] = {...clientForm[name as keyof ClientForm], value: action.value.value, error: action.value.error};         
            return {...state, clientForm: { ...clientForm} }
        }

        case ActionClientsType.CLIENTS_ERROR_REQUIRED : {
            const name = action.value;           
            let clientForm:ClientForm = {...state.clientForm};
            clientForm[name as keyof ClientForm] =  {...clientForm[name as keyof ClientForm], error: {required: true, format: clientForm[name as keyof ClientForm].error.format}};
            return {...state, clientForm: { ...clientForm}  }      
        }

        case ActionClientsType.CLIENTS_FORM_NEW: {
            let clientForm:ClientForm = newFormClient();           
            return {...state, clientForm: { ...clientForm} }
        }

        case ActionClientsType.CLIENTS_FORM_UPDATE: {
            let clientForm:ClientForm = newFormClient();           
            return {...state, clientForm: { ...clientForm, ...action.value} }
        }
      
        case ActionClientsType.CLIENTS_LOADING: {         
            return {...state, loading: true, error: false}
        }

        case ActionClientsType.CLIENTS_SUCCESS: {           
            return {...state, clientsData: action.value.data, loading: false, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionClientsType.CLIENTS_ERROR: {           
            return {...state, loading: false, error: true}
        }

        case ActionClientsType.CLIENTS_EDIT_LOADING: {           
            return {...state, loading: false, error: false, edit: false}
        }

        case ActionClientsType.CLIENTS_EDIT_SUCCESS: {           
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionClientsType.CLIENTS_ADD_LOADING: {           
            return {...state, loading: false, error: false, add: false}
        }

        case ActionClientsType.CLIENTS_ADD_SUCCESS: {           
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionClientsType.CLIENTS_DELETE_LOADING: {           
            return {...state, loading: false, error: false, delete: false}
        }

        case ActionClientsType.CLIENTS_DELETE_SUCCESS: {           
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionClientsType.CLIENTS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionClientsType.CLIENTS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionClientsType.CLIENTS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionClientsType.CLIENTS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }

    }
    return {...state};
}
export default clientsReducer;