import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TypeState from '../../models/initialState/typeState';
import TypePublicJobState from '../../models/initialState/typePublicJobState';
import { ChangeEvent, useMemo, useState } from 'react';
import { AttachedFiles, WebProfiles } from '../../models/dto/ApplicationPublicJobDto';
import './PublicJob.scss';
import AttachedFilesInput from '../commons/AttachedFilesInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import FiledError from '../Form/common/FieldError/FiledError';
import { validateUrl } from '../Form/validators';

interface Props {
  dataProfile(data: {
    webProfiles: WebProfiles[],
    name: string,
    surname: string,
    phone: string,
    attachedFiles: AttachedFiles[]
  }): void
}

function ApplyProfile(props: Props) {
  const { t } = useTranslation();
  const state: TypePublicJobState = useSelector((state: TypeState) => state.publicJobReducer);
  const [name, setName] = useState(state.activateAccount.data?.name ? state.activateAccount.data?.name : '');
  const [errorName, setErrorName] = useState(false);
  const [surname, setSurname] = useState(state.activateAccount.data?.surname ? state.activateAccount.data?.surname : '');
  const [errorSurname, setErrorSurname] = useState(false);
  const [errorAddAttachments, setErrorAddAttachments] = useState(false);
  const [phone, setPhone] = useState(state.activateAccount.data?.phone);
  const [attachedfiles, setAttachedfiles] = useState<AttachedFiles[]>([]);
  const [webProfiles, setWebProfiles] = useState<WebProfiles[]>([]);
  const [profile, setProfile] = useState('');
  const [errorProfile, setErrorProfile] = useState(false);
  const [apply, setApply] = useState(false);

  const changeFile = (files: any, indexFile: number) => {
    setAttachedfiles(attachedfiles.map((attachedfile, index) => index === indexFile ? files[0] : attachedfile))
    if (attachedfiles.length > 0) {
      setErrorAddAttachments(false);
    }
  }

  const addFile = (files: any) => {
    setAttachedfiles([...attachedfiles, files[0]])   
    setErrorAddAttachments(false);
   
  }

  const deleteFile = (indexFile: number) => {
    setAttachedfiles(attachedfiles.filter((attachedfile, index) => index !== indexFile))
  }

  const checkUrl = (webProf: WebProfiles[])=> {
    let error = false;
    webProf.forEach(prof=>{
      if (!validateUrl(prof.webProfileLink)) {
        error = true;
      }
    })    
    setErrorProfile(error);
    return error;
  }

  const addProfile = () => {
    const index = webProfiles.length;    
    if (profile) {
      const wp = [...webProfiles, { webProfileDescription: 'Profile ' + index, webProfileLink: profile }]
      
      if (!checkUrl(wp)) {
        setWebProfiles(wp);
        setProfile('')
        setErrorAddAttachments(false);
      }
      
    }      
  }  

  const changeProfile = (profile: string, indexProfile: number) => {
    const wp = webProfiles.map((webProfile, index) => index === indexProfile ? { webProfileDescription: 'Profile ' + index + 1, webProfileLink: profile } : webProfile)
    checkUrl(wp);   
    setWebProfiles(wp)
  }

  const deleteProfile = (indexProfile: number) => {
    const wp = webProfiles.filter((webProfile, index) => index !== indexProfile)
    setWebProfiles(wp);
    checkUrl(wp); 
  }

  const onChangeName = (e:ChangeEvent<HTMLInputElement>)=>{
    const value = e.currentTarget.value;
    setName(value);
    setErrorName(value === '')
  }

  const onChangeSurname = (e:ChangeEvent<HTMLInputElement>)=>{
    const value = e.currentTarget.value
    setSurname(value);
    setErrorSurname(value === '')
  }

  const applyJob = () => {
    checkUrl(webProfiles); 
    if (name !== '' && surname !== '' && (attachedfiles.length > 0 || webProfiles.length > 0) && !errorProfile) {
      setApply(true);
      const webProfilesAux = [...webProfiles]
      //const index = webProfiles.length;
      if (profile) {
        webProfilesAux.push({ webProfileDescription: '', webProfileLink: profile })
      }
      props.dataProfile({
        webProfiles: webProfilesAux,
        name: name,
        surname: surname, 
        phone: phone as string,
        attachedFiles: attachedfiles
      })
    } else {
      setErrorName((name === ''));  
      setErrorSurname((surname === ''));  
      setErrorAddAttachments((attachedfiles.length === 0 && webProfiles.length === 0));
    }
  }

  const disabledName = useMemo(() => state.activateAccount.data?.name !== null, [state.activateAccount.data]);
  const disabledSurname = useMemo(() => state.activateAccount.data?.surname !== null, [state.activateAccount.data])

  return <div className='profile-container'>
    <div>
      <div className='data-profile-container'>
        <input type='text' className='questions-input-text' disabled={disabledName} value={name} placeholder={t('admin.jobs.label_name')} onChange={onChangeName} />
        {errorName && <FiledError messageError={t('admin.networkers.name_error_required')}></FiledError>}
        <input type='text' className='questions-input-text' disabled={disabledSurname} value={surname} placeholder={t('admin.jobs.label_surname')} onChange={onChangeSurname} />
        {errorSurname && <FiledError messageError={t('admin.networkers.surname_error_required')}></FiledError>}
        <input type='text' className='questions-input-text' disabled value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
      </div>
      <div className='swissinx-job-body-block'>
        <p className='swissinx-job-container-column__subtitle'>{t('publicJob.addAttachments')}</p>
        {attachedfiles.map((attachedfile, index) => <AttachedFilesInput key={index} attachedFiles={(files) => changeFile(files, index)} file={attachedfile.fileName} deleteFile={deleteFile} index={index} />)}
        <AttachedFilesInput attachedFiles={addFile} file={null} index={attachedfiles.length} deleteFile={deleteFile} />
        {errorAddAttachments && <FiledError messageError={t('publicJob.attachments_error_required')}></FiledError>}
      </div>
      <div className='swissinx-job-body-block-profile'>
        <p className='swissinx-job-container-column__subtitle'>{t('publicJob.addPofile')}</p>
        {webProfiles.map((webProfile, index) => <div key={index} className='profile-url-container'>
          <input type='text' className='questions-input-text' placeholder={t('publicJob.addPofilePlaceHolder')} value={webProfile.webProfileLink} onChange={(e) => changeProfile(e.currentTarget.value, index)} />
          <span className='profile-action-icon' onClick={() => deleteProfile(index)}><FontAwesomeIcon icon={faMinus} /></span>         
        </div>)}

        <input type="url" className='questions-input-text' placeholder={t('publicJob.addPofilePlaceHolder')} value={profile} onChange={(e) => setProfile(e.currentTarget.value)} />
        <span className='profile-action' onClick={addProfile}>
          <span className='profile-action-icon'><FontAwesomeIcon icon={faPlus}/></span>
          <span className='profile-action-text'>{t('publicJob.profileActionText')}</span>
        </span>
        {errorAddAttachments && <FiledError messageError={t('publicJob.attachments_error_required')}></FiledError>}
        {errorProfile && <FiledError messageError={t('publicJob.formatProfile')}></FiledError>}
      </div>
    </div>
    <div>
      <div className="public-job-buttons">
        <button className='public-job-button' type="button" onClick={applyJob} disabled={apply}>{t('publicJob.buttonApply')}</button>       
      </div>
    </div>
  </div>
}

export default ApplyProfile;