import TypeMarketsState from '../../models/initialState/typeMarketsState';
import { DEFAULTITEMSPERPAGE, newFormField } from '../../utils/utils';

export const initialMarketsState: TypeMarketsState = {
      loading: false,
      error: false,
      data: [],
      marketsForm: {
            name: newFormField('',true, false)
      },
      filters: {orderBy: 0, orderDirection: 'asc'},
      initialPage: 0,
      searchText: '',
      pageSize: DEFAULTITEMSPERPAGE,
      edit: false,
      add: false,
      delete: false
}