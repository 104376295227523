import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionJobsType, actionJobsEditLoading, actionJobsDeleteLoading, actionJobsAddLoading, actionJobsLoading, actionJobsCandidatesLoading,
    actionJobsSendPushLoading, actionJobsHistoryPushLoading, actionJobsApplicationStatusLoading, actionJobsApplicationDownloadFileLoading,
    actionJobKpisLoading, actionJobsApplicationPathwayLoading, actionAmbassadorListForPushLoading, actionAmbassadorContactsNetworkerPushLoading,
    actionJobsUpdatePushStatusLoading, actionJobsActivityLoading, actionJobsActivityContactsNetworkerPushLoading, actionJobsApplicationImportToXmlLoading,
    actionGetJobLoading, actionJobUpdateStatusExtendedLoading, actionJobCandidatureStatusLoading } from '../models/actions/typeJobsActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import JobsResponseDto from '../models/dto/JobsResponseDto';
import JobResumeListDto from '../models/dto/JobResumeListDto';
import { getBaseUrlApi, getFetchParams, Params } from './utilsSaga';
import PushHistoryJobDto from '../models/dto/PushHistoryJobDto';
import ApplicationJob from '../models/dto/ApplicationsDto';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import { downloadFile } from '../utils/utils';
import { Kpis, PathwayCandidate, UploadJobsXMLResult } from '../models/initialState/typeJobsState';
import AmbassadorWithKpisDto from '../models/dto/AmbassadorWithKpisDto';
import JobActivityDto, { ContactData } from '../models/dto/JobActivityDto';
import JobStatusExtendedDto from '../models/dto/JobStatusExtendedDto';

const baseUrlApi:string = getBaseUrlApi();
const urlJobsEdit = baseUrlApi + '/api/Job/updateJob';
const urlJobsDelete = baseUrlApi + '/api/Job/DeleteJob';
const urlJobsCreate = baseUrlApi + '/api/Job/createJob';
const urlGetJob = baseUrlApi + '/api/Job/getJob';
const urlJobsResumeList = baseUrlApi + '/api/Job/getJobsOfNetworkerInCompanyResume';
const urlJobPush = baseUrlApi + '/api/Job/pushJob';
const urlJobHistoryPush = baseUrlApi + '/api/Job/getPushsForJob';
const urlAmbassadorListForPush = baseUrlApi + '/api/TA/getAmbassadorListForPush';
const urlContactsForNetworkerAndPush = baseUrlApi + '/api/TA/getNextLevelContactsForPush'
const urlLoadCandidates = baseUrlApi + '/api/Job/getJobApplications';
const urlLoadCandidatesPathway = baseUrlApi + '/api/Job/getJobApplicationPathway';
const urlApplicationStatus = baseUrlApi + '/api/Job/UpdateApplicationStatus';
const urlApplicationDownloadFile = baseUrlApi + '/api/Job/downloadFileFromJobApplication';
const urlApplicationSelectedForJob = baseUrlApi + '/api/Job/UpdateApplicationSelectedForJob';
const urlJobKpis = baseUrlApi + '/api/Job/GetJobKpis';
const urlExportJobsToAllXml = baseUrlApi + '/api/Job/exportJobsToAllXml';
const urlImportJobsToAllXml = baseUrlApi + '/api/Job/importJobsToAllXml';
const urlUpdatePushStatus = baseUrlApi + '/api/Job/updatePushStatus';
const urlgetJobActivity = baseUrlApi + '/api/Job/getJobActivity';
const urlNextLevelActivityContactsPush = baseUrlApi + '/api/Job/getNextLevelActivityContactsPush';
const urlGetJobStatusExtended = baseUrlApi + '/api/Job/GetJobStatusExtended'
const urlGetJobCandidatureStatus = baseUrlApi + '/api/Job/GetJobCandidatureStatus'

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* JobsList(action:actionJobsLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        if (state.companySelected) {                 
            const response:Response = yield call(fetch, urlJobsResumeList + '?companyId=' + state.companySelected.company.id + '&networkerId=' + state.loginResponseDto?.networkerId, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:JobResumeListDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionJobsType.JOBS_SUCCESS, value: {data: data, reset: action.value.reset}});
            } else {  
                const data:string = yield call([response, 'text']);          
                yield call(checkError, response.status, {type: ActionJobsType.JOBS_ERROR, value: data});           
            }   
        }        
             
    } catch (e) {              
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job List'});
    }
}

function* getJob(action:actionGetJobLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        if (state.companySelected) {                 
            const response:Response = yield call(fetch, urlGetJob + '?jobId=' + action.value, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:JobsResponseDto = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionJobsType.JOBS_GET_JOB_SUCCESS, value: data});
            } else {  
                const data:string = yield call([response, 'text']);          
                yield call(checkError, response.status, {type: ActionJobsType.JOBS_ERROR, value: data});           
            }   
        }        
             
    } catch (e) {              
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error edit job'});
    }
}

function* JobsEdit(action:actionJobsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        
        const response:Response = yield call(fetch, urlJobsEdit, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: false}});
        } else {
            const data:string = yield call([response, 'text']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job Edit'});
    }
}

function* JobsDelete(action:actionJobsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);         
        
        const response:Response = yield call(fetch, urlJobsDelete + '?jobId=' + action.value, getFetchParams('DELETE', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: false}});  
        } else {
            const data:string = yield call([response, 'text']);     
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job Delete'});
    }
}

function* JobsAdd(action:actionJobsAddLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlJobsCreate, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_ADD_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: true}});
        } else {
            const data:string = yield call([response, 'text']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job Add'});
    }
}

function* jobsSendPush(action:actionJobsSendPushLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const data = action.value;
        const response:Response = yield call(fetch, urlJobPush, getFetchParams('POST', data, state.token) );
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_SEND_PUSH_SUCCESS, value: true}); 
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING, value: data.jobId});               
        } else {
            const data:string = yield call([response, 'text']);         
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }        
    } catch (e){       
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job Send Push'});
    }
}

function* jobsHistoryPush(action:actionJobsHistoryPushLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        const response:Response = yield call(fetch, urlJobHistoryPush + '?jobId=' + action.value, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:PushHistoryJobDto[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_HISTORY_PUSH_SUCCESS, value: data});
        } else {
            const data:string = yield call([response, 'text']);               
            yield put<TypeActions>({type: ActionJobsType.JOBS_HISTORY_PUSH_ERROR});
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_HISTORY_PUSH_ERROR});
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job History Push'});
    }
}

function* ambassadorListForPush(action:actionAmbassadorListForPushLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        const response:Response = yield call(fetch, urlAmbassadorListForPush + '?pushId=' + action.value, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:AmbassadorWithKpisDto[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_SUCCESS, value: data});
            /* yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_SUCCESS, value: datos}); */
        } else {
            const data:string = yield call([response, 'text']);             
            yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_ERROR});
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_ERROR});
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job History Push - Ambassador list'});
    }
}

function* contactsForNetworkerAndPush(action:actionAmbassadorContactsNetworkerPushLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);
        const params = {
            pushId: action.value.pushId,
            contactLinks: action.value.contactLinks
        }       
        const response:Response = yield call(fetch, urlContactsForNetworkerAndPush, getFetchParams('POST', params, state.token) );
        if (response.ok) {
            const data:AmbassadorWithKpisDto[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_SUCCESS, value: {
                data: data,
                networker: action.value.networkerId,
                localPath: action.value.localPath            
            }
            });
        } else {              
            yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR});
            //yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job History Push'});
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_ERROR});
        //yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error Job History Push - Ambassador list'});
    }
}

function* updatePushStatus(action:actionJobsUpdatePushStatusLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const data = action.value.status;
        const response:Response = yield call(fetch, urlUpdatePushStatus, getFetchParams('POST', data, state.token) );
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_UPDATE_PUSH_STATUS_SUCCESS});
            yield put<TypeActions>({type: ActionJobsType.JOBS_HISTORY_PUSH_LOADING, value: action.value.jobId}); 
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING, value: action.value.jobId});     
        } else {
            const data:string = yield call([response, 'text']);                
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }        
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error update push status'});
    }
}

function* loadJobCandidates(action:actionJobsCandidatesLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlLoadCandidates + '?jobId=' + action.value, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:ApplicationJob[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_SUCCESS, value: data});
        } else {
            const data:string = yield call([response, 'text']);  
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_ERROR});
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error load candidates'});
        yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_ERROR});
    }
}

function* loadJobCandidatesPathway(action:actionJobsApplicationPathwayLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlLoadCandidatesPathway + '?jobId=' + action.value.jobId + '&applicationId=' + action.value.applicationId, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:PathwayCandidate[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_SUCCESS, value: data});
        } else { 
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_ERROR});           
        }       
    } catch (e){
        yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_PATHWAY_ERROR});           
    }
}

function* jobsCandidateStatus(action:actionJobsApplicationStatusLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);              
        const response:Response = yield call(fetch, urlApplicationStatus, getFetchParams('POST', action.value, state.token) );
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_STATUS_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_LOADING, value: action.value.jobId});  
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_LOADING, value: action.value.jobId}); 
        } else {
            const data:string = yield call([response, 'text']);           
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_STATUS_ERROR});
            yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: data});
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_STATUS_ERROR});
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error reject job candidate'});
    }
}

function* jobsCandidateSelectedForJob(action:actionJobsApplicationStatusLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);              
        const response:Response = yield call(fetch, urlApplicationSelectedForJob, getFetchParams('POST', action.value, state.token) );
        if (response.ok) {
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionJobsType.JOBS_CANDIDATES_LOADING, value: action.value.jobId});
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING, value: action.value.jobId});
        } else {
            const data:string = yield call([response, 'text']);                     
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_ERROR, value: {error: true, message: data}});           
        }                
                
    } catch (e){
        console.log(e);        
        yield put<TypeActions>({type: ActionJobsType.JOBS_ERROR, value: 'Error to select candidate for job'});
    }
}

function* jobsCandidateDownloadFile(action:actionJobsApplicationDownloadFileLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);              
        const response:Response = yield call(fetch, urlApplicationDownloadFile + '?jobId=' + action.value.jobId + '&urlFile=' + action.value.urlFile, getFetchParams('POST', null, state.token) );
        if (response.ok) {
            const data:Blob = yield call([response, 'blob']);  
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_SUCCESS});
            yield call(downloadFile, data, action.value.fileName);           
        } else {                   
            yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_ERROR, value: true});            
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_ERROR, value: true});        
    }
}

function* JobKpi(action:actionJobKpisLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);
        const response:Response = yield call(fetch, urlJobKpis + '?jobId=' + action.value, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:Kpis = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionJobsType.JOB_KPI_SUCCESS, value: data});
        } else {            
            yield put<TypeActions>({type: ActionJobsType.JOB_KPI_ERROR});         
        }   
             
    } catch (e) {
        console.log(e);
        yield put<TypeActions>({type: ActionJobsType.JOB_KPI_ERROR});        
    }
}

function* exportJobsToXml() {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);
        const response:Response = yield call(fetch, urlExportJobsToAllXml, getFetchParams('POST', {companyId: state.companyIdSelected}, state.token));
        if (response.ok) {
            const data:Blob = yield call([response, 'blob']);
            yield call(downloadFile, data, 'jobs.xml');                                  
            yield put<TypeActions>({type: ActionJobsType.JOBS_EXPORT_TO_XML_SUCCESS, value: true });
        } else {            
            yield put<TypeActions>({type: ActionJobsType.JOBS_EXPORT_TO_XML_ERROR, value: true});          
        }             
    } catch (e) {                
        yield put<TypeActions>({type: ActionJobsType.JOBS_EXPORT_TO_XML_ERROR, value: true}); 
    }
}

function* importJobsToXml(action:actionJobsApplicationImportToXmlLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);
        
        const formData = new FormData();
        formData.append('CompanyId', state.companyIdSelected.toString());
        formData.append('FormFile', action.value);        
        
        //formData.append('FormFile', new Blob([action.value], { type: "text/xml"}));
        const params:Params = {
            method: 'POST',
            headers: {               
                Authorization: 'Bearer ' + state.token
            },
            body: formData            
        };        
        const response:Response = yield call(fetch, urlImportJobsToAllXml, params);
        if (response.ok) {
            const data:UploadJobsXMLResult = yield call([response, 'json']);                                      
            yield put<TypeActions>({type: ActionJobsType.JOBS_IMPORT_TO_XML_SUCCESS, value: data });
            yield put<TypeActions>({type: ActionJobsType.JOBS_LOADING,  value:{reset: false}});   
        } else {                  
            yield put<TypeActions>({type: ActionJobsType.JOBS_IMPORT_TO_XML_ERROR, value: true});          
        }             
    } catch (e) {
        console.log(e);            
        yield put<TypeActions>({type: ActionJobsType.JOBS_IMPORT_TO_XML_ERROR, value: true}); 
    }
}

function* jobActivity(action:actionJobsActivityLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlgetJobActivity + '?jobId=' + action.value, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:JobActivityDto = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_SUCCESS, value: data});
        } else { 
            yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_ERROR});           
        }       
    } catch (e){
        yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_ERROR});           
    }
}

function* activityContactsForNetworkerAndPush(action:actionJobsActivityContactsNetworkerPushLoading) {
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const params = {
            pushId: action.value.pushId,
            contactLinks: action.value.contactLinks
        }           
        const response:Response = yield call(fetch, urlNextLevelActivityContactsPush, getFetchParams('POST', params, state.token) );
        if (response.ok) {
            const data:ContactData[] = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_SUCCESS, value: {data: data, pathId: action.value.pathId, pushId: action.value.pushId, type: action.value.type}});
        } else { 
            yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR});           
        }       
    } catch (e){
        yield put<TypeActions>({type: ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_ERROR});           
    }
}

function* updateJobStatusExtended(action:actionJobUpdateStatusExtendedLoading) {
    const jobId = action.value
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlGetJobStatusExtended + '?jobId=' + jobId, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:JobStatusExtendedDto = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_SUCCESS, value: {jobId,data}});
        } else {     
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_ERROR});   
        }                           
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOB_GET_STATUS_EXTENDED_ERROR});  
    }
}

function* updateJobCandidatureStatus(action:actionJobCandidatureStatusLoading) {
    const jobId = action.value
    try {
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);            
        const response:Response = yield call(fetch, urlGetJobCandidatureStatus + '?jobId=' + jobId, getFetchParams('GET', null, state.token) );
        if (response.ok) {
            const data:JobStatusExtendedDto = yield call([response, 'json']);
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_SUCCESS, value: {jobId,data}});
        } else {     
            yield put<TypeActions>({type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_ERROR});   
        }                           
    } catch (e){        
        yield put<TypeActions>({type: ActionJobsType.JOB_GET_CANDIDATURE_STATUS_ERROR});  
    }
}


function* jobsWhatcher() {
    yield takeLatest(ActionJobsType.JOBS_LOADING, JobsList);
}

function* getJobWhatcher() {
    yield takeLatest(ActionJobsType.JOBS_GET_JOB_LOADING, getJob);
}

function* jobsEditWatcher() {
    yield takeLatest(ActionJobsType.JOBS_EDIT_LOADING, JobsEdit);
}

function* jobsDeleteWatcher() {
    yield takeLatest(ActionJobsType.JOBS_DELETE_LOADING, JobsDelete);
}

function* jobsAddWatcher() {
    yield takeLatest(ActionJobsType.JOBS_ADD_LOADING, JobsAdd);
}

function* jobsSendPushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_SEND_PUSH_LOADING, jobsSendPush);
}

function* jobsHistoryPushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_HISTORY_PUSH_LOADING, jobsHistoryPush);
}

function* ambassadorListForPushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_AMBASSADOR_LIST_PUSH_LOADING, ambassadorListForPush);
}

function* contactsForNetworkerAndPushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING, contactsForNetworkerAndPush);
}

function* updatePushStatushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_UPDATE_PUSH_STATUS_LOADING, updatePushStatus);
}

function* loadJobCandidatesWatcher() {
    yield takeLatest(ActionJobsType.JOBS_CANDIDATES_LOADING, loadJobCandidates);
}

function* loadJobCandidatesPathwayWatcher() {
    yield takeLatest(ActionJobsType.JOBS_CANDIDATES_PATHWAY_LOADING, loadJobCandidatesPathway);
}


function* jobsCandidateStatusWatcher() {
    yield takeLatest(ActionJobsType.JOBS_APPLICATION_STATUS_LOADING, jobsCandidateStatus);
}

function* jobsCandidateDownloadFileWatcher() {
    yield takeLatest(ActionJobsType.JOBS_APPLICATION_DOWNLOAD_FILE_LOADING, jobsCandidateDownloadFile);
}

function* jobsCandidateSelectedForJobWatcher() {
    yield takeLatest(ActionJobsType.JOBS_APPLICATION_SELECTION_FOR_JOB_LOADING, jobsCandidateSelectedForJob);
}

function* JobKpiWatcher() {
    yield takeLatest(ActionJobsType.JOB_KPI_LOADING, JobKpi);
}

function* exportJobsToXmlWatcher() {
    yield takeLatest(ActionJobsType.JOBS_EXPORT_TO_XML_LOADING, exportJobsToXml);
}

function* importJobsToXmlWatcher() {
    yield takeLatest(ActionJobsType.JOBS_IMPORT_TO_XML_LOADING, importJobsToXml);
}

function* jobActivityWatcher() {
    yield takeLatest(ActionJobsType.JOBS_ACTIVITY_LOADING, jobActivity);
}

function* activityContactsForNetworkerAndPushWatcher() {
    yield takeLatest(ActionJobsType.JOBS_ACTIVITY_CONTACTS_NETWORKER_PUSH_LOADING, activityContactsForNetworkerAndPush);
}

function* jobGetStatusExtendedWhatcher() {
    yield takeLatest(ActionJobsType.JOB_GET_STATUS_EXTENDED_LOADING, updateJobStatusExtended);
}

function* jobGetCandidatureStatusWhatcher() {
    yield takeLatest(ActionJobsType.JOB_GET_CANDIDATURE_STATUS_LOADING, updateJobCandidatureStatus);
}

export default function* rootJobsSaga() {
    yield all([
        jobsWhatcher(),
        getJobWhatcher(),
        jobsEditWatcher(),
        jobsDeleteWatcher(),
        jobsAddWatcher(),       
        jobsSendPushWatcher(),
        jobsHistoryPushWatcher(),
        ambassadorListForPushWatcher(),
        contactsForNetworkerAndPushWatcher(),    
        loadJobCandidatesWatcher(),
        loadJobCandidatesPathwayWatcher(),
        jobsCandidateStatusWatcher(),
        jobsCandidateDownloadFileWatcher(),
        jobsCandidateSelectedForJobWatcher(),
        JobKpiWatcher(),
        exportJobsToXmlWatcher(),
        importJobsToXmlWatcher(),
        updatePushStatushWatcher(),
        jobActivityWatcher(),
        activityContactsForNetworkerAndPushWatcher(),
        jobGetStatusExtendedWhatcher(),
        jobGetCandidatureStatusWhatcher()
    ])
}


