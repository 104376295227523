import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from '../../saga/saga';
import TypeMainState from '../../models/initialState/typeMainState';
import LoginForm from '../../models/forms/LoginForm';
import FieldForm from '../../models/forms/FieldForm';
import { TypeActions, ActionsType } from '../../models/actions/typeActions';
import { ActionLoginType } from '../../models/actions/typeLoginActions';
import {initialState} from '../inititalState/initialState';
import jobsReducer from './jobsReducer';
import locationReducer from './locationReducer';
import clientsReducer from './clientsReducer';
import companiesReducer from './companiesReducer';
import publicJobReducer from './publicJobReducer';
import companyGroupsReducer from './companyGroupsReducer';
import networkersReducer from './networkersReducer';
import networkersRequestListReducer from './networkersRequestListReducer';
import ambassadorsReducer from './ambassadorsReducer';
import skillReducer from './skillReducer';
import marketsReducer from './marketsReducer';
import languagesReducer from './languagesReducer';
import dashboardReducer from './dashboardReducer';
import feeReducer from './feeReducer';
import skillGroupReducer from './skillGroupReducer';
import paymentsReducer from './paymentsReducer';


const mainReducer = (state:TypeMainState = initialState, action:TypeActions):TypeMainState => {
    switch (action.type) {
        
        case  ActionLoginType.LOGIN_FORM_SEND_VALUES: {
            const name = action.value.name;
            let loginForm:LoginForm = {...state.loginForm};
            loginForm[name as keyof LoginForm] = {...loginForm[name as keyof LoginForm], value: action.value.value, error: action.value.error};         
            return {...state, loginForm: { ...loginForm} }
        }

        case ActionLoginType.LOGIN_FORM_ERROR_REQUIRED : {
            const name = action.value;
            let loginForm:LoginForm = {...state.loginForm};
            loginForm[name as keyof LoginForm] =  {...loginForm[name as keyof LoginForm], error: {required: true, format: loginForm[name as keyof LoginForm].error.format}};
            return {...state,  loginForm: {...loginForm}}         
        }

        case ActionLoginType.LOGIN_SERVER_SEND_DATA: {
            return {...state, loading: true, errorServerLogin: false, errorServerLoginMessage: '', auth: false, token: '', tokenExpiration: '', loginResponseDto: null}
        }

        case ActionLoginType.LOGIN_SERVER_SUCCESS: {
            localStorage.setItem('TOKEN', action.value.token);
            localStorage.setItem('TOKENEXPIRATION', action.value.expiration);
            localStorage.setItem('LOGINDATA', JSON.stringify(action.value));       
            return {...state, 
                loading: false, 
                errorServerLogin: false, 
                auth: true, 
                token: action.value.token, 
                tokenExpiration: action.value.expiration, 
                loginResponseDto: action.value, 
                renovarToken: true,
                roleCompanySelected: action.value.role.id,
                companySelected: null, 
                companyIdSelected: -1
            }
        }

        case ActionLoginType.LOGIN_SERVER_ERROR: {
            return {...state, loading: false, errorServerLogin: true, errorServerLoginMessage: action.value, auth: false, token: '', tokenExpiration: '', loginResponseDto: null}
        }

        case ActionLoginType.LOGIN_RENEWTOKEN_VALUE: {
            return {...state, renovarToken: action.value}
        }

        case ActionLoginType.ACTION_LOOUT: {
            localStorage.setItem('TOKEN', 'null');
            localStorage.setItem('TOKENEXPIRATION', 'null');
            localStorage.setItem('LOGINDATA', 'null');
            let loginForm:LoginForm = {...state.loginForm};
            let password:FieldForm = {...state.loginForm.password};
            return {...state, loginForm: { ...loginForm, password: {...password, value: ''}, }, auth: false, token: '', tokenExpiration: '', renovarToken: false}
        }

        case ActionLoginType.RECOVER_ACCOUNT_PASSWORD_LOADING: {
            const recoverPassword = state.recoverPassword;
            return {...state, recoverPassword: {...recoverPassword, loading: true, error: false, ok: false}}
        }

        case ActionLoginType.RECOVER_ACCOUNT_PASSWORD_SUCCESS: {
            const recoverPassword = state.recoverPassword;
            return {...state, recoverPassword: {...recoverPassword, loading: false, error: false, ok: true}}
        }

        case ActionLoginType.RECOVER_ACCOUNT_PASSWORD_ERROR: {
            const recoverPassword = state.recoverPassword;
            return {...state, recoverPassword: {...recoverPassword, loading: false, error: true, ok: false}}
        }

        case ActionsType.SEND_COMPANY_SELECTED: {
            return {...state, companySelected: action.value, companyIdSelected: action.value ? action.value.company.id : -1}
        }
    }
    return {...state};
}

const reducer = combineReducers({
    mainReducer, 
    jobsReducer, 
    locationReducer, 
    clientsReducer, 
    companiesReducer, 
    publicJobReducer, 
    companyGroupsReducer, 
    networkersReducer,
    networkersRequestListReducer,
    ambassadorsReducer,
    skillGroupReducer,
    skillReducer,
    marketsReducer,
    languagesReducer,
    dashboardReducer,
    feeReducer,
    paymentsReducer,   
});
//export type RootState = ReturnType<typeof reducer>;
const sagaMiddelware = createSagaMiddleware();
export default createStore(reducer, applyMiddleware(sagaMiddelware));
sagaMiddelware.run(rootSaga);