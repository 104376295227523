import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import { actionLanguagesAddLoading, actionLanguagesDeleteLoading, actionLanguagesEditLoading, actionLanguagesLoading, ActionLanguagesType } from '../models/actions/typeLanguagesActions';
import LanguageDto from '../models/dto/LanguageDto';

const baseUrlApi:string = getBaseUrlApi();
const urlLanguagesList = baseUrlApi + '/api/Language/getLanguages';
const urlLanguagesEdit = baseUrlApi + '/api/Language/updateLanguage';
const urlLanguagesDelete = baseUrlApi + '/api/Language/DeleteLanguage';
const urlLanguagesCreate = baseUrlApi + '/api/Language/createLanguage';

function* checkError(code:number, message: string) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ERROR, value: message});
    }
}

function* languagesList(action:actionLanguagesLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlLanguagesList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:LanguageDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_SUCCESS, value: {data: data, reset: action.value.reset}});           
        } else {
            const data:string = yield call([response, 'text']);                   
            yield call(checkError, response.status, data);           
        }
                
    } catch {        
        yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ERROR, value: ''});
    }
}

function* languagesEdit(action:actionLanguagesEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);      
        const response:Response = yield call(fetch, urlLanguagesEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}});
        } else {           
            const data:string = yield call([response, 'text']);                   
            yield call(checkError, response.status, data);      
        }               
                
    } catch (e){        
        yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ERROR, value: ''});
    }
}

function* languagesDelete(action:actionLanguagesDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        
        const response:Response = yield call(fetch, urlLanguagesDelete + '?languageId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_DELETE_SUCCESS, value: true});     
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}});
        } else {            
            const data:string = yield call([response, 'text']);                   
            yield call(checkError, response.status, data);      
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ERROR, value: ''});
    }
}

function* languagesAdd(action:actionLanguagesAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        const data = {name: action.value};
        
        const response:Response = yield call(fetch, urlLanguagesCreate, getFetchParams('POST', data, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ADD_SUCCESS, value: true});   
            yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: true}});
        } else {           
            const data:string = yield call([response, 'text']);                   
            yield call(checkError, response.status, data);        
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionLanguagesType.LANGUAGES_ERROR, value: ''});
    }
}


function* languagesWhatcher() {
    yield takeLatest(ActionLanguagesType.LANGUAGES_LOADING, languagesList);
}

function* languagesEditWatcher() {
    yield takeLatest(ActionLanguagesType.LANGUAGES_EDIT_LOADING, languagesEdit);
}

function* languagesDeleteWatcher() {
    yield takeLatest(ActionLanguagesType.LANGUAGES_DELETE_LOADING, languagesDelete);
}

function* languagesAddWatcher() {
    yield takeLatest(ActionLanguagesType.LANGUAGES_ADD_LOADING, languagesAdd);
}

export default function* rootLanguagesSaga() {
    yield all([
        languagesWhatcher(),
        languagesEditWatcher(),
        languagesDeleteWatcher(),
        languagesAddWatcher()
    ])
}