import FeeDto from '../dto/FeeDto';
import FeeForm from '../forms/FeeForm';

export enum ActionFeeType {
    
    FEE_LOADING = 'FEE_LOADING',
    FEE_SUCCESS = 'FEE_SUCCESS',
    FEE_ERROR = 'FEE_ERROR',
    FEE_FORM_NEW = 'FEE_FORM_NEW',
    FEE_FORM_UPDATE = 'FEE_FORM_UPDATE',
    FEE_FORM_SEND_VALUES = 'FEE_FORM_SEND_VALUES', 
    FEE_ERROR_REQUIRED = 'FEE_ERROR_REQUIRED',    
    FEE_ADD_LOADING = 'FEE_ADD_LOADING',
    FEE_ADD_SUCCESS = 'FEE_ADD_SUCCESS',
    FEE_ADD_ERROR = 'FEE_ADD_ERROR',
    FEE_EDIT_LOADING = 'FEE_EDIT_LOADING',
    FEE_EDIT_SUCCESS = 'FEE_EDIT_SUCCESS',
    FEE_EDIT_ERROR = 'FEE_EDIT_ERROR',
    FEE_DELETE_LOADING = 'FEE_DELETE_LOADING',
    FEE_DELETE_SUCCESS = 'FEE_DELETE_SUCCESS',
    FEE_DELETE_ERROR = 'FEE_DELETE_ERROR',
    FEE_CHANGE_SEARCH_TABLE = 'FEE_CHANGE_SEARCH_TABLE',
    FEE_CHANGE_PAGE_TABLE = 'FEE_CHANGE_PAGE_TABLE',
    FEE_CHANGE_PAGE_SIZE = 'FEE_CHANGE_PAGE_SIZE',
    FEE_TEMPLATE_JOBS_PUSH_LOADING = 'FEE_TEMPLATE_JOBS_PUSH_LOADING',
    FEE_TEMPLATE_JOBS_PUSH_SUCCESS = 'FEE_TEMPLATE_JOBS_PUSH_SUCCESS',
    FEE_TEMPLATE_JOBS_PUSH_ERROR = 'FEE_TEMPLATE_JOBS_PUSH_ERROR',
    FEE_CHANGE_FILTER = 'FEE_CHANGE_FILTER'
}

export interface actionFeeLoading {
    type: ActionFeeType.FEE_LOADING,
    value: {reset: boolean}
}
export interface actionFeeSuccess {
    type: ActionFeeType.FEE_SUCCESS,
    value: {data: FeeDto[], reset: boolean}
    
}
export interface actionFeeError {
    type: ActionFeeType.FEE_ERROR   
}

export interface actionFeeSendValues {
    type: ActionFeeType.FEE_FORM_SEND_VALUES,
    value: {
        name: string,
        value: string | boolean | number[],
        error: {
            required: boolean,
            format: boolean
        }
    }
}

export interface actionFeeErrorRequired {
    type: ActionFeeType.FEE_ERROR_REQUIRED,
    value: string
}

export interface actionFeeFormNew {
    type: ActionFeeType.FEE_FORM_NEW    
}

export interface actionFeeFormUpdate {
    type: ActionFeeType.FEE_FORM_UPDATE,
    value: FeeForm
}

export interface actionFeeAddLoading {
    type: ActionFeeType.FEE_ADD_LOADING,
    value: FeeDto
}
export interface actionFeeAddSuccess {
    type: ActionFeeType.FEE_ADD_SUCCESS,
    value: boolean
}
    
export interface actionFeeAddError {
    type: ActionFeeType.FEE_ADD_ERROR   
}

export interface actionFeeEditLoading {
    type: ActionFeeType.FEE_EDIT_LOADING,
    value: FeeDto
}
export interface actionFeeEditSuccess {
    type: ActionFeeType.FEE_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionFeeEditError {
    type: ActionFeeType.FEE_EDIT_ERROR   
}

export interface actionFeeDeleteLoading {
    type: ActionFeeType.FEE_DELETE_LOADING,
    value: number
}
export interface actionFeeDeleteSuccess {
    type: ActionFeeType.FEE_DELETE_SUCCESS,
    value: boolean
}
    
export interface actionFeeDeleteError {
    type: ActionFeeType.FEE_DELETE_ERROR   
}

export interface actionFeeChangeSerachTable {
    type: ActionFeeType.FEE_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionFeeChangePageSizeTable {
    type: ActionFeeType.FEE_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionFeeChangePageTable {
    type: ActionFeeType.FEE_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionFeeTemplateJobsPushLoading {
    type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_LOADING
    value: number    
}

export interface actionFeeTemplateJobsPushSuccess {
    type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_SUCCESS,
    value: FeeDto[]
}

export interface actionFeeTemplateJobsPushError {
    type: ActionFeeType.FEE_TEMPLATE_JOBS_PUSH_ERROR
}

export interface actionFeeChangeFilterTable {
    type: ActionFeeType.FEE_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeFeeActions = actionFeeErrorRequired |actionFeeSendValues | 
                            actionFeeFormUpdate | actionFeeFormNew |
                            actionFeeLoading | actionFeeSuccess | actionFeeError |
                            actionFeeAddLoading | actionFeeAddSuccess | actionFeeAddError |
                            actionFeeEditLoading | actionFeeEditSuccess | actionFeeEditError |
                            actionFeeDeleteLoading | actionFeeDeleteSuccess | actionFeeDeleteError |
                            actionFeeChangeSerachTable | actionFeeChangePageSizeTable | actionFeeChangePageTable |
                            actionFeeTemplateJobsPushLoading | actionFeeTemplateJobsPushSuccess | actionFeeTemplateJobsPushError |
                            actionFeeChangeFilterTable;
                                