import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector} from 'react-redux';
import NetworkerDto from '../../models/dto/NetworkerDto';

import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import TypeState from '../../models/initialState/typeState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionNetworkersType } from '../../models/actions/typeNetworkersActions';
import Table from '../table/Table';
import { Reward } from '../../models/dto/RewardDto';
import { convertDateHourIso, SNACBARPOSITION, SNACKBARTIMETOHIDE } from '../../utils/utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material';
//import './Networkers.scss';

interface Props {
    networker: NetworkerDto | null,    
    rewardsBack(): void
}

function RewardsList(props:Props) {
  const { t } = useTranslation();
  const state:TypeNetworkersState = useSelector((state:TypeState) => state.networkersReducer);
  const dispatch = useDispatch();
  const [openNotification, setOpenNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success')

  useEffect(()=>{
    if (state.rewards.downloadPdfSuccess) {
        setMessageNotification('PDF downloaded');
        setOpenNotification(true);
        setSeverity('success');
    }
    if (state.rewards.downloadPdfError) {
        setMessageNotification('Error to download PDF');        
        setOpenNotification(true);
        setSeverity('error')
    }
  }, [state.rewards.downloadPdfError, state.rewards.downloadPdfSuccess]);

  const headers = [
    {title: t('admin.networkers.label_reward'), field: 'reward'},
    {title: t('admin.networkers.label_fee'), field: 'fee'},
    {title: t('admin.networkers.label_currency'), field: 'currency'},
    {title: t('admin.networkers.label_company'), field: 'company.description'},
    {title: t('admin.networkers.label_job'), field: 'job.description'},
    {title: t('admin.networkers.label_dateApplication'), field: 'dateApplication', render: (rowData:Reward) => convertDateHourIso(rowData.dateApplication)},
    {title: t('admin.networkers.label_dateSelectedForJob'), field: 'dateSelectedForJob', render: (rowData:Reward) => convertDateHourIso(rowData.dateSelectedForJob)},
  ];

  const downloadPdf = () => {
    if (props.networker) {
        dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_LOADING, value: {companyId: props.networker.companyId, networkerId: props.networker.networkerId, year: state.rewards.yearSelected}});
    }
  }

  const closeNotification = () => {
    if (state.rewards.downloadPdfSuccess) {
        dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_SUCCESS, value: false});
    }

    if (state.rewards.downloadPdfError) {
        dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_PDF_ERROR, value: false});
    }
    setOpenNotification(false);
  }
  
  const optionsYears = useMemo(()=> state.rewards.years.map((option) => 
                                            <option key={option} value={option}>
                                                {option}
                                            </option>) 
                               , [state.rewards.years]);
   
   const changeYear = (event: ChangeEvent<HTMLSelectElement>) => {   
    const value = parseInt(event.target.value);
    dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_SEND_YEAR_SELECTED, value: value});
    if (props.networker) {
        dispatch<TypeActions>({type: ActionNetworkersType.NETWORKERS_REWARDS_DATA_LOADING, value: {companyId: props.networker.companyId, networkerId: props.networker.networkerId, year: value}});
    }
   }
   const formatNumber = (total: number, currency: string) => Intl.NumberFormat('de-CH', {style: 'currency',currency: currency, minimumFractionDigits: 2}).format(total);
   const formatName = () => props.networker !== null ? `${props.networker.name} ${props.networker.surname}` : '';

return <div className='swissinx-container'>   
            <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.rewardsBack}/>{t('admin.networkers.rewardsListTitle')}</h2>
            {state.rewards.years.length > 0 && <>
                <h3>{formatName()}</h3>
                <label>{t('admin.networkers.rewardsLabelYear')}</label>
                <select
                    className="react-rewards-year__select"
                    value={state.rewards.yearSelected}                                          
                    onChange={changeYear}>
                    {optionsYears}
                </select></>}
                <List >
                    {state.rewards.years.length > 0 && state.rewards.data.totalRewardCurrency.length > 0 && <h3>Total Rewards</h3>}
                    {state.rewards.data.totalRewardCurrency.map((currency, index) => <ListItem key={index}>
                        <ListItemText
                            primary={currency.currency !== '' ? formatNumber(currency.total, currency.currency) : currency.total}                                
                        />
                        </ListItem>
                    )}             
                                 
                </List>
                {state.rewards.data.totalRewardCurrency.length > 0 && 
                <div className='swissinx-pdf'>
                    <button onClick={downloadPdf} disabled={state.rewards.downloadPdfLoading}>{!state.rewards.downloadPdfLoading ? t('admin.networkers.downloadPdf') : t('admin.networkers.downloading')} <CloudDownload /> </button>
                </div>}               
                
            <Table<Reward> 
              data={state.rewards.data.rewards} 
              headers={headers}
            />

            <Snackbar anchorOrigin={SNACBARPOSITION} open={openNotification} autoHideDuration={SNACKBARTIMETOHIDE} onClose={closeNotification}>                    
                <Alert onClose={closeNotification} severity={severity} sx={{ width: '100%' }} >
                    {messageNotification}
                </Alert>
            </Snackbar>
        </div>;
  
 
}

export default RewardsList;