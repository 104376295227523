import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './NotificationsJob.scss';

interface Props {
    message: string
}
function NotoficationsJob(props: Props) {

return <div className='swissinx-notifications-job'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <h2>{props.message}</h2>                 
        </div>
}

export default NotoficationsJob;