
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";

import Table from '../../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TypeState from '../../../models/initialState/typeState';
import TypeLocationState from '../../../models/initialState/typeLocationState';
import { CityDto } from '../../../models/dto/LocationDto';
import { ChangeEvent } from 'react';

interface Props {
  edit(event: any, index: CityDto): void,
  remove(event: any, index: CityDto): void,
  newRegister(): void,
  country: number,
  region: number,
  changeCountry(event: ChangeEvent<HTMLInputElement>): void,
  changeRegion(event: ChangeEvent<HTMLInputElement>): void,
  changePage(page: number, pageSize: number): void,
  changeSearchText(searchText: string): void,
  changeRowsPerPage?(pageSize: number): void,
  onOrderChange?(orderBy: number, orderDirection: string): void,
}

function CitiesList(props: Props) {
  const { t } = useTranslation();
  const state: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);

  const headers = [{ title: t('admin.cities.description'), field: 'description', defaultSort: state.cities.filters.orderBy === 0 ? state.cities.filters.orderDirection: '' }];

  const actions = [
    {
      icon: Edit,
      tooltip: t('table.tooltip.actionEdit'),
      disabled: false,
      onClick: props.edit
    },
    {
      icon: DeleteOutline,
      tooltip: t('table.tooltip.actionDelete'),
      disabled: false,
      onClick: props.remove
    },
  ]

  return <div className='swissinx-container'>
    <h2>{t('admin.cities.listTitle')}</h2>
    <div className="swissinx-container-fields-form">
      <h3>{t("admin.filter")}</h3>
      <div className="swissinx-container-fields">
        <div className='swissinx-field-container'>
          <div className="swissinx-field field-country">
            <TextField
              id="country"
              select={true}
              label={t('admin.cities.label_country')}
              value={props.country}
              error={false}
              onChange={props.changeCountry}
            >
              {state.countries.data.map(country => <MenuItem key={country.id} value={country.id}>{country.description}</MenuItem>)
              }
            </TextField>
          </div>
          <div className="swissinx-field">
            <TextField
              id="region"
              select={true}
              label={t('admin.cities.label_region')}
              value={props.region}
              error={false}
              onChange={props.changeRegion}
            >
              {state.regions.data.map(region => <MenuItem key={region.id} value={region.id}>{region.description}</MenuItem>)
              }
            </TextField>
          </div>
        </div>
      </div>
    </div>

    <Table<CityDto>
      data={state.cities.data}
      headers={headers}
      actions={actions}
      new={true}
      initialPage={state.cities.initialPage}
      serchText={state.cities.searchText}
      pageSize={state.cities.pageSize}
      newRegister={props.newRegister}
      changePage={props.changePage}
      changeSearchText={props.changeSearchText}
      changeRowsPerPage={props.changeRowsPerPage}
      onOrderChange={props.onOrderChange}
       />
  </div>;
}

export default CitiesList;