
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Info from '@material-ui/icons/Info';

//import './ModalInfo.scss';
import NetworkerDto from '../../models/dto/NetworkerDto';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
  };

interface Props {
    title: string,
    description: string,
    label: string,
    textButtonConfirm: string,
    index: NetworkerDto | null,
    show: boolean,
    confirm(index: NetworkerDto | null):void  
}


function NetWorkersModalAnonymizer(props: Props) {
  const [phone, setPhone] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);

  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    setErrorPhone(false);
  }

  const close = ()=>{
    if (phone === props.index?.phone) {
      props.confirm(props.index)
    } else {
      setErrorPhone(true);
    }
    
  }

  return <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.show}
            onClose={()=>props.confirm(props.index)}
        >                            
            <Box sx={style}>
                <div className='swissin-modal-close'>
                  <span onClick={()=>props.confirm(null)}>X</span>
                </div>   
                <div className='swissin-modal-info anonymizer'>
                  <Info />
                  <h2>{props.title}</h2>  
                  <p>{props.description}</p>              
                  <div className='swissin-modal-info-data'>
                    <label>{props.label}</label>
                    <input type='text' value={phone} onChange={onChange}></input>
                    {errorPhone && <p className='modal-data-error'>Error</p>}
                  </div>
                  <div className='swissin-modal-info-buttons'>
                      <button onClick={close}>{props.textButtonConfirm}</button>
                  </div>                    
              </div>
            </Box>
        </Modal>           
       
  }

export default NetWorkersModalAnonymizer;
