import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { actionLocationCitiesAddLoading, actionLocationCitiesDeleteLoading, actionLocationCitiesEditLoading, actionLocationCitiesLoading, actionLocationCountriesAddLoading, actionLocationCountriesDeleteLoading, actionLocationCountriesEditLoading, actionLocationCountriesWithoutPushExceptionsLoading, actionLocationRegionsAddLoading, actionLocationRegionsDeleteLoading, actionLocationRegionsEditLoading, actionLocationRegionsLoading, ActionLocationType } from '../models/actions/typeLocationActions';
import { CityDto, CountryDto, RegionDto } from '../models/dto/LocationDto';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeState from '../models/initialState/typeState';
import TypeMainState from '../models/initialState/typeMainState';

const baseUrlApi:string = getBaseUrlApi();
const urlLocationCountries = baseUrlApi + '/api/Location/getCountries';
const urlLocationCountriesEdit = baseUrlApi + '/api/Location/updateCountry';
const urlLocationCountriesAdd = baseUrlApi + '/api/Location/createCountry';
const urlLocationCountriesDelete = baseUrlApi + '/api/Location/deleteCountry';

const urlLocationRegions = baseUrlApi + '/api/Location/getRegionsOfCountry';
const urlLocationRegionsAdd = baseUrlApi +'/api/Location/createRegion';
const urlLocationRegionsEdit = baseUrlApi +'/api/Location/updateRegion';
const urlLocationRegionsDelete = baseUrlApi +'/api/Location/deleteRegion';

const urlLocationCities = baseUrlApi + '/api/Location/getCitiesOfRegion';
const urlLocationCitiesAdd = baseUrlApi + '/api/Location/createCity';
const urlLocationCitiesEdit = baseUrlApi + '/api/Location/updateCity';
const urlLocationCitiesDelete = baseUrlApi + '/api/Location/deleteCity';

const urlCountriesWithoutPushExceptions = baseUrlApi + '/api/Location/getCountriesWithoutPushAndJobExceptions';

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* loadCountries() {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        
        const response:Response = yield call(fetch, urlLocationCountries, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:CountryDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_SUCCESS, value: data});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_COUNTRIES_ERROR});  
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ERROR});
    }
}


function* createCountry(action: actionLocationCountriesAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCountriesAdd, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ADD_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: true}});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_COUNTRIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ERROR});
    }
}

function* editCountry(action: actionLocationCountriesEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCountriesEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_COUNTRIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ERROR});
    }
}

function* deleteCountry(action: actionLocationCountriesDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCountriesDelete + '?countryId=' + action.value, getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_LOADING, value: {reset: false}});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_COUNTRIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_ERROR});
    }
}

function* loadRegions(action: actionLocationRegionsLoading) {
    try {
        if (action.value !== 0) {
            const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
            const response:Response = yield call(fetch, urlLocationRegions + '?countryId=' + action.value, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:RegionDto[] = yield call([response, 'json']);          
                yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_SUCCESS, value: data});            
            } else {           
                yield call(checkError, response.status, {type: ActionLocationType.LOCATION_REGIONS_ERROR});  
            }    
        } else {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_SUCCESS, value: []});
        }        
    } catch (e){       
        yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ERROR});
    }
}

function* createRegion(action: actionLocationRegionsAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationRegionsAdd, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ADD_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: action.value.countryId});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_REGIONS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ERROR});
    }
}

function* editRegion(action: actionLocationRegionsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationRegionsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: action.value.countryId});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_REGIONS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ERROR});
    }
}

function* deleteRegion(action: actionLocationRegionsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationRegionsDelete + '?regionId=' + action.value.idRegion, getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_LOADING, value: action.value.idCountry});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_REGIONS_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_REGIONS_ERROR});
    }
}

function* loadCities(action: actionLocationCitiesLoading) {
    try {
        if (action.value !== 0) {
            const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
            
            const response:Response = yield call(fetch, urlLocationCities + '?regionId=' + action.value, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:CityDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_SUCCESS, value: data});            
            } else {           
                yield call(checkError, response.status, {type: ActionLocationType.LOCATION_CITIES_ERROR});  
            }
        } else {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_SUCCESS, value: []}); 
        }             
                
    } catch (e){        
        yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ERROR});
    }
}

function* createCity(action: actionLocationCitiesAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCitiesAdd, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ADD_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: action.value.regionId});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_CITIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ERROR});
    }
}

function* editCity(action: actionLocationCitiesEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCitiesEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: action.value.regionId});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_CITIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ERROR});
    }
}

function* deleteCity(action: actionLocationCitiesDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);  
        const response:Response = yield call(fetch, urlLocationCitiesDelete + '?cityId=' + action.value.idCity, getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_DELETE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_LOADING, value: action.value.idRegion});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_CITIES_ERROR});  
        }      
    } catch (e){      
        yield put<TypeActions>({type: ActionLocationType.LOCATION_CITIES_ERROR});
    }
}

function* loadCountriesWithoutPushExceptions(action: actionLocationCountriesWithoutPushExceptionsLoading) {
    try {
        const response:Response = yield call(fetch, urlCountriesWithoutPushExceptions + '?pushId=' + action.value.pushId + '&jobId=' + action.value.jobId, getFetchParams('GET', null, null));
        if (response.ok) {
            const data:CountryDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_SUCCESS, value: data});            
        } else {           
            yield call(checkError, response.status, {type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR});  
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR});
    }
}

function* locationCountriesWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_COUNTRIES_LOADING, loadCountries);
}

function* createCountryWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_COUNTRIES_ADD_LOADING, createCountry);
}
function* editCountryWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_COUNTRIES_EDIT_LOADING, editCountry);
}
function* deleteCountryWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_COUNTRIES_DELETE_LOADING, deleteCountry);
}

function* locationRegionsWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_REGIONS_LOADING, loadRegions);
}

function* createRegionWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_REGIONS_ADD_LOADING, createRegion);
}

function* editRegionWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_REGIONS_EDIT_LOADING, editRegion);
}

function* deleteRegionWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_REGIONS_DELETE_LOADING, deleteRegion);
}

function* locationCitiesWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_CITIES_LOADING, loadCities);
}

function* createCityWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_CITIES_ADD_LOADING, createCity);
}

function* editCityWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_CITIES_EDIT_LOADING, editCity);
}

function* deleteCityWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_CITIES_DELETE_LOADING, deleteCity);
}

function* loadCountriesWithoutPushExceptionsWatcher() {
    yield takeLatest(ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING, loadCountriesWithoutPushExceptions);
}


export default function* rootLocationSaga() {
    yield all([        
        locationCountriesWatcher(),
        createCountryWatcher(),
        editCountryWatcher(),
        deleteCountryWatcher(),
        locationRegionsWatcher(),
        locationCitiesWatcher(),
        createRegionWatcher(),
        editRegionWatcher(),
        deleteRegionWatcher(),
        createCityWatcher(),
        editCityWatcher(),
        deleteCityWatcher(),
        loadCountriesWithoutPushExceptionsWatcher()
    ])
}