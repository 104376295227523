import NetworkDashboardDto from "../dto/NetworkDashboardDto";
import UserDashboardDto from "../dto/UserDashboardDto";

export enum ActionDashboardType {   
   
    USER_DASHBOARD_LOADING = 'USER_DASHBOARD_LOADING',
    USER_DASHBOARD_SUCCESS = 'USER_DASHBOARD_SUCCESS',
    USER_DASHBOARD_ERROR = 'USER_DASHBOARD_ERROR',

    NETWORK_DASHBOARD_LOADING = 'NETWORK_DASHBOARD_LOADING',
    NETWORK_DASHBOARD_SUCCESS = 'NETWORK_DASHBOARD_SUCCESS',
    NETWORK_DASHBOARD_ERROR = 'NETWORK_DASHBOARD_ERROR',
    
}

export interface actionUserDashboardLoading {
    type: ActionDashboardType.USER_DASHBOARD_LOADING,
    value: {skillIds: number[], marketIds: number[]} 
}
export interface actionUserDashboardSuccess {
    type: ActionDashboardType.USER_DASHBOARD_SUCCESS,
    value: UserDashboardDto
}
export interface actionUserDashboardError {
    type: ActionDashboardType.USER_DASHBOARD_ERROR   
}

export interface actionNetworkDashboardLoading {
    type: ActionDashboardType.NETWORK_DASHBOARD_LOADING,
    value: {skillIds: number[], marketIds: number[], regionIds: number[]} 
}
export interface actionNetworkDashboardSuccess {
    type: ActionDashboardType.NETWORK_DASHBOARD_SUCCESS,
    value: NetworkDashboardDto
}
export interface actionNetworkDashboardError {
    type: ActionDashboardType.NETWORK_DASHBOARD_ERROR   
}


export type TypeActionUserDashboardActions = actionUserDashboardLoading | actionUserDashboardSuccess | actionUserDashboardError |
                                             actionNetworkDashboardLoading | actionNetworkDashboardSuccess | actionNetworkDashboardError;