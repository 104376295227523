import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import { actionSkillsAddLoading, actionSkillsDeleteLoading, actionSkillsEditLoading, actionSkillsLoading, ActionSkillsType } from '../models/actions/typeSkillsActions';
import SkillDto from '../models/dto/SkillDto';

const baseUrlApi:string = getBaseUrlApi();
const urlSkillsList = baseUrlApi + '/api/Skill/getSkills';
const urlSkillsEdit = baseUrlApi + '/api/Skill/updateSkill';
const urlSkillsDelete = baseUrlApi + '/api/Skill/DeleteSkill';
const urlSkillsCreate = baseUrlApi + '/api/Skill/createSkill';

function* checkError(code:number, action: any) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* skillsList(action:actionSkillsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlSkillsList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:SkillDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_SUCCESS, value: {data: data, reset: action.value.reset}});           
        } else {  
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: ActionSkillsType.SKILLS_ERROR, value: data});
        }          
                
    } catch {        
        yield put<TypeActions>({type: ActionSkillsType.SKILLS_ERROR, value: ''});
    }
}

function* skillsEdit(action:actionSkillsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);      
        const response:Response = yield call(fetch, urlSkillsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_EDIT_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}});
        } else {           
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: ActionSkillsType.SKILLS_ERROR, value: data});
        }               
                
    } catch (e){        
        yield put<TypeActions>({type: ActionSkillsType.SKILLS_ERROR, value: ''});
    }
}

function* skillsDelete(action:actionSkillsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        
        const response:Response = yield call(fetch, urlSkillsDelete + '?skillId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_DELETE_SUCCESS, value: true});     
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}});
        } else {            
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: ActionSkillsType.SKILLS_ERROR, value: data});
        }                
                
    } catch (e){       
        yield put<TypeActions>({type: ActionSkillsType.SKILLS_ERROR, value: ''});
    }
}

function* skillsAdd(action:actionSkillsAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
                
        const response:Response = yield call(fetch, urlSkillsCreate, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_ADD_SUCCESS, value: true});   
            yield put<TypeActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: true}});
        } else {           
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: ActionSkillsType.SKILLS_ERROR, value: data});
        }                
                
    } catch (e){        
        yield put<TypeActions>({type: ActionSkillsType.SKILLS_ERROR, value: ''});
    }
}


function* skillsWhatcher() {
    yield takeLatest(ActionSkillsType.SKILLS_LOADING, skillsList);
}

function* skillsEditWatcher() {
    yield takeLatest(ActionSkillsType.SKILLS_EDIT_LOADING, skillsEdit);
}

function* skillsDeleteWatcher() {
    yield takeLatest(ActionSkillsType.SKILLS_DELETE_LOADING, skillsDelete);
}

function* skillsAddWatcher() {
    yield takeLatest(ActionSkillsType.SKILLS_ADD_LOADING, skillsAdd);
}

export default function* rootSkillsSaga() {
    yield all([
        skillsWhatcher(),
        skillsEditWatcher(),
        skillsDeleteWatcher(),
        skillsAddWatcher()
    ])
}