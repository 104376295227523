import { useTranslation } from 'react-i18next';
import './TermsAndConditions.scss';
import logoDark from '../../assest/swapp_logo_dark.svg';


function TermsAndConditions() {    
  const { t } = useTranslation();

    return <div className="job-layout">
              <div className='job-layout__header'>
                <div className='header'>
                  <img className='header__logo' src={logoDark} alt=""></img>
                  <div className='header__cta'>
                    <p className='header__title'>{t('publicJob.headerTitle')}</p>
                    <a className='header__button'  href='/' target="_blank">{t('publicJob.buttonMore')}</a>
                  </div>
                </div>
              </div>
                  
              <div className='job-layout__content'>
                <div className="swissinx-privacy-policy">
                    <h2>General Terms and Conditions SWAPP PRO</h2> 

                    <h3>1. Purpose </h3>
                    <p>SWAP PRO operates a referral system platform which rewards you financially when you successfully refer people in your network for open job roles.</p>
                    <p>The Platform is being run by:</p>
                    <p> Swisslinx AG <br/>
                        Löwenstrasse 29 <br/>
                        8001 Zürich <br/>
                        T: +41 58 268 1000 <br/>
                        E-Mail: <a href='mailto:info@swapp.biz'>info@swapp.biz</a>
                    </p>
                    <p>The following General Terms and Conditions (GTC) govern all relationships between SWAPP PRO, Löwenstrasse 29, 8001 Zürich (hereinafter referred to as “SWAPP”) and it’s Users for the services provided on the Platform SWAPP PRO (hereinafter referred to as the “Platform”). </p>

                    <h3>2.	Access</h3>
                    <p>SWAPP provides you with its services providing you accept the GTC’s as well as the SWAPP privacy policy (LINK). These GTC’s set the technical, practical and legal terms of the relationship and exempt SWAPP respectively Swisslinx from or limit its liability. When you install SWAPP and any update thereto, you confirm that you have full and complete understanding of these GTC’s and the privacy policy, which you unconditionally accept and agree to respect. Users can access, download and save these GTC’s on the SWAPP Website. Within the limits of the laws and regulations, the access to SWAPP may be revoked at Swisslinx’ sole discretion at anytime. Users may delete their SWAPP access at anytime. </p>

                    <h3>3.	Users</h3>
                    <p>
                      The Users of the Platform can be divided into the following categories:
                      <ul>
                        <li>Companies that publish openings on the Platform (hereinafter referred to as the “Publishers”)</li>
                        <li>Individuals, who share openings with individuals within their network (hereinafter referred to as the “Networkers”, together with the “Publishers” hereinafter named as the “Users”)</li>
                        <li>Individuals proposed to Publishers by Networkers on the basis of published openings, with the intention of referring these Individuals to the Publishers (hereinafter referred to as “Candidates”)</li>
                      </ul>
                    </p>

                    <h3>4.	Registration</h3>
                    <p>To make full use of the Services provided by the Platform, the Users have to register on the Platform. The registration is free of charge. SWAPP reserves the right to deny a possible User access to the Platform. </p>
                    <p>The User undertakes to ensure that all information provided during registration is true and correct. In particular, the User undertakes to provide the correct name (company name, if applicable), the correct address (domicile address, if applicable), as well as the actual, valid and requested contact data (telephone number, e-mail address, bank-details etc.).</p>
                    <p>The Registration is only permitted to natural persons and legal entities with the capacity to act. The registration of a legal entity may only be carried out by a person authorized to represent the entity.</p>
                    <p>Any information provided by SWAPP outside the scope of the services, in particular on the SWAPP-Website, is non-binding and is to be understood as a mere invitation to make an offer, unless expressly stated otherwise by SWAPP.</p>
                    <p>Publishers will sign separate terms which will be the basis of a separate agreement between the parties. </p>

                    <h3>5.	Use of the Platform</h3>
                    <p>Once you have created your SWAPP account, visit www.swapp.biz and follow the instructions to start using SWAPP. </p>
                    <p>The Users hereby grant SWAPP a free, non-revocable right of use, unlimited in terms of subject matter, location and time, and subject to sublicensing, to the content posted by the Users on the SWAPP website and/or during the SWAPP registration process. Personal images of the Users are excluded from this. SWAPP may use the content - even in modified form - in particular for marketing or promotional purposes. The right of use shall continue for an indefinite period of time after termination of the relationship.</p>
                    <p>Any utilization of the services and contents provided on the SWAPP website by the Users, which is not covered by the relationship between SWAPP and the Users, requires the prior written consent of SWAPP.</p>
                    <p>The Users are responsible for all contents they upload on the SWAPP Website. In particular the Users are obliged, when using the website as well as in all actions connected with the website to:
                      <ul>
                        <li>Comply with the applicable laws, </li>
                        <li>Comply with the provisions arising of the contractual relationship between SWAPP and the Users,</li>
                        <li>Respect the Rights of third parties (in particular copy rights and other intellectual property rights),</li>
                        <li>Not make any links to external contents,</li>
                        <li>Describe openings posted by the Users correctly, including mandatory fields specified by SWAPP,</li>
                        <li>Keep any information uploaded on the SWAPP Website up to date (in particular information regarding the website)</li>
                        <li>Not take any measures that could damage the interests of SWAPP or harm the reputation of SWAPP,</li>
                        <li>Refrain from any harassing actions such as sending chain letters or spreading indecent content,</li>
                        <li>Use addresses, contact information and e-mail addresses obtained through the use of the SWAPP website only for purposes covered by the contractual relationship with SWAPP.</li>
                      </ul>
                    </p>
                    <p>Users are responsible for archiving any information on an independent storage medium, which the Users require for the purpose of preserving evidence, keeping records or other purposes, and which is accessible on the SWAPP Website or is saved by SWAPP.</p>
                    <p>SWAPP reserves the right to modify, improve or develop SWAPP and the services offered therein at any time as well as these General Terms and Conditions. Such modifications will come into effect as soon as SWAPP, in its sole discretion, activates them or publishes them on SWAPP.</p>
                    <p>Furthermore, with new versions you may not be able to access information on our servers until you downloaded the new version from the relevant store.</p>

                    <h3>6.	Finder’s Fee and Payment Terms</h3>
                    <p>As a result of the successful filling of the opening (as described on the SWAPP website), a finder's fee is due. The obligation to pay the “Finder’s Fee” is not impaired by events that lie outside the sphere of influence of SWAPP, such as for example:
                      <ul>
                        <li>The Candidate, placed via SWAPP, does not appear for work, as set out in the employment contract concluded between the Candidate and the Publisher/Employer, or </li>
                        <li>The contractual agreement of the Candidate, placed via SWAPP, is terminated during the probation period. </li>
                      </ul>
                    </p>
                    <p>Unless otherwise stated on the SWAPP website, we will pay you the Finder’s Fee on a monthly basis. </p>
                    <p>SWAPP reserves the right to void any Finder’s Fees in the event SWAPP decides that you have violated any of these Terms and Conditions or did not act in good faith.</p>

                    <h3>7.	Limitation of Liability and exclusion of Warranty</h3>
                    <p>SWAPP is not liable for direct or indirect material or immaterial damage caused by the access, use or inability to access and use the SWAPP platform. SWAPP assumes no liability for the correctness, exhaustiveness and validity of the contents and the consequences thereof of the SWAPP platform. Nor is any guarantee given that the platform is error-free and free of harmful components (e.g. viruses).</p>
                    <p>SWAPP is not liable for any related warranty claims or other liability claims between the parties arising from the aforementioned independent contractual relationship, which results from the User’s interaction on the Platform. </p>
                    <p>SWAPP is committed to keeping the platform available as continuously as possible. However, the User has no right to expect the platform to be available.</p>
                    <p>Unless otherwise expressly stated by SWAPP in writing, SWAPP does not participate in the content of communications between Users. If users conclude contracts, with each other SWAPP shall in no way be involved in this contractual relationship. In particular, the choice of contractual partner, conclusion, such as the fulfilment of the contract are the sole responsibility of the Users. SWAPP only offers the Users a platform to bring them together with other Users.</p>
                    <p>When using and accessing SWAPP you must comply with all applicable legislation. Only genuine, exact and complete information and data will be transmitted via the Platform, Email or any other media.</p>
                    <p>SWAPP is not responsible and therefore cannot guarantee for any action of a Networker using the information obtained from an opening, or a Candidate. Networkers are obliged to use the automatically generated “Sharing Link” on the Platform. Any breach of this obligation is deemed a severe offence against the interests of SWAPP and Swisslinx reserves the right to claim damages. </p>
                    <p>If a Publisher receives a Candidate for an opening he has shared on the Platform, that for whatever reason did not receive the “Sharing Link” from the Networker (e.g. only a Screenshot of the opening), the Publisher is obliged to inform SWAPP before conducting any further negotiations with the Candidate. If the Publisher continues negotiations with the Candidate without informing SWAPP as set out, he commits a severe contractual breach and therefore Swisslinx reserves the right to claim damages.</p>

                    <h3>8.	Data Protection</h3>
                    <p>The data of the Users, which are necessary for the operation of the Platform, are processed in compliance with all Swiss and European data protection regulations.</p>
                    <p>With regard to all data protection issues, please refer to the separate <a href='/private-policy-swapp' target='blank'>data protection declaration.</a></p>
                    <p>Non-Disclosure and Confidentiality</p>
                    <p>Each party may be given access to Confidential Information from the other party. Confidential Information shall not include information that: 
                      <ol>
                        <li>is or becomes publicly known other than through any act or omission of the receiving party; or</li>
                        <li>was in the other party's lawful possession before the disclosure; or </li>
                        <li>is lawfully disclosed to the receiving party by a third party without restriction on disclosure; or </li>
                        <li>is independently developed by the receiving party, which independent development can be shown by written evidence; or </li>
                        <li>is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</li>
                      </ol>
                    </p>
                    <p>Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available to any third party, or use the other's Confidential Information for any purpose other than the implementation of this agreement.</p>
                    <p>Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed in violation of the terms of this agreement.</p>
                    <p>Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential Information caused by any third party.</p>

                    <h3>9.	Copyright and further property rights</h3>
                    <p>SWAPP holds copyrights to all image and text motifs displayed on the website as well as to the systems program on the Platform. </p>
                    <p>All the contents contained on the Platform (such as information, images, texts, logos, trademarks, designs, user interfaces) are the sole property and copyright of SWAPP, unless stated otherwise. It is strictly prohibited to copy, modify, forward, publish or reproduce these contents or parts thereof. The same applies to the graphic representations on the Platform, such as the programming, the structure and the structural and conceptual design of the Platform, as well as the related individual functions themselves, which are protected by copyright by SWAPP and may not be copied by a User or any other third party.</p>
                    <p>In the event of an unauthorized infringement of property rights, copyrights and trademark rights, SWAPP reserves the right to claim damages, loss of profits and lost profits.</p>

                    <h3>10.	Further Rights of SWAPP</h3>
                    <p>SWAPP is entitled - in addition to or in accordance with the aforementioned rights - to take the following further actions:
                      <ul>
                        <li>Right to change, suspend or terminate the Services</li>
                        <li>Right to block a User account </li>
                        <li>In case of change of Control: Right to give access to the data provided that the use of the data for the same purpose or fulfilment is guaranteed</li>
                        <li>Right to change the Platform or the services at anytime, without prior notice</li>
                      </ul>
                    </p>

                    <h3>11.	Applicable Law / Jurisdiction</h3>
                    <p>These GTC’s are governed by Swiss Law. The place of jurisdictions for all legal disputes arising from these GTCs is Zurich.</p>
                    <p>Should one or more clauses of these GTCs be invalid in whole or in part, this shall not affect the validity of the remaining contractual provisions. In such a case, the invalid or void provision shall be replaced by a new, legally permissible provision which comes as close as possible in its economic effect to the invalid or void provision. If an invalid contractual provision is not subsequently corrected, it shall be replaced mutatis mutandis by a relevant statutory provision</p>
                    <br/><br/>
                    <p>Updated November 2022</p>
                  </div>
                  <div className='job-layout__footer'>
                    <div className='header'>
                      <img className='header__logo' src={logoDark} alt=""></img>
                      <div className='header__cta'>
                        <p className='header__title'>{t('publicJob.headerTitle')}</p>
                        <a className='header__button' href='/' target="_blank">{t('publicJob.buttonMore')}</a>
                      </div>
                    </div>
                  </div> 
                </div>
            </div>
  }

export default TermsAndConditions;

