
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';
import { validateNumber, validateSalaryMax } from '../Form/validators'; import Form from '../Form/Form/Form';
import TypeState from '../../models/initialState/typeState';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypeActions } from '../../models/actions/typeActions';
import TypeFeeState from '../../models/initialState/typeFeeState';
import { ActionFeeType, TypeFeeActions } from '../../models/actions/typeFeeActions';
import { getLabelFormatted, listCurrency } from '../../utils/utils';

import MenuItem from "@mui/material/MenuItem";

//import { SelectChangeEvent } from "@mui/material/Select";

interface Props {
    back(): void,
    update(): void,
    add: boolean
}

function FeeEdit(props: Props) {
    const { t } = useTranslation();
    const state: TypeFeeState = useSelector((state: TypeState) => state.feeReducer);
    const dispatch = useDispatch();

    const onSubmit = () => {
        props.update();
    }

    const onChangeSelect = (event: ChangeEvent<HTMLInputElement>, name: string) => {
        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: name, value: event.target.value, error: { required: false, format: false } } })
    }

    /* const handleChange = (event: SelectChangeEvent<number[]>, name: string) => {
      const {
        target: { value },
      } = event;
     
      // On autofill we get a the stringified value.
      let sendValue: number[];
      typeof value === 'string' ? sendValue = [parseInt(value)] : sendValue = value;     
  
      dispatch<TypeActions>({type: ActionFeeType.FEE_FORM_SEND_VALUES, value: {name: name, value: sendValue, error: {required: false, format: false}}});
    };   */

    return <div className='swissinx-container'>
        <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
            {props.add ? t('admin.fee.title_page_new') : t('admin.fee.title_page_edit')}
        </h2>
        {<Form contex={state.feeForm} errorRequerired={(key) => dispatch<TypeActions>({ type: ActionFeeType.FEE_ERROR_REQUIRED, value: key })} onSubmit={onSubmit}
            fields={
                <div className="swissinx-container-fields-form">
                    <div className="swissinx-container-fields">
                        <div className='swissinx-field-container'>
                            <div className="swissinx-field field-lowFee">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_name')}
                                    name='description'
                                    required={state.feeForm.description.rules.required}
                                    requiredError={t('admin.jobs.lowFee_error_required')}
                                    value={state.feeForm.description.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.description.error.required || state.feeForm.description.error.format}
                                    onChange={({ name, value, error }) => dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: name as string, value, error } })}
                                />
                            </div>
                        </div>
                        {/* <div className='swissinx-field-container'>
                            <div className="swissinx-field field-language">
                                <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="languages">{t('admin.jobs.label_languages')}</InputLabel>
                                <Select
                                    labelId="languages"
                                    id="languageId"
                                    label={t('admin.jobs.label_languages')}
                                    multiple
                                    value={state.feeForm.languageIds.value as number[]}
                                    onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'languageIds')}                    
                                    input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_languages')} />}
                                    error={false}
                                    renderValue={(selected: number[]) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value: number) => (
                                            <Chip key={value} label={getLabelLanguage(value, stateLanguages.data, stateLanguages.loading, stateLanguages.error)} />
                                        ))}
                                        </Box>
                                    )}          
                                    >
                                    {stateLanguages.data.map((language) => (
                                        <MenuItem
                                        key={language.languageId}
                                        value={language.languageId}                                              
                                        >
                                        {language.name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    </FormControl>
                            </div>
                            <div className="swissinx-field field-skills">
                                <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="skills">{t('admin.jobs.label_skills')}</InputLabel>
                                <Select
                                    labelId="skills"
                                    id="skillId"
                                    label={t('admin.jobs.label_skills')}
                                    multiple
                                    value={state.feeForm.skillIds.value as number[]}
                                    onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'skillIds')}                    
                                    input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_skills')} />}
                                    error={false}
                                    renderValue={(selected: number[]) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value: number) => (
                                            <Chip key={value} label={getLabelSkill(value, stateSkills.data, stateSkills.loading, stateSkills.error)} />
                                        ))}
                                        </Box>
                                    )}                   
                                    >
                                        {stateSkills.data.map((skill) => (
                                            <MenuItem
                                            key={skill.skillId}
                                            value={skill.skillId}
                                                                
                                            >
                                            {skill.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                </FormControl>
                            </div>   
                    
                            <div className="swissinx-field field-market">
                                <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="marketId">{t('admin.jobs.label_markets')}</InputLabel>
                                <Select
                                    labelId="markets"
                                    id="markets"
                                    label={t('admin.jobs.label_markets')}
                                    multiple
                                    value={state.feeForm.marketIds.value as number[]}
                                    onChange={(e)=>handleChange(e as SelectChangeEvent<number[]>, 'marketIds')}                    
                                    input={<OutlinedInput id="select-multiple-chip" label={t('admin.jobs.label_markets')} />}
                                    error={false}
                                    renderValue={(selected:number[]) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value: number) => (
                                            <Chip key={value} label={getLabelMarket(value, stateMarkets.data, stateMarkets.loading, stateMarkets.error)} />
                                        ))}
                                        </Box>
                                    )}                   
                                    >
                                    {stateMarkets.data.map((market) => (
                                        <MenuItem
                                        key={market.marketId}
                                        value={market.marketId}                                              
                                        >
                                        {market.name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    </FormControl>
                            </div> 
                        </div>        */}
                        <div className='swissinx-field-container'>
                            <div className="swissinx-field field-feeCurrency">
                                <TextField
                                    id="feeCurrency"
                                    select={true}
                                    label={getLabelFormatted(t('admin.jobs.label_feeCurrency'), state.feeForm.feeCurrency.rules.required)}
                                    value={state.feeForm.feeCurrency.value as string}
                                    error={state.feeForm.feeCurrency.error.required}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSelect(event, 'feeCurrency')}
                                    helperText={state.feeForm.feeCurrency.error.required && t('admin.jobs.feeCurrency_error_required')}
                                >
                                    {listCurrency().map(currency => <MenuItem key={currency} value={currency}>{currency}</MenuItem>)}
                                </TextField>
                            </div>
                            <div className="swissinx-field field-push-veryHighFee">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_veryHighFee')}
                                    name='veryHighFee'
                                    required={state.feeForm.veryHighFee.rules.required}
                                    requiredError={t('admin.jobs.veryHighFee_error_required')}
                                    value={state.feeForm.veryHighFee.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.veryHighFee.error.required || state.feeForm.veryHighFee.error.format}

                                    validators={[
                                        { validator: validateNumber, messageError: t('admin.jobs.error_format') },
                                        {
                                            validator: (value) =>
                                              validateSalaryMax(
                                                value,
                                                state.feeForm.highFee.value as string
                                              ),
                                            messageError: t("admin.jobs.veryHighFee_error_value"),
                                          },
                                    ]}
                                    onChange={({ name, value, error }) => {
                                        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name, value, error } });
                                        const highFee = state.feeForm.highFee.value as string;
                                        if (parseFloat(value) > parseFloat(highFee)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'highFee', value: state.feeForm.highFee.value as string, error: {
                                                required: state.feeForm.highFee.error.required,
                                                format: false
                                            } } })
                                        }
                                    }}
                                />
                            </div>
                            <div className="swissinx-field field-push-highFee">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_highFee')}
                                    name='highFee'
                                    required={state.feeForm.highFee.rules.required}
                                    requiredError={t('admin.jobs.highFee_error_required')}
                                    value={state.feeForm.highFee.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.highFee.error.required || state.feeForm.highFee.error.format}
                                    validators={[
                                        { validator: validateNumber, messageError: t('admin.jobs.error_format') },
                                        {
                                            validator: (value) =>
                                              validateSalaryMax(
                                                state.feeForm.veryHighFee.value as string,
                                                value
                                              ),
                                            messageError: t("admin.jobs.highFee_error_value"),
                                          },
                                          {
                                            validator: (value) =>
                                              validateSalaryMax(                                                
                                                value,
                                                state.feeForm.lowFee.value as string,
                                              ),
                                            messageError: t("admin.jobs.highFee_error_value"),
                                          },
                                    ]}
                                    onChange={({ name, value, error }) => {
                                        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name, value, error } });
                                        const lowFee = state.feeForm.lowFee.value as string;
                                        if (parseFloat(value) > parseFloat(lowFee)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'lowFee', value: state.feeForm.lowFee.value as string, error: {
                                                required: state.feeForm.lowFee.error.required,
                                                format: false
                                            } } })
                                        }
                                        const veryHighFee = state.feeForm.veryHighFee.value as string;
                                        if (parseFloat(value) < parseFloat(veryHighFee)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'veryHighFee', value: state.feeForm.veryHighFee.value as string, error: {
                                                required: state.feeForm.veryHighFee.error.required,
                                                format: false
                                            } } })
                                        }

                                    }}
                                />
                            </div>
                            <div className="swissinx-field field-push-lowFee">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_lowFee')}
                                    name='lowFee'
                                    required={state.feeForm.lowFee.rules.required}
                                    requiredError={t('admin.jobs.lowFee_error_required')}
                                    value={state.feeForm.lowFee.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.lowFee.error.required || state.feeForm.lowFee.error.format}
                                    validators={[
                                        { validator: validateNumber, messageError: t('admin.jobs.error_format') },
                                        {
                                            validator: (value) =>
                                              validateSalaryMax(
                                                state.feeForm.highFee.value as string,
                                                value
                                              ),
                                            messageError: t("admin.jobs.lowFee_error_value"),
                                          },
                                    ]}
                                    onChange={({ name, value, error }) => {
                                        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: name as string, value, error } });
                                        const highFee = state.feeForm.highFee.value as string;
                                        if (parseFloat(value) < parseFloat(highFee)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'highFee', value: state.feeForm.highFee.value as string, error: {
                                                required: state.feeForm.highFee.error.required,
                                                format: false
                                            } } })
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='swissinx-field-container'>
                            <div className="swissinx-field field-push-timeToEmbassadors">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_timeToEmbassadors')}
                                    name='timeToEmbassadors'
                                    required={state.feeForm.timeToEmbassadors.rules.required}
                                    requiredError={t('admin.jobs.timeToEmbassadors_error_required')}
                                    value={state.feeForm.timeToEmbassadors.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.timeToEmbassadors.error.required || state.feeForm.timeToEmbassadors.error.format}
                                    validators={[
                                        {
                                          validator: validateNumber,
                                          messageError: t("admin.jobs.error_format"),
                                        },
                                        {
                                          validator: (value) =>
                                            validateSalaryMax(
                                                state.feeForm.timeToNetwork.value as string,
                                              value,
                                              
                                            ),
                                          messageError: t("admin.jobs.timeToEmbassadors_error_value"),
                                        },
                                      ]}
                                    onChange={({ name, value, error }) => {
                                        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name, value, error } });
                                        const timeToNetwork = state.feeForm.timeToNetwork.value as string;
                                        if (parseInt(value) < parseInt(timeToNetwork)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'timeToNetwork', value: state.feeForm.timeToNetwork.value as string, 
                                            error: {required :state.feeForm.timeToNetwork.error.required, format: false }}});
                                        }                                        
                                    }
                                    }
                                />
                            </div>
                            <div className="swissinx-field field-push-timeToNetwork">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.jobs.label_timeToNetwork')}
                                    name='timeToNetwork'
                                    required={state.feeForm.timeToNetwork.rules.required}
                                    requiredError={t('admin.jobs.timeToNetwork_error_required')}
                                    value={state.feeForm.timeToNetwork.value as string}
                                    fullWidth={true}
                                    error={state.feeForm.timeToNetwork.error.required || state.feeForm.timeToNetwork.error.format}
                                    validators={[
                                        { validator: validateNumber, messageError: t('admin.jobs.error_format') },
                                        {
                                            validator: (value) =>
                                              validateSalaryMax( 
                                                value,
                                                state.feeForm.timeToEmbassadors.value as string,
                                              ),
                                            messageError: t("admin.jobs.timeToNetwork_error_value"),
                                          },
                                    ]}
                                    onChange={({ name, value, error }) => {
                                        dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name, value, error } });
                                        const timeToEmbassadors = state.feeForm.timeToEmbassadors.value as string;
                                        if (parseInt(value) >= parseInt(timeToEmbassadors)) {
                                            dispatch<TypeFeeActions>({ type: ActionFeeType.FEE_FORM_SEND_VALUES, value: { name: 'timeToEmbassadors', value: state.feeForm.timeToEmbassadors.value as string, 
                                            error: {required :state.feeForm.timeToEmbassadors.error.required, format: false }}});
                                        } 
                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='swissinx-container-buttons'>
                        <button type='button' className="swissinx-button-back" onClick={props.back}>{t('admin.countries.cancelButtonText')}</button>
                        <button type='submit' className="swissinx-button-submit">{props.add ? t('admin.countries.addButtonText') : t('admin.countries.editButtonText')}</button>
                    </div>
                </div>
            }>
        </Form>
        }

    </div>;
}

export default FeeEdit;