import { useTranslation } from 'react-i18next';
import { useSelector} from 'react-redux';
import TypeState from '../../models/initialState/typeState';
import TypeCompaniesState from '../../models/initialState/typeCompaniesState';
//import './Networkers.scss';
import Table from '../table/Table';
import reject from '@material-ui/icons/ThumbDownAlt';
import like from '@material-ui/icons/ThumbUpAlt';
import TypeNetworkersRequestListState from '../../models/initialState/typeNetworkersRequestListState';
import NetworkerRequestListDto from '../../models/dto/NetworkerRequestListDto';

interface Props {
    like(event: any, index:NetworkerRequestListDto): void,
    reject(event: any, index:NetworkerRequestListDto): void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
  }

function NetworkersRequestList(props:Props) {
  const { t } = useTranslation();
  const state:TypeNetworkersRequestListState = useSelector((state:TypeState) => state.networkersRequestListReducer);
  const stateCompanies:TypeCompaniesState = useSelector((state:TypeState) => state.companiesReducer);

  const headers = [
    {title: t('admin.networkersRequestList.label_name'), field: 'name', defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: ''},
    {title: t('admin.networkersRequestList.label_surname'), field: 'surname', defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: ''},
    {title: t('admin.networkersRequestList.label_company'), field: 'companyId', render: (rowData:NetworkerRequestListDto) => {
      const company = stateCompanies.data.find(company => company.companyId === rowData.companyId);
                    return company !== undefined ? company.name : '';
    }, defaultSort: state.filters.orderBy === 2 ? state.filters.orderDirection: ''},
    {title: t('admin.networkersRequestList.label_date'), field: 'date', render: (rowData:NetworkerRequestListDto) => new Date(rowData.date).toLocaleDateString(),
      
    defaultSort: state.filters.orderBy === 3 ? state.filters.orderDirection: ''},
    {title: t('admin.networkersRequestList.label_credit'), field: 'credit', defaultSort: state.filters.orderBy === 4 ? state.filters.orderDirection: ''},
    {title: t('admin.networkersRequestList.label_status'), field: 'status.description', defaultSort: state.filters.orderBy === 5 ? state.filters.orderDirection: ''}    
  ];
  
  const actions = [
    {
      icon: like,
      tooltip: t('table.tooltip.actionLike'),
      disabled: false,
      onClick: props.like
    },
    {
      icon: reject,
      tooltip: t('table.tooltip.actionReject'),
      disabled: false,
      onClick: props.reject
    },
  ]
  
      
return <div className='swissinx-container'>   
            <h2>{t('admin.networkersRequestList.listTitle')}</h2>
            <Table<NetworkerRequestListDto> 
                data={state.data} 
                headers={headers} 
                actions={actions}
                initialPage={state.initialPage}
                serchText={state.searchText}
                pageSize={state.pageSize}
                changePage={props.changePage}
                changeSearchText={props.changeSearchText}
                changeRowsPerPage={props.changeRowsPerPage}               
            />
        </div>;
  
 
}

export default NetworkersRequestList;