import NetworkerRequestListDto from '../dto/NetworkerRequestListDto';

export enum ActionNetworkersRequestListType {
    
    NETWORKERS_REQUEST_LIST_LOADING = 'NETWORKERS_REQUEST_LIST_LOADING',
    NETWORKERS_REQUEST_LIST_SUCCESS = 'NETWORKERS_REQUEST_LIST_SUCCESS',
    NETWORKERS_REQUEST_LIST_ERROR = 'NETWORKERS_REQUEST_LIST_ERROR',
    NETWORKERS_REQUEST_LIST_CHANGE_STATUS = 'NETWORKERS_REQUEST_LIST_CHANGE_STATUS',
    NETWORKERS_REQUEST_LIST_CHANGE_SEARCH_TABLE = 'NETWORKERS_REQUEST_LIST_CHANGE_SEARCH_TABLE',
    NETWORKERS_REQUEST_LIST_CHANGE_PAGE_TABLE = 'NETWORKERS_REQUEST_LIST_CHANGE_PAGE_TABLE',
    NETWORKERS_REQUEST_LIST_CHANGE_PAGE_SIZE = 'NETWORKERS_REQUEST_LIST_CHANGE_PAGE_SIZE',
    NETWORKERS_REQUEST_LIST_CHANGE_FILTER = 'NETWORKERS_REQUEST_LIST_CHANGE_FILTER'  
}

export interface actionNetworkersRequestListLoading {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_LOADING,
    value: {reset: boolean}    
}
export interface actionNetworkersRequestListSuccess {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_SUCCESS,
    value: {data: NetworkerRequestListDto[], reset: boolean}    
}
export interface actionNetworkersRequestListError {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_ERROR,
    value: string 
}

export interface actionNetworkersRequestListChangeStatus {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_STATUS,
    value: {
        response: boolean,
        requestId: number,
        companyId: number
    }
}

export interface actionNetworkersRequestListChangeSerachTable {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_SEARCH_TABLE
    value: string
}

export interface actionNetworkersRequestListChangePageSizeTable {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionNetworkersRequestListChangePageTable {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionNetworkersRequestListChangeFilterTable {
    type: ActionNetworkersRequestListType.NETWORKERS_REQUEST_LIST_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeNetworkersRequestListActions =  actionNetworkersRequestListLoading | actionNetworkersRequestListSuccess | actionNetworkersRequestListError |
                                                actionNetworkersRequestListChangeStatus |
                                                actionNetworkersRequestListChangeSerachTable | actionNetworkersRequestListChangePageSizeTable | actionNetworkersRequestListChangePageTable |
                                                actionNetworkersRequestListChangeFilterTable;
                                           