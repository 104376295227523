import AmbassadorDto from "../dto/AmbassadorDto";

export enum ActionAmbasadorsType {    
    AMBASADORS_LOADING = 'AMBASADORS_LOADING',
    AMBASADORS_SUCCESS = 'AMBASADORS_SUCCESS',
    AMBASADORS_ERROR = 'AMBASADORS_ERROR',
    LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING = 'LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING',
    LIST_AMBASSADORS_AUTOMATIC_MATCH_SUCCESS = 'LIST_AMBASSADORS_AUTOMATIC_MATCH_SUCCESS',
    LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR = 'LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR',
    FILTER_PUSH_AMBASSADORS_VALUES = 'FILTER_PUSH_AMBASSADORS_VALUES',
    FILTER_PUSH_AMBASSADORS_VALUES_SEARCH = 'FILTER_PUSH_AMBASSADORS_VALUES_SEARCH',    
    FILTER_PUSH_AMBASSADORS_RESET = 'FILTER_PUSH_AMBASSADORS_RESET',
    FILTER_PUSH_AMBASSADORS_LOADING = 'FILTER_PUSH_AMBASSADORS_LOADING',
    FILTER_PUSH_AMBASSADORS_SUCCESS = 'FILTER_PUSH_AMBASSADORS_SUCCESS',
    FILTER_PUSH_AMBASSADORS_ERROR = 'FILTER_PUSH_AMBASSADORS_ERROR',
    LIST_AMBASSADORS_FILTERED = 'LIST_AMBASSADORS_FILTERED',
    LIST_AMBASSADORS_FILTERED_ADD = 'LIST_AMBASSADORS_FILTERED_ADD'
}

export interface actionAmbasadorsLoading {
    type: ActionAmbasadorsType.AMBASADORS_LOADING     
}

export interface actionAmbasadorsSuccess {
    type: ActionAmbasadorsType.AMBASADORS_SUCCESS,
    value: AmbassadorDto[]    
}

export interface actionAmbasadorsError {
    type: ActionAmbasadorsType.AMBASADORS_ERROR   
}

export interface actionListAmbassadorsAutomaticMatchLoading {
    type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING,
    value: {        
        companyId: number,
        jobId: number,
        skillIds: number[],
        marketIds: number[],
        languageIds: number[],
        includeNetworkers:boolean
    }
}

export interface actionListAmbassadorsAutomaticMatchSuccess {
    type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_SUCCESS,
    value: AmbassadorDto[]
}   

export interface actionListAmbassadorsAutomaticMatchError {
    type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR   
}

export interface actionFilterPushAmbassadorsValues {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES,
    value: {
        name: string,
        value: number[] 
    }
}

export interface actionFilterPushAmbassadorsValuesSearch {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_VALUES_SEARCH,
    value: {
        name: string,
        value: string
    }
}

export interface actionListAmbassadorsFilterLoading {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_LOADING,
    value: {
        search: string,
        marketIds: number[],
        skillIds: number[],
        languageIds: number[],
        countryIds: number[],
        companyId: number,
        jobId: number,
        includeNetworkers:boolean
    }
}

export interface actionListAmbassadorsFilterSuccess {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_SUCCESS,
    value: AmbassadorDto[]
} 

export interface actionListAmbassadorsFilterError {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_ERROR   
}

export interface actionListAmbassadorsFiltered {
    type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED,
    value: AmbassadorDto[]
}

export interface actionListAmbassadorsFilteredAdd {
    type: ActionAmbasadorsType.LIST_AMBASSADORS_FILTERED_ADD,
    value: AmbassadorDto[]
}

export interface actionFilterPushAmbassadorsReset {
    type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_RESET    
}

export type TypeAmbasadorsActions =  actionAmbasadorsLoading | actionAmbasadorsSuccess | actionAmbasadorsError |
                                     actionListAmbassadorsAutomaticMatchLoading | actionListAmbassadorsAutomaticMatchSuccess | actionListAmbassadorsAutomaticMatchError |
                                     actionListAmbassadorsFilterLoading | actionListAmbassadorsFilterSuccess | actionListAmbassadorsFilterError |
                                     actionFilterPushAmbassadorsValues | actionListAmbassadorsFiltered | actionListAmbassadorsFilteredAdd | actionFilterPushAmbassadorsReset |
                                     actionFilterPushAmbassadorsValuesSearch;
                                