import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import {actionCompanyGroupsDeleteLoading} from '../models/actions/typeCompanyGroupActions';
import CompanyDto from '../models/dto/CompanyDto';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import { actionCompanyAddLoading, actionCompanyEditLoading, actionCompanyLoading, ActionCompanyType, actionCompanyUploadPictureLoading } from '../models/actions/typeCompanyActions';
import TypeState from '../models/initialState/typeState';
import TypeMainState from '../models/initialState/typeMainState';

const baseUrlApi:string = getBaseUrlApi();
const urlCompanyList = baseUrlApi + '/api/Company/getCompanies';
const urlCompanyEdit = baseUrlApi + '/api/Company/updateCompany';
const urlCompanyDelete = baseUrlApi + '/api/Company/DeleteCompany';
const urlCompanyCreate = baseUrlApi + '/api/Company/createCompany';
const urlCompanyUploadPicture = baseUrlApi + '/api/Company/uploadCompanyPicture'


function* checkError(code:number) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_ERROR});
    }
}

function* companyList(action: actionCompanyLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlCompanyList, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:CompanyDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_SUCCESS, value: {data: data, reset: action.value.reset}});
        } else {           
            yield call(checkError, response.status);           
        }      
    } catch {        
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_ERROR});
    }
}

function* companyEdit(action:actionCompanyEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);     
        const response:Response = yield call(fetch, urlCompanyEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_EDIT_SUCCESS, value: true});          
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
        } else {           
            yield call(checkError, response.status);  
        }   
    } catch (e){        
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_ERROR});
    }
}

function* companyDelete(action:actionCompanyGroupsDeleteLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);       
        
        const response:Response = yield call(fetch, urlCompanyDelete + '?companyId=' + action.value.toString(), getFetchParams('DELETE', null, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_DELETE_SUCCESS, value: true});          
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
        } else {            
            yield call(checkError, response.status);   
        }     
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_ERROR});
    }
}

function* companyAdd(action:actionCompanyAddLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);    
        
        const response:Response = yield call(fetch, urlCompanyCreate, getFetchParams('POST', action.value.company, state.token));
        if (response.ok) {
            const data:CompanyDto = yield call([response, 'json']);  
            if (action.value.image !== null) {                
                if (data.companyId) {
                    const dataImage = {...action.value.image, companyId: data.companyId}                   
                    yield put<TypeActions>({type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_LOADING, value: dataImage});
                    yield put<TypeActions>({type: ActionCompanyType.COMPANY_SEND_IMAGE_VALUES, value: null});
                }                
            }
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_ADD_SUCCESS, value: true});          
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
        } else {           
            yield call(checkError, response.status);   
        }      
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_ERROR});
    }
}

function* companyUploadPicture(action:actionCompanyUploadPictureLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);    
        
        const response:Response = yield call(fetch, urlCompanyUploadPicture, getFetchParams('POST', action.value, state.token));
        if (response.ok) {
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_SUCCESS, value: true});
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});         
        } else {              
            yield put<TypeActions>({type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_ERROR});  
        }      
    } catch (e){       
        yield put<TypeActions>({type: ActionCompanyType.COMPANY_UPLOAD_PICTURE_ERROR});
    }
}


function* comapanyWhatcher() {
    yield takeLatest(ActionCompanyType.COMPANY_LOADING, companyList);
}

function* companyEditWatcher() {
    yield takeLatest(ActionCompanyType.COMPANY_EDIT_LOADING, companyEdit);
}

function* companyDeleteWatcher() {
    yield takeLatest(ActionCompanyType.COMPANY_DELETE_LOADING, companyDelete);
}

function* companyAddWatcher() {
    yield takeLatest(ActionCompanyType.COMPANY_ADD_LOADING, companyAdd);
}

function* companyUploadPictureWatcher() {
    yield takeLatest(ActionCompanyType.COMPANY_UPLOAD_PICTURE_LOADING, companyUploadPicture);
}


export default function* rootCompanySaga() {
    yield all([
        comapanyWhatcher(),
        companyEditWatcher(),
        companyDeleteWatcher(),
        companyAddWatcher(),
        companyUploadPictureWatcher()
    ])
}