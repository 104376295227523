import './faq.scss';
import logo from '../../assest/swapp_logo.svg';

function FAQ(){
    return <div className="swissinx-faq">

        <div className="faq-header">
            <div>
                <img src={logo} alt="" />
                <h2>How can I help you?</h2>
            </div>
        </div>

        <div className='faq'>
            <input id='faq-a' type='checkbox'></input>
            <label htmlFor='faq-a'>
                <p className="faq-heading">How does Swapp work?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    Swapp is a referral system platform which rewards you financially when you successfully refer people in your network for open job roles.
                    <br></br><br></br>
                    With the Swapp app you can always see what jobs are available now and which ones will be available soon.
                    <br></br><br></br>
                    The difference with other referral platforms is that we store and reward each person in the referral chain. 
                </p>
            </label>
            <input id='faq-b' type='checkbox'></input>
            <label htmlFor='faq-b'>
                <p className="faq-heading">How much does Swapp cost?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    Zero. Swapp is 100% free for our app users.
                    <br></br><br></br>
                    Swapp Pro is available in the app stores (apple and android) for everyone free of charge, no hidden payments required. After user registration you start getting notified when a job requirement is available. It’s also free to share jobs or apply for a job. The goal is for you to earn money while helping us to place the best professionals.
                </p>
            </label>
            <input id='faq-c' type='checkbox'></input>
            <label htmlFor='faq-c'>
                <p className="faq-heading">How do I register?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    Open Swapp Pro app and click “Sign up here” at the bottom of the screen. Follow the instructions to to create your profile (name, email and telephone number) and your network details (languages, skills and markets).
                </p>
            </label>
            <input id='faq-d' type='checkbox'></input>
            <label htmlFor='faq-d'>
                <p className="faq-heading">Why do I need to register my network details?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    To ensure that you are only alerted to relevant job openings we need you to complete your network description. The more accurate the network description, the more relevant the job alerts will be that you will receive from our Talent Acquisition team.
                </p>
            </label>
            <input id='faq-e' type='checkbox'></input>
            <label htmlFor='faq-e'>
                <p className="faq-heading">What happens when I share a job?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    When a link is created, will keep its source always intact so Swapp can keep track of its journey, this means that when you create a link to share with your friends, they can use the same link to create another link, and then another link... In case of a successful placement, Swapp makes sure all users involved get their part of the referral fee.
                </p>
            </label>
            <input id='faq-f' type='checkbox'></input>
            <label htmlFor='faq-f'>
                <p className="faq-heading">When do I get paid?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You will be notified by Swapp and paid when any of your referral paths ends up in a successful placement. To receive the payment, please make sure that you add your “Payment method” in your Swapp profile.
                </p>
            </label>
            <input id='faq-g' type='checkbox'></input>
            <label htmlFor='faq-g'>
                <p className="faq-heading">How much do I get paid?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You will get a proportion of the referral fee that you see stated in the job description. The more users get involved in the chain to your successful placement, the smaller your fee will be since you’ll be sharing it with all of your chain.
                </p>
            </label>
            <input id='faq-h' type='checkbox'></input>
            <label htmlFor='faq-h'>
                <p className="faq-heading">How do I get targeted jobs?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You will receive targeted jobs when a member of the talent acquisition team chooses you as a prime networker to receive their roles or a friend/contact on Swapp forwards you a link to a job in the app.
                </p>
            </label>
            <input id='faq-i' type='checkbox'></input>
            <label htmlFor='faq-i'>
                <p className="faq-heading">What’s an insider?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    An insider is a trusted person in Swapp. We reserve the right to choose our own insiders. The insiders will be the first users to receive an alert when relevant jobs to their network become available. In  Swapp we want to reward committed users with the insider status.
                </p>
            </label>
            <input id='faq-j' type='checkbox'></input>
            <label htmlFor='faq-j'>
                <p className="faq-heading">How can I become an insider?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You can apply to become an insider for a specific company from your profile. Use the 3 dots icon on the top right corner to access the “Insider Details”. From there you’ll see the companies where you are already an insider and a form to apply for other companies.
                    <br></br><br></br>
                    Your request will be evaluated by the talent acquisition team of the company you applied, where they will choose to grant you the insider badge based on their own criteria. The criteria can be from your personal relationship with the company to the performance of your shares in Swapp. 
                </p>
            </label>
            <input id='faq-k' type='checkbox'></input>
            <label htmlFor='faq-k'>
                <p className="faq-heading">I see some jobs that I can’t open</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    All jobs are publicly displayed in the Swapp Pro app, but they are made available to people to start the referral chain in phases.
                    <br></br><br></br>
                    In the 1st phase, jobs are made available to a few select users, such as insiders.
                    <br></br><br></br>
                    In the 2nd phase the job is made available for all insiders and eventually it will be available for all Swapp Pro users. (Think we should define the time allowance before general release eg. Within 24 hrs etc).
                    <br></br><br></br>
                    The duration of each phase is unique to each job. So you can expect different jobs to have different phase duration.
                    <br></br><br></br>
                    If a job of your interest is not available to you and you are still not an insider for the company, you can request to become an insider and increase your chances to have an earlier access to the jobs.
                    <br></br><br></br>
                    See related questions “What’s an insider?” and “How can I become an insider?”
                </p>
            </label>
            <input id='faq-l' type='checkbox'></input>
            <label htmlFor='faq-l'>
                <p className="faq-heading">How many people can I share a job with?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You can share a link with as many of your contacts as you wish, but please do not send to public platforms like social media or job sites. 
                    <br></br><br></br>
                    We collect statistics about the links’ activity, any link will be automatically deactivated if too many people register with it.
                    <br></br><br></br>
                    On the other hand you can create as multiple shares (links) for a job.
                </p>
            </label>
            <input id='faq-m' type='checkbox'></input>
            <label htmlFor='faq-m'>
                <p className="faq-heading">How can I follow up my recommendations? </p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    After you have shared a job, you can follow up on the link’s activity under the statistics of the job.
                    <br></br><br></br>
                    There you’ll see how many times your link is seen and shared. You can also check how many people uses your link or a link produced from your link to apply. If eventually one of these candidates gets the job, you will see it also in the stats.
                    <br></br><br></br>
                    Use the filter in the homepage to show all the jobs that you shared.
                </p>
            </label>
            <input id='faq-n' type='checkbox'></input>
            <label htmlFor='faq-n'>
                <p className="faq-heading">How do I know if I’m entitled to a reward?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    We’ll notify you via email when any of your referral paths ends up in a placement. You will also be able to access this information in the app.
                </p>
            </label>
            <input id='faq-' type='checkbox'></input>
            <label htmlFor='faq-'>
                <p className="faq-heading">Can I also apply for the role? Would the fee still be applicable?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You can apply for a role and at the same time forward it to someone else. Candidates who apply directly for the role do not qualify to take part in the referral scheme.
                    <br></br><br></br>
                    Which mean that either you get the job or you get the referral fee.
                </p>
            </label>
            <input id='faq-o' type='checkbox'></input>
            <label htmlFor='faq-o'>
                <p className="faq-heading">My links always open in the browser, how can I make them open in the App?</p>
                <div className='faq-arrow'></div>
                <p className="faq-text">
                    You need to configure your mobile device to open the links from Swapp with the SWAPP PRO app.
                </p>
            </label>
        </div>

        <div className="faq-footer">
            <div>
                <img src={logo} alt="" />
            </div>
        </div>

    </div>
}

export default FAQ;
