import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { ActionCompanyGroupsType } from '../../models/actions/typeCompanyGroupActions';
import { useSelector, useDispatch } from 'react-redux';
import CompanyGroupsDto from '../../models/dto/CompanyGroupsDto'; 
import CompanyGroupsList from './CompanyGroupsList';
import CompanyGroupsEdit from './CompanyGroupsEdit';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeCompanyGroupsState from '../../models/initialState/typeCompayGroupsState';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
import { ActionCompanyType } from '../../models/actions/typeCompanyActions';

/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function CompanyGroups(props: Props) {
  const [editCompanyGroup, setEditCompanyGroup] = useState(false);
  const [deleteCompanyGroup, setDeleteCompanyGroup] = useState(false);
  const [addCompanyGroup, setAddCompanyGroup] = useState(false);  
  const [companyGroupEdit, setCompanyGroupEdit] = useState({companyGroupId: 0, name: ''});
  const [companyGroupRemove, setCompanyGroupRemove] = useState({companyGroupId: 0, name: ''});
  
  const { t } = useTranslation();
  const state:TypeCompanyGroupsState = useSelector((state:TypeState) => state.companyGroupsReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}});
      setEditCompanyGroup(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyType.COMPANY_LOADING, value: {reset: false}}); 
    }          
  }, []);  

  const edit = (event:SyntheticEvent, index:CompanyGroupsDto) => {
    setCompanyGroupEdit(index);
    setEditCompanyGroup(true);
    setAddCompanyGroup(false);
  }

  const removeConfirmation = (event:SyntheticEvent, companyGroup:CompanyGroupsDto) => {
    setCompanyGroupRemove(companyGroup);
    setDeleteCompanyGroup(true);  
  }

  const newRegister = () => {
    setCompanyGroupEdit({companyGroupId: 0, name: ''});
    setEditCompanyGroup(true);
    setAddCompanyGroup(true);
  }  

  const back = () => {
    setEditCompanyGroup(false);
  }

  const update = (companyGroup: CompanyGroupsDto) => {
    setEditCompanyGroup(false);
    if (addCompanyGroup) {
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_LOADING, value: companyGroup.name});      
    } else {
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_LOADING, value: companyGroup});
    }
    //setEditCompanyGroup(false);
  }  

  const remove = () => {
    dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_LOADING, value: companyGroupRemove.companyGroupId});
    setDeleteCompanyGroup(false);
  }

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editCompanyGroup && <CompanyGroupsList 
                                                                        edit={edit} 
                                                                        remove={removeConfirmation} 
                                                                        newRegister={newRegister}
                                                                        changePage={changePage}
                                                                        changeSearchText={changeSearchText}
                                                                        changeRowsPerPage={changeRowsPerPage}
                                                                        onOrderChange={onOrderChange}/>}
            {!state.loading && !state.error && editCompanyGroup && <CompanyGroupsEdit data={companyGroupEdit} update={update} back={back} add={addCompanyGroup}/>}
           
            {deleteCompanyGroup && <ModalConfirmDelete show={deleteCompanyGroup} title={t('admin.companyGroups.modal_delete_title')} back={()=>setDeleteCompanyGroup(false)} confirm={remove} textButtonBack={t('admin.companyGroups.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.companyGroups.modal_delete_buttonDelete')} />}
            
            {state.error && <ComponentError
                              title={t('admin.companyGroups.errorServerTitle')} 
                              message={t('admin.companyGroups.errorServerDescription')} 
                              buttonMessage={t('admin.companyGroups.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.companyGroups.editMessage')}
              addMessage={t('admin.companyGroups.addMessage')}
              delteMessage={t('admin.companyGroups.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default CompanyGroups;
