
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import Close from '@material-ui/icons/Close';

import './ModalInfo.scss';
import { UploadJobsXMLResult, VacanciesNotImported } from '../../models/initialState/typeJobsState';
import Table from '../table/Table';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',    
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    minWidth: '900px',
    height: '600px',
    overflow: 'scroll',
  };

interface Props {    
    show: boolean,
    data:UploadJobsXMLResult | null,   
    confirm():void  
}

function ModalResultImportXMLJobs(props: Props) {
  const { t } = useTranslation();

  const headers = [  
    { title: t('admin.jobs.label_id'), field: 'id' },
    { title: t('admin.jobs.label_title'), field: 'title' },
    { title: t('admin.jobs.label_reason'), field: 'reason', render: ((rowData: VacanciesNotImported) =>rowData.reasons.map(reason=><p>{reason}</p>))}
  ]
  return <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.show}
            onClose={props.confirm}
        >               
                <Box sx={style}>
                  <div className='swissin-modal-info'>
                    <div className='swissin-modal-header'>
                      <Close onClick={props.confirm}/>
                    </div>
                      <Info className='swissin-modal-header__icon'/>
                    <h2>{t('admin.jobs.importXMLTitle')}</h2>

                    {props.data && 
                      <div className='swissin-modal-body'>
                          <p><span className="swissin-label">{t('admin.jobs.numVancanciesInXML')}</span> <span className='swissin-info'>{props.data.numVancanciesInXML}</span></p>       
                          <p><span className="swissin-label">{t('admin.jobs.numVancanciesAdded')}</span> <span className='swissin-info'>{props.data.numVancanciesAdded}</span></p>
                          <p><span className="swissin-label">{t('admin.jobs.numVancanciesUpdated')}</span> <span className='swissin-info'>{props.data.numVancanciesUpdated}</span></p>
                          <p><span className="swissin-label">{t('admin.jobs.marketsNotFound')} </span></p>
                          <p>
                            {props.data.marketsNotFound && props.data.marketsNotFound.map((market,index, array)=>
                              <span className='swissin-info'>{market}{index === (array.length - 1) ? '.' : ', '}
                              </span>)}
                          </p>
                          <p><span className="swissin-label">{t('admin.jobs.skillsNotFound')} </span></p>
                          <p>
                            {props.data.skillsNotFound && props.data.skillsNotFound.map((skill,index, array)=>
                            <span className='swissin-info'>{skill}{index === (array.length - 1) ? '.' : ', '}
                            </span>)}
                          </p>
                          <p></p>
                          
                          {props.data.vacanciesNotImported.length > 0 && <>        
                            <p><span className="swissin-label">{t('admin.jobs.vacanciesNotImported')}</span></p>                 
                            <Table<VacanciesNotImported>
                              data={props.data.vacanciesNotImported}
                              headers={headers}                    
                            />
                            </> 
                          }
                          {props.data.vacanciesNotImported.length === 0 &&        
                            <p><span className="swissin-label">{t('admin.jobs.allVacanciesImported')}</span></p>
                          }
                        </div>
                    }             
                    
                    <div className='swissin-modal-info-buttons'>
                        <button onClick={props.confirm}>Close</button>
                    </div>                    
                  </div>
            </Box>
        </Modal>           
       
  }

export default ModalResultImportXMLJobs;
