import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';


import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import AmbassadorDto from '../models/dto/AmbassadorDto';
import { actionAmbasadorsLoading, ActionAmbasadorsType, actionListAmbassadorsAutomaticMatchLoading, actionListAmbassadorsFilterLoading } from '../models/actions/typeAmbassadorActions';
import TypeState from '../models/initialState/typeState';
import TypeMainState from '../models/initialState/typeMainState';

const baseUrlApi:string = getBaseUrlApi();
const urlAmbassadors = baseUrlApi + '/api/TA/getAmbassadorListForCompany';
const urlAutomaticMatchAmbassadors = baseUrlApi + '/api/TA/getAmbassadorListForCompanyWithAutomaticMatch';
const urlAmbassadorListForCompanyByFilter = baseUrlApi + '/api/TA/getAmbassadorListForCompanyByFilter'

function* checkError(code:number, action: TypeActions) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* loadAmbassadors(action:actionAmbasadorsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlAmbassadors + '?companyId=' + state.companySelected.company.id, getFetchParams('GET', null, state.token));
            if (response.ok) {
                const data:AmbassadorDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionAmbasadorsType.AMBASADORS_SUCCESS, value: data});
                
            } else {           
                yield call(checkError, response.status, {type: ActionAmbasadorsType.AMBASADORS_ERROR});  
            } 
        }       
    } catch (e){
        console.log(e);
        yield put<TypeActions>({type: ActionAmbasadorsType.AMBASADORS_ERROR});
    }
}

function* loadAutomaticMatchAmbassadors(action:actionListAmbassadorsAutomaticMatchLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlAutomaticMatchAmbassadors, getFetchParams('POST', action.value, state.token));
            if (response.ok) {
                const data:AmbassadorDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_SUCCESS, value: data});                            
            } else {           
                yield call(checkError, response.status, {type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR});  
            } 
        }       
    } catch {        
        yield put<TypeActions>({type: ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_ERROR});
    }
}

function* ambassadorListForCompanyByFilter(action:actionListAmbassadorsFilterLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer);        
        if (state.companySelected) {
            const response:Response = yield call(fetch, urlAmbassadorListForCompanyByFilter, getFetchParams('POST', action.value, state.token));
            if (response.ok) {
                const data:AmbassadorDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_SUCCESS, value: data});                           
            } else {           
                yield call(checkError, response.status, {type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_ERROR});  
            } 
        }       
    } catch {        
        yield put<TypeActions>({type: ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_ERROR});
    }
}


function* loadAmbassadorsWatcher() {
    yield takeLatest(ActionAmbasadorsType.AMBASADORS_LOADING, loadAmbassadors);
}

function* loadAutomaticMatchAmbassadorsWatcher() {
    yield takeLatest(ActionAmbasadorsType.LIST_AMBASSADORS_AUTOMATIC_MATCH_LOADING, loadAutomaticMatchAmbassadors);
}

function* ambassadorListForCompanyByFilterWatcher() {
    yield takeLatest(ActionAmbasadorsType.FILTER_PUSH_AMBASSADORS_LOADING, ambassadorListForCompanyByFilter);
}



export default function* rootAmbassadorsSaga() {
    yield all([
        loadAmbassadorsWatcher(),
        loadAutomaticMatchAmbassadorsWatcher(),
        ambassadorListForCompanyByFilterWatcher()
    ])
}