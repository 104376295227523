import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';
import { ActionLoginType } from '../models/actions/typeLoginActions';
import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';
import PaymentDto from '../models/dto/PaymentDto';
import { actionPaymentsEditLoading, actionPaymentsLoading, actionPaymentsType } from '../models/actions/typePaymentsActions';

const baseUrlApi:string = getBaseUrlApi();
const urlPaymentsList = baseUrlApi + '/api/TA/findRewards';
const urlPaymentsEdit = baseUrlApi + '/api/TA/updateReward'


function* checkError(code:number, action: any) {
    if (code === 401) {
        yield put<TypeActions>({type: ActionLoginType.LOGIN_SERVER_ERROR, value: 'page_login.errorPermissions'});
    } else {
        yield put<TypeActions>(action);
    }
}

function* paymentsList(action:actionPaymentsLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlPaymentsList + '?CompanyId=' + action.value, getFetchParams('GET', null, state.token));
        if (response.ok) {
            const data:PaymentDto[] = yield call([response, 'json']);            
            yield put<TypeActions>({type: actionPaymentsType.PAYMENTS_SUCCESS, value: data});           
        } else {  
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: actionPaymentsType.PAYMENTS_ERROR, value: data});
        }          
                
    } catch {        
        yield put<TypeActions>({type: actionPaymentsType.PAYMENTS_ERROR, value: ''});
    }
}

function* paymentsEdit(action:actionPaymentsEditLoading) {
    try {        
        const state:TypeMainState = yield select((state:TypeState) => state.mainReducer); 
        
        const response:Response = yield call(fetch, urlPaymentsEdit, getFetchParams('PUT', action.value, state.token));
        if (response.ok) {                  
            yield put<TypeActions>({type: actionPaymentsType.PAYMENTS_EDIT_SUCCESS, value: true});
        } else {  
            const data:string = yield call([response, 'text']);          
            yield call(checkError, response.status, {type: actionPaymentsType.PAYMENTS_ERROR, value: data});
        }          
                
    } catch {        
        yield put<TypeActions>({type: actionPaymentsType.PAYMENTS_ERROR, value: ''});
    }
}

function* paymentsWhatcher() {
    yield takeLatest(actionPaymentsType.PAYMENTS_LOADING, paymentsList);
}

function* paymentsEditWhatcher() {
    yield takeLatest(actionPaymentsType.PAYMENTS_EDIT_LOADING, paymentsEdit);
}

export default function* rootPaymentsSaga() {
    yield all([
      paymentsWhatcher(),
      paymentsEditWhatcher()     
    ])
}