import { ROLE_ADMIN, ROLE_TA, ROLE_TAADMIN } from "../../constanst/GlobalConstants";

export function isAdmin(role: number) {
    return (role === ROLE_ADMIN);    
}

export function isTAAdmin(role: number) {   
    return (role === ROLE_TAADMIN);    
}

export function isTA(role: number) {   
    return (role === ROLE_TA); 
}

export function isTATAAdmin(role: number) {   
    return (role === ROLE_TA || role === ROLE_TAADMIN); 
}

export function isAdminTAAdmin(role: number) {   
    return (role === ROLE_ADMIN || role === ROLE_TAADMIN); 
}