import { TypeActions } from '../../models/actions/typeActions';
import { ActionLocationType } from '../../models/actions/typeLocationActions';
import { CityForm, CountryForm, RegionForm } from '../../models/forms/LocationForm';
import TypeLocationState from '../../models/initialState/typeLocationState';
import { newFormField } from '../../utils/utils';
import { initialLocationState } from '../inititalState/initialLocationState';

const locationReducer = (state:TypeLocationState = initialLocationState, action:TypeActions):TypeLocationState => {
    switch (action.type) {

        /* Countries */
        case  ActionLocationType.LOCATION_COUNTRIES_SEND_VALUES: {
            const countries = state.countries;
            const name = action.value.name;           
            let countryForm:CountryForm = {...state.countries.countriesForm};
            countryForm[name as keyof CountryForm] = {...countryForm[name as keyof CountryForm], value: action.value.value, error: action.value.error};         
            return {...state, countries: { ...countries, countriesForm: {...countryForm}} }
        }

        case ActionLocationType.LOCATION_COUNTRIES_ERROR_REQUIRED : {
            const countries = state.countries;
            const name = action.value;           
            let countryForm:CountryForm = {...state.countries.countriesForm};
            countryForm[name as keyof CountryForm] =  {...countryForm[name as keyof CountryForm], error: {required: true, format: countryForm[name as keyof CountryForm].error.format}};
            return {...state, countries: { ...countries, countriesForm: {...countryForm}} }     
        }

        case ActionLocationType.LOCATION_COUNTRIES_LOADING: {
            const countries = state.countries;
            const regions = state.regions;
            const cities = state.cities;     
            return {...state, countries: {...countries, loading: true, error: false, data: []}, regions: {...regions, data: []}, cities: {...cities, data: []}}
        }

        case ActionLocationType.LOCATION_COUNTRIES_SUCCESS: {
            const countries = state.countries;            
            return {...state, countries: {...countries, loading: false, error: false, data: action.value}, }
        }

        case ActionLocationType.LOCATION_COUNTRIES_ERROR: {
            const countries = state.countries;         
            return {...state, countries: {...countries, loading: false, error: true, data: []}}      
        }

        case ActionLocationType.LOCATION_COUNTRIES_FORM_NEW: {
            const countries = state.countries;         
            return {...state, countries: {...countries, countriesForm: {
                                                            description: newFormField('',true, false), 
                                                            code: newFormField('',true, false), 
                                                            language: newFormField('',true, false),
                                                            phoneCode: newFormField('',true, false),
                                                        }}}      
        }

        case ActionLocationType.LOCATION_COUNTRIES_FORM_UPDATE: {
            const countries = state.countries;         
            return {...state, countries: {...countries, countriesForm: {
                                                            description: newFormField(action.value.description, true, false), 
                                                            code: newFormField(action.value.code, true, false), 
                                                            language: newFormField(action.value.language, true, false),
                                                            phoneCode: newFormField(action.value.phoneCode, true, false),
                                                        }}}      
        }

        case ActionLocationType.LOCATION_COUNTRIES_CHANGE_SEARCH_TABLE: { 
            const countries = state.countries;                         
            return {...state, countries: {...countries, searchText: action.value}}    
        }

        case ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_TABLE: { 
            const countries = state.countries;                         
            return {...state, countries: {...countries, initialPage: action.value}}         
        }

        case ActionLocationType.LOCATION_COUNTRIES_CHANGE_PAGE_SIZE: {
            const countries = state.countries;                          
            return {...state, countries: {...countries, pageSize: action.value}}           
        }

        case ActionLocationType.LOCATION_COUNTRIES_DELETE_LOADING:
        case ActionLocationType.LOCATION_COUNTRIES_EDIT_LOADING:
        case ActionLocationType.LOCATION_COUNTRIES_ADD_LOADING: {
            const countries = state.countries;           
            return {...state, countries: {...countries, loading: true, error: false}, }
        }

        case ActionLocationType.LOCATION_COUNTRIES_ADD_SUCCESS: {
            const countries = state.countries;            
            return {...state, countries: {...countries, loading: false, error: false, add: action.value}, }
        }

        case ActionLocationType.LOCATION_COUNTRIES_EDIT_SUCCESS: {
            const countries = state.countries;            
            return {...state, countries: {...countries, loading: false, error: false, edit: action.value}, }
        }

        case ActionLocationType.LOCATION_COUNTRIES_DELETE_SUCCESS: {
            const countries = state.countries;            
            return {...state, countries: {...countries, loading: false, error: false, delete: action.value}, }
        }

        /* Regions */
        case  ActionLocationType.LOCATION_REGIONS_SEND_VALUES: {
            const regions = state.regions;
            const name = action.value.name;           
            let regionsForm:RegionForm = {...state.regions.regionsForm};
            regionsForm[name as keyof RegionForm] = {...regionsForm[name as keyof RegionForm], value: action.value.value, error: action.value.error};         
            return {...state, regions: { ...regions, regionsForm: {...regionsForm}} }
        }

        case ActionLocationType.LOCATION_REGIONS_ERROR_REQUIRED : {
            const regions = state.regions;
            const name = action.value;           
            let regionsForm:RegionForm = {...state.regions.regionsForm};
            regionsForm[name as keyof RegionForm] =  {...regionsForm[name as keyof RegionForm], error: {required: true, format: regionsForm[name as keyof RegionForm].error.format}};
            return {...state, regions: { ...regions, regionsForm: {...regionsForm}} }
        }

        case ActionLocationType.LOCATION_REGIONS_FORM_NEW: {
            const regions = state.regions;      
            return {...state, regions: {...regions, regionsForm: {
                                                        name: newFormField('',true, false)                                                            
                                                    }}}      
        }

        case ActionLocationType.LOCATION_REGIONS_FORM_UPDATE: {
            const regions = state.regions;        
            return {...state, regions: {...regions, regionsForm: {
                                                        name: newFormField(action.value.description, true, false)                                                            
                                                    }}}      
        }

        case ActionLocationType.LOCATION_REGIONS_CHANGE_SEARCH_TABLE: { 
            const regions = state.regions;                             
            return {...state, regions: {...regions, searchText: action.value}}    
        }

        case ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_TABLE: { 
            const regions = state.regions;                               
            return {...state, regions: {...regions, initialPage: action.value}}         
        }

        case ActionLocationType.LOCATION_REGIONS_CHANGE_PAGE_SIZE: {
            const regions = state.regions;                                
            return {...state, regions: {...regions, pageSize: action.value}}           
        }

        case ActionLocationType.LOCATION_REGIONS_LOADING: {
            const regions = state.regions;
            const cities = state.cities;               
            return {...state, regions: {...regions, loading: true, error: false, data: []}, cities: {...cities, data: []}}
        }

        case ActionLocationType.LOCATION_REGIONS_SUCCESS: {           
            const regions = state.regions;
            return {...state, regions: {...regions, loading: false, error: false, data: action.value}}
        }

        case ActionLocationType.LOCATION_REGIONS_ERROR: {           
            const regions = state.regions;
            return {...state, regions: {...regions, loading: false, error: true, data: []}}
        }

        case ActionLocationType.LOCATION_REGIONS_DELETE_LOADING:
        case ActionLocationType.LOCATION_REGIONS_EDIT_LOADING:
        case ActionLocationType.LOCATION_REGIONS_ADD_LOADING: {
            const regions = state.regions;          
            return {...state, regions: {...regions, loading: true, error: false}, }
        }

        case ActionLocationType.LOCATION_REGIONS_ADD_SUCCESS: {
            const regions = state.regions;            
            return {...state, regions: {...regions, loading: false, error: false, add: action.value}, }
        }

        case ActionLocationType.LOCATION_REGIONS_EDIT_SUCCESS: {
            const regions = state.regions;           
            return {...state, regions: {...regions, loading: false, error: false, edit: action.value}, }
        }

        case ActionLocationType.LOCATION_REGIONS_DELETE_SUCCESS: {
            const regions = state.regions;          
            return {...state, regions: {...regions, loading: false, error: false, delete: action.value}, }
        }

        /* Cities */
        case  ActionLocationType.LOCATION_CITIES_SEND_VALUES: {
            const cities = state.cities;
            const name = action.value.name;           
            let citiesForm:CityForm = {...state.cities.citiesForm};
            citiesForm[name as keyof CityForm] = {...citiesForm[name as keyof CityForm], value: action.value.value, error: action.value.error};         
            return {...state, cities: { ...cities, citiesForm: {...citiesForm}} }
        }

        case ActionLocationType.LOCATION_CITIES_ERROR_REQUIRED : {
            const cities = state.cities;
            const name = action.value;           
            let citiesForm:CityForm = {...state.cities.citiesForm};
            citiesForm[name as keyof CityForm] =  {...citiesForm[name as keyof CityForm], error: {required: true, format: citiesForm[name as keyof CityForm].error.format}};
            return {...state, cities: { ...cities, citiesForm: {...citiesForm}} }
        }

        case ActionLocationType.LOCATION_CITIES_FORM_NEW: {
            const cities = state.cities;  
            return {...state, cities: {...cities, citiesForm: {
                                                        name: newFormField('',true, false)                                                            
                                                    }}}      
        }

        case ActionLocationType.LOCATION_CITIES_FORM_UPDATE: {
            const cities = state.cities;       
            return {...state, cities: {...cities, citiesForm: {
                                                        name: newFormField(action.value.description, true, false)                                                            
                                                    }}}      
        }

        case ActionLocationType.LOCATION_CITIES_LOADING: {
            const cities = state.cities;          
            return {...state, cities: {...cities, loading: true, error: false, data: []}}
        }

        case ActionLocationType.LOCATION_CITIES_SUCCESS: {           
            const cities = state.cities;          
            return {...state, cities: {...cities, loading: false, error: false, data: action.value}}
        }

        case ActionLocationType.LOCATION_CITIES_ERROR: {           
            const cities = state.cities;          
            return {...state, cities: {...cities, loading: false, error: true, data: []}}
        }

        case ActionLocationType.LOCATION_CITIES_CHANGE_SEARCH_TABLE: { 
            const cities = state.cities;                           
            return {...state, cities: {...cities, searchText: action.value}}    
        }

        case ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_TABLE: { 
            const cities = state.cities;                         
            return {...state, cities: {...cities, initialPage: action.value}}         
        }

        case ActionLocationType.LOCATION_CITIES_CHANGE_PAGE_SIZE: {
            const cities = state.cities;                          
            return {...state, cities: {...cities, pageSize: action.value}}           
        }

        case ActionLocationType.LOCATION_CITIES_DELETE_LOADING:
        case ActionLocationType.LOCATION_CITIES_EDIT_LOADING:
        case ActionLocationType.LOCATION_CITIES_ADD_LOADING: {
            const cities = state.cities;            
            return {...state, cities: {...cities, loading: true, error: false}, }
        }

        case ActionLocationType.LOCATION_CITIES_ADD_SUCCESS: {
            const cities = state.cities;            
            return {...state, cities: {...cities, loading: false, error: false, add: action.value}, }
        }        

        case ActionLocationType.LOCATION_CITIES_EDIT_SUCCESS: {
            const cities = state.cities;        
            return {...state, cities: {...cities, loading: false, error: false, edit: action.value}, }
        }

        case ActionLocationType.LOCATION_CITIES_DELETE_SUCCESS: {
            const cities = state.cities;         
            return {...state, cities: {...cities, loading: false, error: false, delete: action.value}, }
        }

        case ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_LOADING: { 
            const countriesWithoutPushExceptions = state.countriesWithoutPushExceptions;         
            return {...state, countriesWithoutPushExceptions: {...countriesWithoutPushExceptions, loading: true, error: false, data: []}}
        }

        case ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_SUCCESS: {
            const countriesWithoutPushExceptions = state.countriesWithoutPushExceptions;         
            return {...state, countriesWithoutPushExceptions: {...countriesWithoutPushExceptions, loading: false, error: false, data: action.value}}
        }

        case ActionLocationType.LOCATION_COUNTRIES_WITHOUT_PUSH_EXCEPTIONS_ERROR: {           
            const countriesWithoutPushExceptions = state.countriesWithoutPushExceptions;         
            return {...state, countriesWithoutPushExceptions: {...countriesWithoutPushExceptions, loading: false, error: true, data: []}}
        }

        case ActionLocationType.LOCATION_COUNTRIES_CHANGE_FILTER: {            
            const countries = state.countries;            
            return {...state, countries: {...countries, filters: action.value}, }        
        }

        case ActionLocationType.LOCATION_REGIONS_CHANGE_FILTER: {            
            const regions = state.regions;            
            return {...state, regions: {...regions, filters: action.value}, }        
        }

        case ActionLocationType.LOCATION_CITIES_CHANGE_FILTER: {            
            const cities = state.cities;            
            return {...state, cities: {...cities, filters: action.value}, }        
        }
    }
    return {...state};
}
export default locationReducer;
