
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from '../table/Table';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TypeState from '../../models/initialState/typeState';
import SkillDto from '../../models/dto/SkillDto';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import typeSkillGroupState from '../../models/initialState/typeSkillGroupState';

interface Props {
    edit(event: any, index:SkillDto): void,
    remove(event: any, index:SkillDto): void,
    newRegister():void,
    changePage(page: number, pageSize: number):void,
    changeSearchText(searchText: string):void,
    changeRowsPerPage?(pageSize: number):void,
    onOrderChange?(orderBy: number, orderDirection: string): void,
}

function SkillsList(props: Props) {
  const { t } = useTranslation();
  const state:TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);  
  const stateSkillGroup: typeSkillGroupState = useSelector((state: TypeState) => state.skillGroupReducer);

  const headers = [{title: t('admin.skills.name'), 
                    defaultSort: state.filters.orderBy === 0 ? state.filters.orderDirection: '' , 
                    field: 'name'},

                  {title: t('admin.skills.group'), 
                   field: 'skillGroupId', 
                   render: (rowData:SkillDto) => {
                    const skillGroup = stateSkillGroup.data.find(skillGroup => skillGroup.id === rowData.skillGroupId);
                    return skillGroup !== undefined ? skillGroup.name : '';
                   }, 
                   defaultSort: state.filters.orderBy === 1 ? state.filters.orderDirection: '', 
                   customSort: (a:SkillDto, b:SkillDto) => {    
                    const groupA = stateSkillGroup.data.find(skillGroup => skillGroup.id === a.skillGroupId);
                    const nameA = groupA !== undefined ? groupA.name : ''    
                    const groupB = stateSkillGroup.data.find(skillGroup => skillGroup.id === b.skillGroupId);
                    const nameB = groupB !== undefined ? groupB.name : ''    
                    if (nameA > nameB) {
                      return -1;
                    }   
                    if (nameA < nameB) {
                      return 1;
                    }
                    return 0}
                   }
                  ];
  console.log(headers)

  const actions = [
    {
      icon: Edit,
      tooltip: t('table.tooltip.actionEdit'),
      disabled: false,
      onClick: props.edit
    },
    {
      icon: DeleteOutline,
      tooltip: t('table.tooltip.actionDelete'),
      disabled: false,
      onClick: props.remove
    },
]

  return <div className='swissinx-container'>   
            <h2>{t('admin.skills.listTitle')}</h2>
            <Table<SkillDto> 
              data={state.data} 
              headers={headers} 
              actions={actions} 
              new={true}
              initialPage={state.initialPage}
              serchText={state.searchText} 
              pageSize={state.pageSize} 
              newRegister={props.newRegister}
              changePage={props.changePage}
              changeSearchText={props.changeSearchText}
              changeRowsPerPage={props.changeRowsPerPage}
              onOrderChange={props.onOrderChange}
              />
        </div>;
  }

export default SkillsList;