import { useTranslation } from 'react-i18next';
import './EndSMSRegistration.scss';
import GooglePlay from '../../assest/GooglePlay.svg';
import AppStore from '../../assest/AppStore.svg';

function DownloadApp() { 
  const { t } = useTranslation();
  
  return <div className='download-app'>
              <p className='download-app-title'>{t('publicJob.downloadAppTitle')}</p>
              <p>{t('publicJob.downloadAppDescription')}</p>
              <a href={t('publicJob.urlAppStore')}><img src={AppStore} alt=""></img></a>
              <a href={t('publicJob.urlGooglePlay')}><img src={GooglePlay} alt="Share icon"></img></a>
          </div>
}

export default DownloadApp;