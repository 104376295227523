import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import TypeMainState from '../../models/initialState/typeMainState';
import {TypeLoginActions, ActionLoginType, KeysLoginForm} from '../../models/actions/typeLoginActions';
import { useSelector, useDispatch } from 'react-redux';
import logoDark from '../../assest/swapp_logo_dark.svg';
import Form from '../Form/Form/Form';
import FieldText from '../Form/FieldText/FieldText';
import {validateLogin, validatePassword} from '../Form/validators';

import './Login.scss';
import TypeState from "../../models/initialState/typeState";
import { useState } from "react";

function Login() {
  const [showMessageRecoverPassword, setShowMessageRecoverPassword] = useState(false);
  const [messageRecoverPassword, setMessageRecoverPassword] = useState('');
  const { t } = useTranslation();
  const state:TypeMainState = useSelector((state:TypeState) => state.mainReducer);
  const dispatch = useDispatch();
  

  const onSubmit = () => {
    dispatch<TypeLoginActions>({type: ActionLoginType.LOGIN_SERVER_SEND_DATA, value: {username: state.loginForm.username.value as string, password: state.loginForm.password.value as string}})
  }

  const recoverPassword = () => {
    setShowMessageRecoverPassword(false);
    if (state.loginForm.username.value !== '' && !state.loginForm.username.error.format) {
      dispatch<TypeLoginActions>({type: ActionLoginType.RECOVER_ACCOUNT_PASSWORD_LOADING, value: {phoneOrEmail: state.loginForm.username.value as string}});
    } else {
      setShowMessageRecoverPassword(true);
      setMessageRecoverPassword(t('page_login.recoverPasswordErrorUser'));
    }
  }

  return <div className="swissinx-login">      
   <div className="swissinx-login-container">
     <img src={logoDark} alt=""></img>     
     <h2>{t('page_login.title')}</h2>
     <h2 className="subtitle">{t('page_login.subTitle')}</h2>
     {<Form contex={state.loginForm}  errorRequerired={(key)=>dispatch<TypeLoginActions>({type: ActionLoginType.LOGIN_FORM_ERROR_REQUIRED, value: key})} onSubmit={onSubmit} 
         fields={
           <div className="swissinx-container-fields-form">                             
             <div className="swissinx-login-field">
               <FieldText                              
                 placeholder = {t('page_login.label_login')}
                 label = ''                   
                 name='login'                                              
                 required={state.loginForm.username.rules.required}               
                 requiredError = {t('page_login.login_error_required')}
                 value={state.loginForm.username.value as string}
                 error={state.loginForm.username.error.required || state.loginForm.username.error.format}
                 validators = {[
                   {validator: validateLogin, messageError: t('page_login.login_error_format')}                                                      
               ]}                                             
                 onChange = {({value, error})=>dispatch<TypeLoginActions>({type: ActionLoginType.LOGIN_FORM_SEND_VALUES, value: {name:KeysLoginForm.login, value, error}})}                             
               />
             </div>
             <div className="swissinx-login-field">
               <FieldText      
                 label= ''
                 placeholder = {t('page_login.label_password')}
                 name='password'
                 type='password'                                            
                 required={state.loginForm.password.rules.required}               
                 requiredError = {t('page_login.password_error_required')}
                 value={state.loginForm.password.value as string}
                 error={state.loginForm.password.error.required}
                 /* validators = {[
                    {validator: validatePassword, messageError: t('page_login.password_error_format')}                                                      
                ]}    */                                                     
                 onChange = {({value, error})=>dispatch<TypeLoginActions>({type: ActionLoginType.LOGIN_FORM_SEND_VALUES, value: {name:KeysLoginForm.password, value, error}})}         
               />
             </div>  
             <p className="swissinx-login-recover-password" onClick={recoverPassword}>{state.recoverPassword.loading ? t('page_login.loading') : t('page_login.recoverPassword')}</p> 
             {showMessageRecoverPassword && <p className="react-campo-server-error">{messageRecoverPassword}</p>}
             {state.recoverPassword.ok && <p className="swissinx-login-recover-password_success">{t('page_login.sendPasswordSuccessfully')}</p>}          
             <button className="swissinx-login-button" data-testid="buttonLogin" disabled={state.loading}>{state.loading ? t('page_login.loading') : t('page_login.text_button')}</button>                      
               
           </div>
        }>
       </Form> }
     {state.auth && <Navigate to="/admin" />} 
         
     {state.errorServerLogin && <p className="react-campo-server-error">{t(state.errorServerLoginMessage)}</p>}   

   </div>
   <div className="swissinx-login-footer">
        <p>{t('page_login.noAccount')}<a href="#">{t('page_login.noAccountLink')}</a></p>
   </div>
 </div>
}

export default Login;
