import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import logo from '../../assest/swapp_logo.svg';
import './SkeletonJob.scss';

export default function SkeketonJob() {
  return (
    <div className="swissinx-job-container">
        <div className='swissinx-job-header'>
            <img src={logo} alt=""></img>        
        </div>
    <Stack spacing={1}>
    <div className='swissinx-skeleton-container'>
        <div className='swissinx-skeleton-header'>
          <Skeleton variant="text" width={'50%'}/>  
          <Skeleton variant="text" width={'20%'}/> 
          <Skeleton variant="text" width={'30%'}/>
        </div>
        <div className='swissinx-skeleton-shares'>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
        </div>   
        <div className='swissinx-skeleton-skills'>
            <Skeleton variant="rectangular" width={60} height={20} />
            <Skeleton variant="rectangular" width={80} height={20} />
            <Skeleton variant="rectangular" width={30} height={20} />
            <Skeleton variant="rectangular" width={30} height={20} />
            <Skeleton variant="rectangular" width={40} height={20} />
        </div>
        <Skeleton variant="text" width={'15%'}/>
        <div className='swissinx-skeleton-details-container'>
            <div className='swissinx-skeleton-details'>
                <Skeleton variant="text" width={'20%'}/>  
                <Skeleton variant="text" width={'15%'}/> 
            </div>
            <div className='swissinx-skeleton-details'>
                <Skeleton variant="text" width={'20%'}/>  
                <Skeleton variant="text" width={'30%'}/> 
            </div>
            <div className='swissinx-skeleton-details'>
                <Skeleton variant="text" width={'15%'}/>  
                <Skeleton variant="text" width={'35%'}/> 
            </div>
            <div className='swissinx-skeleton-details'>
                <Skeleton variant="text" width={'20%'}/>  
                <Skeleton variant="text" width={'15%'}/> 
            </div>
            <div className='swissinx-skeleton-details'>
                <Skeleton variant="text" width={'20%'}/>  
                <Skeleton variant="text" width={'10%'}/> 
            </div>
        </div>
        
        <Skeleton variant="text" width={'15%'}/> 
        <div className='swissinx-skeleton-description'>
            <Skeleton variant="text" />  
            <Skeleton variant="text" /> 
            <Skeleton variant="text" />  
            <Skeleton variant="text" /> 
        </div>

        <Skeleton variant="text" width={'15%'}/> 
        <div className='swissinx-skeleton-referBy'>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
        </div>

        <div className='swissinx-skeleton-buttons'>
            <Skeleton variant="rectangular" width={'40%'} height={40} />
            <Skeleton variant="rectangular" width={'40%'} height={40} />            
        </div>

    </div>
    </Stack>
    </div>
  );
}