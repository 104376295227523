import { ActionsType, TypeActions} from '../../models/actions/typeActions';
import { newFormField, newFormNetworker } from '../../utils/utils';
import { ActionNetworkersType } from '../../models/actions/typeNetworkersActions';
import NetworkerForm from '../../models/forms/NetworkerForm';
import TypeNetworkersState from '../../models/initialState/typeNetworkersState';
import { initialNetworkersState } from '../inititalState/initialNetworkersState';

const networkersReducer = (state:TypeNetworkersState = initialNetworkersState, action:TypeActions):TypeNetworkersState => {
    switch (action.type) {

        case  ActionNetworkersType.NETWORKERS_FORM_SEND_VALUES: {
            const name = action.value.name;            
            let networkersForm:NetworkerForm = {...state.networkersForm};
            networkersForm[name as keyof NetworkerForm] = {...networkersForm[name as keyof NetworkerForm], value: action.value.value, error: action.value.error};         
            return {...state, networkersForm: { ...networkersForm} }
        }
        
        case ActionNetworkersType.NETWORKERS_ERROR_REQUIRED : {
            const name = action.value;           
            let networkersForm:NetworkerForm = {...state.networkersForm};
            networkersForm[name as keyof NetworkerForm] =  {...networkersForm[name as keyof NetworkerForm], error: {required: true, format: networkersForm[name as keyof NetworkerForm].error.format}};
            return {...state, networkersForm: { ...networkersForm} }       
        }

        case ActionNetworkersType.NETWORKERS_FORM_NEW: {
            let networkersForm:NetworkerForm = newFormNetworker();
            let extraFields = state.extraFields;         
            return {...state, extraFields: {...extraFields, data: null}, networkersForm: { ...networkersForm}}
        }

        case ActionNetworkersType.NETWORKERS_FORM_UPDATE: {
            let networkersForm:NetworkerForm = newFormNetworker();            
            return {...state, networkersForm: { ...networkersForm, ...action.value} }
        }

        case ActionNetworkersType.NETWORKERS_LOADING: {            
            return {...state, loading: true, error: false}
        }

        case ActionNetworkersType.NETWORKERS_SUCCESS: {           
            return {...state, networkersData: action.value.data, loading: false, error: false,
                searchText: action.value.reset ? '' : state.searchText, initialPage: action.value.reset ? 0 : state.initialPage}
        }

        case ActionsType.SEND_COMPANY_SELECTED: {
            return {...state, networkersData: []}
        }

        case ActionNetworkersType.NETWORKERS_EDIT_LOADING: {            
            return {...state, loading: true, error: false, edit: false}
        }

        case ActionNetworkersType.NETWORKERS_EDIT_SUCCESS: {            
            return {...state, loading: false, error: false, edit: action.value}
        }

        case ActionNetworkersType.NETWORKERS_ADD_LOADING: {            
            return {...state, loading: true, error: false, add: false}
        }

        case ActionNetworkersType.NETWORKERS_ADD_SUCCESS: {            
            return {...state, loading: false, error: false, add: action.value}
        }

        case ActionNetworkersType.NETWORKERS_DELETE_LOADING: {            
            return {...state, loading: true, error: false, delete: false}
        }

        case ActionNetworkersType.NETWORKERS_DELETE_SUCCESS: {            
            return {...state, loading: false, error: false, delete: action.value}
        }

        case ActionNetworkersType.NETWORKERS_ERROR: {           
            return {...state, loading: false, error: true, messageError: action.value}
        }
        
        case ActionNetworkersType.NETWORKERS_CHANGE_SEARCH_TABLE: {                     
            return {...state, searchText: action.value}      
        }

        case ActionNetworkersType.NETWORKERS_CHANGE_PAGE_TABLE: {                     
            return {...state, initialPage: action.value}           
        }

        case ActionNetworkersType.NETWORKERS_CHANGE_PAGE_SIZE: {                     
            return {...state, pageSize: action.value}           
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_DATA_LOADING: { 
            const rewards = state.rewards;        
            return {...state, rewards: {...rewards, loading: true, error: false, data: {
                rewards: [],
                totalRewards: 0,
                totalRewardCurrency: []
              }}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_DATA_SUCCESS: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, loading: false, error: false, data: action.value}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_DATA_ERROR: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, loading: false, error: true, data: {
                rewards: [],
                totalRewards: 0,
                totalRewardCurrency: []
              }}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_YEARS_LOADING: { 
            const rewards = state.rewards;        
            return {...state, rewards: {...rewards, loading: true, error: false, years: [], data: {
                rewards: [],
                totalRewards: 0,
                totalRewardCurrency: []
              }}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_YEARS_SUCCESS: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, loading: false, error: false, years: action.value}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_YEARS_ERROR: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, loading: false, error: true, years: []}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_SEND_YEAR_SELECTED: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, yearSelected: action.value}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_PDF_LOADING: { 
            const rewards = state.rewards;        
            return {...state, rewards: {...rewards, downloadPdfLoading: true, downloadPdfError: false, downloadPdfSuccess: false}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_PDF_SUCCESS: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, downloadPdfLoading: false, downloadPdfError: false, downloadPdfSuccess: action.value}}
        }

        case ActionNetworkersType.NETWORKERS_REWARDS_PDF_ERROR: {
            const rewards = state.rewards;   
            return {...state, rewards: {...rewards, downloadPdfLoading: false, downloadPdfError: action.value, downloadPdfSuccess: false}}
        }

        case ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_LOADING: { 
            const extraFields = state.extraFields;        
            return {...state, extraFields: {...extraFields, loading: true, error: false, data: null}}
        }

        case ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_SUCCESS: {
            const extraFields = state.extraFields;
            const networkersForm = state.networkersForm;
            const skillId = action.value.skills.map(skill => skill.id);
            const marketId = action.value.markets.map(market => market.id);
            const countryIds = action.value.countries.map(country => country.id);
            const country = action.value.country && action.value.country.id ? action.value.country.id : '';
            const region = action.value.region && action.value.region.id ? action.value.region.id : '';
            const city = action.value.city && action.value.city.id ? action.value.city.id : '';
            const language = action.value.languages.map(language => language.id);
            const address = action.value.address ? action.value.address : '';
            const postalCode = action.value.postalCode ? action.value.postalCode : '';
            const birthDay = action.value.birthDay ? action.value.birthDay : '';

            return {...state, 
                extraFields: {...extraFields, loading: false, error: true, data: action.value},
                networkersForm: {...networkersForm, 
                                skillId: newFormField(skillId,false, false),
                                marketId: newFormField(marketId,false, false),
                                countryIds: newFormField(countryIds,false, false),
                                countryId: newFormField(country,false, false),
                                regionId: newFormField(region,false, false),
                                cityId: newFormField(city,false, false),
                                languageId: newFormField(language,false, false),
                                address: newFormField(address, false, false),
                                postalCode: newFormField(postalCode,false, false),
                                birthDay: newFormField(birthDay,false, false),
                }
            }
        }

        case ActionNetworkersType.NETWORKERS_EXTRA_FIELDS_ERROR: {
            const extraFields = state.extraFields;   
            return {...state, extraFields: {...extraFields, loading: false, error: true, data: null}}
        }

        case ActionNetworkersType.NETWORKERS_CHANGE_FILTER: {            
            return {...state, filters: action.value}           
        }

        case ActionNetworkersType.NETWORKERS_ANONYMIZER_LOADING: {  
            const anonymizer = state.anonymizer;          
            return {...state, anonymizer: {...anonymizer, loading: false, error: false, ok: false, textError: ''}}
        }

        case ActionNetworkersType.NETWORKERS_ANONYMIZER_SUCCESS: {            
            const anonymizer = state.anonymizer;          
            return {...state, anonymizer: {...anonymizer, loading: false, error: false, ok: false, textError: ''}}
        }

        case ActionNetworkersType.NETWORKERS_ANONYMIZER_ERROR: {            
            const anonymizer = state.anonymizer;          
            return {...state, anonymizer: {...anonymizer, loading: false, error: false, ok: false, textError: action.value}}
        }

        case ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_LOADING: {  
            const performanceReport = state.performanceReport;          
            return {...state, performanceReport: {...performanceReport, loading: false, error: false, ok: false, textError: ''}}
        }

        case ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_SUCCESS: {            
            const performanceReport = state.performanceReport;          
            return {...state, performanceReport: {...performanceReport, loading: false, error: false, ok: false, textError: ''}}
        }

        case ActionNetworkersType.NETWORKERS_PERFORMANCE_REPORT_ERROR: {            
            const performanceReport = state.performanceReport;         
            return {...state, performanceReport: {...performanceReport, loading: false, error: false, ok: false, textError: action.value}}
        }
    }
    return {...state};
}
export default networkersReducer;