import SkillGroupDto from "../dto/SkillGroupDto"

export enum ActionSkillGroupType {    
    SKILL_GROUP_ERROR_REQUIRED = 'SKILL_GROUP_ERROR_REQUIRED',
    SKILL_GROUP_LOADING = 'SKILL_GROUP_LOADING',
    SKILL_GROUP_SUCCESS = 'SKILL_GROUP_SUCCESS',
    SKILL_GROUP_ERROR = 'SKILL_GROUP_ERROR',
    SKILL_GROUP_ADD_LOADING = 'SKILL_GROUP_ADD_LOADING',
    SKILL_GROUP_ADD_SUCCESS = 'SKILL_GROUP_ADD_SUCCESS',
    SKILL_GROUP_ADD_ERROR = 'SKILL_GROUP_ADD_ERROR',
    SKILL_GROUP_EDIT_LOADING = 'SKILL_GROUP_EDIT_LOADING',
    SKILL_GROUP_EDIT_SUCCESS = 'SKILL_GROUP_EDIT_SUCCESS',
    SKILL_GROUP_EDIT_ERROR = 'SKILL_GROUP_EDIT_ERROR',
    SKILL_GROUP_DELETE_LOADING = 'SKILL_GROUP_DELETE_LOADING',
    SKILL_GROUP_DELETE_SUCCESS = 'SKILL_GROUP_DELETE_SUCCESS',
    SKILL_GROUP_DELETE_ERROR = 'SKILL_GROUP_DELETE_ERROR',
    SKILL_GROUP_CHANGE_SEARCH_TABLE = 'SKILL_GROUP_CHANGE_SEARCH_TABLE',
    SKILL_GROUP_CHANGE_PAGE_TABLE = 'SKILL_GROUP_CHANGE_PAGE_TABLE',
    SKILL_GROUP_CHANGE_PAGE_SIZE = 'SKILL_GROUP_CHANGE_PAGE_SIZE',
    SKILL_GROUP_CHANGE_FILTER = 'SKILL_GROUP_CHANGE_FILTER'
}

export interface actionSkillGroupErrorRequired {
    type: ActionSkillGroupType.SKILL_GROUP_ERROR_REQUIRED,
    value: string
}

export interface actionSkillGroupLoading {
    type: ActionSkillGroupType.SKILL_GROUP_LOADING,
    value: {reset: boolean} 
}
export interface actionSkillGroupSuccess {
    type: ActionSkillGroupType.SKILL_GROUP_SUCCESS,
    value: {data: SkillGroupDto[], reset: boolean}
}
export interface actionSkillGroupError {
    type: ActionSkillGroupType.SKILL_GROUP_ERROR   
}

export interface actionSkillGroupAddLoading {
    type: ActionSkillGroupType.SKILL_GROUP_ADD_LOADING,
    value: string
}
export interface actionSkillGroupAddSuccess {
    type: ActionSkillGroupType.SKILL_GROUP_ADD_SUCCESS
    value: boolean
}
    
export interface actionSkillGroupAddError {
    type: ActionSkillGroupType.SKILL_GROUP_ADD_ERROR   
}

export interface actionSkillGroupEditLoading {
    type: ActionSkillGroupType.SKILL_GROUP_EDIT_LOADING,
    value: SkillGroupDto
}
export interface actionSkillGroupEditSuccess {
    type: ActionSkillGroupType.SKILL_GROUP_EDIT_SUCCESS,
    value: boolean
}
    
export interface actionSkillGroupEditError {
    type: ActionSkillGroupType.SKILL_GROUP_EDIT_ERROR   
}

export interface actionSkillGroupDeleteLoading {
    type: ActionSkillGroupType.SKILL_GROUP_DELETE_LOADING,
    value: number
}
export interface actionSkillGroupDeleteSuccess {
    type: ActionSkillGroupType.SKILL_GROUP_DELETE_SUCCESS
    value: boolean
}
    
export interface actionSkillGroupDeleteError {
    type: ActionSkillGroupType.SKILL_GROUP_DELETE_ERROR   
}

export interface actionSkillGroupChangeSerachTable {
    type: ActionSkillGroupType.SKILL_GROUP_CHANGE_SEARCH_TABLE,
    value: string
}

export interface actionSkillGroupChangePageSizeTable {
    type: ActionSkillGroupType.SKILL_GROUP_CHANGE_PAGE_SIZE,
    value: number
}

export interface actionSkillGroupChangePageTable {
    type: ActionSkillGroupType.SKILL_GROUP_CHANGE_PAGE_TABLE,
    value: number
}

export interface actionSkillGroupChangeFilterTable {
    type: ActionSkillGroupType.SKILL_GROUP_CHANGE_FILTER,
    value: {orderBy: number, orderDirection: string}
}

export type TypeActionSkillGroupActions = actionSkillGroupErrorRequired |  
                            actionSkillGroupLoading | actionSkillGroupSuccess | actionSkillGroupError |
                            actionSkillGroupAddLoading | actionSkillGroupAddSuccess | actionSkillGroupAddError |
                            actionSkillGroupEditLoading | actionSkillGroupEditSuccess | actionSkillGroupEditError |
                            actionSkillGroupDeleteLoading | actionSkillGroupDeleteSuccess | actionSkillGroupDeleteError |
                            actionSkillGroupChangeSerachTable | actionSkillGroupChangePageSizeTable | actionSkillGroupChangePageTable |
                            actionSkillGroupChangeFilterTable;