import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import {TypeActions} from '../models/actions/typeActions';

import { getBaseUrlApi, getFetchParams } from './utilsSaga';
import { actionApplyJobLoading, actionApplyQuestionsLoading, actionCheckIfLinkCanBeSharedLoading, actionJobForwaredLoading, actionLinkOpenedLoading, actionPublicJobLoading, ActionPublicJobType, actionSendPasswordBySMSLoading, actionUpdateLinkNetworkerIdLoading } from '../models/actions/typePublicJobActions';
import JobPublicResponseDto from '../models/dto/JobPublicResponseDto';
import LinkOpenedDto from '../models/dto/LinkOpenedDto';
import JobForwaredResponseDto from '../models/dto/JobForwaredResponseDto';
import TypeState from '../models/initialState/typeState';
import TypePublicJobState from '../models/initialState/typePublicJobState';
import { ApplicationJobQuestionsDto } from '../models/dto/ApplicationJobQuestionsDto';

const baseUrlApi:string = getBaseUrlApi();
const urlPublicJob = baseUrlApi + '/api/Job/getJobPublicWeb';
const urlLinkOpened = baseUrlApi + '/api/Job/LinkOpened';
const urlUpdateLinkNetworkerId = baseUrlApi + '/api/Job/UpdateLinkNetworkerIdAuth';
const urlJobForwared = baseUrlApi + '/api/Job/ForwardJobAuth';
const urlRejectJob = baseUrlApi + '/api/Job/RejectJob';
const urlJobForwaredUpdate = baseUrlApi + '/api/Job/ForwardUpdateAuth';
const urlSendPasswordBySMS = baseUrlApi + '/api/Account/RecoverAccountPassword';
const urlApplicationQuestions = baseUrlApi + '/api/Job/GetJobApplicationJobQuestions';
const urlApplicationJob = baseUrlApi + '/api/Job/JobApplication';
const urlCheckIfLinkCanBeShared = baseUrlApi + '/api/Job/CheckIfLinkCanBeShared'

function* publicJob(action: actionPublicJobLoading) {
    try {       
        
        const response:Response = yield call(fetch, urlPublicJob + '?linkId=' + action.value.linkId, getFetchParams('GET', null, null));
        if (response.ok) {
            const data: JobPublicResponseDto= yield call([response, 'json']);
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_SUCCESS, value: data});
                 
        } else {
            const data:string = yield call([response, 'text']);          
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: data});
        }                
                
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: ''});
    }
}

function* linkOpened(action: actionLinkOpenedLoading) {
    try {        
        const response:Response = yield call(fetch, urlLinkOpened , getFetchParams('POST', action.value, null));        
        if (response.ok) {
            const data: LinkOpenedDto= yield call([response, 'json']);
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_SUCCESS, value: data});
                 
        } else {   
            const data:string = yield call([response, 'text']);                   
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_ERROR, value: data});
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: data});
        }     
    } catch(e) {
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_ERROR, value:''});
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: ''});
    }
}

function* updateLinkNetworkerId(action: actionUpdateLinkNetworkerIdLoading) {
    try {
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);
        if (state.activateAccount.data) { 
            const response:Response = yield call(fetch, urlUpdateLinkNetworkerId , getFetchParams('POST', action.value, state.activateAccount.data.token.token));
            if (response.ok) {
                const data:string = yield call([response, 'text']);     
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_SUCCESS, value: parseInt(data)});                 
            } else {
                const data:string = yield call([response, 'text']);       
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR});
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: data});
            }
        }   
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_ERROR});
    }
}

function* jobForwared(action: actionJobForwaredLoading) {
    try {
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);
        if (state.activateAccount.data) { 
            const response:Response = yield call(fetch, urlJobForwared , getFetchParams('POST', action.value, state.activateAccount.data.token.token));
            if (response.ok) {
                const data:JobForwaredResponseDto = yield call([response, 'json']);        
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_SUCCESS, value: data});                 
            } else {                    
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_ERROR});
            }
        }     
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_ERROR});
    }
}

function* jobForwaredUpdate(action: actionJobForwaredLoading) {
    try {
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);
        if (state.activateAccount.data) { 
            const response:Response = yield call(fetch, urlJobForwaredUpdate , getFetchParams('POST', action.value, state.activateAccount.data.token.token));
            if (response.ok) {            
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_SUCCESS});                 
            } else {           
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_ERROR});
            }
        }
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_ERROR});
    }
}

function* rejectJob(action: actionJobForwaredLoading) {
    try {        
        const response:Response = yield call(fetch, urlRejectJob , getFetchParams('POST', action.value, null));
        if (response.ok) {                  
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_SUCCESS});                 
        } else {           
            yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_ERROR});
        }     
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_ERROR});
    }
}

function* sendPasswordBySMS(action: actionSendPasswordBySMSLoading) {
    try {
        const response:Response = yield call(fetch, urlSendPasswordBySMS, getFetchParams('POST', action.value, null));
        if (response.ok) {                       
            yield put<TypeActions>({type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_SUCCESS});                      
        } else {
            yield put<TypeActions>({type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_ERROR});  
        }                
    } catch (e) {             
        yield put<TypeActions>({type: ActionPublicJobType.SEND_PASSWORD_BY_SMS_ERROR});  
    }
}

function* applicationQuestions(action: actionApplyQuestionsLoading) {
    try {        
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);        
        if (state.activateAccount.data) {
            const response:Response = yield call(fetch, urlApplicationQuestions + '?jobId=' + action.value, getFetchParams('GET', null, state.activateAccount.data.token.token));
            if (response.ok) {
                const data:ApplicationJobQuestionsDto[] = yield call([response, 'json']);            
                yield put<TypeActions>({type: ActionPublicJobType.APPLY_QUESTIONS_SUCCESS, value: data});            
            } else {         
                const data:string = yield call([response, 'text']);    
                yield put<TypeActions>({type: ActionPublicJobType.APPLY_QUESTIONS_ERROR, value: data});  
            }    
        }                   
                
    } catch (e){       
        yield put<TypeActions>({type: ActionPublicJobType.APPLY_QUESTIONS_ERROR, value: ''});
    }
}

function* applyJob(action: actionApplyJobLoading) {
    try {
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);
        if (state.activateAccount.data) {
            const response:Response = yield call(fetch, urlApplicationJob, getFetchParams('POST', action.value, state.activateAccount.data.token.token));
            if (response.ok) {                       
                yield put<TypeActions>({type: ActionPublicJobType.APPLY_JOB_SUCCESS});                      
            } else {
                const data:string = yield call([response, 'text']);    
                yield put<TypeActions>({type: ActionPublicJobType.APPLY_JOB_ERROR, value: data});
                // yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: data});
            }
        }
    } catch (e) {             
        yield put<TypeActions>({type: ActionPublicJobType.APPLY_JOB_ERROR, value: ''});
    }
}

function* checkLinkIfCanBeShared(action: actionCheckIfLinkCanBeSharedLoading) {
    try {       
        const state:TypePublicJobState = yield select((state:TypeState) => state.publicJobReducer);
        if (state.activateAccount.data) {
            const response:Response = yield call(fetch, urlCheckIfLinkCanBeShared + '?forwardId=' + action.value.forwardId + '&pushId=' + action.value.pushId, getFetchParams('GET', null, state.activateAccount.data.token.token));
            if (response.ok) {                
                yield put<TypeActions>({type: ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_SUCCESS, value: true});
                    
            } else {
                const data:string = yield call([response, 'text']);          
                yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: data});
            }
        }            
                
    } catch {            
        yield put<TypeActions>({type: ActionPublicJobType.PUBLIC_JOB_ERROR, value: ''});
    }
}

function* publicJobWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_JOB_LOADING, publicJob);
}

function* linkOpenedWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_JOB_LINK_OPENED_LOADING, linkOpened);
}

function* updateLinkNetworkerIdWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_UPDATE_LINK_NETWORKED_ID_LOADING, updateLinkNetworkerId);
}

function* jobForwaredWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_JOB_FORWARED_LOADING, jobForwared);
}

function* rejectJobWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_JOB_REJECT_JOB_LOADING, rejectJob);
}

function* jobForwaredUpdateWhatcher() {
    yield takeLatest(ActionPublicJobType.PUBLIC_JOB_FORWARED_UPDATE_LOADING, jobForwaredUpdate);
}

function* sendPasswordBySMSWhatcher() {
    yield takeLatest(ActionPublicJobType.SEND_PASSWORD_BY_SMS_LOADING, sendPasswordBySMS);
}

function* applicationQuestionsWhatcher() {
    yield takeLatest(ActionPublicJobType.APPLY_QUESTIONS_LOADING, applicationQuestions);
}

function* applyJobWhatcher() {
    yield takeLatest(ActionPublicJobType.APPLY_JOB_LOADING, applyJob);
}

function* checkLinkIfCanBeSharedWhatcher() {
    yield takeLatest(ActionPublicJobType.CHECK_IF_LINK_CAN_BE_SHARED_LOADING, checkLinkIfCanBeShared);
}



export default function* rootPublicJobSaga() {
    yield all([        
        publicJobWhatcher(),
        linkOpenedWhatcher(),
        updateLinkNetworkerIdWhatcher(),
        jobForwaredWhatcher(),
        rejectJobWhatcher(),
        jobForwaredUpdateWhatcher(),
        sendPasswordBySMSWhatcher(),
        applicationQuestionsWhatcher(),
        applyJobWhatcher(),
        checkLinkIfCanBeSharedWhatcher()
    ])
}