import React from 'react';

import { Navigate } from "react-router-dom";
import { ROUTE_LOGIN } from './routes';


import { useSelector } from 'react-redux';
import TypeMainState from '../models/initialState/typeMainState';
import TypeState from '../models/initialState/typeState';

interface Props {    
    children: JSX.Element
}
const PrivateRoute: React.FC<Props> = ({children}) =>{
  const state:TypeMainState = useSelector((state:TypeState) => state.mainReducer);
    
    
    return state.auth ? children : <Navigate to={ROUTE_LOGIN} />;     
  }

export default PrivateRoute;
