import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddBox from '@material-ui/icons/AddBox';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
import { useEffect, useState } from 'react';
import TypeJobsState, { ambassadorPath } from '../../models/initialState/typeJobsState';
import TypeState from '../../models/initialState/typeState';
import { ActionJobsType, TypeActionJobsActions } from '../../models/actions/typeJobsActions';
import AmbassadorWithKpisDto from '../../models/dto/AmbassadorWithKpisDto';
//import './Jobs.scss';

interface Props {
    ambassador: AmbassadorWithKpisDto,
    pushId: number,   
    branch: ambassadorPath,
    index: number,
    page: number
}

function AmbassadorListForPush(props: Props) {  
  
  const { t } = useTranslation();
  const state:TypeJobsState = useSelector((state:TypeState) => state.jobsReducer);
  const dispatch = useDispatch();
  
  const [localBranch, setLocalBranch] = useState<ambassadorPath>(props.branch);
  const [open, setOpen] = useState(false);
  
  useEffect(()=>{
      const auxBranch = localBranch.path.find(elem=> elem.networker === props.ambassador.networkerId);
      if (auxBranch) {
        setLocalBranch(auxBranch);
      }      
  }, [])

  useEffect(()=>{
    /* Si se cambia de rama se cierran todas las ramas */
    setOpen(false);    
}, [props.page])

  const moreAmbassadors = ()=>{    
    if (props.branch.path.length === 0 && props.ambassador.contacts.length > 0) {
        dispatch<TypeActionJobsActions>({type: ActionJobsType.JOBS_AMBASSADOR_CONTACTS_NETWORKER_PUSH_LOADING, value: {
            pushId: props.pushId, 
            networkerId: props.ambassador.networkerId,
            localPath: props.branch.pathId,
            contactLinks: props.ambassador.contacts
        }});        
    }
    setOpen(true);    
  }

  const lessAmbassadors = ()=>{
    setOpen(false);  
  }
     
return <div className='swissinx-job-activity-container'>
<div className='swissinx-push-tree-container'>           
            <div className={`swissinx-push-path ${props.index % 2 === 0 ? 'even' : 'odd'} ${open ? 'open1' : ''} `}>
                
                {/*  <div>Networker - {props.ambassador.networkerId} </div> */}          
                <div className='swissinx-push-path__data-container'>
                    {!open && props.ambassador.contacts.length > 0 && <span><AddBox onClick={moreAmbassadors} /><span className='swissinx-push-path_index'> ({props.ambassador.contacts.length})</span></span>}
                    {open && <div onClick={lessAmbassadors}><IndeterminateCheckBox /> </div>}
                    <div className='swissinx-push-path__name'>{props.ambassador.name} {props.ambassador.surname} </div>
                    <div className='swissinx-push-path__name'>{props.ambassador.phone} - {props.ambassador.email} </div>
                </div>                     
                
                <div className='swissinx-push-path__block-container'>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListShares')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.shares}</span>
                    </div>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListReshares')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.reshares}</span>
                    </div>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListApplies')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.applies}</span>
                    </div>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListViews')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.views}</span>
                    </div>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListNetworkers')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.networkers}</span>
                    </div>
                    <div className='swissinx-push-path__block-data'>
                        <span>{t('admin.jobs.pushListRejects')}</span>
                        <span className='swissinx-push-path__data'>{props.ambassador.rejects}</span>
                    </div>
                </div>
                    
            </div>
            {open && localBranch.path.map((elem, index)=><AmbassadorListForPush key={'ambassador-' + elem.networker}
                pushId={props.pushId} 
                ambassador={elem.data}                
                branch={elem}
                index={index}
                page={props.page}    
            />)}
            {state.historyPush.ambassadorPathwayLoading && <span>{t('admin.jobs.loading')}</span>}
            {state.historyPush.ambassadorPathwayError && <span>{t('admin.jobs.error')}</span>}
        </div>
        </div>; 
}

export default AmbassadorListForPush;