
import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useTranslation } from 'react-i18next';
import {TypeActions} from '../../models/actions/typeActions';
import { useSelector, useDispatch } from 'react-redux';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import TypeState from '../../models/initialState/typeState';
import Notification from '../commons/Notification';
//import './Fee.scss';
import { ActionFeeType } from '../../models/actions/typeFeeActions';
import TypeFeeState from '../../models/initialState/typeFeeState';
import FeeDto from '../../models/dto/FeeDto';
import FeeList from './FeeList';
import FeeEdit from './FeeEdit';
import { FeedDtoToForm } from '../../utils/utils';
import { ActionLanguagesType, TypeActionLanguagesActions } from '../../models/actions/typeLanguagesActions';
import { ActionMarketsType, TypeActionMarketsActions } from '../../models/actions/typeMarketsActions';
import { ActionSkillsType, TypeActionSkillsActions } from '../../models/actions/typeSkillsActions';
import TypeSkillsState from '../../models/initialState/typeSkillsState';
import TypeMarketsState from '../../models/initialState/typeMarketsState';
import TypeLanguagesState from '../../models/initialState/typeLanguagesState';
/*
    Management of the company Groups table
*/

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Fee(props: Props) {
  const [editFee, setEditFee] = useState(false);
  const [showModaldeleteFee, setshowModaldeleteFee] = useState(false);
  const [addFee, setaddFee] = useState(false);  
  const [feeSelected, setFeeSelected] = useState<FeeDto | null>(null);
  
  const { t } = useTranslation();
  const state:TypeFeeState = useSelector((state:TypeState) => state.feeReducer);
  const stateSkills: TypeSkillsState = useSelector((state:TypeState) => state.skillReducer);
  const stateMarkets: TypeMarketsState = useSelector((state:TypeState) => state.marketsReducer);
  const stateLanguages:TypeLanguagesState = useSelector((state:TypeState) => state.languagesReducer); 
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: true}});
      setEditFee(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: true}}); 
    }          
  }, []);

  useEffect(()=>{    
    if(stateLanguages.data.length === 0) {
      dispatch<TypeActionLanguagesActions>({type: ActionLanguagesType.LANGUAGES_LOADING, value: {reset: false}});
    }
    if (stateMarkets.data.length === 0) {
      dispatch<TypeActionMarketsActions>({type: ActionMarketsType.MARKETS_LOADING, value: {reset: false}});
    }
    if (stateSkills.data.length === 0) {
      dispatch<TypeActionSkillsActions>({type: ActionSkillsType.SKILLS_LOADING, value: {reset: false}});
    }        
}, []);

  const edit = (event:SyntheticEvent, index:FeeDto) => {    
    setaddFee(false)
    setFeeSelected(index);
    dispatch<TypeActions>({type: ActionFeeType.FEE_FORM_UPDATE, value: FeedDtoToForm(index)}); 
    setEditFee(true);
  }

  const removeConfirmation = (event:SyntheticEvent, country:FeeDto) => {
    setFeeSelected(country);
    setshowModaldeleteFee(true);
  }

  const remove = () => {
    setshowModaldeleteFee(false);
    if (feeSelected !== null && feeSelected.id !== undefined) {
      dispatch<TypeActions>({type: ActionFeeType.FEE_DELETE_LOADING, value: feeSelected.id});
    }  
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionFeeType.FEE_FORM_NEW});
    setEditFee(true);
    setaddFee(true);
  }  

  const back = () => {
    setEditFee(false);
  }

  const update = () => {
    setEditFee(false);
    const data:FeeDto = {
      id: addFee ? undefined : (feeSelected && feeSelected.id) ? feeSelected.id : undefined,
      description: state.feeForm.description.value as string,
      marketIds: state.feeForm.marketIds.value as number[],
      languageIds: state.feeForm.languageIds.value as number[],
      skillIds: state.feeForm.skillIds.value as number[],
      veryHighFee: parseInt(state.feeForm.veryHighFee.value as string),
      highFee: parseInt(state.feeForm.highFee.value as string),
      lowFee: parseInt(state.feeForm.lowFee.value as string),
      feeCurrency: state.feeForm.feeCurrency.value as string,
      timeToEmbassadors: parseFloat(state.feeForm.timeToEmbassadors.value as string),
      timeToNetwork: parseFloat(state.feeForm.timeToNetwork.value as string),
      autoReplyDeleted: true
    }
    if (addFee) {
      dispatch<TypeActions>({type: ActionFeeType.FEE_ADD_LOADING, value: data});      
    } else {
      dispatch<TypeActions>({type: ActionFeeType.FEE_EDIT_LOADING, value: data});
    }

  }    

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionFeeType.FEE_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionFeeType.FEE_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionFeeType.FEE_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionFeeType.FEE_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <> 
            {state.loading && !state.error && <Loading />}
            {!state.loading && !state.error && !editFee && <FeeList 
                                                              edit={edit} 
                                                              remove={removeConfirmation} 
                                                              newRegister={newRegister}
                                                              changePage={changePage}
                                                              changeSearchText={changeSearchText}
                                                              changeRowsPerPage={changeRowsPerPage}
                                                              onOrderChange={onOrderChange}/>}
            {!state.loading && !state.error && editFee && <FeeEdit update={update} back={back} add={addFee}/>}
           
            {showModaldeleteFee && <ModalConfirmDelete show={showModaldeleteFee} title={t('admin.countries.modal_delete_title')} back={()=>setshowModaldeleteFee(false)} confirm={remove} textButtonBack={t('admin.countries.modal_delete_title_buttonCancel')} textButtonConfirm={t('admin.countries.modal_delete_buttonDelete')} />}
            
            {state.error && <ComponentError
                              title={t('admin.error.errorServerTitle')} 
                              message={t('admin.error.errorServerDescription')} 
                              buttonMessage={t('admin.error.errorServerTextButton')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionFeeType.FEE_LOADING, value: {reset: false}})}/>}
            
            <Notification 
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.fee.editMessage')}
              addMessage={t('admin.fee.addMessage')}
              delteMessage={t('admin.fee.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionFeeType.FEE_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionFeeType.FEE_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionFeeType.FEE_DELETE_SUCCESS, value: false})}/>            
        </>;
  }

export default Fee;
