
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Form from '../Form/Form/Form';
import TypeState from '../../models/initialState/typeState';
import LanguageForm from '../../models/forms/LanguagesForm';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TypeLanguageState from '../../models/initialState/typeLanguagesState';
import LanguageDto from '../../models/dto/LanguageDto';
import FieldTextMui from '../Form/FieldTextMui/FieldTextMui';

interface Props {
  data: LanguageDto,
  back(): void,
  update(language: LanguageDto): void,
  add: boolean
}

interface dataState {
  name: string,
  value: string,
  error: {
    required: boolean,
    format: boolean
  }
}

function LanguagesEdit(props: Props) {
  const state: TypeLanguageState = useSelector((state: TypeState) => state.languagesReducer);
  const languageForm: LanguageForm = state.languagesForm;
  const name = languageForm.name;
  const [stateForm, setStateForm] = useState({ ...languageForm, name: { ...name, value: props.data.name } });
  const { t } = useTranslation();

  const change = (dataState: dataState) => {
    const name = stateForm.name;
    setStateForm({ ...stateForm, name: { ...name, value: dataState.value, error: dataState.error } });
  }

  const errorRequired = (key: string) => {
    setStateForm({ ...stateForm, name: { ...name, value: '', error: { required: true, format: false } } });
  }

  const onSubmit = () => {
    props.update({ languageId: props.data.languageId, name: stateForm.name.value });
  }

  return <div className='swissinx-container'>
    <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
      {props.add ? t('admin.language.title_page_new') : t('admin.language.title_page_edit')}
    </h2>
    {<Form contex={stateForm} errorRequerired={(key) => errorRequired(key)} onSubmit={onSubmit}
      fields={<div className="swissinx-container-fields-form">
        <div className="swissinx-container-fields">
          <div className='swissinx-field-container'>
            <div className="swissinx-field field-name">
              <FieldTextMui
                placeholder=''
                label={t('admin.language.name')}
                name='name'
                required={true}
                requiredError={t('admin.language.nameErrorRequired')}
                value={stateForm.name.value}
                error={stateForm.name.error.required}
                fullWidth={false}
                onChange={change}
              />
            </div>
          </div>
        </div>
        <div className='swissinx-container-buttons'>
          <button type='button' className="swissinx-button-back" onClick={props.back}>{t('admin.language.cancelButtonText')}</button>
          <button type='submit' className="swissinx-button-submit">{props.add ? t('admin.language.addButtonText') : t('admin.language.editButtonText')}</button>
        </div>
      </div>
      }>
    </Form>
    }

  </div>;
}

export default LanguagesEdit;