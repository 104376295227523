export enum ModePushJob {
    MANUAL = 0,
    AUTO = 1
}

export const MANUAL_SEND_PUSH = 'MANUAL';

export const AUTO_SEND_PUSH = 'AUTO'

export interface JobModePush {
    id: ModePushJob,
    name: string
} 

