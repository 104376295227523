export const REJECT_JOB = 0;
export const APPLY_JOB = 1;
export const REFER_BY_WHATSAPP = 2;
export const REFER_BY_MAIL = 3;
export const REFER_BY_SMS = 3;
export const REFER_BY_CLIPBOARD = 5;
export const URL_WHATSAPP = 'https://api.whatsapp.com/send?text=';

export const CUSTOM_TEMPLATE = 'Custom';
export const OPTION_CUSTOM_TEMPLATE = '-1';
export const OPTION_NO_TEMPLATE = '0';

export const APPROVED_APPLICATION_JOB = 1;
export const REJECT_APPLICATION_JOB = 2;
export const ROLE_ADMIN = 0;
export const ROLE_TAADMIN = 1;
export const ROLE_TA = 2;

export const PREFIX_DEFAULT = '+41';

export const COUNTRY_DEFAULT = 6;
export const REGION_DEFAULT = 68;

export const FORWARD_TIME_USED_LIMIT = 10;
export const MAX_SIZE_FILE = 5000000;
export const MAX_SIZE_FILE_MB = 5;
export const LANGUAGE_NUMBER_FORMAT = 'de-CH';
export const MILES_SEPARATOR = /’/g;
export const MANDATORY_CHARACTER = '*';
