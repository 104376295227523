
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Form from '../../Form/Form/Form';
import TypeState from '../../../models/initialState/typeState';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TypeLocationState from '../../../models/initialState/typeLocationState';
import { TypeActions } from '../../../models/actions/typeActions';
import { ActionLocationType } from '../../../models/actions/typeLocationActions';
import FieldTextMui from '../../Form/FieldTextMui/FieldTextMui';

interface Props {
    back(): void,
    update(): void,
    add: boolean
}


function CitiesEdit(props: Props) {
    const { t } = useTranslation();
    const state: TypeLocationState = useSelector((state: TypeState) => state.locationReducer);
    const dispatch = useDispatch();

    const onSubmit = () => {
        props.update();
    }

    return <div className='swissinx-container'>
        <h2><FontAwesomeIcon icon={faArrowLeft} onClick={props.back} />
            {props.add ? t('admin.cities.title_page_new') : t('admin.cities.title_page_edit')}
        </h2>
        {<Form contex={state.cities.citiesForm} errorRequerired={(key) => dispatch<TypeActions>({ type: ActionLocationType.LOCATION_CITIES_ERROR_REQUIRED, value: key })} onSubmit={onSubmit}
            fields={
                <div className="swissinx-container-fields-form">
                    <div className="swissinx-container-fields">
                        <div className='swissinx-field-container'>
                            <div className="swissinx-field field-name">
                                <FieldTextMui
                                    placeholder=''
                                    label={t('admin.cities.description')}
                                    name='name'
                                    required={state.cities.citiesForm.name.rules.required}
                                    requiredError={t('admin.cities.nameErrorRequired')}
                                    value={state.cities.citiesForm.name.value as string}
                                    fullWidth={true}
                                    error={state.cities.citiesForm.name.error.required}
                                    onChange={({ name, value, error }) => dispatch<TypeActions>({ type: ActionLocationType.LOCATION_CITIES_SEND_VALUES, value: { name, value, error } })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='swissinx-container-buttons'>
                        <button type='button' className="swissinx-button-back" onClick={props.back}>{t('admin.cities.cancelButtonText')}</button>
                        <button type='submit' className="swissinx-button-submit">{props.add ? t('admin.cities.addButtonText') : t('admin.cities.editButtonText')}</button>
                    </div>
                </div>
            }>
        </Form>
        }

    </div>;
}

export default CitiesEdit;