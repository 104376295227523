import TypeLocationState from '../../models/initialState/typeLocationState';
import { DEFAULTITEMSPERPAGE, newFormField } from '../../utils/utils';

export const initialLocationState: TypeLocationState = {
    countries: {
        loading: false,
        error: false,
        data: [],
        countriesForm: {
            description: newFormField('',true, false), 
            code: newFormField('',true, false), 
            language: newFormField('',true, false),
            phoneCode: newFormField('',true, false),
        },
        filters: {orderBy: 0, orderDirection: 'asc'},
        initialPage: 0,
        searchText: '',
        pageSize: DEFAULTITEMSPERPAGE,
        edit: false,
        add: false,
        delete: false
    },
    regions: {
        loading: false,
        error: false,
        data: [],
        regionsForm: {name: newFormField('',true, false)},
        filters: {orderBy: 0, orderDirection: 'asc'},
        initialPage: 0,
        searchText: '',
        pageSize: DEFAULTITEMSPERPAGE,
        edit: false,
        add: false,
        delete: false
    },
    cities: {
        loading: false,
        error: false,
        data: [],
        citiesForm: {name: newFormField('',true, false)},
        filters: {orderBy: 0, orderDirection: 'asc'},
        initialPage: 0,
        searchText: '',
        pageSize: DEFAULTITEMSPERPAGE,
        edit: false,
        add: false,
        delete: false
    },
    countriesWithoutPushExceptions: {
        loading: false,
        error: false,
        data: [],
    }

}