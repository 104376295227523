import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SyntheticEvent, useEffect, useState } from 'react';
import ClientDto from '../../models/dto/ClientDto';
import { TypeActions } from '../../models/actions/typeActions';
import { ActionCompanyGroupsType } from '../../models/actions/typeCompanyGroupActions';
import { clientDtoToForm, clientFormToDto } from '../../utils/utils';
import ModalConfirmDelete from '../commons/ModalConfirmDelete';
import ComponentError from '../commons/ComponentError';
import Loading from '../commons/Loading';
import { ActionClientsType } from '../../models/actions/typeClientsActions';
import ClientList from './ClientList';
import ClientEdit from './ClientEdit';
import TypeClientsState from '../../models/initialState/typeClientsState';
import TypeState from '../../models/initialState/typeState';
//import './Client.scss';
import Notification from '../commons/Notification';

interface Props {
  reload: boolean,
  setReload(reload: boolean): void
}

function Client(props: Props) {
  const { t } = useTranslation();  
  const state:TypeClientsState = useSelector((state:TypeState) => state.clientsReducer);
  const dispatch = useDispatch();
  const [clientSelected, setClientSelected] = useState<ClientDto | null>(null);
  const [editClient, setEditClient] = useState(false);
  const [showModalDeleteClient, setShowModalDeleteClient] = useState(false);
  const [addClient, setAddClient] = useState(false);
 
  useEffect(() => {
    if (props.reload === true) {
      dispatch<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
      setEditClient(false);
      props.setReload(false);
    }
  }, [dispatch, props.reload]);

  useEffect(() => {
    if (!props.reload) {
      dispatch<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: true}});
      dispatch<TypeActions>({type: ActionCompanyGroupsType.COMPANY_GROUPS_LOADING, value: {reset: true}});
    }
  }, [dispatch]);

  const edit = (event:SyntheticEvent, index:ClientDto) => {
    setClientSelected(index); 
    const data = clientDtoToForm(index);    
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_FORM_UPDATE, value: data}); 
    setEditClient(true);
    setAddClient(false);
  }

  const removeConfirmation = (event: SyntheticEvent, index: ClientDto)=>{
    setClientSelected(index);   
    setShowModalDeleteClient(true);
  };

  const deleteClient = () => {
    setShowModalDeleteClient(false);
    if (clientSelected !== null && clientSelected.clientId !== undefined) {
      dispatch<TypeActions>({type: ActionClientsType.CLIENTS_DELETE_LOADING, value: clientSelected.clientId});
    }    
  }

  const newRegister = () => {
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_FORM_NEW});     
    setEditClient(true);
    setAddClient(true);
  }

  const update = () => {   
    const data:ClientDto = clientFormToDto(state.clientForm, clientSelected === null ? undefined : clientSelected.clientId);
    if (addClient) {      
      dispatch<TypeActions>({type: ActionClientsType.CLIENTS_ADD_LOADING, value: data});
    } else {
      dispatch<TypeActions>({type: ActionClientsType.CLIENTS_EDIT_LOADING, value: data});
    }
    setEditClient(false);
  }

  const back = () => setEditClient(false);

  const changePage = (page: number, total: number) =>{
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_CHANGE_PAGE_TABLE, value: page});
  }

  const changeSearchText = (searchText: string) =>{
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_CHANGE_SEARCH_TABLE, value: searchText});
  }

  const changeRowsPerPage = (pageSize: number) =>{
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_CHANGE_PAGE_SIZE, value: pageSize});
  }

  const onOrderChange = (orderBy: number, orderDirection: any) => {
    dispatch<TypeActions>({type: ActionClientsType.CLIENTS_CHANGE_FILTER, value: {orderBy, orderDirection}});
  }

  return <>
      {state.loading && !state.error && <Loading />}
      {!state.loading && !state.error && !editClient && <ClientList 
                                                          edit={edit} 
                                                          remove={removeConfirmation} 
                                                          newRegister={newRegister}
                                                          changePage={changePage}
                                                          changeSearchText={changeSearchText}
                                                          changeRowsPerPage={changeRowsPerPage}
                                                          onOrderChange={onOrderChange}/>}
      {!state.loading && !state.error && editClient && <ClientEdit update={update} back={back} add={addClient}/>}
      {state.error && <ComponentError
                              title={t('admin.clients.error_title')} 
                              message={''} 
                              buttonMessage={t('admin.clients.error_button')} 
                              onClick={()=>dispatch<TypeActions>({type: ActionClientsType.CLIENTS_LOADING, value: {reset: false}})}/>}
      {showModalDeleteClient && <ModalConfirmDelete show={showModalDeleteClient} title={t('admin.clients.modal_confirm_delete_title')} back={()=>setShowModalDeleteClient(false)} confirm={deleteClient} textButtonBack={t('admin.clients.modal_confirm_delete_back')} textButtonConfirm={t('admin.clients.modal_confirm_delete_confirm')} />}
      <Notification
              edit={state.edit}
              add={state.add}
              delete={state.delete}
              editMessage={t('admin.clients.editMessage')}
              addMessage={t('admin.clients.addMessage')}
              delteMessage={t('admin.clients.deleteMessage')}
              onCloseEdit={()=>dispatch<TypeActions>({type: ActionClientsType.CLIENTS_EDIT_SUCCESS, value: false})}
              onCloseAdd={()=>dispatch<TypeActions>({type: ActionClientsType.CLIENTS_ADD_SUCCESS, value: false})}
              onCloseDelete={()=>dispatch<TypeActions>({type: ActionClientsType.CLIENTS_DELETE_SUCCESS, value: false})}/> 
  </>
}

export default Client;